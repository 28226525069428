import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Avatar, AvatarGroup } from '@mui/material';
import { Listbox, ListboxButton,ListboxOptions, ListboxOption } from '@headlessui/react';
import { putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { statusOptions } from '../statusOptions';
import toast from 'react-hot-toast';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { isDatePastDue } from '../../utils/DateFormating';

function WOTodoListRow({workOrder, isMobile, selectedOrganization, selectedWorkOrderUuid, getWorkOrders}) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const isDark = useRecoilValue(darkModeState);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState({});
    const [hasRead, setHasRead] = useState(true);

    

    useEffect(() => {
        if(workOrder && workOrder.workorder_uuid ){
            setHasRead(workOrder.has_read)
            if((workOrder.assigned_to_teams && Object.keys(workOrder.assigned_to_teams).length > 0) || (workOrder.assigned_to_users && Object.keys(workOrder.assigned_to_users).length > 0)){
                
                let members = []
                if(workOrder.assigned_to_teams && Object.keys(workOrder.assigned_to_teams).length > 0){
                    workOrder.assigned_to_teams.map(team => {
                        members.push(team)
                    })
                }
                if(workOrder.assigned_to_users && Object.keys(workOrder.assigned_to_users).length > 0){
                    workOrder.assigned_to_users.map(user => {
                        members.push(user)
                    })
                }

                setSelectedMembers(members)
                // console.log('members', members)
                
            }else{
                setSelectedMembers({})
            }
        }

    },[workOrder])

    useEffect(() => {
        // console.log('workOrder', workOrder)
        if(workOrder && workOrder.workorder_uuid){
            if(workOrder.workorder_status){
                let status = statusOptions.find((status) => status.workorder_status_uuid === workOrder.workorder_status.workorder_status_uuid)
                setSelectedStatus(status)
            }
        }

    },[workOrder])

    const handleGetFirstImage = (workOrder, selectedWorkOrderUuid) => {
        if(workOrder.workorder_files && Object.keys(workOrder.workorder_files).length > 0){
            const compressedFile = workOrder.workorder_files.find(obj => obj.file_is_compressed == true);
            // Return the signed URL if found, otherwise null
            // return compressedFile ? `${compressedFile.file_signed_url}` : '/brokenLink.png';
            if(compressedFile){
                
                return (
                    <div className='w-10 h-10'>
                        <img 
                            alt={''}
                            src={compressedFile.file_signed_url}
                            // src='https://iotflows-organizations-files.s3.amazonaws.com/file_de4d3846d3cd32c039b7b9885f95ad11?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT4JNKDY3WT3EFT6V%2F20241223%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241223T204844Z&X-Amz-Expires=86400&X-Amz-Signature=8a2ec5621f6fb8bde0138d749a729e86db56f7993b79a507d7db694185f83a46&X-Amz-SignedHeaders=host'
                            className='object-cover rounded-lg w-10 h-10 shrink-0' 
                        />
                    </div>
                )
            }else{
                return(
                    <div className={`pl-1.5 pr-0.5 py-1 rounded-lg text-gray-500 dark:text-gray-300 ${selectedWorkOrderUuid == workOrder.workorder_uuid ? 'bg-slate-100  dark:bg-slate-800/50': 'bg-slate-200 dark:bg-slate-700'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-8 h-8' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
                            <path  d="m21.04,17.18c-.05-.27-.26-.48-.53-.52-.74-.11-1.25-.8-1.15-1.54.01-.1.04-.2.08-.3.1-.25.02-.54-.19-.72-.45-.38-.97-.68-1.52-.88-.26-.1-.55-.02-.72.2-.4.52-1.11.66-1.68.37-.08-.04-.15-.09-.22-.14-.09-.07-.16-.15-.23-.23-.17-.22-.46-.3-.72-.2-.51.19-.99.46-1.42.8-.22.18-.3.47-.2.74.28.69-.05,1.48-.74,1.76-.12.05-.25.08-.39.09-.28.03-.51.24-.56.52-.06.32-.1.64-.1.97,0,.27.02.54.07.81.01.07.04.14.07.21.1.18.28.31.49.33.74.07,1.29.73,1.22,1.47-.01.15-.05.3-.12.44-.12.26-.05.57.18.75.45.37.96.67,1.51.87.07.02.14.04.22.04.21,0,.4-.1.52-.27.25-.36.66-.58,1.11-.58.43,0,.83.2,1.09.55.17.23.48.32.75.21.5-.2.97-.48,1.38-.83.21-.18.29-.47.19-.73-.29-.69.03-1.48.72-1.77.12-.05.24-.08.36-.1.28-.04.5-.25.54-.53.06-.29.09-.58.09-.88,0-.31-.03-.62-.09-.92Zm-5.1,2.87c-.71,0-1.33-.38-1.67-.95-.18-.29-.28-.64-.28-1,0-.86.56-1.59,1.34-1.85.19-.06.4-.1.61-.1,1.08,0,1.95.87,1.95,1.95s-.87,1.95-1.95,1.95Z"/>
                            <path  d="m15.33,4.52v9.29c-.08-.04-.15-.09-.22-.14-.09-.07-.16-.15-.23-.23-.17-.22-.46-.3-.72-.2-.51.19-.99.46-1.42.8-.22.18-.3.47-.2.74.28.69-.05,1.48-.74,1.76-.12.05-.25.08-.39.09-.28.03-.51.24-.56.52-.06.32-.1.64-.1.97,0,.27.02.54.07.81.01.07.04.14.07.21h-6.75c-.7,0-1.28-.57-1.28-1.28V4.52c0-.7.57-1.28,1.28-1.28h2.82v.79h4.27v-.79h2.82c.7,0,1.28.57,1.28,1.28Z"/>
                            <path  d="m11.24,2.73v1.3h-4.27v-1.3c0-.3.24-.54.54-.54h3.2c.3,0,.54.24.54.54Z"/>
                            <path  d="m9.1.98h0c.42,0,.76.34.76.76v.46h-1.52v-.46c0-.42.34-.76.76-.76Z"/>
                            <g>
                            <g>
                                <line  x1="12.9" y1="7.43" x2="9.24" y2="7.43"/>
                                <polyline  points="7.45 6.43 6.11 7.78 5.61 7.28"/>
                            </g>
                            <g>
                                <line  x1="12.9" y1="11.36" x2="9.24" y2="11.36"/>
                                <polyline  points="7.45 10.36 6.11 11.71 5.61 11.21"/>
                            </g>
                            <g>
                                <line  x1="12.6" y1="15.3" x2="9.24" y2="15.3"/>
                                <polyline  points="7.45 14.29 6.11 15.64 5.61 15.14"/>
                            </g>
                            </g>
                        </svg>
                    </div>
                )
            }
            

        }else {
            return (
                <div className={`pl-1.5 pr-0.5  py-1 rounded-lg text-gray-500 dark:text-gray-300 ${selectedWorkOrderUuid == workOrder.workorder_uuid ? 'bg-slate-100  dark:bg-slate-800/50': 'bg-slate-200 dark:bg-slate-700'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-8 h-8' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
                        <path  d="m21.04,17.18c-.05-.27-.26-.48-.53-.52-.74-.11-1.25-.8-1.15-1.54.01-.1.04-.2.08-.3.1-.25.02-.54-.19-.72-.45-.38-.97-.68-1.52-.88-.26-.1-.55-.02-.72.2-.4.52-1.11.66-1.68.37-.08-.04-.15-.09-.22-.14-.09-.07-.16-.15-.23-.23-.17-.22-.46-.3-.72-.2-.51.19-.99.46-1.42.8-.22.18-.3.47-.2.74.28.69-.05,1.48-.74,1.76-.12.05-.25.08-.39.09-.28.03-.51.24-.56.52-.06.32-.1.64-.1.97,0,.27.02.54.07.81.01.07.04.14.07.21.1.18.28.31.49.33.74.07,1.29.73,1.22,1.47-.01.15-.05.3-.12.44-.12.26-.05.57.18.75.45.37.96.67,1.51.87.07.02.14.04.22.04.21,0,.4-.1.52-.27.25-.36.66-.58,1.11-.58.43,0,.83.2,1.09.55.17.23.48.32.75.21.5-.2.97-.48,1.38-.83.21-.18.29-.47.19-.73-.29-.69.03-1.48.72-1.77.12-.05.24-.08.36-.1.28-.04.5-.25.54-.53.06-.29.09-.58.09-.88,0-.31-.03-.62-.09-.92Zm-5.1,2.87c-.71,0-1.33-.38-1.67-.95-.18-.29-.28-.64-.28-1,0-.86.56-1.59,1.34-1.85.19-.06.4-.1.61-.1,1.08,0,1.95.87,1.95,1.95s-.87,1.95-1.95,1.95Z"/>
                        <path  d="m15.33,4.52v9.29c-.08-.04-.15-.09-.22-.14-.09-.07-.16-.15-.23-.23-.17-.22-.46-.3-.72-.2-.51.19-.99.46-1.42.8-.22.18-.3.47-.2.74.28.69-.05,1.48-.74,1.76-.12.05-.25.08-.39.09-.28.03-.51.24-.56.52-.06.32-.1.64-.1.97,0,.27.02.54.07.81.01.07.04.14.07.21h-6.75c-.7,0-1.28-.57-1.28-1.28V4.52c0-.7.57-1.28,1.28-1.28h2.82v.79h4.27v-.79h2.82c.7,0,1.28.57,1.28,1.28Z"/>
                        <path  d="m11.24,2.73v1.3h-4.27v-1.3c0-.3.24-.54.54-.54h3.2c.3,0,.54.24.54.54Z"/>
                        <path  d="m9.1.98h0c.42,0,.76.34.76.76v.46h-1.52v-.46c0-.42.34-.76.76-.76Z"/>
                        <g>
                        <g>
                            <line  x1="12.9" y1="7.43" x2="9.24" y2="7.43"/>
                            <polyline  points="7.45 6.43 6.11 7.78 5.61 7.28"/>
                        </g>
                        <g>
                            <line  x1="12.9" y1="11.36" x2="9.24" y2="11.36"/>
                            <polyline  points="7.45 10.36 6.11 11.71 5.61 11.21"/>
                        </g>
                        <g>
                            <line  x1="12.6" y1="15.3" x2="9.24" y2="15.3"/>
                            <polyline  points="7.45 14.29 6.11 15.64 5.61 15.14"/>
                        </g>
                        </g>
                    </svg>
                </div>
            )
        }
         
    }

    const navToChat = (workOrder) => {
        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        
        if(isMobile){
            // navigate(`/${selectedOrganization}/work-orders?selected_wo=${workOrder.workorder_uuid}&platform=mobile`)
            setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: workOrder.workorder_uuid, platform: 'mobile'})
        }else{
            // navigate(`/${selectedOrganization}/work-orders?selected_wo=${workOrder.workorder_uuid}`)
            setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: workOrder.workorder_uuid})
        }
   }

   const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus)
    if(!loadingStatus){
        setLoadingStatus(true)
        putData(apiLink + '/v1/workorders/' + workOrder.workorder_uuid , 
        {'workorder_status_uuid': newStatus.workorder_status_uuid})
        .then(async response => {
            const data = await response.json();
            if(response.ok){
                setLoadingStatus(false)
                getWorkOrders()
            }
            else{
                let status = statusOptions.find((status) => status.workorder_status_uuid === workOrder.workorder_status.workorder_status_uuid)
                setSelectedStatus(status)
                setLoadingStatus(false)
                try{
                    toast.error(data.message)
                }catch (e){
                    toast.error(e)
                }
            }
        })
    }
  };


  
  return (
    <div 
        key={workOrder.workorder_uuid} 
        className={`flex flex-col pl-1 pr-2 py-2 cursor-pointer ${selectedWorkOrderUuid == workOrder.workorder_uuid ? 'bg-slate-200 dark:bg-slate-700': ' bg-slate-100 hover:bg-slate-200 dark:bg-slate-800 dark:hover:bg-slate-800/50'} items-center truncate cursor-pointer rounded-lg`}
        onClick={() => navToChat(workOrder)} 
    >
        <div className={`flex w-full items-center truncate `}>
            <div className='w-3 mr-0.5'>
                {!hasRead ? (
                    <span className="flex rounded-full h-2.5 w-2.5 bg-blue-600 dark:bg-blue-400"></span>
                ):(
                    // <span className="flex rounded-full h-2.5 w-2.5 bg-blue-600 dark:bg-blue-400"></span>
                    <div/>
                )}
            </div>
            <div className='flex items-center'>
                {handleGetFirstImage(workOrder, selectedWorkOrderUuid)}
            </div>
            <div className={`flex flex-col truncate w-full pl-2`}>
                <div className='flex justify-between items-center pb-0.5'>
                    <div className='flex items-center space-x-1'>
                        <div className={`${!hasRead ? 'text-gray-700 dark:text-gray-100':'text-gray-500 dark:text-gray-400'} truncate text-sm`}>{`#${workOrder.workorder_number} `}</div>
                        {workOrder.workorder_recurrence_description ? (
                            <div className='text-gray-600 dark:text-gray-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960"  fill="currentColor" >
                                    <path xmlns="http://www.w3.org/2000/svg" d="m274-200 34 34q12 12 11.5 28T308-110q-12 12-28.5 12.5T251-109L148-212q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l103-103q12-12 28.5-11.5T308-370q11 12 11.5 28T308-314l-34 34h406v-120q0-17 11.5-28.5T720-440q17 0 28.5 11.5T760-400v120q0 33-23.5 56.5T680-200H274Zm412-480H280v120q0 17-11.5 28.5T240-520q-17 0-28.5-11.5T200-560v-120q0-33 23.5-56.5T280-760h406l-34-34q-12-12-11.5-28t11.5-28q12-12 28.5-12.5T709-851l103 103q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L709-589q-12 12-28.5 11.5T652-590q-11-12-11.5-28t11.5-28l34-34Z"/>
                                </svg>
                            </div>
                        ):(
                            <div/>
                        )}

                    </div>
                    <div>
                        {AssignedCell(isDark, selectedMembers)}
                    </div>
                </div>
                <div className='flex justify-between items-center pb-0.5'>
                    <div className={`${!hasRead ? 'text-gray-800 dark:text-white':'text-gray-600 dark:text-gray-200'} truncate text-sm font-medium`}>{`${workOrder.workorder_title}`}</div>
                    {isDatePastDue(workOrder.workorder_due_timestamp) && (
                        <div className={`text-red-500 ml-auto mr-1 rounded-full`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </div>
                    )}
                    {workOrder && workOrder.workorder_priority && workOrder.workorder_priority.workorder_priority_name != 'None' &&
                        <div className=''>
                            <div  className={`${workOrder.workorder_priority.workorder_priority_icon_color ? workOrder.workorder_priority.workorder_priority_icon_color : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960"  fill="currentColor" >
                                <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                            </svg>
                        </div>
                        </div>
                    }
                </div>
                <div className='flex justify-between items-center '>
                    <div className={`text-gray-500 dark:text-gray-400 truncate text-sm`}>Requested by {workOrder.created_by_user?.user_first_name}</div>
                    <div className={`flex w-fit items-center py-0.5 pl-2 pr-2 border-[0.01em] border-gray-400 dark:border-gray-500 rounded-full relative`}>
                        <span className={`mr-1 ${isDark ? `${selectedStatus.icon_only_dark_color}`:`${selectedStatus.icon_only_light_color}`} `}>{selectedStatus.icon}</span>
                        <span className='truncate text-sm text-gray-600 dark:text-gray-300'>{selectedStatus.workorder_status_name}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WOTodoListRow


const AssignedCell = (isDark, selectedMembers) => {
    let membersList = []
    if (selectedMembers && Object.keys(selectedMembers).length > 0) {
        selectedMembers.map(value => {
            if(value && value.user_username){
                let name = `${value.user_first_name ? value.user_first_name:''} ${value.user_last_name ? value.user_last_name:''}`
                    membersList.push({
                        user_color: value.user_color ? value.user_color:'',
                        name: name,
                        image_url: value.user_image_url ? value.user_image_url : '/broken-image.jpg',
                    })
                }else if(value && value.team_organization_handle){
                    membersList.push({
                        user_color: value.user_color ? value.user_color:'',
                        name: value.team_name ? value.team_name:'',
                        image_url: '/broken-image.jpg',
                    })
                }
        })
    
          return(
            <div className='flex ml-auto items-center'>
                <AvatarGroup max={3} spacing={5} sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(203, 213, 225, 0.1)', width:20, height:20, fontSize:'11px' } }} >
                    {membersList.map((member,index) =>(
                        <Avatar
                            key={`avatar-group-${index}`}
                            sx={{ 
                                bgcolor: member.user_color? member.user_color : '' , 
                                fontSize:'11px',
                                width:20, 
                                height:20,
                            }}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                            }}
                            alt={member.name}
                            src={member.image_url ? member.image_url :'/broken-image.jpg'}
                        />
                    ))}
                </AvatarGroup>
    
            </div>
        )
      }


}