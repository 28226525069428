import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import {Resizable} from 'react-resizable';
import { motion, AnimatePresence } from "framer-motion";
import SelectedWorkOrderPage from './SelectedWorkOrderPage';
import WOTodoList from './toDoViewComponents/WOTodoList';
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../atoms/darkModeState';

function WOToDoView({isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage}) {
    const isDark = useRecoilValue(darkModeState)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const location = useLocation();
    const [leftColumnWidth, setLeftColumnWidth] = useState(() => {
        return Number(localStorage.getItem('todoViewWidth')) || 500;
      });
    const minWidth = 300;
    const maxWidth = 550;

    useEffect(() => {
        let selectedWorkOrder = searchParams.get('selected_wo')
        setSelectedWorkOrderUuid(selectedWorkOrder)
      },[location])

      const handleResize = (event, { size }) => {
        const newWidth = Math.max(minWidth, Math.min(maxWidth, size.width));
        setLeftColumnWidth(newWidth);
        localStorage.setItem('todoViewWidth', newWidth);
      };


  return (
    <div className={`${isMobile ? 'h-[calc(100vh-0.5rem)]' : 'h-[calc(100vh-11.7rem)]'}  rounded-xl border-[1px] border-dashed border-slate-200 dark:border-slate-800`} style={{display: 'grid', gridTemplateColumns: `${leftColumnWidth}px auto`}} >
            <Resizable
              width={leftColumnWidth}
              height={200} // I could add 100% since its not a number, put 200 to see what happens
              // minWidth={minWidth}
              // maxWidth={maxWidth}
              // axis='x'
              onResize={handleResize}
            >
                <div>
                    <WOTodoList workOrdersList={workOrdersList} selectedOrganization={selectedOrganization} selectedWorkOrderUuid={selectedWorkOrderUuid} isMobile={isMobile} getWorkOrders={getWorkOrders} workOrdersDoneList={workOrdersDoneList} />
                </div>
            </Resizable>
              <AnimatePresence mode='wait'>
                {selectedWorkOrderUuid ?(
                  <motion.div
                        key='WODetailsSection'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`p-2 overflow-scroll	message_window_scrollbar ${isDark ? 'scrollbar_dark':'scrollbar_light'}`}

                    >
                    <SelectedWorkOrderPage userInfo={userInfo} mqttMessage={mqttMessage} />
                  </motion.div>
                ):(
                    <div className='mx-auto mt-20'>
                        <div className='px-4 py-2 bg-slate-100 dark:bg-slate-700 text-gray-600 dark:text-gray-300 rounded-xl'>
                            Select a work order to view its details.
                        </div>
                    </div>
                )}
              </AnimatePresence>
        </div>
  )
}

export default WOToDoView