import { Switch } from "@mui/material";
import { LightTooltip } from "../../../styles/Tooltip"
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

function ExcludeNoneSwitch() {
    const [toggleExcludeNone, setToggleExcludeNone] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();




   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch ){
            let exclude = searchParams.get('exclude_none_in_oee')
            if(exclude == 'true'){
                setToggleExcludeNone(true)
            }else{
                setToggleExcludeNone(false)
            }
        }

    }, [location])

    const handleApplyFilter = (e) => {
        setToggleExcludeNone(e.target.checked)

        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_by_min = searchParams.getAll('shift_by_min')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let sort_by_advanced = searchParams.getAll('sort_by_advanced')
        let to = searchParams.getAll('to')
        let from = searchParams.getAll('from')
        let machines = searchParams.getAll('machines')
        let time_period = searchParams.getAll('time_period')
        let show_percentage = searchParams.getAll('show_percentage')
        let group_by_advanced = searchParams.getAll('group_by_advanced')
        let operations = searchParams.getAll('operations')
        let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')

        setSearchParams({select,parts, departments, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_by_min, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,group_by_advanced, sort_by_advanced, to, from, machines, time_period, show_percentage, exclude_none_in_oee: e.target.checked, operations, exclude_unknown_in_oee}) 
        
    

}
    

  return (
    <div className=''>
        <LightTooltip title='Exclude None will remove any downtime under the None severity from the OEE calculation, such as breaks, no shift, etc...'>
            <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                <p className='text-gray-600 dark:text-gray-400 text-sm truncate'>Exclude None</p>
                <Switch
                    size="small"
                    color="secondary"
                    checked={toggleExcludeNone}
                    onChange={handleApplyFilter}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>
        </LightTooltip>
    </div>
  )
}

export default ExcludeNoneSwitch