import React, { useEffect, useRef, useState } from 'react'
import { convertTo24HourFormat, formatCalendarDate, formatCalendarTimeWithExactTime, isDatePastDue, timeOptions } from '../../utils/DateFormating'
import { LightTooltip } from '../../styles/Tooltip'
import { Calendar } from 'react-date-range'
import { motion, AnimatePresence } from "framer-motion";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { tzStrings } from '../../data/TimezonesList';
import { globalSelectedOrganizationTimezone } from '../../atoms/orgStates';
import { convertToISO, getLocalTimezone } from '../../utils/TimezoneFormatting';

function DueDateComponent({selectedWorkOrder, getSelectedWorkOrder}) {
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const isDark = useRecoilValue(darkModeState);
    const [isEditingDate, setIsEditingDate] = useState(false)
    const [showDueDateCalendar, setShowDueDateCalendar] = useState(false)
    const [dueDateTimestamp, setDueDateTimestamp] = useState(null)
    const [dueDate, setDueDate] = useState(null)
    const [dueDateTime, setDueDateTime] = useState(null)
    const dueDateDropdownRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [selectedTimezoneOption, setSelectedTimezoneOption] = useState("local");
    const [customTimezone, setCustomTimezone] = useState("");
    const [localTimezone, setLocalTimezone] = useState([]);


    useEffect(() => {
        
        const localTz = getLocalTimezone();
        setLocalTimezone(localTz)


        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if(selectedWorkOrder.workorder_due_timestamp){
                setDueDateTimestamp(selectedWorkOrder.workorder_due_timestamp)
    
                // Parse the date for Calendar
                const dateForCalendar = new Date(selectedWorkOrder.workorder_due_timestamp);
                setDueDate(dateForCalendar)
    
                // Extract local time details
                const hours = dateForCalendar.getHours();
                const minutes = dateForCalendar.getMinutes();
    
                // Find matching time option index
                const timeOptionIndex = (hours * 2) + (minutes === 0 ? 0 : 1);
                const matchingTimeOption = timeOptions[timeOptionIndex];
                setDueDateTime(matchingTimeOption)
            }else{
                setDueDateTimestamp(null)
                setDueDate(null)
                setDueDateTime(null)
            }
        }

    },[selectedWorkOrder])

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dueDateDropdownRef.current && !dueDateDropdownRef.current.contains(event.target)) {
            setShowDueDateCalendar(false)
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }, [])


    const handleSelectDueDate = (selectedDate) => {
        setDueDate(selectedDate)
        setShowDueDateCalendar(false)
        // checking to mae sure the due date time isn't empty 
        if(dueDateTime === null){
            setDueDateTime('9:00 AM')
        }
      }

    const handleDueDateTimeChange = (newTime) => {
        setDueDateTime(newTime)
    }


    const handleCancelDueDate = () => {
        setIsEditingDate(false)
        if(selectedWorkOrder.workorder_due_timestamp){
            const dateForCalendar = new Date(selectedWorkOrder.workorder_due_timestamp);
            setDueDate(dateForCalendar)
    
            // Extract local time details
            const hours = dateForCalendar.getHours();
            const minutes = dateForCalendar.getMinutes();
    
            // Find matching time option index
            const timeOptionIndex = (hours * 2) + (minutes === 0 ? 0 : 1);
            const matchingTimeOption = timeOptions[timeOptionIndex];
            setDueDateTime(matchingTimeOption)
        }else{
            setDueDate(null)
            setDueDateTime(null)
        }

    }

    const handleOptionChange = (e) => {
        setSelectedTimezoneOption(e.target.value);
        if (e.target.value !== "custom") {
          setCustomTimezone("");
        }
      };
    
      const handleCustomTimezoneChange = (e) => {
        setCustomTimezone(e.target.value);
      };



    const handleUpdateDueDate = () => {

        if(!loading){
            setLoading(true)
            let due_date = ''
            let due_date_time = ''
            let due_date_iso = ''
            if(dueDate){
                const originalDate = new Date(dueDate);
                // due_date = originalDate.toISOString().split('T')[0];
                // due_date_time = convertTo24HourFormat(dueDateTime)

                let shortDate = originalDate.toISOString().split('T')[0];
                let dateTime = convertTo24HourFormat(dueDateTime)
                let timezone = localTimezone?.value
                if(selectedTimezoneOption === 'organization'){
                    timezone = selectedOrganizationTimeZone?.name
                }else if(selectedTimezoneOption === 'custom'){
                    console.log('customTimezone', customTimezone)
                    timezone = customTimezone
                }
                let isoDate = convertToISO(shortDate, dateTime, timezone)
                due_date_iso = isoDate
            }
            
            console.log('due_date_iso', due_date_iso)
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            {
                // "workorder_due_date": due_date,
                // "workorder_due_time": due_date_time,
                "workorder_due_timestamptz": due_date_iso,
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoading(false)
                    setIsEditingDate(false)
                    if(typeof getSelectedWorkOrder === 'function') {
                        getSelectedWorkOrder();
                    }
                    if(data && data.data && data.data[0] ){
                        setDueDateTimestamp(data.data[0].workorder_due_timestamp)
                    }else{
                        toast.error('Issues updating due date')
                        setDueDateTimestamp(selectedWorkOrder.workorder_due_timestamp)
                        const dateForCalendar = new Date(selectedWorkOrder.workorder_due_timestamp);
                        setDueDate(dateForCalendar)
            
                        // Extract local time details
                        const hours = dateForCalendar.getHours();
                        const minutes = dateForCalendar.getMinutes();
            
                        // Find matching time option index
                        const timeOptionIndex = (hours * 2) + (minutes === 0 ? 0 : 1);
                        const matchingTimeOption = timeOptions[timeOptionIndex];
                        setDueDateTime(matchingTimeOption)
                    }
                }
                else{
                    setDueDateTimestamp(selectedWorkOrder.workorder_due_timestamp)
                    // Parse the date for Calendar
                    const dateForCalendar = new Date(selectedWorkOrder.workorder_due_timestamp);
                    setDueDate(dateForCalendar)
        
                    // Extract local time details
                    const hours = dateForCalendar.getHours();
                    const minutes = dateForCalendar.getMinutes();
        
                    // Find matching time option index
                    const timeOptionIndex = (hours * 2) + (minutes === 0 ? 0 : 1);
                    const matchingTimeOption = timeOptions[timeOptionIndex];
                    setDueDateTime(matchingTimeOption)

                    setLoading(false)
                    setIsEditingDate(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }

    }

    

  return (
    <div>
        <div className='flex flex-col group px-2'>
            <div className={`flex transition-all duration-500 ${!isEditingDate ? "justify-center" : "justify-start"} text-gray-800 dark:text-white mb-2 relative'`}>
                <div className='relative'>
                    <span className='font-medium'>Due Date</span>
                    {!isEditingDate && (
                        <LightTooltip title='Edit due date'>
                            <button 
                                className={`absolute right-[-20px] top-1 text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                                onClick={() => setIsEditingDate(true)}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>

                            </button>
                        </LightTooltip>
                    )}

                </div>
                {isEditingDate ?(
                    <div className='flex ml-1 space-x-2'>
                        <div className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} onClick={handleUpdateDueDate}>
                            Save
                        </div>
                        <div 
                            className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                            onClick={handleCancelDueDate}>
                            Cancel
                        </div>
                    </div>
                ):(
                    <div/>

                )}
            </div>
            {isEditingDate ? (
                <div className='relative'>
                    <div className='flex items-center'>
                        <motion.button  
                        // whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                            className={`w-44 items-center flex justify-between pl-3 pr-2 py-1.5 text-sm font-normal ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'}  bg-clip-padding bg-no-repeat  rounded shadow-iotflows-lg`} 
                            onClick={() => setShowDueDateCalendar(!showDueDateCalendar)}
                        >
                            <span className='text-sm tracking-wider'>{formatCalendarDate(dueDate)}</span>
                        </motion.button>
                        {dueDate && (
                        <div className='flex'>
                            <motion.div
                            key='selectorDueTimeContainer' 
                            className={`flex ml-2`}
                            >
                            <select
                                value={dueDateTime}
                                onChange={(e) => handleDueDateTimeChange(e.target.value)}
                                className={`pr-10 py-1.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                            >
                                {timeOptions.map(t => (
                                <option key={t} value={t}>{t}</option>
                                ))}
                            </select>
                            </motion.div>
                            <motion.button  
                                whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                                className={`ml-2 ${isDark?'text-gray-400':'text-gray-600'} `} 
                                onClick={(e) => {
                                setDueDate(null)
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </motion.button>
                        </div>
                        )}
                    </div>
                    <div className="pt-2 relative" >
                        <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} >
                            Time Zone
                        </label>
                        <div className='flex space-x-2 items-center'>
                            <div >
                            <select
                                className={`pr-10 py-1.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                value={selectedTimezoneOption}
                                onChange={handleOptionChange}
                            >
                                <option value="local">Local Timezone</option>
                                <option value="organization">Organization Timezone</option>
                                <option value="custom">Custom Timezone</option>
                            </select>
                            </div>
                            <div className={`${isDark ? 'text-gray-300 ' : 'text-gray-600'} text-sm items-center`}>
                            {selectedTimezoneOption === 'local' && (
                                <span>{localTimezone?.label}</span>
                            )}
                            {selectedTimezoneOption === 'organization' && (
                                <span>{selectedOrganizationTimeZone?.full_name}</span>
                            )}
                            {selectedTimezoneOption === 'custom' && (
                                <div className="outline-none">
                                    <select onChange={handleCustomTimezoneChange} value={customTimezone}  aria-label="timezone-select" 
                                    className={`pr-2 py-1.5 text-sm font-normal border-[0px] rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                    >
                                        <option disabled value=''> -- select a timezone -- </option>
                                        {tzStrings.map((tz, index) => (
                                            <option key={`${tz.label}`} value={tz.value}>{tz.label}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                    {loading && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 left-1/4 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                    {showDueDateCalendar && (
                        <div ref={dueDateDropdownRef} className={`absolute z-20 left-0 top-10 flex flex-col bg-slate-700 rounded-xl max-h-[450px] overflow-y-scroll md:max-h-[600px] `}>
                        <div className='customWrapper pr-2'>
                            <Calendar
                                date={dueDate}
                                onChange={handleSelectDueDate}
                                color="#3182ce"  // Customizable color
                            />
                        </div>
                    </div>
                    )}
                </div>

            ):(
                <div className={`${isDatePastDue(dueDateTimestamp) ? 'text-red-600 dark:text-red-200':'text-gray-600 dark:text-gray-300'} text-lg truncate justify-center`}>
                    {dueDateTimestamp ? (
                        formatCalendarTimeWithExactTime(dueDateTimestamp)
                    ):(
                        <button
                            onClick={() => setIsEditingDate(true)} 
                            className='text-blue-600 dark:text-blue-400 italic text-sm'>
                            Add due date
                        </button>
                    )}
                </div>
            )}
        </div>
    </div>
  )
}

export default DueDateComponent