import React, { useEffect, useState } from 'react'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { LightTooltip } from '../../styles/Tooltip';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData, putData } from '../../ApiCalls/DataApis';
import toast from 'react-hot-toast';

function EstimatedTimeComponent({selectedWorkOrder}) {
    const [isEditing, setIsEditing] = useState(false)   
    const isDark = useRecoilValue(darkModeState)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [totalCycleTime, setTotalCycleTime] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if(selectedWorkOrder.workorder_duration_min && selectedWorkOrder.workorder_duration_min > 0 ){
                convertMinutesToTime(selectedWorkOrder.workorder_duration_min)
                setTotalCycleTime(selectedWorkOrder.workorder_duration_min)
            }else{
                convertMinutesToTime(0)
                setTotalCycleTime(0)
            }
        }

    },[selectedWorkOrder])

    const convertMinutesToTime = (mins) => {  
        let hours = Math.floor(parseFloat(mins) / 60);
        let minutes = Math.floor(parseFloat(mins) % 60);

        setHours(hours)
        setMinutes(minutes)
    }

    

    const handleCancel = () => {
        setIsEditing(false)
    }

    const updateCycleTimes = (e, selectedInput) => {
        var hours_to_min = 0
        if(selectedInput === 'operation_cycle_time_h'){
            if(e.target.value){
                // const newValue = e.target.value.slice(0, 1) // Only allow 1 character
                const newValue = e.target.value
                setHours(parseInt(newValue))
                if(parseInt(e.target.value) >= 0){
                    hours_to_min = parseInt(newValue) * 60
                }
            }else{
                setHours('')
            }
        }else {
            if(hours){
                hours_to_min = hours * 60
            }
        }

        var mins = 0
        if(selectedInput === 'operation_cycle_time_m'){
            // console.log('e.target.value)', e.target.value)
            if(e.target.value){
                const newValue = parseInt(e.target.value.slice(0, 2)) // Only allow 2 character
                if(newValue > 59){
                    setMinutes(59)
                }else{
                    setMinutes(newValue)
                }
                if(newValue > 0 && newValue < 60){
                    mins = newValue
                }
            }else{
                setMinutes('')
                mins = 0
            }
        }else {
            if(minutes){
                mins = minutes
            }
        }



       


        // console.log('hours_to_min', hours_to_min)
        // console.log('min_to_s', min_to_s)
        // console.log('secs', secs)
        // console.log('milliSecs', milliSecs)
        let totalTime =  hours_to_min + mins
        // console.log('totalTime', totalTime)
        setTotalCycleTime(totalTime)    
    }

    const handleUpdate = () => {

        if(!loading){
            setLoading(true)
            setIsEditing(false)
            let duration = null
            if(totalCycleTime && totalCycleTime > 0){
                duration = totalCycleTime
            }

            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            {  "workorder_duration_min": duration})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoading(false)
                    if(data && data.data){
                        // setWorkOrderPriority(selectedPriority)
                    }else{
                        toast.error('Issues updating priority')
                        if(selectedWorkOrder.workorder_duration_min && selectedWorkOrder.workorder_duration_min > 0 ){
                            convertMinutesToTime(selectedWorkOrder.workorder_duration_min)
                            setTotalCycleTime(selectedWorkOrder.workorder_duration_min)
                        }else{
                            convertMinutesToTime(0)
                            setTotalCycleTime(0)
                        }
                    }
                }
                else{
                    setLoading(false)
                    if(selectedWorkOrder.workorder_duration_min && selectedWorkOrder.workorder_duration_min > 0 ){
                        convertMinutesToTime(selectedWorkOrder.workorder_duration_min)
                        setTotalCycleTime(selectedWorkOrder.workorder_duration_min)
                    }else{
                        convertMinutesToTime(0)
                        setTotalCycleTime(0)
                    }
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }

    }


  return (
    <div>
        <div className='flex flex-col group'>
            <div className={`flex transition-all duration-500 ${!isEditing ? "justify-center" : "justify-start"}  text-gray-800 dark:text-white mb-2`}>
                <div className='relative'>
                    <span className='font-medium'>Estimated Time</span>
                    {!isEditing && (
                        <LightTooltip title='Edit Estimated Time'>
                            <button 
                                className={`absolute right-[-20px] top-1 text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                                onClick={() => setIsEditing(true)}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>

                            </button>
                        </LightTooltip>
                    )}

                </div>
                {isEditing ? (
                    <div className='flex ml-1 space-x-2'>
                        <div 
                            className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} 
                            onClick={handleUpdate}
                        >
                            Save
                        </div>
                        <div 
                            className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                            onClick={handleCancel}
                        >
                            Cancel
                        </div>
                    </div>
                ):(
                   <div/>
                )}
            </div>
            {isEditing ? (
                <div className='flex items-center' >
                    <div className='flex items-center' >
                    {/* <div className='flex items-center' onBlur={handleInputsBlur}> */}
                        <div className='flex flex-col w-[50px] text-center'>
                            <input 
                                className={`text-center text-sm block bg-transparent w-full border-1 focus:ring-1 ${isDark ? ' placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600  border-gray-300 focus:border-blue-300 focus:ring-blue-300'} rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent `} 
                                // className={`text-center text-sm block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                min={0}
                                placeholder={0}
                                type={'number'}
                                name={'operation_cycle_time_h'}
                                value={hours}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_h')}
                            />
                            <div className={`text-sm ${isDark?'text-gray-400':'text-gray-500'} `}>
                                <span>{`hr`}</span>
                            </div>
                        </div>
                        <div className={`flex flex-col text-xl mx-1 ${isDark?'text-gray-400':'text-gray-500'}`}>
                            :
                            <div className={`h-6 text-xs ${isDark?'text-gray-400':'text-gray-500'} `}>
                                <span>{``}</span>
                            </div>
                        </div>
                        <div className='flex flex-col w-[50px] text-center'>
                            <input 
                                className={`text-center text-sm block bg-transparent w-full border-1 focus:ring-1 ${isDark ? ' placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600  border-gray-300 focus:border-blue-300 focus:ring-blue-300'} rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent `} 
                                min={0}
                                max={59}
                                placeholder={`00`}
                                type={'number'}
                                name={'operation_cycle_time_m'}
                                value={minutes}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_m')}
                            />
                            <div className={`text-sm ${isDark?'text-gray-400':'text-gray-500'} `}>
                                <span>{`min`}</span>
                            </div>
                        </div>
                    </div>
                </div>

            ):(
                <div className='relative flex justify-center  space-x-2 items-center'>
                    {totalCycleTime && totalCycleTime > 0 ?(
                        <span className='text-gray-600 dark:text-gray-300 text-lg text-center'>
                            {hours && hours > 0 ? (
                                <span className='mr-2'>
                                    {hours}<span className='text-lg pl-0.5'>{`hr${hours > 1 ? 's':''}`}</span>
                                </span>
                            ):''}
                            {minutes && minutes > 0 ? (
                                <span className=''>
                                    {minutes}<span className='text-lg pl-0.5'>{`min${minutes > 1 ? 's':''}`}</span>
                                </span>
                            ):''}
                        </span>
                    ):(
                        <button
                            onClick={() => setIsEditing(true)} 
                            className='text-blue-600 dark:text-blue-400 italic text-sm'>
                            Add estimated time
                        </button>
                    )}
                    
                    {loading && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 left-1/2 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </div>
            )}
        </div>
    </div>
  )
}

export default EstimatedTimeComponent