import {PencilIcon} from '@heroicons/react/solid'
import { useState, Fragment } from 'react'
import { useEffect } from 'react'
import ImageZoomCrop from '../components/modals/ImageZoomCrop'
import { deleteData, putData } from '../ApiCalls/DataApis'
import toast from 'react-hot-toast';
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import {LightTooltip, MemberTooltip} from '../styles/Tooltip'
import Zoom from '@mui/material/Zoom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import WorkShiftInputs from '../components/forms/WorkShiftInputs'
import {tzStrings} from '../data/TimezonesList'
import { getData } from "../ApiCalls/DataApis"
import { apiLink } from '../ApiCalls/ApisVariables'
import moment from 'moment';
import OrganizationHandleRow from './OrgPageComponents/OrganizationHandleRow'
import { darkModeState } from '../atoms/darkModeState'
import AutoDowntimeClassificationSection from './OrgPageComponents/AutoDowntimeClassificationSection'


function OrgPage({user,  updateOrganizationsList}) {
    const isDark = useRecoilValue(darkModeState)
    const [showGODview, setShowGODview] = useState(false)
    const [loadingOrgInfo, setLoadingOrgInfo] = useState(false)
    const [editOrg, setEditOrg] = useState(false)
    const [orgInfo, setOrgInfo] = useState([])
    const [orgName, setOrgName] = useState('')
    const [orgDescription, setOrgDescription] = useState('')
    const [orgHandle, setOrgHandle] = useState('')
    const [orgImage, setOrgImage] = useState(null)
    const [editSchedules, setEditSchedules] = useState(false)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loadingDeleteOrg, setLoadingDeleteOrg] = useState(false)
    const [loadingLeaveOrg, setLoadingLeaveOrg] = useState(false)
    const [loadingWorkShifts, setLoadingWorkShifts] = useState(false)
    const [deleteOrg, setDeleteOrg] = useState(false)
    const [leaveOrg, setLeaveOrg] = useState(false)
    const [selectedTimezone, setSelectedTimezone] = useState('')
    const [tempTimezone, setTempTimezone] = useState('')
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [workShiftList, setWorkShiftList] = useState([
        {
          shift_name:'',
          shift_days:[],
          shift_start_time:new Date().setHours(8, 0, 0, 0),
          shift_end_time:new Date().setHours(17, 0, 0, 0),
        }
      ]
    )
    const [currentShifts, setCurrentShifts] = useState([])
    const [isMobile, setIsMobile] = useState(false)
    const [shippingInfo, setShippingInfo] = useState([])

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[])

    useEffect(() => {
        // Determining if user had GOD view access
        getData(apiLink + '/v1/users/iotflows')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
              setShowGODview(true)
            } 
          }
        });
  
      }, [])




    useEffect(() => {
        if(selectedOrganization ){
          getData(apiLink + '/v1/organizations/' + selectedOrganization)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    setOrgInfo(data.data[0])
                    setOrgName(data.data[0].organization_name)
                    setOrgDescription(data.data[0].organization_description)
                    setOrgHandle(data.data[0].organization_handle)
                    setOrgImage(data.data[0].organization_logo_url)
                }
                }
                
            });
          getData(apiLink + '/v1/organizations/' + selectedOrganization + '/addresses')
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    setShippingInfo(data.data[0])
                }
                }
                
            });
        }
        
      },[selectedOrganization])

    useEffect(() => {
        if(selectedOrganization){
          getShifts()
        }
        
      },[selectedOrganization,])
    
    const getShifts = () => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        setCurrentShifts(data.data)
                        setWorkShiftList(data.data)
                        if(data.data[0]){
                            setSelectedTimezone(data.data[0].organization_time_zone)
                            setTempTimezone(data.data[0].organization_time_zone)
                        }
                    }
                }
            })    
        }
    }



    // ********** Functions for Work Shift Inputs **********
  let handleChangeWorkShiftInput = (i, e, id, data) => {
    let newWorkShiftList = [...workShiftList];
    
    if(id && id === 'day-checkbox') {
      newWorkShiftList[i]['shift_days'] = data
    }else if(id && id === 'shift_times') {
        newWorkShiftList[i]['shift_start_time'] = data.shift_start_time
        newWorkShiftList[i]['shift_end_time'] = data.shift_end_time
    }else{
      // works for parts amount
      newWorkShiftList[i][e.target.name] = e.target.value
    }

    
    setWorkShiftList(newWorkShiftList);
  }
    
  let addWorkShiftInputFormFields = () => {
      setWorkShiftList([...workShiftList, {shift_name:'', shift_days:[], shift_start_time:new Date().setHours(8, 0, 0, 0), shift_end_time:new Date().setHours(17, 0, 0, 0),}])
  }

  let removeWorkShiftInputs = (i) => {
      let newWorkShiftList = [...workShiftList]
      newWorkShiftList.splice(i, 1)
      setWorkShiftList(newWorkShiftList)
  }

  // ********** Functions for Work Shift Inputs **********


  const convertToTime =(time) => {
    if(time){
        let date = new Date(time);
        let hoursMinutes = date.toLocaleTimeString('default', {
            hour: '2-digit',
            minute: '2-digit',
          })
        return hoursMinutes
    }
    
  }


  const updateOrgInfo = () => {
    try {
        if(!loadingOrgInfo){
            setLoadingOrgInfo(true)
            putData(apiLink + '/v1/organizations/' + selectedOrganization, 
            {'organization_name': orgName,
            'organization_description': orgDescription,
            'organization_handle': orgHandle,
            'organization_is_private': true})
            .then(async response => {
                if(response.ok){
                toast.success('Organization modified')
                setLoadingOrgInfo(false)
                updateOrganizationsList()
                setEditOrg(false)
                }
                else{
                try{
                    const data = await response.json();
                    toast.error(data.message); 
                    setEditOrg(false) 
                    setLoadingOrgInfo(false)
                }catch (e){
                    toast.error(e.message);
                    setEditOrg(false)
                    setLoadingOrgInfo(false)
                }
                }
            })
        }
    } catch (error) {
        console.log(error.message)
        setLoadingOrgInfo(false)
    }
    
  }

    const submitDeleteOrg = () =>{
        // Delete organization API
        try {
            if(!loadingDeleteOrg){
                setLoadingDeleteOrg(true)
                deleteData(apiLink + '/v1/organizations/' + selectedOrganization)
                .then(async response => {
                    if(response.ok){
                    updateOrganizationsList()
                    setLoadingDeleteOrg(false)
                    toast.success(`${orgName} organization deleted`)
                    navigate('/')
                    }
                    else{
                    try{
                        const data = await response.json();
                        toast.error(data.message)
                        setLoadingDeleteOrg(false)
                    }catch (e){
                        toast.error('Something went wrong')
                        setLoadingDeleteOrg(false)
                    }
                    }
                })
                
            }
        } catch (error) {
            // console.log(error.message)
            toast.error(error.message)
            setLoadingDeleteOrg(false)
        }
    }
    const submitLeaveOrg = () =>{
        // Leave organization API
        try {
        if(!loadingLeaveOrg && user && user.user_username){
            setLoadingLeaveOrg(true)
            deleteData(apiLink + '/v1/organizations/' + selectedOrganization + '/members', 
            {'member_username': user.user_username,
            'delete_mode': 'delete_safe'})
            .then(async response => {
                if(response.ok){
                updateOrganizationsList()
                setLoadingLeaveOrg(false)
                toast.success(`You left ${orgName} organization`)
                navigate('/')
                }
                else{
                try{
                    const data = await response.json();
                    toast.error(data.message)
                    setLoadingLeaveOrg(false)
                }catch (e){
                    toast.error('Something went wrong')
                    setLoadingLeaveOrg(false)
                }
                }
            })
            
        }
        } catch (error) {
        // console.log(error.message)
        toast.error(error.message)
        setLoadingLeaveOrg(false)
        }
    }


    const handleChangeTimezone = (e) => {
        setSelectedTimezone(e.target.value)
    }

    const handleCancelEdit = () => {
        setEditSchedules(false)
        setSelectedTimezone(tempTimezone)
    }



  const submitWorkShifts = () => {
    try {
        if(!loadingWorkShifts){
            setLoadingWorkShifts(true)
            let shift = workShiftList
            workShiftList.map((currentShift, i) => {
                if(typeof currentShift.shift_start_time === 'string' || currentShift.shift_start_time instanceof String){
                    // console.log("dont change time")
                }else if(typeof currentShift.shift_start_time === 'number' || currentShift.shift_start_time instanceof Number){
                    let formattedStart = moment(currentShift.shift_start_time).format('HH:mm:ss')
                    shift[i].shift_start_time = formattedStart
                }else if(currentShift.shift_start_time && currentShift.shift_start_time.$d) {
                    let formatStartTime = currentShift.shift_start_time.$d.toLocaleTimeString('en-US',{ hour12: false })
                    shift[i].shift_start_time = formatStartTime
                }

                if(typeof currentShift.shift_end_time === 'string' || currentShift.shift_end_time instanceof String){
                    // console.log("dont change time")
                }else if(typeof currentShift.shift_end_time === 'number' || currentShift.shift_end_time instanceof Number){
                    let formattedEnd = moment(currentShift.shift_end_time).format('HH:mm:ss')
                    shift[i].shift_end_time = formattedEnd
                }else if(currentShift.shift_end_time && currentShift.shift_end_time.$d) {
                    let formatEndTime = currentShift.shift_end_time.$d.toLocaleTimeString('en-US',{ hour12: false })
                    shift[i].shift_end_time = formatEndTime
                }
            })
            putData(apiLink + '/v1/organizations/' + selectedOrganization + '/shifts', 
            {'shifts': shift,
            'organization_time_zone': selectedTimezone})
            .then(async response => {
                if(response.ok){
                toast.success('Work shifts modified')
                setLoadingWorkShifts(false)
                getShifts()
                setEditSchedules(false)
                }
                else{
                    setLoadingWorkShifts(false)
                try{
                    const data = await response.json();
                    toast.error(data.message); 
                    setEditSchedules(false) 
                }catch (e){
                    toast.error('Something went wrong');
                    setEditSchedules(false)
                }
                }
            })
        }
    } catch (error) {
        console.log(error.message)
        setLoadingWorkShifts(false)
    }
    
  }

  

    

  return (
    <div className={`pl-6 pr-6 pt-1 pb-10 ${isMobile ? 'max-h-[calc(100vh-1rem)]' : 'max-h-[calc(100vh-5rem)]'}  mx-auto overflow-scroll scrollbar-hide`}>
        <AnimatePresence mode='wait'>
        <motion.div 
            key='orgPage'
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-4"
        >
            <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
                <div className='flex flex-col'>
                    <span className='text-xl font-bold text-gray-800 dark:text-gray-300'>Organization</span>
                    <span className='text-base sm:text-sm text-gray-500 dark:text-gray-400'>Manage and customize how your organization is identified. This ensures consistent identity across your collaborations. Plus, your organization handle allows you to easily chat and connect with anyone inside and outside of your organization.</span>

                </div>
                {editOrg ? (
                    <div className='flex space-x-2'>
                        <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditOrg(false)}>
                            <span >Cancel</span>
                        </button>
                        <button 
                            className='relative flex text-sm text-white bg-blue-600 dark:text-gray-100 items-center border-[1px] border-blue-600 dark:border-blue-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' 
                            onClick={updateOrgInfo}
                            disabled={loadingOrgInfo}
                            >
                            <span >Save</span>
                            {loadingOrgInfo && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                        </button>
                    </div>

                ):(
                <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditOrg(true)}>
                    <span className='mr-1'>Edit</span>
                    <PencilIcon className='w-4 h-4' />
                </button>
                )}
            </div>

            <div className='flex flex-col space-y-4 mt-4 text-base ml-2'>
                <div className='flex grow justify-center sm:justify-start'>
                    <ImageZoomCrop 
                        title="Organization logo"
                        isDisabled={editOrg} 
                        isRounded={false} 
                        currentPicture={orgImage} 
                        submitType={'organizationLogo'}
                        updateInfo={updateOrganizationsList}
                        uuid={selectedOrganization}
                    />
                </div>
                <div className='flex flex-col sm:flex-row scrollbar-hide'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium scrollbar-hide'>
                        <div className='flex items-center'>
                            <span>Organization handle</span>
                            <div className='ml-2'> 
                            <MemberTooltip title={
                                <div className={`rounded-xl ${isDark? ' bg-slate-800':' bg-slate-50'} p-2 text-xs`}>
                                    <div className={` flex w-full ${isDark? ' bg-yellow-500/10 text-yellow-200/80':' bg-yellow-600/20 text-yellow-600'} rounded-xl p-2`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                        </svg>
                                        <div className='ml-2 w-full flex'>
                                            <span className='font-bold'>Warning: </span> 
                                            <div className='flex flex-col ml-2'>
                                                <span>
                                                {`Changing this organization handle will update it for every user in the organization. All message and chat handles will also change, which may cause issues for external users trying to find you with the previous handle.`}
                                                </span>
                                                <span className='mt-1 italic'>
                                                {`Example with current organization handle => user:${orgInfo.organization_handle}`}
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                </svg>
                            </MemberTooltip>
                            </div>
                        </div>
                        
                    </div>
                    <div className='flex-1 scrollbar-hide'>
                            <OrganizationHandleRow orgInfo={orgInfo} orgHandle={orgHandle} setOrgHandle={setOrgHandle} editOrg={editOrg}/>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <p className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Organization name</p>
                    <div className='flex-1'>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm
                                        focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                                        dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                                        placeholder:text-gray-400 text-gray-600 border-gray-200 
                                        disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent `} 
                            id={'orgName'} 
                            type={'text'}
                            name={'orgName'}
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            disabled={!editOrg}
                            />
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Organization description</div>
                    <div className='flex-1 '>
                        <input 
                            className={` bg-transparent w-full max-w-[300px] xl:max-w-[800px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm
                            focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                            dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                            placeholder:text-gray-400 text-gray-600 border-gray-200 
                            disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                            id={'orgDescription'} 
                            type={'text'}
                            name={'orgDescription'}
                            value={orgDescription}
                            onChange={(e) => setOrgDescription(e.target.value)}
                            disabled={!editOrg}
                            />
                    </div>
                </div>
                {showGODview && 
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Delete entire organization</div>
                    <div className='mx-auto mt-2 sm:flex-1 sm:mt-0'>
                    {!deleteOrg ? (
                        <motion.div 
                            key='deleteOrgSection'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='flex items-center'
                            >
                        {/* <LightTooltip TransitionComponent={Zoom} title="Delete entire organization" arrow > */}
                            <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border dark:border-red-900 dark:text-red-600 dark:hover:text-red-400 dark:hover:bg-transparent  border-red-100 text-red-600 hover:bg-red-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={() => setDeleteOrg(true)}
                            >
                            Delete
                            </button>
                        {/* </LightTooltip> */}
                        </motion.div>
                    ):(
                        <motion.div
                            key='deleteOrgConfirm'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='flex items-center'
                            >
                                <div className='px-4 my-auto'>
                                    <div className={`text-gray-600 dark:text-gray-400 text-sm font-bold`}>
                                    Are you sure you want to delete entire {orgName} organization?
                                    </div>
                                </div> 
                                <button
                                    type="button"
                                    className={`inline-flex rounded-md bg-transparent border dark:border-slate-600 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-gray-300 border-gray-100 text-gray-600 hover:bg-gray-50 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                    onClick={() => setDeleteOrg(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={`relative inline-flex rounded-md ml-2 bg-transparent border dark:border-slate-600 dark:text-red-400 dark:hover:bg-red-400 dark:hover:bg-opacity-10 border-gray-100 text-red-600 hover:bg-red-100 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                    onClick={submitDeleteOrg}
                                    disabled={loadingDeleteOrg}
                                >
                                    Confirm Delete
                                    {loadingDeleteOrg && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                    }
                                </button>
                            </motion.div>
                    )}
                    </div>
                </div>
                }
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Leave organization</div>
                    <div className='mx-auto mt-2 sm:flex-1 sm:mt-0'>
                        {!leaveOrg ? (
                            <motion.div 
                                key='leaveOrgSection'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                >
                            {/* <LightTooltip TransitionComponent={Zoom} title="Delete entire organization" arrow > */}
                                <button
                                type="button"
                                className={`inline-flex rounded-md bg-transparent border dark:border-slate-600 dark:text-gray-400 dark:hover:bg-slate-600 border-gray-100 text-gray-600 hover:bg-gray-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={() => setLeaveOrg(true)}
                                >
                                Leave&nbsp;
                                </button>
                            {/* </LightTooltip> */}
                            </motion.div>
                        ):(
                            <motion.div
                                key='leaveOrgConfirm'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className='flex items-center'
                                >
                                    <div className='px-4 my-auto'>
                                        <div className={`text-gray-600 dark:text-gray-400 text-sm font-bold`}>
                                        Are you sure you want to leave {orgName} organization?
                                        </div>
                                    </div> 
                                    <button
                                        type="button"
                                        className={`inline-flex rounded-md bg-transparent border dark:border-slate-600 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-gray-300 border-gray-100 text-gray-600 hover:bg-gray-50 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                        onClick={() => setLeaveOrg(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className={`relative inline-flex rounded-md ml-2 bg-transparent border  dark:border-slate-600  dark:text-gray-400  dark:bg-gray-700  dark:hover:bg-gray-600 border-slate-100 text-gray-800 bg-gray-100 hover:bg-gray-200 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                        onClick={submitLeaveOrg}
                                        disabled={loadingLeaveOrg}
                                    >
                                        Confirm Leave
                                        {loadingLeaveOrg && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                        }
                                    </button>
                                </motion.div>
                        )}
                    </div>
                </div>
                <div className='flex flex-col space-y-1'>
                    <div className='flex truncate'>
                        <p className='grow max-w-[320px] text-gray-600 dark:text-gray-400 text-sm font-medium '>Shipping Information</p>
                        <div className='flex flex-col'>
                                <p className=' text-gray-600 dark:text-gray-300 text-sm truncate'>{shippingInfo.address_person_name? shippingInfo.address_person_name: ''} </p>
                                <p className=' text-gray-600 dark:text-gray-300 text-sm font-medium truncate'>{shippingInfo.address_street_line_1? shippingInfo.address_street_line_1: ''} </p>
                                <p className=' text-gray-600 dark:text-gray-300 text-sm font-medium truncate'>{shippingInfo.address_street_line_2? shippingInfo.address_street_line_2: ''}  </p>
                                <p className=' text-gray-600 dark:text-gray-300 text-sm font-medium truncate uppercase'>
                                    {`${shippingInfo.address_city? shippingInfo.address_city + ',': ''} ${shippingInfo.address_state? shippingInfo.address_state: ''} ${shippingInfo.address_zip_code? shippingInfo.address_zip_code: ''} ${shippingInfo.address_country? shippingInfo.address_country: ''}`}
                                </p>
                                <p className='text-gray-500 dark:text-gray-400 text-sm truncate'>{shippingInfo.address_email? shippingInfo.address_email: ''}  </p>
                                <p className=' text-gray-500 dark:text-gray-400 text-sm truncate'>{showGODview && shippingInfo.address_phone_number? shippingInfo.address_phone_number: ''}  </p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
        <motion.div className="mt-16">
            <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
                <span className='text-lg font-bold text-gray-800 dark:text-gray-300'>Schedules</span>
                {editSchedules ? (
                    <div className='flex space-x-2'>
                        <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={handleCancelEdit}>
                            <span >Cancel</span>
                        </button>
                        <button className='relative flex text-sm text-white bg-blue-600 dark:text-gray-100 items-center border-[1px] border-blue-600 dark:border-blue-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={submitWorkShifts}>
                            <span >Save</span>
                            {loadingWorkShifts && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                        </button>
                    </div>

                ):(
                <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditSchedules(true)}>
                    <span className='mr-1'>Edit</span>
                    <PencilIcon className='w-4 h-4' />
                </button>
                )}
            </div>

            <div className='flex flex-col space-y-4 mt-4 text-base ml-2'>
                {/* Time zone dropdown */}
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Schedule Timezone</div>
                    <div className='flex-1 '>
                        <div className="flex justify-center">
                            
                            <div className="my-3 w-full outline-none">
                                <select onChange={handleChangeTimezone} value={selectedTimezone}  aria-label="timezone-select" disabled={!editSchedules}
                                className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full max-w-[500px] py-2 px-0 sm:py-1 sm:px-2 text-sm
                                font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300
                                disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent disabled:bg-transparent disabled:dark:bg-transparent disabled:text-gray-600 disabled:dark:text-gray-300 disabled:opacity-100 " >
                                    <option disabled value=''> -- select a timezone -- </option>
                                    {tzStrings.map((tz, index) => (
                                        <option key={`${tz.label}`} value={tz.value}>{tz.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] flex flex-col text-gray-600 dark:text-gray-400 mb-auto mt-2 text-sm font-medium'>
                        <span>Work Schedule</span>
                        <span className='italic pt-1 font-normal'>{`(Shifts can't overlap in time)`}</span>
                    </div>
                    {editSchedules? (
                        <motion.div 
                            key='workScheduleEdit'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }} 
                            className='flex-1 '
                        >
                            <div className='flex flex-col'>
                                {workShiftList.map((shift, index) => (
                                    <div key={index}>
                                    <WorkShiftInputs shift={shift} index={index}  handleChangeWorkShiftInput={handleChangeWorkShiftInput} removeWorkShiftInputs={removeWorkShiftInputs} />
                                    </div>
                                ))}
                                <div className='px-4 w-full flex pt-1'>
                                    <div className={`dark:text-blue-400 dark:hover:text-blue-600 text-blue-600 hover:text-blue-800 text-base sm:text-sm font-medium cursor-pointer `} onClick={addWorkShiftInputFormFields}>
                                        Add another shift
                                    </div>
                                </div>
                            </div>
                        
                        </motion.div>
                    ):(
                        <motion.div 
                            key='workScheduleDisabled'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }} 
                            className='flex-1 '
                        >
                            <div className='flex flex-col space-y-4 mt-2 ml-3'>
                            {currentShifts && Object.keys(currentShifts).length > 0 && 
                                currentShifts.map((shift, index) => (
                                <div key={shift.shift_name} className='grid grid-cols-10 max-w-[550px] lg:max-w-[650px] xl:max-w-[700px]'>
                                    <div className='col-span-10 lg:col-span-2 text-base sm:text-sm text-gray-600 dark:text-gray-300 lg:mr-2 italic font-semibold truncate'>{shift.shift_name}:</div>
                                    <div className='flex col-span-10 lg:col-span-5'>
                                        {shift.shift_days?.map((day, index) => (
                                            <div key={`'shift-'${index}`} className='text-base sm:text-sm text-gray-600 dark:text-gray-300'>{index != 0 && ', '}{day.substring(0,3)}</div>
                                        ))}
                                    </div>
                                    <div className='lg:ml-2 col-span-10 lg:col-span-3'>
                                        <div className='text-base sm:text-sm text-gray-600 dark:text-gray-300 '>
                                            {shift.shift_start_time} - {shift.shift_end_time}
                                        </div>
                                    </div>
                                    
                                </div>

                            ))}
                            </div>
                        </motion.div >

                    )}
                    

                </div>
            </div>
        </motion.div>
        <motion.div 
            key='AutoDowntimeClassificationContainer' className='mt-16'>
            <AutoDowntimeClassificationSection selectedOrganization={selectedOrganization}/>
        </motion.div>
        </AnimatePresence>
    </div>
  )
}

export default OrgPage