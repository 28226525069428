import React, { useEffect, useState } from 'react'
import { LightTooltip } from '../../styles/Tooltip'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { getData, putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import ExpandWOImage from './ExpandWOImage';
import SelectedWOImageUploadSection from './SelectedWOImageUploadSection';

function DescriptionImagesComponent({selectedWorkOrder, handleDownloadFile, getSelectedWorkOrder}) {
    const [isEditing, setIsEditing] = useState(false)
    const isDark = useRecoilValue(darkModeState); 
    const [woDescription, setWODescription] = useState('')
    const [isEditingWODescription, setIsEditingWODescription] = useState(false)
    const [loadingDescription, setLoadingDescription] = useState(false);

    const [workOrderImages, setWorkOrderImages] = useState([])
    

    useEffect(() => {
        
        
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if(selectedWorkOrder.workorder_description){
                setWODescription(selectedWorkOrder.workorder_description)
            }else{
                setWODescription([])
            }

            if(selectedWorkOrder.workorder_files && Object.keys(selectedWorkOrder.workorder_files).length > 0){
                
                const compressedFiles = selectedWorkOrder?.workorder_files?.filter(file => file.file_is_compressed) || [];
                // console.log('compressedFiles', compressedFiles)
                setWorkOrderImages(compressedFiles)
            }else{
                setWorkOrderImages([])
            }
        }

    },[selectedWorkOrder])

    const handleClose = () => {
        setIsEditing(false)
        setIsEditingWODescription(false)
    }

    const handleDescriptionUpdate = (event) => {
        try {
            if(!loadingDescription){
                setLoadingDescription(true);
                setIsEditingWODescription(false);
                let description = ''
                if(event.target.value){
                    description = event.target.value
                }
        
                putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid, 
                {'workorder_description': description })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        console.log('data', data)
                        // toast.success('Description Change')
                        setLoadingDescription(false)
                        setWODescription(description)
                    }
                    else{
                        setWODescription(selectedWorkOrder.workorder_description)
                        setLoadingDescription(false)
                        try{
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e)
                        }
                    }
                })

            }

        } catch (e) {
            setWODescription(selectedWorkOrder.workorder_description);
            toast.error(e.toString());
        }
    };


  return (
    <div className='flex flex-col w-full group'>
        <div className='flex text-gray-800 dark:text-white mb-2'>
            <span className='font-medium'>Description</span>
            {isEditing ?(
                <div className='flex ml-1 space-x-2'>
                    {/* <div className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} onClick={handleUpdate}>
                        Save
                    </div> */}
                    <div 
                        className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                        onClick={handleClose}>
                        Close
                    </div>
                </div>
            ):(
                <LightTooltip title='Edit description'>
                    <button 
                        className={`text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                        onClick={() => {
                            setIsEditing(true)
                            setIsEditingWODescription(true)
                        }}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                        </svg>

                    </button>
                </LightTooltip>

            )}
        </div>
            <div className='relative'>
                <input
                    type="text"
                    value={woDescription}
                    onChange={(e) => setWODescription(e.target.value)}
                    // onBlur={handleTitleBlur}
                    // onKeyDown={handleKeyPressTitle}
                    onBlur={handleDescriptionUpdate}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Tab') {
                            handleDescriptionUpdate(e);
                        }
                    }}
                    className={`py-0 pr-3 pl-1 rounded-md text-base text-gray-600 dark:text-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-600 
                        ${isEditingWODescription
                            ? 'w-full bg-transparent border shadow-sm focus:outline-none focus:bg-transparent focus:ring-1  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300  dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800' 
                            : 'bg-transparent w-full  truncate border-none focus:ring-0 cursor-pointer'}
                    `}
                    placeholder={isEditing ? 'Work order description':'Add description'}
                    onClick={() => setIsEditingWODescription(true)}
                    readOnly={!isEditingWODescription}
                    autoFocus={isEditingWODescription}
                />
                {loadingDescription && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }

            </div>
        {isEditing ? (
            <div className='flex flex-col'>
                <div className=''>
                    <SelectedWOImageUploadSection workOrderImages={workOrderImages}  selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                </div>
            </div>
        ):(
            <div className='flex '>
                {workOrderImages &&  Object.keys(workOrderImages).length > 0 ? (    
                    <div className={`flex mt-1 space-x-4 overflow-x-auto message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} `}>
                        {workOrderImages.map(file => (
                            <ExpandWOImage key={file.file_uuid} file={file} imageUrl={file.file_signed_url}  handleDownloadFile={handleDownloadFile} />
                        ))} 
                    </div>
                ):(
                    <button
                        onClick={() => setIsEditing(true)} 
                        className='text-blue-600 dark:text-blue-400 italic text-sm mt-2'>
                        Add Image
                    </button>
                )}
            </div>

        )}
    
</div>
  )
}

export default DescriptionImagesComponent