import React, { useEffect, useState } from 'react'
import { darkModeState } from '../../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'

function WOEstimatedTimeSection({setWODuration, selectedDateInfo}) {
    const isDark = useRecoilValue(darkModeState)
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [totalCycleTime, setTotalCycleTime] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        convertMinutesToTime(60)
        setTotalCycleTime(60)
        if(selectedDateInfo && Object.keys(selectedDateInfo).length > 0){
            console.log('selectedDateInfo.duration', selectedDateInfo.duration)
            if(selectedDateInfo.duration){
                convertMinutesToTime(selectedDateInfo.duration)
                setTotalCycleTime(selectedDateInfo.duration)
            }
            
          }
    },[selectedDateInfo])

    useEffect(() => {
        setWODuration(totalCycleTime)

    }, [totalCycleTime])


    const convertMinutesToTime = (mins) => {  
        let hours = Math.floor(parseFloat(mins) / 60);
        let minutes = Math.floor(parseFloat(mins) % 60);

        setHours(hours)
        setMinutes(minutes)
    }


    const updateCycleTimes = (e, selectedInput) => {
        var hours_to_min = 0
        if(selectedInput === 'operation_cycle_time_h'){
            if(e.target.value){
                // const newValue = e.target.value.slice(0, 1) // Only allow 1 character
                const newValue = e.target.value
                console.log('newValue', newValue)
                setHours(parseInt(newValue))
                if(parseInt(e.target.value) >= 0){
                    hours_to_min = parseInt(newValue) * 60
                }
            }else{
                setHours('')
            }
        }else {
            if(hours){
                hours_to_min = hours * 60
            }
        }

        var mins = 0
        if(selectedInput === 'operation_cycle_time_m'){
            // console.log('e.target.value)', e.target.value)
            if(e.target.value){
                const newValue = parseInt(e.target.value.slice(0, 2)) // Only allow 2 character
                if(newValue > 59){
                    setMinutes(59)
                }else{
                    setMinutes(newValue)
                }
                if(newValue > 0 && newValue < 60){
                    mins = newValue
                }
            }else{
                setMinutes('')
                mins = 0
            }
        }else {
            if(minutes){
                mins = minutes
            }
        }



       


        // console.log('hours_to_min', hours_to_min)
        // console.log('min_to_s', min_to_s)
        // console.log('secs', secs)
        // console.log('milliSecs', milliSecs)
        let totalTime =  hours_to_min + mins
        // console.log('totalTime', totalTime)
        setTotalCycleTime(totalTime)    
}

// const handleInputsBlur = (event) => {
//     if (!event.currentTarget.contains(event.relatedTarget)) {
//       // Focus moved outside the div (not to a child input)
//     //   console.log('Blurred outside the div'); // Your desired action
//         let percent = stdevPercent
//         if(operationAlgorithmId === 200){
//             percent = 0
//         }else{
//             if(percent == 0 || !percent){
//                 percent = 0.1
//                 setStdevPercent(10)
//             }else{
//                 percent = percent/100
//             }
//         }
//         if(!loading){
//             setLoading(true)
//             putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
//             {'operation_cycle_time': totalCycleTime,
//             'operation_stdev_percentage': percent})
//             .then(async response => {
//                 const data = await response.json();
//                 if(response.ok){
//                     // console.log('data', data.data[0])
//                     setLoading(false)
//                     if(totalCycleTime != data.data[0].operation_cycle_time){
//                         // console.log('NOT THE SAME')
//                         convertMinutesToTime(data.data[0].operation_cycle_time)
//                     }
//                 }
//                 else{
//                     try{
//                         convertMinutesToTime(operation.operation_cycle_time)
//                         setLoading(false)
//                         toast.error(data.message)
//                     }catch (e){
//                         convertMinutesToTime(operation.operation_cycle_time)
//                         toast.error(e.message)
//                         setLoading(false)
//                     }
//                 }
//             })
//         }
//     }
//   };


  return (
    <div className={``}>
        <div className='flex items-center' >
                    <div className='flex items-center' >
                    {/* <div className='flex items-center' onBlur={handleInputsBlur}> */}
                        <div className='flex flex-col w-[50px] text-center'>
                            <input 
                                className={`text-center text-sm block bg-transparent w-full border-1 focus:ring-1 ${isDark ? ' placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600  border-gray-300 focus:border-blue-300 focus:ring-blue-300'} rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent `} 
                                // className={`text-center text-sm block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                min={0}
                                max={9}
                                maxLength={1}
                                placeholder={`0`}
                                type={'number'}
                                name={'operation_cycle_time_h'}
                                value={hours}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_h')}
                            />
                            <div className={`text-sm ${isDark?'text-gray-400':'text-gray-500'} `}>
                                <span>{`hr`}</span>
                            </div>
                        </div>
                        <div className={`flex flex-col text-xl mx-1 ${isDark?'text-gray-400':'text-gray-500'}`}>
                            :
                            <div className={`h-6 text-xs ${isDark?'text-gray-400':'text-gray-500'} `}>
                                <span>{``}</span>
                            </div>
                        </div>
                        <div className='flex flex-col w-[50px] text-center'>
                            <input 
                                className={`text-center text-sm block bg-transparent w-full border-1 focus:ring-1 ${isDark ? ' placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600  border-gray-300 focus:border-blue-300 focus:ring-blue-300'} rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent `} 
                                min={0}
                                max={59}
                                placeholder={`00`}
                                type={'number'}
                                name={'operation_cycle_time_m'}
                                value={minutes}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_m')}
                            />
                            <div className={`text-sm ${isDark?'text-gray-400':'text-gray-500'} `}>
                                <span>{`min`}</span>
                            </div>
                        </div>
                    </div>
            </div>
    </div>
  )
}

export default WOEstimatedTimeSection