import { tzStrings } from "../data/TimezonesList";
import { DateTime } from 'luxon';




export const getLocalTimezone = () => {
    // Get current offset in minutes
    const offsetInMinutes = new Date().getTimezoneOffset();
    // Convert to hours and format as GMT string
    const offsetHours = -offsetInMinutes / 60; // Negative because getTimezoneOffset returns opposite of GMT
    const offsetStr = `GMT${offsetHours >= 0 ? '+' : '-'}${Math.abs(offsetHours).toString().padStart(2, '0')}:00`;
    
    // Find matching timezone in the list
    const matchingTz = tzStrings.find(tz => tz.label.includes(offsetStr));
    
    return matchingTz || {
      label: `(${offsetStr}) Your Local Time`,
      value: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  };


  export const convertToISO = (dateStr, timeStr, timezone) => {
    try {

       // Combine date and time strings
        const dateTimeStr = `${dateStr}T${timeStr}`;

        // Parse the input as being in the target timezone
        const dateInTargetZone = DateTime.fromISO(dateTimeStr, { zone: timezone });

        // Convert to UTC and get the ISO string
        const utcISOString = dateInTargetZone.toUTC().toISO();

        // console.log(`ISO time in UTC: ${utcISOString}`);

        return utcISOString

      
      
    } catch (error) {
        
      console.log('Invalid date/time combination');
    }
  };