import React, { useEffect, useState } from 'react'
import {PencilIcon} from '@heroicons/react/solid'
import { getData, postData, putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { Switch } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import DowntimeSelector from '../../components/modals/organizationModals/DowntimeSelector'
import toast from 'react-hot-toast'
import { convertTo12Hour } from '../../utils/DateFormating'
import AddDowntimeBreakModal from '../../components/modals/organizationModals/AddDowntimeBreakModal'
import EditDowntimeBreakItem from './EditDowntimeBreakItem'

function AutoDowntimeClassificationSection({selectedOrganization}) {
    const [editAutoDowntimes, setEditAutoDowntimes] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isAddingBreak, setIsAddingBreak] = useState(false)
    const [downtimeCategories, setDowntimeCategories] = useState([])
    const [downtimeBreaksList, setDowntimeBreaksList] = useState([])
    const [settings, setSettings] = useState({
        shortDowntime: false,
        shortDowntimeName: '',
        shortDowntimeId: '',
        shiftStart: true,
        shiftStartName: '',
        shiftStartId: '',
        shiftEnd: false,
        shiftEndName:'',
        shiftEndId:'',
      });
    
     

    useEffect(() => {
        if(selectedOrganization ){
            getDowntimeParameters()
            refreshDowntimeCategories()
            getDowntimeBreaks()
        }
        
      },[selectedOrganization])


      const getDowntimeParameters = () => {
        if(selectedOrganization ){
            getData(apiLink + '/v1/organizations/' + selectedOrganization)
              .then(async response => {
                  if(response.ok ){
                  const data = await response.json();
                  if(data && data.data[0]){
                    //   console.log('auto_down_classification',data.data[0])
                      setSettings({
                          shortDowntime: data.data[0].organization_auto_dt_short_downtime_enabled,
                          shortDowntimeName: data.data[0].organization_auto_dt_short_downtime_category_name,
                          shortDowntimeId: data.data[0].organization_auto_dt_short_downtime_category_id,
                          shiftStart: data.data[0].organization_auto_dt_start_of_shift_enabled,
                          shiftStartName: data.data[0].organization_auto_dt_start_of_shift_category_name,
                          shiftStartId: data.data[0].organization_auto_dt_start_of_shift_category_id,
                          shiftEnd: data.data[0].organization_auto_dt_end_of_shift_enabled,
                          shiftEndName: data.data[0].organization_auto_dt_end_of_shift_category_name,
                          shiftEndId: data.data[0].organization_auto_dt_end_of_shift_category_id
                      })
                  }
                  }
                  
              });
            
        }
    }
      const getDowntimeBreaks = () => {
        if(selectedOrganization ){
            getData(apiLink + `/v1/organizations/${selectedOrganization}/auto_downtime_breaks`)
              .then(async response => {
                  if(response.ok ){
                  const data = await response.json();
                //   console.log('Breaks data',data)
                  if(data && data.data){
                      setDowntimeBreaksList(data.data)
                      
                  }
                  }
                  
              });
            
        }
    }

    const refreshDowntimeCategories = () => {
        try {
          if(selectedOrganization){
            // Get list of downtime categories 
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/downtime_categories')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    // console.log('downtime categories', data.data)
                  setDowntimeCategories(data.data)
                }
              }
            });
          }
          
        } catch (error) {
          console.log(error.message)
        }
  
      }


    const handleCancelEdit = () => {
        setEditAutoDowntimes(false)
    }


    const updateToggle = (setting, apiKey) => {
        try {
            setSettings(prev => ({
                ...prev,
                [setting]: !prev[setting]
              }));
              const newValue = !settings[setting];
            if(!loading ){
              setLoading(true)
              putData(apiLink + `/v1/organizations/${selectedOrganization}/auto_down_classification`, 
              {
                [apiKey]: newValue
                })
                .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    // const data = await response.json();
                    let message = newValue ? 'Enabled':'Disabled'
                    toast.success(message)
                    getDowntimeParameters()
                    setLoading(false)
                  }
                  else{
                    getDowntimeParameters()
                    try{
                      const data = await response.json();
                      setLoading(false)
                      toast.error(data.message)
                    }catch (error){
                      toast.error(error)
                      setLoading(false)
                    }
                  }
                })
            }
          } catch (error) {
            toast.error(error)
            getDowntimeParameters()
            setLoading(false)
            
          }
    }


  return (
    <div>
        <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
            <div>
                <span className='text-lg font-bold text-gray-800 dark:text-gray-300'>Auto-Downtime Classifications</span>
                <span className="bg-red-500 text-white text-base font-semibold px-2 py-0.5 rounded-full ml-2">
                    NEW
                </span>
            </div>
            {editAutoDowntimes ? (
                <div className='flex space-x-2'>
                    <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={handleCancelEdit}>
                        <span >Close</span>
                    </button>
                    {/* <button className='relative flex text-sm text-white bg-blue-600 dark:text-gray-100 items-center border-[1px] border-blue-600 dark:border-blue-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={submitAutoDowntimes}>
                        <span >Save</span>
                        {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                            }
                    </button> */}
                </div>

            ):(
            <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditAutoDowntimes(true)}>
                <span className='mr-1'>Edit</span>
                <PencilIcon className='w-4 h-4' />
            </button>
            )}
        </div>
        <AnimatePresence mode='wait'>
            {!editAutoDowntimes ? (
                <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    key={`autoDowntimesSummary`}  
                    className={`space-y-4 ml-2 mt-8`}
                >
                    <div className='flex flex-col sm:flex-row'>
                        <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 text-sm font-medium'>Short Downtimes</div>
                        <div className='flex-1 '>
                            <div className='flex space-x-3 '>
                                <div className={`w-[58px] text-sm ${settings.shortDowntime ? 'text-green-600 dark:text-green-400':'text-gray-500 dark:text-gray-400'}`}>
                                    {settings.shortDowntime ? 'Enabled' : 'Disabled'}
                                </div>
                                <div className='text-sm text-gray-600 dark:text-gray-300 '>
                                If machines are down for less than the specified duration (default 5 minutes), auto classify downtimes as <span className='italic text-blue-600 dark:text-blue-300'>{settings.shortDowntimeName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row'>
                        <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 text-sm font-medium'>Start of Shift</div>
                        <div className='flex-1 '>
                            <div className='flex space-x-3'>
                                <div className={`w-[58px] text-sm ${settings.shiftStart ? 'text-green-600 dark:text-green-400':'text-gray-500 dark:text-gray-400'}`}>
                                    {settings.shiftStart ? 'Enabled' : 'Disabled'}
                                </div>
                                <div className='text-sm text-gray-600 dark:text-gray-300'>
                                    Auto classify downtimes at the beginning of shifts as <span className='italic text-blue-600 dark:text-blue-300'>{settings.shiftStartName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row'>
                        <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 text-sm font-medium'>End of Shift</div>
                        <div className='flex-1 '>
                            <div className='flex space-x-3 '>
                                <div className={`w-[58px] text-sm ${settings.shiftEnd ? 'text-green-600 dark:text-green-400':'text-gray-500 dark:text-gray-400'}`}>
                                    {settings.shiftEnd ? 'Enabled' : 'Disabled'}
                                </div>
                                <div className='text-sm text-gray-600 dark:text-gray-300'>
                                    Auto classify downtimes at the end of shifts as <span className='italic text-blue-600 dark:text-blue-300'>{settings.shiftEndName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row'>
                        <div className=' grow max-w-[320px] '>
                            <div className={`flex items-center space-x-1 text-gray-600 dark:text-gray-400 text-sm font-medium`}>
                                <span>Breaks</span>
                                <AddDowntimeBreakModal downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} getDowntimeBreaks={getDowntimeBreaks} />
                            </div>
                        </div>
                        <div className='flex-1 '>
                            <div className={`flex flex-col space-y-4`}>
                            {downtimeBreaksList && Object.keys(downtimeBreaksList).length > 0 ? (
                                downtimeBreaksList.map(breakItem => (
                                    <div key={`${breakItem.auto_downtime_break_uuid}`} className='flex space-x-3 '>
                                        <div className={`w-[58px] text-sm ${breakItem.auto_downtime_break_enabled ? 'text-green-600 dark:text-green-400':'text-gray-500 dark:text-gray-400'}`}>
                                        {breakItem &&  breakItem.auto_downtime_break_enabled ? 'Enabled' : 'Disabled'}
                                        </div>
                                        <div className='text-sm text-gray-600 dark:text-gray-300'>
                                        During scheduled shifts, if machines are down, between <span className='italic text-blue-600 dark:text-blue-300'>{convertTo12Hour(breakItem.auto_downtime_break_start_time)}</span> and <span className='italic text-blue-600 dark:text-blue-300'>{convertTo12Hour(breakItem.auto_downtime_break_end_time)}</span> classify downtimes as <span className='italic text-blue-600 dark:text-blue-300'>{breakItem.downtime_category_name}</span>
                                        </div>
                                    </div>
                                ))
                            ) :(
                                <div/>
                            )}
                            </div>
                        </div>
                    </div>

                </motion.div>
            ):(
                <motion.div 
                    key={`editAutoDowntimes`}  
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`space-y-4 ml-2 mt-8`}
                >
                    <div className='flex flex-col md:flex-row'>
                        <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Short Downtimes</div>
                        <div className='flex-1 '>
                            <div className='flex space-x-3'>
                                <Switch 
                                    size="small"
                                    checked={settings.shortDowntime}
                                    onChange={() => updateToggle('shortDowntime', 'organization_auto_dt_short_downtime_enabled')}
                                    color="primary"
                                    disabled={loading}
                                />
                                <div className='flex flex-wrap lg:items-center'>
                                    <div className='text-sm text-gray-600 dark:text-gray-300 mr-2'>
                                    If machines are down for less than the specified duration (default 5 minutes), auto classify downtimes as
                                    </div>
                                    <DowntimeSelector currentDowntimeCategoryId={settings.shortDowntimeId} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} getDowntimeParameters={getDowntimeParameters} apiKey='organization_auto_dt_short_downtime_category_id' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row'>
                        <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Start of Shift</div>
                        <div className='flex-1 '>
                            <div className='flex space-x-3'>
                                <Switch 
                                    size="small"
                                    checked={settings.shiftStart}
                                    onChange={() => updateToggle('shiftStart', 'organization_auto_dt_start_of_shift_enabled')}
                                    color="primary"
                                    disabled={loading}
                                />
                                <div className='flex flex-wrap lg:items-center'>
                                    <div className='text-sm text-gray-600 dark:text-gray-300 mr-2'>
                                    Auto classify downtimes at the beginning of shifts as
                                    </div>
                                    <DowntimeSelector currentDowntimeCategoryId={settings.shiftStartId} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} getDowntimeParameters={getDowntimeParameters} apiKey='organization_auto_dt_start_of_shift_category_id' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row'>
                        <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>End of Shift</div>
                        <div className='flex-1 '>
                            <div className='flex space-x-3 '>
                                <Switch 
                                    size="small"
                                    checked={settings.shiftEnd}
                                    onChange={() => updateToggle('shiftEnd', 'organization_auto_dt_end_of_shift_enabled')}
                                    color="primary"
                                    disabled={loading}
                                />
                                <div className='flex flex-wrap lg:items-center'>
                                <div className='text-sm text-gray-600 dark:text-gray-300 mr-2'>
                                    Auto classify downtimes at the end of shifts as
                                    </div>
                                    <DowntimeSelector currentDowntimeCategoryId={settings.shiftEndId} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} getDowntimeParameters={getDowntimeParameters} apiKey='organization_auto_dt_end_of_shift_category_id' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row'>
                        <div className=' grow max-w-[320px] '>
                            <div className={`flex items-center space-x-1 text-gray-600 dark:text-gray-400 text-sm font-medium`}>
                                <span>Breaks</span>
                                <AddDowntimeBreakModal downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} getDowntimeBreaks={getDowntimeBreaks} />
                            </div>
                        </div>
                        <div className='flex-1 '>
                            <div className={`flex flex-col space-y-4`}>
                                {downtimeBreaksList && Object.keys(downtimeBreaksList).length > 0 ? (
                                    downtimeBreaksList.map(breakItem => (
                                        <EditDowntimeBreakItem key={`edit-${breakItem.auto_downtime_break_uuid}`} breakItem={breakItem} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories}  getDowntimeBreaks={getDowntimeBreaks} />
                                    ))
                                ) :(
                                    <div/>
                                )}
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    </div>
  )
}

export default AutoDowntimeClassificationSection