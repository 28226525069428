import { Avatar, AvatarGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { darkModeState } from '../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import { deleteData, getData, putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import toast from 'react-hot-toast'
import { LightTooltip, MemberTooltip } from '../../styles/Tooltip'
import { motion } from 'framer-motion'

function ChatMembersList({participants, chatInfo, setParticipants, userInfo, memberObjects, setMemberObjects}) {
    const isDark = useRecoilValue(darkModeState)
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])

    useEffect(() => {
      if(memberObjects && Object.keys(memberObjects).length > 0){
        if(memberObjects.teams && Object.keys(memberObjects.teams).length > 0){
          setTeams(memberObjects.teams)
        }
        if(memberObjects.users && Object.keys(memberObjects.users).length > 0){
          setUsers(memberObjects.users)
        }

      }

    },[memberObjects])

    const handleDeleteUser = (user, type) => {
        try {
          let payloadData = {}
          if(type == 'user'){
            payloadData = {
              chatroom_member_to_delete_username: user.user_username
            }
          }else{
            payloadData = {
              chatroom_member_to_delete_team_uuid: user.team_uuid
            }
          }
          // console.log('payloadData', payloadData)
          
            // deleteData(apiLink + '/v1/chats/' + chatInfo.chat_uuid + '/members',
            deleteData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + '/members',
              payloadData)
            .then(async response => {
                  const data = await response.json();
                  if(response.ok){ 
                    if(data && data.data ){
                      toast.success(`${type == 'user'? 'User': 'Team'} has been removed`)
                      // console.log('Delete User', data)
                      // setParticipants(data.data.members)
                      setParticipants(data.data.all_users)
                      setMemberObjects(data.data)
                    }
                  //   setLoading(false)
                  }
                  else{
                    try{
                      toast.error(data.message)
                      // setLoading(false)
                    }catch (e){
                      toast.error(e.message);
                      // setLoading(false)
                    }
                  }
                })
            // if(!loading){
            // //   setLoading(true)
      
            // }
            
          } catch (error) {
            console.log(error.message)
          }
    }
    

    const handleMakeOwner = (id, ownerState) => {
        try {
            // putData(apiLink + '/v1/chats/' + chatInfo.chat_uuid + '/members/' + user.user_username + '/roles',
            putData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + '/members/' + id + '/roles',
            {'update_to_owner': ownerState})
            .then(async response => {
                  const data = await response.json();
                  if(response.ok){ 
                    if(data && data.data ){
                      console.log('Change role User', data)   
                      // let all_users = participants
                      // all_users[user.user_username].chatroom_member_is_owner = ownerState
                      // setParticipants({...all_users})
                      setParticipants(data.data.all_users)
                      setMemberObjects(data.data)

                      
                      if(ownerState){
                        toast.success('Owner Added')
                      }else{
                        toast.success('Owner Removed')
                      }
                    }
                  }
                  else{
                    try{
                      toast.error(data.message)
                      // setLoading(false)
                    }catch (e){
                      toast.error(e.message);
                      // setLoading(false)
                    }
                  }
                })
          } catch (error) {
            console.log(error.message)
          }
    }

    // console.log('teams', teams)
    // console.log('users', users)


  return (
    <div className='bg-slate-100 dark:bg-slate-800 rounded-xl px-4 py-2'>
      <div className='flex flex-col'>
        <div>
          {teams && Object.keys(teams).length > 0 && teams.map((currentTeam) => (
              <div key={currentTeam.team_uuid} className={`${currentTeam.chatroom_member_has_left && 'hidden'} flex text-gray-600 dark:text-gray-300 items-center py-1 group`}>
                  <div className='relative'>
                      <Avatar 
                        sx={{  bgcolor: currentTeam.user_color? currentTeam.user_color : '' , width: 44, height: 44, }} 
                        style={{
                          background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${currentTeam.user_color? currentTeam.user_color:''} 100%)`,
                        }}
                        alt={currentTeam.team_name || ''}
                        src={currentTeam.team_image_url || '/brokenLink.png'} 
                      />
                      {currentTeam.chatroom_member_is_owner &&
                      <LightTooltip title='Owner'>
                          <div className='absolute rounded-full bg-green-500 dark:bg-green-300 text-green-900 right-[-3px] top-[-3px] p-0.5'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-3 h-3">
                                <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                              </svg>
                          </div>
                      </LightTooltip>
                      }
                      {currentTeam.is_external &&
                          <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                  <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                              </svg>
                          </div>
                      }
                  </div>
                  <div className='flex ml-2 grow overflow-hidden border-b-[0.01em] border-slate-200 dark:border-slate-600 py-1 items-center'>
                      <div className='flex flex-col '>
                          <div className='flex items-center '>
                                  <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{currentTeam.team_name}</h1>
                                  <div className='ml-4'>
                                    <MemberTooltip title={
                                      <div className={`py-2 px-4 ${isDark ? 'bg-slate-700':'bg-white'} shadow-md rounded-xl`}>
                                        <h1 className={`${isDark ? 'text-gray-400':'text-gray-500'} text-sm`}> {currentTeam.team_name} Organizations</h1>
                                        <div className='flex flex-col space-y-1'>
                                        {currentTeam.organizations.map(org =>(
                                              <div key={`${currentTeam.team_handle}-${org.organization_uuid}`} className={`${isDark ? 'text-gray-200':'text-gray-600'} text-sm`}>{org.organization_name}</div>
                                          ))}
                                        </div>

                                      </div>
                                    }>
                                      <AvatarGroup  spacing='medium'  sx={{ '& .MuiAvatar-root': { border: '1px solid rgba(203, 213, 225, 0.1)' } }} >
                                          {currentTeam.organizations.map(org =>(
                                            !org.chatroom_member_has_left &&
                                              <Avatar
                                                  key={`avatar-${currentTeam.team_handle}-${org.organization_uuid}`}
                                                  sx={{ 
                                                      bgcolor: 'rgba(59, 131, 246, 0.2)', 
                                                      fontSize:'12px',
                                                      width: 20, 
                                                      height: 20,
                                                      // filter: 'grayscale(100%)'
                                                  }}
                                                  alt={org.organization_name}
                                                  src={org.organization_logo_url}
                                              />
                                          ))}
                                      </AvatarGroup>
                                    </MemberTooltip>

                                  </div>
                          </div>
                          <div className='flex items-baseline '>
                                  <p className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate `}>{currentTeam.team_handle}</p>
                          </div>
                      </div>
                      <div className='ml-auto items-center flex space-x-2'>
                        {/* {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && !currentTeam.chatroom_member_is_owner && participants[userInfo.user_username].chatroom_member_is_owner &&  */}
                          <motion.button 
                            whileHover={{ scale:1.05 }} 
                            whileTap={{ scale: 0.95  }}  
                              className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5'
                              onClick={() => handleMakeOwner(currentTeam.team_uuid, true)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-4 h-4">
                              <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                            </svg>
                              <span className='text-sm ml-1'>Make Owner</span>

                          </motion.button>
                        {/* } */}
                        {/* {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && currentTeam.chatroom_member_is_owner && participants[userInfo.user_username].chatroom_member_is_owner && currentTeam.user_username != userInfo.user_username  && */}
                          <motion.button 
                          whileHover={{ scale:1.05 }} 
                          whileTap={{ scale: 0.95  }}  
                              className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5'
                              onClick={() => handleMakeOwner(currentTeam.team_uuid, false)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-4 h-4">
                              <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                            </svg>
                              <span className='text-sm ml-1'>Remove Owner</span>

                          </motion.button>
                        {/* } */}
                        {/* {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username != currentTeam.user_username && participants[userInfo.user_username] && participants[userInfo.user_username].chatroom_member_is_owner && */}
                          <motion.button 
                            whileHover={{ scale:1.05 }} 
                            whileTap={{ scale: 0.95  }} 
                              className='flex items-center text-red-500 dark:text-red-400 hover:text-red-400 dark:hover:text-red-600 opacity-0  ease-in-out duration-300 group-hover:opacity-100'
                              onClick={() => handleDeleteUser(currentTeam, 'team')}
                          >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
                              <span className='text-sm ml-1'>Delete</span>

                          </motion.button>
                        {/* } */}
                      </div>
                  </div>
              </div>
          ))}
        </div>
        <div>
          {users && Object.keys(users).length > 0 && users.map((currentUser) => (
              <div key={currentUser.user_username} className={`${currentUser.chatroom_member_has_left && 'hidden'} flex text-gray-600 dark:text-gray-300 items-center py-1 group`}>
                  <div className='relative'>
                      <Avatar 
                        sx={{  bgcolor: currentUser.user_color? currentUser.user_color : '' , width: 44, height: 44, }} 
                        style={{
                          background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${currentUser.user_color? currentUser.user_color:''} 100%)`,
                        }}
                        alt={currentUser.user_first_name || ''}
                        src={currentUser.user_image_url || ''} 
                      />
                      {currentUser.chatroom_member_is_owner &&
                      <LightTooltip title='Owner'>
                          <div className='absolute rounded-full bg-green-500 dark:bg-green-300 text-green-900 right-[-3px] top-[-3px] p-0.5'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-3 h-3">
                                <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                              </svg>
                          </div>
                      </LightTooltip>
                      }
                      {currentUser.is_external &&
                          <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                  <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                              </svg>
                          </div>
                      }
                  </div>
                  <div className='flex ml-2 grow overflow-hidden border-b-[0.01em] border-slate-200 dark:border-slate-600 py-1 items-center'>
                      <div className='flex flex-col '>
                          <div className='flex items-center '>
                                  <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{currentUser.user_first_name}&nbsp;{currentUser.user_last_name}</h1>
                                  <div className='ml-4'>
                                    <MemberTooltip title={
                                      <div className={`py-2 px-4 ${isDark ? 'bg-slate-700':'bg-white'} shadow-md rounded-xl`}>
                                        <h1 className={`${isDark ? 'text-gray-400':'text-gray-500'} text-sm`}> {currentUser.user_first_name} Organizations</h1>
                                        <div className='flex flex-col space-y-1'>
                                        {currentUser.organizations.map(org =>(
                                              <div key={`${currentUser.user_username}-${org.organization_uuid}`} className={`${isDark ? 'text-gray-200':'text-gray-600'} text-sm`}>{org.organization_name}</div>
                                          ))}
                                        </div>

                                      </div>
                                    }>
                                      <AvatarGroup  spacing='medium'  sx={{ '& .MuiAvatar-root': { border: '1px solid rgba(203, 213, 225, 0.1)' } }} >
                                          {currentUser.organizations.map(org =>(
                                            !org.chatroom_member_has_left &&
                                              <Avatar
                                                  key={`avatar-${currentUser.user_username}-${org.organization_uuid}`}
                                                  sx={{ 
                                                      bgcolor: 'rgba(59, 131, 246, 0.2)', 
                                                      fontSize:'12px',
                                                      width: 20, 
                                                      height: 20,
                                                      // filter: 'grayscale(100%)'
                                                  }}
                                                  alt={org.organization_name}
                                                  src={org.organization_logo_url}
                                              />
                                          ))}
                                      </AvatarGroup>
                                    </MemberTooltip>

                                  </div>
                          </div>
                          <div className='flex items-baseline '>
                                  <p className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate `}>{currentUser.user_username}</p>
                          </div>
                      </div>
                      <div className='ml-auto items-center flex space-x-2'>
                        {/* {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && !currentUser.chatroom_member_is_owner && participants[userInfo.user_username].chatroom_member_is_owner &&  */}
                          <motion.button 
                            whileHover={{ scale:1.05 }} 
                            whileTap={{ scale: 0.95  }}  
                              className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5'
                              onClick={() => handleMakeOwner(currentUser.user_username, true)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-4 h-4">
                              <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                            </svg>
                              <span className='text-sm ml-1'>Make Owner</span>

                          </motion.button>
                        {/* } */}
                        {/* {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && currentUser.chatroom_member_is_owner && participants[userInfo.user_username].chatroom_member_is_owner && currentUser.user_username != userInfo.user_username  && */}
                          <motion.button 
                          whileHover={{ scale:1.05 }} 
                          whileTap={{ scale: 0.95  }}  
                              className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5'
                              onClick={() => handleMakeOwner(currentUser.user_username, false)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-4 h-4">
                              <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                            </svg>
                              <span className='text-sm ml-1'>Remove Owner</span>

                          </motion.button>
                        {/* } */}
                        {/* {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username != currentUser.user_username && participants[userInfo.user_username] && participants[userInfo.user_username].chatroom_member_is_owner && */}
                          <motion.button 
                            whileHover={{ scale:1.05 }} 
                            whileTap={{ scale: 0.95  }} 
                              className='flex items-center text-red-500 dark:text-red-400 hover:text-red-400 dark:hover:text-red-600 opacity-0  ease-in-out duration-300 group-hover:opacity-100'
                              onClick={() => handleDeleteUser(currentUser, 'user')}
                          >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
                              <span className='text-sm ml-1'>Delete</span>

                          </motion.button>
                        {/* } */}
                      </div>
                  </div>
              </div>
          ))}
        </div>
      </div>
          {/* {participants && Object.keys(participants).length > 0 && Object.keys(participants).map((member) => (
              <div key={member} className={`${participants[member].chatroom_member_has_left && 'hidden'} flex text-gray-600 dark:text-gray-300 items-center py-1 group`}>
                  <div className='relative'>
                      <Avatar 
                        sx={{  bgcolor: participants[member].user_color? participants[member].user_color : '' , width: 44, height: 44, }} 
                        style={{
                          background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants[member].user_color? participants[member].user_color:''} 100%)`,
                        }}
                        alt={participants[member].user_first_name || ''}
                        src={participants[member].user_image_url || ''} 
                      />
                      {participants[member].chatroom_member_is_owner &&
                      <LightTooltip title='Owner'>
                          <div className='absolute rounded-full bg-green-500 dark:bg-green-300 text-green-900 right-[-3px] top-[-3px] p-0.5'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-3 h-3">
                                <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                              </svg>
                          </div>
                      </LightTooltip>
                      }
                      {participants[member].is_external &&
                          <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                  <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                              </svg>
                          </div>
                      }
                  </div>
                  <div className='flex ml-2 grow overflow-hidden border-b-[0.01em] border-slate-200 dark:border-slate-600 py-1 items-center'>
                      <div className='flex flex-col '>
                          <div className='flex items-center '>
                                  <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{participants[member].user_first_name}&nbsp;{participants[member].user_last_name}</h1>
                                  <div className='ml-4'>
                                    <MemberTooltip title={
                                      <div className={`py-2 px-4 ${isDark ? 'bg-slate-700':'bg-white'} shadow-md rounded-xl`}>
                                        <h1 className={`${isDark ? 'text-gray-400':'text-gray-500'} text-sm`}> {participants[member].user_first_name} Organizations</h1>
                                        <div className='flex flex-col space-y-1'>
                                        {participants[member].organizations.map(org =>(
                                              <div key={`${member}-${org.organization_uuid}`} className={`${isDark ? 'text-gray-200':'text-gray-600'} text-sm`}>{org.organization_name}</div>
                                          ))}
                                        </div>

                                      </div>
                                    }>
                                      <AvatarGroup  spacing='medium'  sx={{ '& .MuiAvatar-root': { border: '1px solid rgba(203, 213, 225, 0.1)' } }} >
                                          {participants[member].organizations.map(org =>(
                                            !org.chatroom_member_has_left &&
                                              <Avatar
                                                  key={`avatar-${member}-${org.organization_uuid}`}
                                                  sx={{ 
                                                      bgcolor: 'rgba(59, 131, 246, 0.2)', 
                                                      fontSize:'12px',
                                                      width: 20, 
                                                      height: 20,
                                                      // filter: 'grayscale(100%)'
                                                  }}
                                                  alt={org.organization_name}
                                                  src={org.organization_logo_url}
                                              />
                                          ))}
                                      </AvatarGroup>
                                    </MemberTooltip>

                                  </div>
                          </div>
                          <div className='flex items-baseline '>
                                  <p className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate `}>{participants[member].user_username}</p>

                          </div>
                      </div>
                      <div className='ml-auto items-center flex space-x-2'>
                        {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && !participants[member].chatroom_member_is_owner && participants[userInfo.user_username].chatroom_member_is_owner && 
                          <motion.button 
                            whileHover={{ scale:1.05 }} 
                            whileTap={{ scale: 0.95  }}  
                              className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5'
                              onClick={() => handleMakeOwner(participants[member], true)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-4 h-4">
                              <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                            </svg>
                              <span className='text-sm ml-1'>Make Owner</span>

                          </motion.button>
                        }
                        {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && participants[member].chatroom_member_is_owner && participants[userInfo.user_username].chatroom_member_is_owner && participants[member].user_username != userInfo.user_username  &&
                          <motion.button 
                          whileHover={{ scale:1.05 }} 
                          whileTap={{ scale: 0.95  }}  
                              className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5'
                              onClick={() => handleMakeOwner(participants[member], false)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-4 h-4">
                              <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                            </svg>
                              <span className='text-sm ml-1'>Remove Owner</span>

                          </motion.button>
                        }
                        {chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username != participants[member].user_username && participants[userInfo.user_username] && participants[userInfo.user_username].chatroom_member_is_owner &&
                          <motion.button 
                            whileHover={{ scale:1.05 }} 
                            whileTap={{ scale: 0.95  }} 
                              className='flex items-center text-red-500 dark:text-red-400 hover:text-red-400 dark:hover:text-red-600 opacity-0  ease-in-out duration-300 group-hover:opacity-100'
                              onClick={() => handleDeleteUser(participants[member])}
                          >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
                              <span className='text-sm ml-1'>Delete</span>

                          </motion.button>
                        }
                      </div>
                  </div>
              </div>
          ))} */}

    </div>
  )
}

export default ChatMembersList


