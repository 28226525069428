import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';
import { LightTooltip } from '../../styles/Tooltip';
import GroupDropdownSelector from './GroupDropdownSelector';
import { putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';

function SelectedGroupComponent({selectedWorkOrder, getSelectedWorkOrder}) {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false)   
    const isDark = useRecoilValue(darkModeState); 
    const [selectedGroup, setSelectedGroup] = useState([])   
    const [selectedGroupUuid, setSelectedGroupUuid] = useState('')   

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_group && Object.keys(selectedWorkOrder.workorder_group).length > 0){
            setSelectedGroup(selectedWorkOrder.workorder_group)

        }else{
            setSelectedGroup([])
        }
    },[selectedWorkOrder])

    const handleCancel = () => {
        setIsEditing(false)
        // setSelectedPriority(selectedWorkOrder.workorder_priority)
        // setWorkOrderPriority(selectedWorkOrder.workorder_priority)
    }

    const handleUpdate = () => {

        if(!loading){
            setLoading(true)
            setIsEditing(false)
            // setWorkOrderPriority(selectedPriority)
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            {  "workorder_group_uuid": selectedGroupUuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoading(false)
                    getSelectedWorkOrder()
                    if(data && data.data ){
                        // setWorkOrderPriority(selectedPriority)
                    }else{
                        toast.error('Issues updating group')
                        // setSelectedPriority(selectedWorkOrder.workorder_priority)
                        // setWorkOrderPriority(selectedWorkOrder.workorder_priority)
                    }
                }
                else{
                    // setSelectedPriority(selectedWorkOrder.workorder_priority)
                    // setWorkOrderPriority(selectedWorkOrder.workorder_priority)
                    setLoading(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }

    }


  return (
    <div>
        <div className='flex flex-col group'>
            <div className={`flex transition-all duration-500 ${!isEditing ? "justify-center" : "justify-start"} text-gray-800 dark:text-white mb-2`}>
                <div className='relative'>
                    <span className='font-medium'>Group</span>
                    {!isEditing && (
                        <LightTooltip title='Edit group'>
                            <button 
                                className={`absolute right-[-20px] top-1 text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                                onClick={() => setIsEditing(true)}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>

                            </button>
                        </LightTooltip>
                    )}

                </div>
                {isEditing ? (
                    <div className='flex ml-1 space-x-2'>
                        <div 
                            className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} 
                            onClick={handleUpdate}
                        >
                            Save
                        </div>
                        <div 
                            className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                            onClick={handleCancel}
                        >
                            Cancel
                        </div>
                    </div>
                ):(
                    <div/>
                )}
            </div>
            {isEditing ? (
                <div className="flex items-center">
                    <GroupDropdownSelector setSelectedGroupUuid={setSelectedGroupUuid} selectedGroup={selectedGroup} />
                </div>

            ):(
                <div className='relative flex justify-center space-x-2 items-center'>
                    {selectedGroup && Object.keys(selectedGroup).length > 0 ? (
                        <span 
                            className='text-lg text-center font-semibold'
                            style={{color: isDark ? selectedGroup.group_dark_color_hex : selectedGroup.group_light_color_hex}}
                        >
                            {selectedGroup.group_name}
                        </span>
                    ):(
                        <button
                            onClick={() => setIsEditing(true)} 
                            className='text-center  text-blue-600 dark:text-blue-400 italic text-sm'>
                            Add Group
                        </button>
                    )}
                </div>
            )}
        </div>
    </div>
  )
}

export default SelectedGroupComponent