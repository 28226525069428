import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon, PencilIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { deleteData, getData, putData } from '../../../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import {LightTooltip} from '../../../styles/Tooltip'
import AddCategoryButton from './AddCategoryButton';
import DowntimeCategoryButton from './DowntimeCategoryButton';
import moment from "moment"


  // var downtimeCategories = [
  //   {
  //     type:'None',
  //     // categories: ['No Shift']
  //   },
  //   {
  //     type:'Low',
  //     // categories: ['Break', 'Shift Change','Machine Setup', 'Planned Tool Change', 'Lunch Break', 'Inspection of Parts', 'Inpection of Tooling', 'Loading Material',]
  //   },
  //   {
  //     type:'Medium',
  //     // categories: ['Planned Maintenance', 'Unplanned Tool Change', 'Machine Fault', 'No Job', ]
  //   },
  //   {
  //     type:'High',
  //     // categories: ['Equipment Failure', 'Unplanned Maintenance', 'Lack of Material', 'Lack of Tooling', 'No Personnel']
  //   },
   
  // ]


// const downtimeList = [
//   {
//     id:1,
//     time:'2023-02-27T18:09:35.788Z',
//     downtimeAmount:'2.1h',
//     name:'Unclassified'
//   },
//   {
//     id:2,
//     time:'2023-02-27T17:41:35.771Z',
//     downtimeAmount:'2.1h',
//     name:'Unclassified'
//   },
//   {
//     id:3,
//     time:'2023-02-27T17:05:35.753Z',
//     downtimeAmount:'2.1h',
//     name:'Unclassified'
//   },
//   {
//     id:4,
//     time:'2023-02-27T15:13:35.700Z',
//     downtimeAmount:'2.1h',
//     name:'Unclassified'
//   },
// ]

function EditDowntimeClassificationModal({btnType, remoteOpen, selectedDowntime, refreshActivityList, downtimeCategories, refreshDowntimeCategories, handleCloseModal}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loading, setLoading] = useState(false)
    const [loadingRemoved, setLoadingRemoved] = useState(false)
    const [downtimeList, setDowntimeList] = useState([])
    const [selectedDowntimeCategory, setSelectedDowntimeCategory] = useState([])
    const [additionalNotes, setAdditionalNotes] = useState('')
    const [numberOfDowntimes, setNumberOfDowntimes] = useState(null)
    

    useEffect(() => {
      if(remoteOpen){
        setIsOpen(true)
      }
    },[remoteOpen])



    

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  

  const submitClassifyDowntime = () => {
    try {
      if(!loading && selectedDowntime && Object.keys(selectedDowntime).length > 0 && selectedDowntimeCategory && Object.keys(selectedDowntimeCategory).length > 0 ){
        setLoading(true)
        putData(apiLink + '/v1/downtime/' + selectedDowntime.utilization_id + '/classify', 
        {'downtime_downtime_category_id': selectedDowntimeCategory.downtime_category_id,
          'downtime_note': additionalNotes})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              refreshActivityList()
              setLoading(false)
              closeModal()
              toast.success('Downtime has been classified')
              if(handleCloseModal){
                handleCloseModal()
              }
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })
      }
    } catch (error) {
      
    }
  }

  const formatDate = (end) => {
    if(end){
        var endDate = new Date(end)
        var endHours = endDate.getHours();
        var endMinutes = endDate.getMinutes();
        var endAmPm = endHours >= 12 ? 'pm' : 'am';
        endHours = endHours % 12;
        endHours = endHours ? endHours : 12; // the hour '0' should be '12'
        endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
        var endStrTime = endHours + ':' + endMinutes + ' ' + endAmPm;
        var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
        // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
        // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
        return shortEndDate + "  " + endStrTime;
    }
  }

  const formatTime = (seconds) => {
    if(seconds){
      let formatted = moment.utc(seconds*1000).format('HH:mm:ss')
      return formatted
    }else{
        let formatted = moment.utc(0).format('HH:mm:ss')
        return formatted
    }
  }


  const handleSelectedDowntimeCategory = (selectedCat) => {
    setSelectedDowntimeCategory(selectedCat)
  }

  const badgeStyle = (severity) => { 
    if(severity === 'None'){
        return{
            backgroundColor: isDark  ? 'rgba(212, 212, 212,0.3)' : 'rgba(212, 212, 212,0.5)',
            color: isDark  ? '#d4d4d4' : '#737373',
          }
    }
    if(severity === 'Low'){
        return{
            backgroundColor: isDark  ? 'rgba(134, 239, 172,0.5)' : 'rgba(134, 239, 172,0.5)',
            color: isDark  ? '#86efac' : '#16a34a',
          }
    }
    if(severity === 'Medium'){
        return{
            backgroundColor: isDark  ? 'rgba(253, 230, 138,0.3)' : 'rgba(253, 230, 138,0.5)',
            color: isDark  ? '#fcd34d' : '#d97706',
          }
    }
    if(severity === 'High'){
        return{
            backgroundColor: isDark  ? 'rgba(252, 165, 165,0.3)' : 'rgba(252, 165, 165,0.3)',
            color: isDark  ? '#fca5a5' : '#ef4444',
          }
    }else{
        return{
            backgroundColor: isDark  ? 'rgba(252, 165, 165,0.3)' : 'rgba(252, 165, 165,0.3)',
            color: isDark  ? '#fca5a5' : '#ef4444',
          }
    }
    
  }

  const submitRemoveClassification = () => {
    try {
        if(!loadingRemoved){
          setLoadingRemoved(true)
            deleteData(apiLink + '/v1/downtime/' + selectedDowntime.utilization_id + '/classifications' )
                .then(async response => {
                  if(response.ok){ 
                    toast.success('Classification Removed')
                    setLoadingRemoved(false)
                    refreshActivityList()
                    handleCloseModal()
                  }
                  else{
                    try{
                      const data = await response.json();
                      toast.error(data.message)
                      setLoadingRemoved(false)
                    }catch (e){
                      toast.error('Something went wrong');
                      setLoadingRemoved(false)
                    }
                  }
                })
    
          }
        
    } catch (error) {
        
    }
}


  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        // <LightTooltip title="Categorize downtime">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className={`flex relative border-[1px] ${isDark ? 'text-gray-300  border-gray-400 hover:text-gray-100 hover:border-gray-300':'text-gray-500 border-gray-400 hover:text-gray-600 '} items-center bg-transparent rounded py-1 pl-1 pr-3 w-max`} onClick={openModal}>
              {/* <PlusSmIcon className='btnMainIcon' /> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='btnMainIcon '>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg> */}
              <span className='text-sm pl-2'>Edit Downtime</span>
              {numberOfDowntimes && 
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-medium leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{numberOfDowntimes}</span>
              }
          </motion.button>
        // </LightTooltip>
        }
        {btnType ==='inlineIconBtn' &&
          <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} flex ml-auto`} onClick={openModal}>
            <PencilIcon className={`w-5 h-5`}/>
          </button>
          }
      {btnType ==='tableBtn' &&
        <LightTooltip title="Classify asset downtime">
          <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
              <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
              <span className=' text-blue-600'>Classify</span>
          </motion.button>
        </LightTooltip>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95" 
              >
                <DialogPanel className={`w-full max-w-2xl overflow-scroll scrollbar-hide transform rounded-2xl text-left  shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                      <motion.div
                        key='classifySelectedDowtime'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='my-2  overflow-y-scroll scrollbar-hide'
                      >
                        <h6 className={`mb-1 px-4 py-2  text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                          Classify Downtime
                        </h6>
                        <div className='mx-4 py-1'>
                            <div className={`my-1 flex w-full ${isDark ? 'bg-slate-700 bg-opacity-50':'bg-slate-200 bg-opacity-30'} p-2 pl-3 rounded justify-between`}>
                                <div className={`flex flex-col text-left items-start pr-2`} >
                                    <span className={`${isDark?'text-gray-300':'text-gray-600'} font-base text-sm uppercase `}>{formatDate(selectedDowntime.utilization_start_timestamp)}</span>
                                    <span className={`text-base ${isDark?'text-gray-300':'text-gray-600'} `}>{selectedDowntime.downtime_category_name}</span>
                                </div>
                                <div className='w-[80px] text-center my-auto'>
                                    <div className='py-0 text-base rounded w-full' style={badgeStyle(selectedDowntime.downtime_severity_name)}>{selectedDowntime.downtime_severity_name}</div>
                                </div>
                                <div className={`flex items-center pr-1 my-auto`} >
                                    <span className={`text-base ${isDark?'text-gray-300':'text-gray-600'} `}>{formatTime(selectedDowntime.duration_s)}</span>
                                </div>
                            </div>
                            <div className='flex flex-col mt-3'>
                              <p className={`${isDark?'text-gray-300 ':'text-gray-600'} my-2 text-center text-lg`}>
                                  Select Downtime Category
                              </p>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-neutral-300 border-neutral-300':'text-neutral-600 border-neutral-600'} pr-0.5 w-fit`}>
                                  None
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'None' &&
                                  <div key={categoryType.downtime_category_id}  >
                                    <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories}  />
                                  </div>
                                    
                                ))}
                                <AddCategoryButton categoryType={'None'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={1}  />
                                </div>
                              </div>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-green-300 border-green-300':'text-green-500 border-green-500'} pr-0.5 w-fit`}>
                                  Low
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'Low' &&
                                    <div key={categoryType.downtime_category_id}  >
                                      <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories}  />
                                    </div>
                                ))}
                                <AddCategoryButton categoryType={'Low'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={2}  />
                                </div>
                              </div>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-amber-300 border-amber-300':'text-amber-500 border-amber-500'} pr-0.5 w-fit`}>
                                  Medium
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'Medium' &&
                                    <div key={categoryType.downtime_category_id}  >
                                      <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories}  />
                                    </div>
                                ))}
                                <AddCategoryButton categoryType={'Medium'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={3}  />
                                </div>
                              </div>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-red-300 border-red-300':'text-red-600 border-red-600'} pr-0.5 w-fit`}>
                                  High
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'High' &&
                                    <div key={categoryType.downtime_category_id}  >
                                      <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories}  />
                                    </div>
                                ))}
                                <AddCategoryButton categoryType={'High'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={4}  />
                                </div>
                              </div>
                              <div>
                                <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'additionalNotesInput'}>
                                    Additional notes
                                </label>
                                <textarea 
                                id='additionalNotesInput'
                                className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}  
                                type={'textarea'}
                                onChange={(e) => setAdditionalNotes(e.target.value)}
                                value={additionalNotes}
                                />
                            </div>

                            </div>
                        </div>

                        <div className={`flex p-3 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-red-100 bg-red-900 hover:bg-red-800 disabled:bg-red-600':'text-red-900 bg-red-100 hover:bg-red-200 disabled:bg-red-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitRemoveClassification}
                          disabled={loadingRemoved || !selectedDowntime.downtime_category_name }
                        >
                          Remove Classification 
                          {loadingRemoved && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                        </button>
                        <button
                          type="submit"
                          className={`relative ml-2 inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-600':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitClassifyDowntime}
                          disabled={loading || Object.keys(selectedDowntime).length === 0 ||  Object.keys(selectedDowntimeCategory).length === 0 }
                        >
                          Classify 
                          {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                        </button>
                      </div>
                      
                      </motion.div>

                </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default EditDowntimeClassificationModal