import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { getData, putData } from '../../../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import {LightTooltip} from '../../../styles/Tooltip'
import moment from "moment"
import AddCategoryButton from '../assetmodals/AddCategoryButton';
import DowntimeCategoryButton from '../assetmodals/DowntimeCategoryButton';

function DowntimeSelector({currentDowntimeCategoryId, downtimeCategories, refreshDowntimeCategories, apiKey, getDowntimeParameters, passBackState, setCurrentDowntimeCategoryId, apiUrl}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loading, setLoading] = useState(false)
    const [selectedDowntimeCategory, setSelectedDowntimeCategory] = useState([])

    useEffect(() => {
        if(currentDowntimeCategoryId){
            let foundObject = downtimeCategories.find(item => item.downtime_category_id == currentDowntimeCategoryId);
            if (foundObject) {
                setSelectedDowntimeCategory(foundObject);
            } else {
            console.error(`Object with ID ${currentDowntimeCategoryId} not found.`);
            }
        }

    },[currentDowntimeCategoryId])

  
    function closeModal() {
      setIsOpen(false)
    }
  
    function openModal() {
      setIsOpen(true)
    }
  
    
  
    const submitClassifyDowntime = (category) => {
      try {
        if(passBackState){
            if(category && Object.keys(category).length > 0 ){
                setCurrentDowntimeCategoryId(category.downtime_category_id)
                closeModal()
            }

        }else{
            if(!loading &&  category && Object.keys(category).length > 0 ){
              setLoading(true)
              let link = ''
              if(apiUrl && apiUrl.length > 0){
                link = apiUrl
              }else{
                link = `/v1/organizations/${selectedOrganization}/auto_down_classification`
              }

              putData(apiLink + link, 
              {
                [apiKey]: category.downtime_category_id
                })
                .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    // const data = await response.json();
                    toast.success('Downtime Category Updated')
                    getDowntimeParameters()
                    setLoading(false)
                    closeModal()
                  }
                  else{
                    try{
                      const data = await response.json();
                      setLoading(false)
                      toast.error(data.message)
                    }catch (e){
                      toast.error('Something went wrong')
                      setLoading(false)
                    }
                  }
                })
            }
        }
      } catch (error) {
        toast.error(error)
      }
    }
  
    const formatDate = (end) => {
      if(end){
          var endDate = new Date(end)
          var endHours = endDate.getHours();
          var endMinutes = endDate.getMinutes();
          var endAmPm = endHours >= 12 ? 'pm' : 'am';
          endHours = endHours % 12;
          endHours = endHours ? endHours : 12; // the hour '0' should be '12'
          endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
          var endStrTime = endHours + ':' + endMinutes + ' ' + endAmPm;
          var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
          // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
          // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
          return shortEndDate + "  " + endStrTime;
      }
    }
  
    const formatTime = (seconds) => {
      if(seconds){
        let formatted = moment.utc(seconds*1000).format('HH:mm:ss.SS')
        return formatted
      }else{
          let formatted = moment.utc(0).format('HH:mm:ss.SS')
          return formatted
      }
    }
  
  
    const handleSelectedDowntimeCategory = (category) => {
      setSelectedDowntimeCategory(category)
      submitClassifyDowntime(category)
    }
  
  return (
    <div className='scrollbar-hide'>
      <div className="flex items-center justify-center">
        <motion.button  whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}  className={`flex relative border-[0.01em] text-sm ${isDark ? 'text-gray-800  bg-gray-300/90':'text-white  bg-gray-500'}  items-center rounded py-0.5 px-2 w-max`} onClick={openModal}>
        {/* <motion.button  whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}  className={`flex relative border-[0.01em] text-sm ${isDark ? 'text-gray-300  border-gray-400 hover:text-gray-100 hover:border-gray-300':'text-gray-500 border-gray-400 hover:text-gray-600 '}  items-center bg-transparent  rounded py-0.5 px-2 w-max`} onClick={openModal}> */}
           {selectedDowntimeCategory && selectedDowntimeCategory.downtime_category_name? selectedDowntimeCategory.downtime_category_name : ''}
        </motion.button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-2xl overflow-scroll scrollbar-hide transform rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                      <motion.div
                        key='classifySelectedDowtime'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='my-2 max-h-[800px] overflow-y-scroll scrollbar-hide'
                      >
                        <h6 className={`mb-1 px-4 py-2  text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'} scrollbar-hide`}>
                            Update Downtime Category
                        </h6>
                        <div className='mx-4 py-1'>
                            <div className='flex flex-col mt-3'>
                              {/* <p className={`${isDark?'text-gray-300 ':'text-gray-600'} my-2 text-center text-lg`}>
                                  Update Downtime Category
                              </p> */}
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-neutral-300 border-neutral-300':'text-neutral-600 border-neutral-600'} pr-0.5 w-fit`}>
                                  None
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'None' &&
                                  <div key={categoryType.downtime_category_id}  >
                                    <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories} />
                                  </div>
                                    
                                ))}
                                <AddCategoryButton categoryType={'None'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={1}/>
                                </div>
                              </div>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-green-300 border-green-300':'text-green-500 border-green-500'} pr-0.5 w-fit`}>
                                  Low
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'Low' &&
                                    <div key={categoryType.downtime_category_id}  >
                                      <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories} />
                                    </div>
                                ))}
                                <AddCategoryButton categoryType={'Low'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={2}/>
                                </div>
                              </div>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-amber-300 border-amber-300':'text-amber-500 border-amber-500'} pr-0.5 w-fit`}>
                                  Medium
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'Medium' &&
                                    <div key={categoryType.downtime_category_id}  >
                                      <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories} />
                                    </div>
                                ))}
                                <AddCategoryButton categoryType={'Medium'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={3}/>
                                </div>
                              </div>
                              <div className='mb-5'>
                                <div className={`border-b-[0.01em]  ${isDark?'text-red-300 border-red-300':'text-red-600 border-red-600'} pr-0.5 w-fit`}>
                                  High
                                </div>
                                <div className='flex flex-wrap pt-1'>
                                {Object.keys(downtimeCategories).length > 0 && downtimeCategories.map((categoryType, index) => (
                                  categoryType.downtime_severity_name === 'High' &&
                                    <div key={categoryType.downtime_category_id}  >
                                      <DowntimeCategoryButton categoryType={categoryType} selectedDowntimeCategory={selectedDowntimeCategory} handleSelectedDowntimeCategory={handleSelectedDowntimeCategory} refreshDowntimeCategories={refreshDowntimeCategories} />
                                    </div>
                                ))}
                                <AddCategoryButton categoryType={'High'} refreshDowntimeCategories={refreshDowntimeCategories} categorySeverity={4}/>
                                </div>
                              </div>

                            </div>
                        </div>

                        <div className={`flex p-3 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        {/* <button
                          type="submit"
                          className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-600':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitClassifyDowntime}
                          disabled={loading || Object.keys(selectedDowntimeCategory).length === 0 }
                        >
                          Update 
                          {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                        </button> */}
                      </div>
                      
                      </motion.div>

                </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild> 
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default DowntimeSelector