import { useEffect, Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'


const groupByList = [
    {
        name: 'Department',
        id: 'department',
        index: 0
    },
    {
        name: 'Status',
        id: 'status',
        index: 1
    },
    {
        name: 'Machine type',
        id: 'machine_type',
        index: 2
    },
]

function DepartmentFilterBtn({select}) {
   const [allGroupBy, setAllGroupBy] = useState([])
   const [tempSelectedGroupBy, setTempSelectedGroupBy] = useState([])
   const [selectedGroupBy, setSelectedGroupBy] = useState([])
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();


   useEffect(() =>{
        let tempGroupBy = []
        groupByList.map(type => {
            tempGroupBy.push({name: type.name, id: type.id})
        })
        setAllGroupBy(tempGroupBy)
   }, [])


   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch && Object.keys(allGroupBy).length >0){
            // Pulling all parts search parameters into an array
            let searchGroupBy = searchParams.get('group_by')
            // console.log('searchGroupBy', searchGroupBy)

            if(searchGroupBy === null){
                setSelectedGroupBy('')
            }else{
                groupByList.map(group =>{
                    if(group.id == searchGroupBy){
                        setSelectedGroupBy(group)
                    }
                })
            }

            
        }

    }, [location, allGroupBy])


   const handleChangeGroupBy = (e) => {
        if(e.target.value){
            setTempSelectedGroupBy(allGroupBy[e.target.value])
        }else{
            setTempSelectedGroupBy([])
        }
        
        
    }

    const handleClear = () => {

        setTempSelectedGroupBy([])
        setSelectedGroupBy([])

        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_yesterday = searchParams.getAll('shift_yesterday')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        setSearchParams({select,parts, departments, machine_types,tags, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_yesterday, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled})

    }

    const handleApplyFilter = () => {
        setSelectedGroupBy(tempSelectedGroupBy)

        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_yesterday = searchParams.getAll('shift_yesterday')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        
        if(Object.keys(tempSelectedGroupBy).length > 0){
            setSearchParams({select,parts, departments, machine_types, tags, group_by: tempSelectedGroupBy.id, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_yesterday, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled}) 
        }else{
            setSearchParams({select,parts, departments, machine_types,tags, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_yesterday, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled})
        }
        

    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
                onClick={() => setTempSelectedGroupBy([])}
            >
                <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }}   
                    className='bg-white w-3 h-3 rounded-full m-1'
                    onClick={(e) => {
                        if(Object.keys(selectedGroupBy).length > 0){
                            e.stopPropagation();
                            handleClear();
                        }
                    }}  
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedGroupBy).length > 0? ' rotate-45 ':'rotate-0'}  h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </motion.button>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300 truncate min-w-fit'>
                    Group by
                </div>
                {Object.keys(selectedGroupBy).length > 0 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                        <div className='flex '>
                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedGroupBy.name}</div>
                        </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-scroll scrollbar-hide rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Group by</div>
                        <div className="flex justify-center">
                            <div className="my-3 w-full outline-none">
                                <select defaultValue={selectedGroupBy?.index} onChange={handleChangeGroupBy}  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                    <option value={''}  >{""}</option>
                                    {groupByList.map((group, index) => (
                                        <option key={group.id} value={index}  >{group.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>    
                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default DepartmentFilterBtn