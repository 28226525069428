import { motion, AnimatePresence } from "framer-motion";
import MessagesSection from "./MessagesSection";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
// import CreateNewChatSection from "./CreateNewChatSection";
import { useRecoilValue } from "recoil";
import { insertMessageByDate, updateOrInsertMessageByDate, removeMessage } from "../../utils/MessageDataSorting";
// import PinnedMessagesListSection from "./PinnedMessagesListSection";
import { darkModeState } from "../../atoms/darkModeState";
import { getData } from "../../ApiCalls/DataApis";
import { apiLink } from "../../ApiCalls/ApisVariables";
import InfoSection from "./InfoSection";
import PinnedMessagesListSection from "../../MyMessaging/chatComponents/PinnedMessagesListSection";

function MessageDetails({userInfo, mqttMessage, allEmojis, interactionsEmojis, chatRoomId, selectedWorkOrder}) {
    const [selectedSubNav, setSelectedSubNav] = useState('messages')
    const location = useLocation();
    const [pinnedMessages, setPinnedMessages] = useState({})
    const [messages, setMessages] = useState({})
    const [isGroup, setIsGroup] = useState(false)
    const [participants, setParticipants] = useState([])
    const [chatInfo, setChatInfo] = useState([])
    const [loadNewData, setLoadNewData] = useState(false)
    const isDark = useRecoilValue(darkModeState)
    const [loadingChatInfo, setLoadingChatInfo] = useState(false)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [loadingPinnedMessages, setLoadingPinnedMessages] = useState(false)
    const [showMessageData, setShowMessageData] = useState(true)
    


    useEffect(() => {

      setParticipants([])
      setChatInfo([])
      setMessages({})
      setPinnedMessages({})
      setShowMessageData(false)

      if(chatRoomId){
        console.log('selectedWorkOrder', selectedWorkOrder)
        setChatInfo({
            chatroom_uuid: chatRoomId,
            chat_picture_url: null,
            chat_name: selectedWorkOrder.workorder_title,
            chat_color : '',
            chat_is_direct: false

        })

        setLoadNewData(true)

        setLoadingMembers(true)
        // getData(apiLink + '/v1/chats/' + selectedChatId + '/members')
        getData(apiLink + '/v1/chatrooms/' + chatRoomId + '/members')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            if(data && data.data && data.data[0]){
                // console.log('participants', data.data)
                setParticipants(data.data[0].all_users)
                setLoadingMembers(false)
            }
            }
        })
        

        
        setLoadingMessages(true)
        // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages')
        getData(apiLink + '/v1/chatrooms/' + chatRoomId + '/messages')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            if(data && data.data){
                // console.log('messages', data.data)
                setMessages(data.data)
                // setMessages(data.messages_array)
                setLoadingMessages(false)
            }
            }
        })
        

        setLoadingPinnedMessages(true)
        // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages?message_is_pinned=true')
        getData(apiLink + '/v1/chatrooms/' + chatRoomId + '/messages?message_is_pinned=true')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            if(data){
                if(data.data){
                setPinnedMessages(data.data)
                }else{
                setPinnedMessages({})
                }
                setLoadingPinnedMessages(false)
            }
            }
        })
      }else {
        setMessages({})
        setPinnedMessages({})
      }

    },[chatRoomId])


    useEffect(() => {
      if(!loadingMessages && !loadingMembers && !loadingChatInfo){
        setShowMessageData(true)
      }else{
        setShowMessageData(false)
      }

    }, [loadingMessages, loadingMembers, loadingChatInfo])

    
    useEffect(() => {
      if(mqttMessage){
          if(mqttMessage && mqttMessage.message && chatRoomId === mqttMessage.message.chatroom_uuid){
              
              if(mqttMessage.action === 'delete_message'){
                  const updatedMessages = removeMessage(messages, mqttMessage.message)
                  setMessages({...updatedMessages})
                  
                }else if(mqttMessage.action === 'pin_message'){
                    if(!mqttMessage.message.message_is_pinned_for_all){
                    
                    const updatedPinnedMessages = removeMessage(pinnedMessages, mqttMessage.message)
                    // console.log('updatedMessages', updatedMessages)
                    setPinnedMessages({...updatedPinnedMessages})
                    
                    }else{
                    // console.log('Add pin message', mqttMessage)
                    const updatedPinnedMessages = updateOrInsertMessageByDate(pinnedMessages, mqttMessage.message)
                    // console.log('updatedMessages', updatedMessages)
                    setPinnedMessages({...updatedPinnedMessages})
                    }

          } else{
            
            // console.log('mqttMessage', mqttMessage)
            const updatedMessages = updateOrInsertMessageByDate(messages, mqttMessage.message)
            // console.log('updatedMessages', updatedMessages)
            setMessages({...updatedMessages})

          }
        }
      }

    },[mqttMessage])

    // useEffect(() => {
    //   if(membersMqttMessage){
    //     let selectedChatId = searchParams.get('chatId')
    //     // console.log('Details mqttMessage', membersMqttMessage)
    //     if(membersMqttMessage.action === "update_members" && selectedChatId === membersMqttMessage.chat_uuid){
    //       // console.log('update_members mqttMessage', membersMqttMessage.members)
    //       setParticipants({...membersMqttMessage.members})

    //     } 

    //   }

    // }, [membersMqttMessage])

    // useEffect(() => {
    //   let selectedChatId = searchParams.get('chatId')
    //   // I need to make sure that all the states are properly updated before rending anything
    //   if(selectedChatId == currentChatId){
    //     if(participants && Object.keys(participants).length > 0 && chatInfo && Object.keys(chatInfo).length > 0){
    //       setLoadNewData(false)
    //     }
    //   }else{
    //     setLoadNewData(true)
    //   }

    // }, [participants, chatInfo, currentChatId])

    
  return (
    <div >
        {showMessageData &&  selectedSubNav && participants && Object.keys(participants).length > 0 && chatInfo && Object.keys(chatInfo).length > 0 &&
            subLinkPage(selectedSubNav, setSelectedSubNav, chatRoomId,messages, participants, userInfo, chatInfo, mqttMessage, allEmojis, interactionsEmojis, setMessages, pinnedMessages, setPinnedMessages)
        }
    </div>
  )
}

export default MessageDetails


const subLinkPage = (link, setSelectedSubNav, chatRoomId, messages, participants, userInfo, chatInfo, mqttMessage,allEmojis, interactionsEmojis, setMessages, pinnedMessages, setPinnedMessages) => {
    const allLinks = {
        messages: { link: "messages", content: <MessagesSection setSelectedSubNav={setSelectedSubNav} chatRoomId={chatRoomId} messages={messages} participants={participants} userInfo={userInfo} chatInfo={chatInfo}  mqttMessage={mqttMessage} allEmojis={allEmojis} interactionsEmojis={interactionsEmojis} setMessages={setMessages} pinnedMessages={pinnedMessages} setPinnedMessages={setPinnedMessages} /> },
        info: { link: "info", content: <InfoSection  participants={participants} chatInfo={chatInfo} userInfo={userInfo} setMainSelectedSubNav={setSelectedSubNav} /> },
        pinnedMessagesList: { link: "pinnedMessagesList", content: <PinnedMessagesListSection inputType='work_order' setSelectedSubNav={setSelectedSubNav}  pinnedMessages={pinnedMessages} setPinnedMessages={setPinnedMessages} userInfo={userInfo} participants={participants} chatInfo={chatInfo} messages={messages} setMessages={setMessages} mqttMessage={mqttMessage} allEmojis={allEmojis} interactionsEmojis={interactionsEmojis} /> },
    };
    
      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )  

            
}