import React, { useEffect, useState } from 'react'
import { deleteData, putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion';
import ColorPicker from '../../utils/ColorPicker';

function GroupOptionRow({option, selectedOption, setSelectedOption, setIsAddingNew, setIsOpen, isDark, getGroups, colorPalette}) {
    const [isEditing, setIsEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [newOptionColor, setNewOptionColor] = useState([]);
    const [newOptionValue, setNewOptionValue] = useState('');

    useEffect(() => {

        if(option && colorPalette){
            setNewOptionValue(option.group_name)
            const currentColor = colorPalette.find(pallet => pallet.tailwind_dark_color_id === option.group_dark_color_tailwind_id)
            setNewOptionColor(currentColor)
        }

    },[option, colorPalette])



    // const colorCSS = (colorData, isDark) => {
    //     if(colorData && colorData.tailwind_color_name){
            
    //         return { backgroundColor: isDark ? colorData.tailwind_dark_color_id : colorData.tailwind_light_color_id };
    //     }else{
    //         return { backgroundColor: '#0ea5e9' };
    //     }
    //   };



    const handleSelect = () => {
        setSelectedOption(option);
        setIsColorPickerOpen(false);
        setIsColorPickerOpen(false)
        setIsAddingNew(false)
        setIsOpen(false)
    }

    const handleDelete = () => {
        try {
            if(!loadingDelete){
                setLoadingDelete(true)
                deleteData(apiLink + '/v1/groups/' + option.group_uuid )
                .then(async response => {
                    if(response.ok){
                        // const data = await response.json();
                        toast.success('Group deleted')
                        setLoadingDelete(false)
                        setIsDeleting(false)
                        getGroups()
                    }
                    else{
                        try{
                            const data = await response.json();
                            setLoadingDelete(false)
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e)
                            setLoadingDelete(false)
                            }
                    }
                })
            }
            
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleCancelSave = () => {
        setIsEditing(false)
    }

    const handleSave = () => {
        setIsColorPickerOpen(false)
        setIsAddingNew(false)

        try{
            if (newOptionValue.trim()) {
              if(!loadingSave){
                setLoadingSave(true)
                //   console.log('newOptionValue', newOptionValue)
                //   console.log('newOptionColor', newOptionColor)

                  putData(apiLink + `/v1/groups/${option.group_uuid}`, 
                    {
                        "group_name": newOptionValue.trim(),
                        "group_light_color_tailwind_id": newOptionColor.tailwind_light_color_id,
                        "group_dark_color_tailwind_id": newOptionColor.tailwind_dark_color_id
                    })
                  .then(async response => {
                      const data = await response.json();
                      console.log('PUT Group data', data)
                      if(response.ok){
                        getGroups() 
                        setIsEditing(false);
                        setIsColorPickerOpen(false);
                        setLoadingSave(false)
                        
                      }
                      else{
                      try{
                          const data = await response.json();
                          setLoadingSave(false)
                          toast.error(data.message)
                      }catch (e){
                          toast.error('Something went wrong')
                          setLoadingSave(false)
                      }
                      }
                  })
                  
              }
            }
        }catch(e){
            toast.error(e)
        }

    }

  return (
    <li
        key={option.group_uuid}
    >
        {!isEditing && !isDeleting && (
            <div className={`flex items-center cursor-pointer pr-1 py-1  items-center rounded-md ${isDark ? 'hover:bg-slate-800':' hover:bg-slate-100'}`}>
                <div className='mr-0.5' onClick={handleSelect}>
                    {selectedOption?.group_uuid === option.group_uuid ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"  className={`w-4 h-4 ${isDark ? 'text-gray-300':'text-gray-600'}`}>
                            <path d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"/>
                        </svg>
                    ):(
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"  className={`w-4 h-4 text-transparent`}>
                            <path d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"/>
                        </svg>
                    )}
                </div>
                <div
                    onClick={handleSelect} 
                    className="block truncate font-medium text-sm grow" style={{ color: isDark ? option.group_dark_color_hex : option.group_light_color_hex}}
                >
                {option.group_name}
                </div>   
                <div className='ml-auto space-x-1.5 items-center'>
                    <button onClick={() => {
                        setIsAddingNew(false)
                            setIsEditing(true)
                        }} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 ${isDark ? 'text-blue-400 hover:text-blue-300':'text-blue-500 hover:text-blue-600'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                        </svg>
                    </button>
                    <button onClick={() => setIsDeleting(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 ${isDark ? 'text-blue-400 hover:text-blue-300':'text-blue-500 hover:text-blue-600'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </button>
                </div>

            </div>
        )}
        {isEditing && !isDeleting && (
            // <div className={`flex items-center px-2 py-1 rounded-md `}>
            <div className={`flex items-center px-2 py-1 rounded-md w-full max-w-full overflow-x-hidden`}>
                <motion.div
                    key={`${option.group_uuid}-editOption`}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className=''
                >
                    
                    <div className="flex flex-col">
                        <div className={`flex items-center ${isDark? 'bg-slate-800':'bg-slate-100'} rounded-lg px-2 py-1 min-w-0`}>
                            <button
                                type="button"
                                onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                                className="p-0.5 rounded-lg"
                                // style={colorCSS(newOptionColor, isDark)}
                                style={{ backgroundColor: isDark ? newOptionColor.tailwind_dark_color_hex : newOptionColor.tailwind_light_color_hex }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5`}>
                                    <path xmlns="http://www.w3.org/2000/svg" d="M440-80q-33 0-56.5-23.5T360-160v-160H240q-33 0-56.5-23.5T160-400v-280q0-66 47-113t113-47h440q17 0 28.5 11.5T800-800v400q0 33-23.5 56.5T720-320H600v160q0 33-23.5 56.5T520-80h-80ZM240-560h480v-200h-40v120q0 17-11.5 28.5T640-600q-17 0-28.5-11.5T600-640v-120h-40v40q0 17-11.5 28.5T520-680q-17 0-28.5-11.5T480-720v-40H320q-33 0-56.5 23.5T240-680v120Zm0 160h480v-80H240v80Zm0 0v-80 80Z"/>
                                </svg>
                            </button>
                            <input className={`w-full min-w-0 py-0  bg-transparent text-gray-600 border-0 border-gray-300 
                                            ${isDark? 'focus:ring-gray-500 text-white placeholder:text-gray-400':'focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '}
                                            focus:ring-[0px] text-sm text-ellipsis `}
                                autoFocus
                                type="text"
                                value={newOptionValue}
                                onChange={(e) => setNewOptionValue(e.target.value)}
                                placeholder="Type and press Enter..."
                                // onKeyDown={(e) => {
                                //     if (e.key === 'Enter' || e.key === 'Tab') {
                                //         handleAddOption(e);
                                //         setIsColorPickerOpen(false)
                                //         setIsAddingNew(true)
                                //     }
                                // }}
                            />
                        </div>
                        <motion.div
                            key='colorPickerContainer'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            {colorPalette && Object.keys(colorPalette).length > 0 && (
                                <ColorPicker
                                    selectedColor={newOptionColor} 
                                    onColorSelect={setNewOptionColor}
                                    isOpen={isColorPickerOpen}
                                    setIsOpen={setIsColorPickerOpen}
                                    isDark={isDark}
                                    colorPalette={colorPalette}
                                    />
                            )}
                        </motion.div>
                    </div>
                </motion.div>
                <button className={` ${isDark?'text-red-500 hover:text-red-600 ':'text-red-500 hover:text-red-700 '} flex ml-2`} onClick={handleCancelSave}>
                    {/* X icon */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" stroke="currentColor" strokeWidth={20} className='w-5 h-5'>
                        <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z"/>
                    </svg>
                </button>
                {/* <button className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex ml-2`} onClick={handleCancelSave}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className='w-5 h-5'>
                    <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z"/>
                </svg>
                </button> */}
                <button disabled={loadingSave} className={`relative ${isDark?'text-green-500 hover:text-green-600 ':'text-green-500 hover:text-green-700 '} ml-1 `} onClick={handleSave}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" stroke="currentColor" strokeWidth={20} className='w-5 h-5'>
                        <path d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"/>
                    </svg>
                    {loadingSave && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-0 w-5 h-5 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button>
                {/* <button disabled={loadingSave} className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white`} onClick={handleSave}>
                    Save
                    {loadingSave && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button> */}
                
            </div>
        )}
        {!isEditing && isDeleting && (
            <div className={`flex items-center cursor-pointer pl-2 py-1  items-center rounded-md ${isDark ? 'hover:bg-slate-800':' hover:bg-slate-100'}`}>
                <span className={`text-sm text-left font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Are you sure you want to delete group {option.group_name}?</span>
                <button className={` ${isDark?'text-gray-300 hover:text-gray-200 ':'text-gray-500 hover:text-gray-700 '} flex ml-2`} onClick={() => setIsDeleting(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" fill="currentColor" className={`w-5 h-5`}>
                    <path xmlns="http://www.w3.org/2000/svg" d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z"/>
                    </svg>
                </button>
                {/* <button className={` ml-auto shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex ml-2`} onClick={() => setIsDeleting(false)}>
                    Cancel
                </button> */}
                <button disabled={loadingDelete} className={`relative ${isDark?'text-red-500 hover:text-red-600 ':'text-red-500 hover:text-red-700 '} flex ml-1`} onClick={handleDelete}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                    {loadingDelete && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button>
                {/* <button disabled={loadingDelete} className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-xs bg-red-600 hover:bg-red-700 hover:border-red-700 text-white `} onClick={handleDelete}>
                    Delete
                    {loadingDelete && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button> */}
                
            </div>
        )}
    </li>
  )
}

export default GroupOptionRow