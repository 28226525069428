import React, { useEffect, useState } from 'react'
import { statusOptions, statusXLOptions } from '../statusOptions'
import { useSearchParams } from 'react-router-dom'
import { putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import toast from 'react-hot-toast'

function StatusButtons({selectedWorkOrder}) {
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentStatus, setCurrentStatus] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(false);


    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[])

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if(selectedWorkOrder && selectedWorkOrder.workorder_status && Object.keys(selectedWorkOrder.workorder_status).length > 0){
                let status = statusOptions.find((status) => status.workorder_status_uuid === selectedWorkOrder.workorder_status.workorder_status_uuid)
                setCurrentStatus(status)
            }
        }

    },[selectedWorkOrder])

    
    const handleStatusChange = (newStatus) => {
        setCurrentStatus(newStatus)
        if(!loadingStatus){
            setLoadingStatus(true)
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            {'workorder_status_uuid': newStatus.workorder_status_uuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    // console.log('data',data)
                    setLoadingStatus(false)
                }
                else{
                    let status = statusOptions.find((status) => status.workorder_status_uuid === selectedWorkOrder.workorder_status_uuid)
                    setCurrentStatus(status)
                    setLoadingStatus(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }
      };

  return (
        <div className='flex flex-col w-full'>
            <h6 className='text-gray-800 dark:text-white mb-2 font-medium'>
                Status
            </h6>
            <div className="grid grid-cols-4 gap-2 sm:hidden">
                {statusOptions.map((status) => (
                    <button
                    key={status.id}
                    onClick={() => handleStatusChange(status)}
                    className={`
                        flex flex-col space-y-0.5 items-center justify-center px-1 py-1.5 rounded-xl text-sm font-medium border-[0.01em] border-slate-200 dark:border-slate-600
                        transition-all duration-200 ease-in-out
                        ${currentStatus.workorder_status_uuid === status.workorder_status_uuid 
                        ? status.color 
                        : 'text-blue-500 dark:text-blue-200 bg-gray-50 dark:bg-slate-800 hover:bg-gray-200'}
                        ${currentStatus.workorder_status_uuid === status.workorder_status_uuid 
                        ? 'cursor-default' 
                        : 'cursor-pointer'}
                    `}
                    disabled={currentStatus === status.workorder_status_uuid}
                    >
                    {status.icon}
                    <span>{status.workorder_status_name}</span>
                    </button>
                ))}
            </div>
            <div className="hidden sm:flex space-x-2">
                {statusXLOptions.map((status) => (
                    <button
                    key={status.id}
                    onClick={() => handleStatusChange(status)}
                    className={`
                        flex flex-col space-y-1 items-center justify-center px-3 pt-2 pb-2 w-[150px] rounded-xl border-[0.01em] border-slate-200 dark:border-slate-600
                        transition-all duration-200 ease-in-out
                        ${currentStatus.workorder_status_uuid === status.workorder_status_uuid 
                        ? status.color 
                        : 'text-blue-500 dark:text-blue-200 bg-gray-50 dark:bg-slate-800 hover:bg-gray-200'}
                        ${currentStatus.workorder_status_uuid === status.workorder_status_uuid 
                        ? 'cursor-default' 
                        : 'cursor-pointer'}
                    `}
                    disabled={currentStatus === status.workorder_status_uuid}
                    >
                    {status.icon}
                    <span className='text-base'>{status.workorder_status_name}</span>
                    </button>
                ))}
            </div>
        </div>
  )
}

export default StatusButtons