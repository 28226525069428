import React, { useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import MobileRightDrawer from '../components/MobileRightDrawer'
import { useLocation, useSearchParams } from 'react-router-dom'
import WorkOrderViewSelector from '../components/filterButtons/WorkOrderFilters/WorkOrderViewSelector'
import { motion, AnimatePresence } from 'framer-motion'
import WorkOrderTableView from './tableViewComponents/WorkOrderTableView'
import { globalSelectedOrganization } from '../atoms/orgStates'
import { getData, putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { useRecoilValue } from 'recoil'
import NewWorkOrderModal from '../components/modals/workOrderModals/NewWorkOrderModal'
import SelectedWorkOrderPage from './SelectedWorkOrderPage'
import WOCalendarView from './calendarViewComponents/WOCalendarView'
import WOToDoView from './WOToDoView'
import SearchTextFilter from '../components/filterButtons/SearchTextFilter'
import SortByWOFilterBtn from '../components/filterButtons/WorkOrderFilters/SortByWOFilterBtn'
import PriorityWOFilterBtn from '../components/filterButtons/WorkOrderFilters/PriorityWOFilterBtn'
import StatusWOFilterBtn from '../components/filterButtons/WorkOrderFilters/StatusWOFilterBtn'
import SearchQFilter from '../components/filterButtons/SearchQFilter'
import DateRangeFilterWOBtn from '../components/filterButtons/WorkOrderFilters/DateRangeFilterWOBtn'
import WOKanbanView from './kanbanViewComponents/WOKanbanView'
import AssignedWOFilterBtn from '../components/filterButtons/WorkOrderFilters/AssignedWOFilterBtn'


function WorkOrdersPage({mqttMessage, userInfo}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const [currentView, setCurrentView] = useState(null)
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const location = useLocation();
    const [allWorkOrdersList, setAllWorkOrdersList] = useState([])
    const [allWorkOrdersDoneList, setAllWorkOrdersDoneList] = useState([])
    const [workOrdersList, setWorkOrdersList] = useState([])
    const [workOrdersDoneList, setWorkOrdersDoneList] = useState([])
    const [isAddingColumn, setIsAddingColumn] = useState(false);
    const [isManagingColumns, setIsManagingColumns] = useState(false);
    const [filterAssignees, setFilterAssignees] = useState([]);

    
    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        let selectedWorkOrder = searchParams.get('selected_wo')
        setSelectedWorkOrderUuid(selectedWorkOrder)
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])


    useEffect(() => {
        if(selectedOrganization){
            // let selectedWorkOrder = searchParams.get('selected_wo')
            // if(selectedWorkOrder) return
            getWorkOrders()
        }
    }, [selectedOrganization, location])

    useEffect(() => {

        if(filterAssignees && Object.keys(filterAssignees).length > 0){
            let filteredSections = []

            allWorkOrdersList.map(section => {
                let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                if(isMatching && Object.keys(isMatching).length > 0){
                    filteredSections.push(section)
                }
            })
            setWorkOrdersList(filteredSections)


            //Done Work Order
            let filteredDoneSections = []
            allWorkOrdersDoneList.map(section => {
                let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                if(isMatching && Object.keys(isMatching).length > 0){
                    filteredDoneSections.push(section)
                }
            })
            setWorkOrdersDoneList(filteredDoneSections)


        }else{
            setWorkOrdersList(allWorkOrdersList)
            setWorkOrdersDoneList(allWorkOrdersDoneList)
        }
    },[filterAssignees])

    const getWorkOrders = () => {

        if(selectedOrganization){
            let currentSearch = location.search
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/workorders' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Workorders JSON.stringify', JSON.stringify(data.data[0].workorders.slice(0,3)))
                            // console.log('Workorders ', data.data)
                            setAllWorkOrdersList(data.data)
                            // setWorkOrdersList(data.data)

                            if(filterAssignees && Object.keys(filterAssignees).length > 0){
                                let filteredSections = []
                    
                                data.data.map(section => {
                                    let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                                    if(isMatching && Object.keys(isMatching).length > 0){
                                        filteredSections.push(section)
                                    }
                                })
                                setWorkOrdersList(filteredSections)
                    
                            }else{
                                setWorkOrdersList(data.data)
                            }
                            
                            
                        }else{
                            setAllWorkOrdersList([])
                            setWorkOrdersList([])
                        }
                    }else{
                        setAllWorkOrdersList([])
                        setWorkOrdersList([])
                    }
                })
            let doneSearch = '?show_done_only=true'
            if(currentSearch && currentSearch.length > 0){
                doneSearch = currentSearch + '&show_done_only=true'
            }
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/workorders' + doneSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Workorders JSON.stringify', JSON.stringify(data.data[0].workorders.slice(0,3)))
                            // console.log('DONE Workorders ', data.data)
                            // setWorkOrdersDoneList(data.data)
                            setAllWorkOrdersDoneList(data.data)

                            if(filterAssignees && Object.keys(filterAssignees).length > 0){
                                let filteredSections = []
                    
                                data.data.map(section => {
                                    let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                                    if(isMatching && Object.keys(isMatching).length > 0){
                                        filteredSections.push(section)
                                    }
                                })
                                setWorkOrdersDoneList(filteredSections)
                    
                            }else{
                                setWorkOrdersDoneList(data.data)
                            }
                            
                        }else{
                            setWorkOrdersDoneList([])
                            setAllWorkOrdersDoneList([])
                        }
                    }else{
                        setWorkOrdersDoneList([])
                        setAllWorkOrdersDoneList([])
                    }
                })
        }

    }



  return (
    <div className={`${currentView == 'calendar_view'? 'mt-4':'pt-4'} pl-5 pr-5  ${isMobile ? 'h-[calc(100vh-0.5rem)]' : 'h-[calc(100vh-3rem)]'} overflow-scroll scrollbar-hide`}>
        <AnimatePresence mode='wait'>
            {currentView != 'todo_view' && selectedWorkOrderUuid && selectedWorkOrderUuid.length > 0 ? (
                <motion.div 
                    key={'SelectedWorkOrderContainer'}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                  <SelectedWorkOrderPage mqttMessage={mqttMessage} userInfo={userInfo} />
              </motion.div>
            ): (
                <motion.div 
                    key={'WorkOrderContainer'}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                {/* <div className={`${isMobile ? 'flex':'flex md:hidden'} justify-end mr-4`}>
                    <MobileRightDrawer >
                        <div className='flex flex-col space-y-3'>
                        </div>

                    </MobileRightDrawer>
                </div> */}
                {/* Filter section */}
                <div className={`${isMobile && 'hidden'} flex flex-col lg:flex-row lg:items-center justify-between mb-2 lg:space-x-2`}>
                    <div className='flex space-x-2'>
                        <StatusWOFilterBtn />
                        <PriorityWOFilterBtn />
                        {currentView && currentView != 'calendar_view' && (
                            <SortByWOFilterBtn />
                        )}
                        <DateRangeFilterWOBtn />
                        <AssignedWOFilterBtn setFilterAssignees={setFilterAssignees} />
                    </div>
                    <div className='flex space-x-2 mt-2 ml-auto lg:mt-0'>
                        <WorkOrderViewSelector setCurrentView={setCurrentView} />
                        <NewWorkOrderModal btnType='standardBtn' getWorkOrders={getWorkOrders} />
                    </div>
                </div>
                <div className='flex'>
                    <SearchQFilter />
                </div>
                {currentView ? (
                    <div className='mt-1'>
                        {showView(currentView, isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage)}
                    </div>
                ):(
                    <div />
                )}
                </motion.div>
            )}
        </AnimatePresence>
    </div>
  )
}

export default WorkOrdersPage


const showView = (view, isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage) =>{
    const allLinks = {
        todo_view: { content: <WOToDoView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} workOrdersDoneList={workOrdersDoneList} setWorkOrdersList={setWorkOrdersList} getWorkOrders={getWorkOrders} userInfo={userInfo} mqttMessage={mqttMessage} />},
        table_view: { content: <WorkOrderTableView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} setWorkOrdersList={setWorkOrdersList}  /> },
        calendar_view: { content: <WOCalendarView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} getWorkOrders={getWorkOrders} />},
        kanban_view: { content: <WOKanbanView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} getWorkOrders={getWorkOrders} workOrdersDoneList={workOrdersDoneList} />},
        // workload_view: { content: <div>Workload </div>},
    };
    

    return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={view}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className='pb-10'
        >
            {allLinks[view].content} 
        </motion.div>
      </AnimatePresence>
    )  
}