
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/solid'
import IoTFlowsIcon from '../images/IoTFlowsIcon.png'
import { useState, Fragment, useEffect } from 'react'
import { Listbox, Transition, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { useRecoilState } from 'recoil';
import {addOrgModalState} from '../atoms/modalStates'
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useNavigate, useLocation } from 'react-router-dom';
import {globalSelectedOrganization, globalSelectedOrganizationTimezone} from '../atoms/orgStates'
import { tzStrings, tzInts } from '../data/TimezonesList';
import { darkModeState } from '../atoms/darkModeState';


function OrganizationDropdownList({orgList, showGODview}) {
    const [isDark, setIsDark] = useRecoilState(darkModeState)
    const [filteredOrgList, setFilteredOrgList] = useState(orgList)
    const [selectedOrganization, setSelectedOrganization] = useState(orgList[0])
    const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useRecoilState(addOrgModalState)
    const [currentSelectedOrg, setCurrentSelectedOrg] = useRecoilState(globalSelectedOrganization)
    const [currentSelectedOrgTimeZone, setCurrentSelectedOrgTimeZone] = useRecoilState(globalSelectedOrganizationTimezone)
    const location = useLocation()
    const navigate = useNavigate();
    const [orgSearchText, setOrgSearchText] = useState('')

    useEffect(() => {
        setFilteredOrgList(orgList)
    },[orgList])


    useEffect(() => {
        if(location && location.pathname && orgList && Object.keys(orgList).length > 0){
            
            let paths = location.pathname.split("/")
            // console.log('selectedOrganization.organization_uuid', selectedOrganization.organization_uuid)
            // console.log('paths[1]', paths[1])

            // if((paths && (paths[1] != selectedOrganization.organization_uuid))){
                if( paths && paths[1]){
                // if(paths && paths[1] &&  (paths[1] != selectedOrganization.organization_uuid)){
                    orgList.map(org => {
                        if(org.organization_uuid == paths[1]){
                            setSelectedOrganization(org)
                            setCurrentSelectedOrg(org.organization_uuid)
                            let timeZoneLabel = ''
                            {tzStrings.map((tz, index) => {
                                if(tz.value == org.organization_time_zone){
                                    timeZoneLabel = tz.label.slice(4, 10)
                                    setCurrentSelectedOrgTimeZone(
                                        {
                                            name: org.organization_time_zone,
                                            value: timeZoneLabel,
                                            full_name:tz.label
                                        }
                                    )
                                    
                                }
                            })}
                        }
                    }) 
                }else{
                    setSelectedOrganization(orgList[0])
                    setCurrentSelectedOrg(orgList[0].organization_uuid)
                    let timeZoneLabel = ''
                    {tzStrings.map((tz, index) => {
                        if(tz.value == orgList[0].organization_time_zone){
                            timeZoneLabel = tz.label.slice(4, 10)
                            setCurrentSelectedOrgTimeZone({
                                name: orgList[0].organization_time_zone,
                                value:timeZoneLabel
                            })
                        }
                    })}
                    navigate(`/${orgList[0].organization_uuid}`)
                }


        }
    },[orgList])


    useEffect(() => {
        if(orgList && Object.keys(orgList).length > 0){
            
            orgList.map(org => {
                if(org.organization_uuid == currentSelectedOrg){
                    setSelectedOrganization(org)
                }
            }) 
               
        }

    }, [currentSelectedOrg])

    const handleSelectedOrganizationChange = (e) => {
        
        if(e === 'newOrganization'){
            console.log(e)
            setIsAddOrgModalOpen(true)
        }else{
            if(e && e.organization_uuid){
                setSelectedOrganization(e)
                setCurrentSelectedOrg(e.organization_uuid)
                navigate(`/${e.organization_uuid}/assets?select=overview&group_by=department`)
                let timeZoneLabel = ''
                {tzStrings.map((tz, index) => {
                    if(tz.value == e.organization_time_zone){
                        timeZoneLabel = tz.label.slice(4, 10)
                        setCurrentSelectedOrgTimeZone({
                            name: e.organization_time_zone,
                            value:timeZoneLabel
                        })
                    }
                })}
            }
        }
        
    }

    const handleFilterOrgList = (e) => {
        setOrgSearchText(e.target.value)
        if (!Array.isArray(orgList)) {
            throw new Error("Input must be a JSON array");
          }
        
        // Convert both name and array elements to lowercase for case-insensitive matching
        const lowercaseName = e.target.value.toLowerCase();
        const lowercasedData = orgList.map(item => ({ ...item, organization_name: item.organization_name.toLowerCase() }));

        let filteredArray = []
        lowercasedData.filter((item,index) => {
            const itemName = item.organization_name;
            if (itemName.includes(lowercaseName)) {
                filteredArray.push(orgList[index])
            }
        });
        setFilteredOrgList(filteredArray)

 }
   

  return (
    <div>   
        

        <Listbox as="div" value={selectedOrganization} onChange={handleSelectedOrganizationChange} className='relative h-7 z-[999]'>
            <ListboxButton>
            <div className='flex max-w-[200px] space-x-1 oveflow-hidden items-center cursor-pointer '>
                {selectedOrganization && selectedOrganization.organization_logo_url?(
                        <img
                            src={selectedOrganization.organization_logo_url}
                            alt={selectedOrganization.organization_name}
                            className='h-7 w-7 rounded-md cursor-pointer'
                        />
                    ) : (
                        <div className='text-gray-400 dark:text-gray-300 h-7 w-7 rounded-md cursor-pointer items-center  group-hover:text-gray-300'>
                            <CorporateFareIcon style={{height:'1.50rem', width:'1.50rem', margin:'auto'}} />
                        </div>
                            
                    )}
                <div className='text-gray-600 dark:text-gray-100 text-sm hidden md:inline-flex truncate max-w-[125px] xl:max-w-[145px]'>
                    <span>{selectedOrganization?.organization_name}</span>
                </div>
                <ChevronDownIcon className="h-5 w-5 text-gray-600 dark:text-gray-100 hidden sm:inline-flex"/>
            </div>
            </ListboxButton>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <ListboxOptions className="absolute left-32 max-h-[600px] w-[275px]  scrollbar-hide overflow-y-scroll -translate-x-1/2 bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg ">
                {showGODview &&
                <div >
                    <input className={`w-full ${isDark? 'hover:bg-gray-700 focus:bg-gray-700 bg-gray-600 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400' : 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500'}  
                            text-xs rounded-lg text-ellipsis`}
                        value={orgSearchText} 
                        onChange={handleFilterOrgList} 
                        type="text" 
                        placeholder="Search..."
                        autoFocus
                        
                    />
                </div>
                }
                {filteredOrgList.map((org) => (
                    <ListboxOption key={org.organization_uuid} value={org}>
                    <div className='group flex w-full items-center cursor-pointer rounded-lg py-1 pl-1 pr-3 hover:bg-blue-600 text-gray-600 hover:text-white'>
                    {org.organization_logo_url?(
                        <img
                            src={org.organization_logo_url}
                            alt={org.organization_name}
                            className='h-7 w-7 rounded-md cursor-pointer'
                        />
                    ) : (
                        <div className='text-gray-400 dark:text-gray-300 h-7 w-7 rounded-md cursor-pointer items-center group-hover:text-gray-300'>
                            <CorporateFareIcon style={{height:'1.50rem', width:'1.50rem', margin:'auto'}} />
                        </div>
                            
                    )}
                        <div className='pl-1 dark:text-gray-100 text-sm truncate'>{org.organization_name}</div>
                    </div>
                    </ListboxOption>
                ))}
                    {showGODview &&
                    <ListboxOption  value={'newOrganization'}>
                    <div className='flex space-x-1 items-center cursor-pointer rounded-lg py-1.5 mb-1 pl-1 pr-3 hover:bg-blue-600 text-gray-600 hover:text-white'>
                        <PlusIcon  className='h-5 w-5 mx-1 dark:text-white' />
                        <div className=' dark:text-gray-100 text-sm truncate'>Add Organization</div>
                    </div>
                    </ListboxOption>
                    }
                </ListboxOptions> 
            </Transition>
        </Listbox>
    </div>
  )
}

export default OrganizationDropdownList