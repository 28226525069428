

export function formatDateToAmPm(dateString) {
    try {
        if(dateString){
            // Parse the date string considering UTC timezone
            const dateObj = new Date(dateString);
            // console.log('dateObj', dateObj)
      
          // Format the date in 12-hour AM/PM format with leading zeros for hours and minutes
          const formattedTime = dateObj.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true, // Use 12-hour clock
          });
      
          return formattedTime;
        }
    } catch (error) {
      console.error('Error formatting date:', error);
      // Optionally return an empty string or a default value for invalid dates
      return ''; // Or a default value like 'Invalid Date'
    }
  }


  export function formatDateToDayMonthYear(dateString) {
    try {
        if(dateString){
        // Parse the date string considering UTC timezone
        const dateObj = new Date(dateString);

        // Extract day, month (0-indexed), and year
        const day = dateObj.getDate();
        const month = dateObj.getMonth();
        const year = dateObj.getFullYear();
    
        // Use locale-aware formatting for full month name (options for different locales)
        const fullMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObj);
    
        // Format the date as "Numeric Day Full Month Numeric Year" (adjust as needed)
        return `${day} ${fullMonth} ${year}`;

        }
    } catch (error) {
        console.log('Error parsing date:', error);
        // Optionally return an empty string or a default value for invalid dates
        return ''; // Or a default value like 'Invalid Date'
    }
  }

  export function formatTimeOrDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    // Function to pad single digit numbers with leading zero
    const pad = (num) => num.toString().padStart(2, '0');
  
    // Check if the date is today
    if (date.toDateString() === now.toDateString()) {
      let hours = date.getHours();
      const minutes = pad(date.getMinutes());
      const ampm = hours >= 12 ? 'PM' : 'AM';
      
      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      
      return `${pad(hours)}:${minutes} ${ampm}`;
    } else {
      // If it's not today, return MM/DD/YY
      const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
      const day = pad(date.getDate());
      const year = date.getFullYear().toString().slice(-2);
      
      return `${month}/${day}/${year}`;
    }
  }


  export function formatRelativeTime(dateString) {
        if(dateString){
            const thenDate = new Date(dateString);
            const now = new Date();
            
            const diffInDays = Math.floor((now - thenDate) / (1000 * 60 * 60 * 24));
          
            if (diffInDays === 0) {
              return thenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Output time for same day
            } else if (diffInDays === 1) {
              return 'Yesterday';
            } else if (diffInDays >= 2 && diffInDays <= 4) {
              return thenDate.toLocaleDateString([], { weekday: 'long' }); // Output day of the week (2-4 days ago)
            } else {
              return thenDate.toLocaleDateString([],  { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '/'); // Month/Day/Year (YYYY-MM-DD)
            }
        }
    }

  export function formatRelativeTimeWithExactTime(dateString) {
      if (dateString) {
          const thenDate = new Date(dateString);
          const now = new Date();
          
          const diffInDays = Math.floor((now - thenDate) / (1000 * 60 * 60 * 24));
          const timeString = thenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Exact time

  
          if (diffInDays === 0) {
              return `Today at ${timeString}`; // Time for the same day
          } else if (diffInDays === 1) {
              return `Yesterday at ${timeString}`;
          } else if (diffInDays >= 2 && diffInDays <= 4) {
              const dayOfWeek = thenDate.toLocaleDateString([], { weekday: 'long' });
              return `${dayOfWeek} at ${timeString}`; // Day of the week with time
          } else {
              const dateString = thenDate.toLocaleDateString([], { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '/');
              return `${dateString} at ${timeString}`; // Date with time
          }
      }
  }
  
  export function formatCalendarTimeWithExactTime(dateString) {
    if (dateString) {
        const thenDate = new Date(Date.parse(dateString));
        const now = new Date();
        
        // Reset hours, minutes, seconds, and milliseconds for accurate day comparison
        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const thenDateStart = new Date(thenDate.getFullYear(), thenDate.getMonth(), thenDate.getDate());
        
        const diffInDays = Math.floor((thenDateStart - todayStart) / (1000 * 60 * 60 * 24));
        const timeString = thenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        if (diffInDays === 0) {
            return `Today at ${timeString}`;
        } else if (diffInDays === 1) {
            return `Tomorrow at ${timeString}`;
        } else if (diffInDays === -1) {
            return `Yesterday at ${timeString}`;
        } else {
            const dateString = thenDate.toLocaleDateString([], { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '/');
            return `${dateString} at ${timeString}`;
        }
    }
  }

  export function isDatePastDue(dateString) {
    if (dateString) {
        const givenDate = new Date(dateString);
        const now = new Date();

        // Return true if the given date is earlier than now
        return givenDate < now;
    }
    // If no date string is provided, return false by default
    return false;
}

  export function formatDateTime(dateString) {
    try {
      if(dateString){
          // Parse the date string considering UTC timezone
          const dateObj = new Date(dateString);
          // console.log('dateObj', dateObj)
    
        // Format the date in 12-hour AM/PM format with leading zeros for hours and minutes
        const formattedTime = dateObj.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true, // Use 12-hour clock
        });

        const thenDate = new Date(dateString);
        const now = new Date();
        
        const diffInDays = Math.floor((now - thenDate) / (1000 * 60 * 60 * 24));
      
        if (diffInDays === 0) {
          return thenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' at ' + formattedTime; // Output time for same day
        } else if (diffInDays === 1) {
          return 'Yesterday at' +  formattedTime;
        } else if (diffInDays >= 2 && diffInDays <= 4) {
          return thenDate.toLocaleDateString([], { weekday: 'long' }) + ' at ' + formattedTime; // Output day of the week (2-4 days ago)
        } else {
          return thenDate.toLocaleDateString([],  { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '/') + ' at ' + formattedTime; // Month/Day/Year (YYYY-MM-DD)
        }
    
      }
    } catch (error) {
      console.error('Error formatting date:', error);
      // Optionally return an empty string or a default value for invalid dates
      return ''; // Or a default value like 'Invalid Date'
    }

  }


  export function formatCalendarDate(date) {
    if(date){
        var currentDate = new Date(date)
        var shortCurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString()
        return shortCurrentDate;

    }else{
      return 'mm/dd/yyyy'
    }

}
  // export const timeOptions = Array.from({ length: 48 }, (_, i) => {
  //     let hour = Math.floor(i / 2)
  //     const minute = i % 2 === 0 ? '00' : '30'
  //     const amPm = hour < 12 ? 'AM' : 'PM'
      
  //     // Convert to 12-hour format
  //     hour = hour % 12
  //     // Handle midnight and noon special cases
  //     hour = hour === 0 ? 12 : hour
      
  //     return `${hour}:${minute} ${amPm}`
  //   })

export function convertTo24HourFormat(timeString) {
  // Use a regular expression to extract hours, minutes, and meridiem (am/pm)
  const regex = /(\d{1,2}):(\d{2})\s*(am|pm)/i;
  const match = timeString.match(regex);

  if (!match) {
      throw new Error("Invalid time format. Please provide a time like '9:00 am'.");
  }

  let [ , hours, minutes, meridiem ] = match;

  // Convert hours to a number for manipulation
  hours = parseInt(hours, 10);

  // Convert to 24-hour format
  if (meridiem.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
  } else if (meridiem.toLowerCase() === "am" && hours === 12) {
      hours = 0;
  }

  // Format hours and minutes with leading zeros
  return `${hours.toString().padStart(2, "0")}:${minutes}:00`;
}

export const convertTo12Hour = (time24) => {
  // Split the time into hours, minutes, seconds
  const [hours, minutes, seconds] = time24.split(':');
  
  // Convert hours to number for comparison
  let hour = parseInt(hours);
  
  // Determine if it's AM or PM
  const period = hour >= 12 ? 'PM' : 'AM';
  
  // Convert to 12-hour format
  hour = hour % 12;
  // If hour is 0 (midnight), make it 12
  hour = hour || 12;
  
  // Return formatted time
  return `${hour}:${minutes} ${period}`;
};

export const timeOptions = [
  "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", 
  "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", 
  "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", 
  "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", 
  "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", 
  "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", 
  "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", 
  "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
];