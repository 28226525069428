import React from 'react'
import InteractionsComponent from '../utils/InteractionsComponent'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import { Avatar } from '@mui/material'
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { createHyperlinks } from '../utils/HyperlinkUtils';
import { shouldAddPin } from '../../../utils/messagingUtils';


const linkify = new LinkifyIt();
linkify.tlds(tlds);

function ReceiverMessageOnlyComponent({isMobile, message, scrollToMessage, isGroup,showName, showImage, handleTailCss, participants, userInfo, updateEmoji, shouldRoundTopCorner,shouldRoundBottomCorner}) {
    let cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content
    
    const links = linkify.match(cleanHTML);
    if(links && links.length > 0){
        cleanHTML = createHyperlinks(message.message_text_html);
    }

    // var cleanReplyHTML = ''; // Sanitize the HTML content
    // if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid){
    //     cleanReplyHTML = DOMPurify.sanitize(message.message_replied_from.message_text);
    // }

    const cornersCSS = (topCorner, bottomCorner) => {
        return {
            borderTopLeftRadius: topCorner? '0.25rem': '1rem',
            borderBottomLeftRadius: bottomCorner ? '0.25rem':'1rem',
            borderTopRightRadius: '1rem',
            borderBottomRightRadius: '1rem',
        }
    }

    
  return (
    <>
        <div 
            className={` pl-4 pr-4 py-1.5 rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-col ml-2 order-2 bg-slate-100 dark:bg-slate-800 text-gray-600 dark:text-gray-300`}
            style={cornersCSS(shouldRoundTopCorner,shouldRoundBottomCorner)}
        >
            {isGroup && showName &&
                <div 
                    style={{color: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'', }}
                    className='flex text-sm items-baseline'
                >
                    {participants && participants[message.user_username] && participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                    {participants && participants[message.user_username] && participants[message.user_username].from_team_name? (
                        <span className='text-xs pl-1 italic text-gray-400'>
                            {`- ${participants[message.user_username].from_team_name}`}
                        </span>

                    ) : (
                        <div/>
                    )}
                </div>
            }
            {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
                <div className='w-full rounded-xl flex my-1 cursor-pointer text-sm' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                    <div className={`bg-sky-400 dark:bg-sky-600  w-[3px] min-w-[3px] rounded-l-full`} />
                    <div className={`bg-sky-400/20 dark:bg-sky-600/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                        <span className={`text-sky-600 dark:text-sky-500 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                        <span className={`text-gray-500 dark:text-gray-400 truncate `} >{message.message_replied_from.message_text}</span>
                        {/* <div
                            className="text-gray-500 dark:text-gray-400 h-5"
                            dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                        /> */}
                    </div>
                </div>
            }
            {message.emoji_interactions && Object.keys(message.emoji_interactions).length > 0 ? (
                    <div className='flex flex-col w-full'>
                        {/* <span className='text-sm'>{message.message_text}</span> */}
                        <div
                            className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                            dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                        />
                        <div className='flex jusitfy-between'>
                            <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                            <span className={`flex text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto mb-[-5px] min-w-[60px] italic pl-3 py-0.5`}>
                            {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '} {formatDateToAmPm(message.message_created_at)}
                            </span>

                        </div>
                    </div>
                ):(
                    <div className='flex flex-wrap '>
                        {/* <span className='text-sm'>{message.message_text}</span>  */}
                        <div
                            className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                            dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                        />
                        <span className={`flex text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto text-right mb-[-5px] italic pl-3 py-0.5`}>
                        {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                        </span>
                    </div>
                )}
        </div>
        <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
            {showImage &&
                <Avatar
                    sx={{ 
                        bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'', 
                        width: 32, 
                        height: 32 }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                        }}
                    alt={participants && participants[message.user_username] && participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                    src={participants && participants[message.user_username] && participants[message.user_username].user_image_url ? participants[message.user_username].user_image_url: '/brokenLink.jpeg'}
                />
            }
        </div>
            {showImage && isGroup &&
            <div 
                className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
            >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" />
                </svg>
            </div>
            }
            {showImage && !isGroup &&
            <div 
                className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" />
                </svg>
            </div>
            }
    </>
  )
}

export default ReceiverMessageOnlyComponent