import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { getData, postData, putData } from '../../../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import {LightTooltip} from '../../../styles/Tooltip'
import moment from "moment"
import AddCategoryButton from '../assetmodals/AddCategoryButton';
import DowntimeCategoryButton from '../assetmodals/DowntimeCategoryButton';
import DowntimeSelector from './DowntimeSelector';

function AddDowntimeBreakModal({downtimeCategories, refreshDowntimeCategories, getDowntimeBreaks}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loading, setLoading] = useState(false)
    const [currentDowntimeCategoryId, setCurrentDowntimeCategoryId] = useState('501')
    const [selectedStartTime, setSelectedStartTime] = useState({
        hour: '12',
        minute: '00',
        period: 'PM'
      });
    const [selectedEndTime, setSelectedEndTime] = useState({
        hour: '12',
        minute: '30',
        period: 'PM'
      });

  
    function closeModal() {
      setIsOpen(false)
    }
  
    function openModal() {
      setIsOpen(true)
    }

    const hours = Array.from({ length: 12 }, (_, i) => {
        const hour = i + 1;
        return hour.toString().padStart(2, '0');
    });
    
    const minutes = Array.from({ length: 60 }, (_, i) => {
        return i.toString().padStart(2, '0');
    });

    const handleStartTimeChange = (type, value) => {
        const newTime = { ...selectedStartTime, [type]: value };
        setSelectedStartTime(newTime);
        
    };
    const handleEndTimeChange = (type, value) => {
        const newTime = { ...selectedEndTime, [type]: value };
        setSelectedEndTime(newTime);
        
        
    };
  
    const submitAddDowntime = () => {
      try {
        if(!loading){
          setLoading(true)
            let hour24Start = parseInt(selectedStartTime.hour);
            if (selectedStartTime.period === 'PM' && hour24Start !== 12) hour24Start += 12;
            if (selectedStartTime.period === 'AM' && hour24Start === 12) hour24Start = 0;
            const startTimeString = `${hour24Start.toString().padStart(2, '0')}:${selectedStartTime.minute}:00`;

            let hour24End = parseInt(selectedEndTime.hour);
            if (selectedEndTime.period === 'PM' && hour24End !== 12) hour24End += 12;
            if (selectedEndTime.period === 'AM' && hour24End === 12) hour24End = 0;
            const endTimeString = `${hour24End.toString().padStart(2, '0')}:${selectedEndTime.minute}:00`;


            postData(apiLink + `/v1/organizations/${selectedOrganization}/auto_downtime_breaks`, 
            {
                auto_downtime_break_downtime_category_id: currentDowntimeCategoryId,
                auto_downtime_break_start_time: startTimeString,
                auto_downtime_break_end_time: endTimeString,
                auto_downtime_break_enabled:true,
            })
                .then(async response => {
                const data = await response.json();
                if(response.ok){
                    toast.success('Downtime Break Added')
                    getDowntimeBreaks()
                    setLoading(false)
                    closeModal()
                }
                else{
                    setLoading(false)
                    try{
                        toast.error(data.message)
                    }catch (error){
                        toast.error(error)
                    }
                }
                })
        }
      } catch (error) {
        toast.error(error)
      }
    }
  
  
  return (
    <div className='scrollbar-hide'>
      <div className="flex items-center justify-center">
        <motion.button  whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}  
            className='text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-300'
            onClick={openModal}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
            </svg>

        </motion.button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-2xl overflow-scroll scrollbar-hide transform rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                      <motion.div
                        key='classifySelectedDowtime'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='my-2 max-h-[800px] overflow-y-scroll scrollbar-hide'
                      >
                        <h6 className={`mb-1 px-4 py-2  text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'} scrollbar-hide`}>
                            Add Downtime Break
                        </h6>
                        <div className='px-4 py-2'>
                            <div className={`flex flex-col space-y-4 ${isDark ? 'text-gray-300':'text-gray-600'} `}>
                                <span>During scheduled shifts, if machines are down,</span>
                                <div className='flex space-x-2'>
                                    <span>between</span>
                                    <div className="flex items-center ">
                                        <select
                                            value={selectedStartTime.hour}
                                            onChange={(e) => handleStartTimeChange('hour', e.target.value)}
                                            className={`pr-7 pl-2 py-0.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                        >
                                            {hours.map((hour) => (
                                                <option key={hour} value={hour}>
                                                {hour}
                                                </option>
                                            ))}
                                        </select>

                                        <span className="text-gray-500 mx-0.5">:</span>

                                        <select
                                            value={selectedStartTime.minute}
                                            onChange={(e) => handleStartTimeChange('minute', e.target.value)}
                                            className={`mr-1 pr-7 pl-2 py-0.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                        >
                                            {minutes.map((minute) => (
                                                <option key={minute} value={minute}>
                                                {minute}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            value={selectedStartTime.period}
                                            onChange={(e) => handleStartTimeChange('period', e.target.value)}
                                            className={`pr-7 pl-2 py-0.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                        >
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                        </div>

                                        <span>and</span>

                                        <div className="flex items-center">
                                            <select
                                                value={selectedEndTime.hour}
                                                onChange={(e) => handleEndTimeChange('hour', e.target.value)}
                                                className={`pr-7 pl-2 py-0.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                            >
                                            {hours.map((hour) => (
                                                <option key={hour} value={hour}>
                                                {hour}
                                                </option>
                                            ))}
                                            </select>

                                            <span className="text-gray-500 mx-0.5">:</span>

                                            <select
                                                value={selectedEndTime.minute}
                                                onChange={(e) => handleEndTimeChange('minute', e.target.value)}
                                                className={`mr-1 pr-7 pl-2 py-0.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                            >
                                            {minutes.map((minute) => (
                                                <option key={minute} value={minute}>
                                                {minute}
                                                </option>
                                            ))}
                                            </select>

                                            <select
                                                value={selectedEndTime.period}
                                                onChange={(e) => handleEndTimeChange('period', e.target.value)}
                                                className={`pr-7 pl-2 py-0.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                            >
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                            </div>
                                </div>
                                <div className='flex items-center space-x-1'>
                                    <span>classify downtimes as </span>
                                    <DowntimeSelector currentDowntimeCategoryId={currentDowntimeCategoryId} setCurrentDowntimeCategoryId={setCurrentDowntimeCategoryId} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories}  passBackState={true} />
                                </div>
                                
                            </div>
                        </div>
                        <div className={`flex p-3 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-600':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitAddDowntime}
                          disabled={loading}
                        >
                          Add Break
                          {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                        </button>
                      </div>
                      
                      </motion.div>

                </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild> 
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default AddDowntimeBreakModal