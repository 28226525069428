import React, { useEffect, useRef } from 'react'

function ColorPicker({selectedColor, onColorSelect, isOpen, setIsOpen, isDark, colorPalette}) {
    const pickerRef = useRef(null);
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [setIsOpen]);
  
    if (!isOpen) return null;
  
    
  
    // const colorCSS = (colorData, isDark) => {
    //   const colorInfo = colorData.tailwind_color_info.find(
    //     color => color.color_mode === (isDark ? "DARK" : "LIGHT")
    //   );
    //   return { backgroundColor: colorInfo?.tailwind_hex };
    // };

    console.log('colorPalette', colorPalette)
    console.log('selectedColor', selectedColor)

  return (
    <div 
        ref={pickerRef}
        className={`mt-1 ${isDark ? 'bg-slate-800':'bg-slate-100'} rounded-lg shadow-lg p-2 `}
      >
        <div className="flex flex-wrap mt-1">
          {colorPalette.map((color, index) => (
            <button
              key={`color-${index}`}
              onClick={() => {
                onColorSelect(color);
                setIsOpen(false);
              }}
              className="w-6 h-6 rounded-lg hover:scale-110 transition-transform duration-100 relative m-1"
              style={{backgroundColor: isDark ? color.tailwind_dark_color_hex : color.tailwind_light_color_hex}}
              // style={colorCSS(color, isDark)}
            >
              {selectedColor && (selectedColor.tailwind_color_name == color.tailwind_color_name) && (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5 ${color === '#FFFFFF' ? 'text-black' : 'text-white'}`}>
                      <path xmlns="http://www.w3.org/2000/svg" d="M440-80q-33 0-56.5-23.5T360-160v-160H240q-33 0-56.5-23.5T160-400v-280q0-66 47-113t113-47h440q17 0 28.5 11.5T800-800v400q0 33-23.5 56.5T720-320H600v160q0 33-23.5 56.5T520-80h-80ZM240-560h480v-200h-40v120q0 17-11.5 28.5T640-600q-17 0-28.5-11.5T600-640v-120h-40v40q0 17-11.5 28.5T520-680q-17 0-28.5-11.5T480-720v-40H320q-33 0-56.5 23.5T240-680v120Zm0 160h480v-80H240v80Zm0 0v-80 80Z"/>
                  </svg>
              )}
            </button>
          ))}
        </div>
      </div>
  )
}

export default ColorPicker