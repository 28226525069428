import React from 'react'
import WorkOrderTableRow from './WorkOrderTableRow'


function WorkOrderTableView({isMobile, selectedOrganization, workOrdersList, setWorkOrdersList}) {
 
  console.log('workOrdersList', workOrdersList)

  return (
    <div className={`overflow-x-auto pb-56 `}>
        <table className="min-w-full border-collapse table-auto min-w-[800px] border-separate border-spacing-y-1">
        <thead>
          <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
            <th className="rounded-tl-3xl rounded-bl-xl bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 pl-4 pr-2.5 py-3 text-left sticky left-0 z-10">#</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Title</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Priority</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Due Date</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-center">Status</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Assigned To</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Asset</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Recurrence</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-left">Created By</th>
            <th className="bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-center min-w-[125px]">Created On</th>
            <th className="rounded-tr-3xl rounded-br-xl bg-slate-100 dark:bg-slate-800 text-base font-medium text-gray-600 dark:text-gray-400 px-2.5 py-3 text-center min-w-[125px]">Updated On</th>
          </tr>
        </thead>
          {/* {workOrdersList.map((order) => (
            <WorkOrderTableRow key={order.workorder_uuid} isMobile={isMobile} selectedOrganization={selectedOrganization} order={order} setWorkOrdersList={setWorkOrdersList} />
          ))} */}
        <tbody>
          {workOrdersList.map((section) => (
            <React.Fragment key={section.workorder_section}>
              <tr className="">
                  <td colSpan={11} className="py-1.5 relative">
                    <div className='relative'>
                      <div className={`absolute top-1/2 left-0 right-0 border-t-2 ${section.workorder_section == 'Past Due'? 'border-red-200 dark:border-red-800/40':'border-blue-200 dark:border-blue-800/60'}    border-dashed`}></div>
                      <div className={`text-base py-0 px-3 w-fit rounded-full ${section.workorder_section == 'Past Due'? 'text-red-800 dark:text-white bg-red-200 dark:bg-red-900':'text-blue-800 dark:text-white bg-blue-200 dark:bg-blue-950'}  ml-4 relative z-10`}>
                        {section.workorder_section}
                      </div>
                    </div>
                  </td>
              </tr>
              {section && section.workorders && Object.keys(section.workorders).length > 0 && section.workorders.map(order => (
                <WorkOrderTableRow key={order.workorder_uuid} isMobile={isMobile} selectedOrganization={selectedOrganization} order={order} setWorkOrdersList={setWorkOrdersList} />

              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WorkOrderTableView