import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getData, putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import toast from 'react-hot-toast';
import StatusButtons from './components/StatusButtons';
import { formatRelativeTimeWithExactTime, isDatePastDue } from '../utils/DateFormating';
import { Avatar } from '@mui/material';
import DueDateComponent from './components/DueDateComponent';
import PriorityComponent from './components/PriorityComponent';
import AssetComponent from './components/AssetComponent';
import AssigneesComponent from './components/AssigneesComponent';
import SelectedRecurrenceComponent from './components/SelectedRecurrenceComponent';
import DescriptionImagesComponent from './components/DescriptionImagesComponent';
import StartDateComponent from './components/StartDateComponent';
import FilesComponent from './components/FilesComponent';
import EstimatedTimeComponent from './components/EstimatedTimeComponent';
import SelectedGroupComponent from './components/SelectedGroupComponent';
import LogsTables from '../MyLogsPage/LogsTables';
import { AnimatePresence, motion } from 'framer-motion';
import MessagingPage from './woMessagingComponents.js/MessagingPage';
import SelectedWODetailsPage from './SelectedWODetailsPage';


const subNav = [
    {
        name:'Details',
        url:'details'
    },
    {
        name:'Messages',
        url:'messages'
    },
    {
        name:'Activity Logs',
        url:'activity_logs' 
    },
    // {
    //     name:'History',
    //     url:'history'
    // },
    // {
    //     name:'Insights',
    //     url:'insights'
    // },
]

function SelectedWorkOrderPage({mqttMessage, userInfo}) {
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const [selectedWorkOrder, setSelectedWorkOrder] = useState([])
    const [workOrderLogs, setWorkOrderLogs] = useState([])
    const [loadingTable, setLoadingTable] = useState(false)
    const location = useLocation()

   
    // const [isEditingWODescription, setIsEditingWODescription] = useState(false)
    // const [woDescription, setWODescription] = useState('')
    // const [loadingDescription, setLoadingDescription] = useState(false);
    const [selectedSubNav, setSelectedSubNav] = useState('details')
    const [selectedTab, setSelectedTab] = useState(subNav[0])



    useEffect(() =>{
        setSelectedSubNav(selectedTab.url)
    },[selectedTab])


    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[])

    useEffect(() => {
        let selectedWorkOrder = searchParams.get('selected_wo')
        setSelectedWorkOrderUuid(selectedWorkOrder)
        // setting logs to false before trying to load again since its taking so long 
        setLoadingTable(false)
        
      },[location])


    useEffect(() => {
        if(selectedOrganization && selectedWorkOrderUuid){
            getSelectedWorkOrder()
            getWorkOrderLogs()
        }
    }, [selectedOrganization, selectedWorkOrderUuid])

    const getSelectedWorkOrder = () => {
        if(selectedWorkOrderUuid){
            getData(apiLink + '/v1/workorders/'+ selectedWorkOrderUuid )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Selected WO ', data.data[0])
                            setSelectedWorkOrder(data.data[0])
                            
                        }else{
                            setSelectedWorkOrder([])
                        }
                    }else{
                        setSelectedWorkOrder([])
                    }
                })

        }

    }
    const getWorkOrderLogs = () => {
        if(selectedOrganization && selectedWorkOrderUuid){
            if(!loadingTable){
                setWorkOrderLogs([])
                setLoadingTable(true)
                getData(apiLink + `/v1/organizations/${selectedOrganization}/logs?text=${selectedWorkOrderUuid}`)
                    .then(async response => {                        
                        const data = await response.json();
                        if(response.ok ){
                            setLoadingTable(false)
                            if(data && data.data){
                                setWorkOrderLogs(data.data)
                                
                            }
                        }else{
                            setLoadingTable(false)
                        }
                    })
            }
        }

    }



    // const handleDescriptionUpdate = (event) => {
    //     const newTitle = event.target.value;
    
    //     // If no value, reset to original title
    //     if (!newTitle) {
    //         setWOTitle(selectedWorkOrder.workorder_description);
    //         setIsEditingWODescription(false);
    //         return;
    //     }

    //     if (loadingTitle) return;
    
    //     try {
    //         setLoadingDescription(true);
    //         setIsEditingWODescription(false);
    
    //         putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid, 
    //         {'workorder_description': event.target.value })
    //         .then(async response => {
    //             console.log('response', response)
    //             const data = await response.json();
    //             if(response.ok){
    //                 // toast.success('Description Change')
    //                 setLoadingDescription(false)
    //                 setWODescription(event.target.value)
    //             }
    //             else{
    //                 setWODescription(selectedWorkOrder.workorder_description)
    //                 setLoadingDescription(false)
    //                 try{
    //                     toast.error(data.message)
    //                 }catch (e){
    //                     toast.error(e)
    //                 }
    //             }
    //         })

    //     } catch (e) {
    //         setWODescription(selectedWorkOrder.workorder_description);
    //         toast.error(e.toString());
    //     }
    // };

    

    const handleBackNavigation = () => {
        

        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        let platform = searchParams.getAll('platform')
        setSearchParams({wo_status,wo_priority, sort_by, from, to, q, platform})

    }
    
  

  return (
    <div className={`flex `}>
        <div className='grow'>
        {/* <div className={`${isMobile ? 'max-h-[calc(100vh)]':'min-h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide pb-10`}> */}
            {/* Breadcrums */}
            {isMobile ? (
                <nav className='mb-2 flex items-center'>
                    <ol className='hidden sm:flex'>
                        <li className='breadcrumbsNav' onClick={handleBackNavigation}>Work Orders</li>
                        <li className='breadcrumbsSpacer'>/</li>
                        <li className='breadcrumbsCurrent'>{selectedWorkOrder.workorder_number  ? selectedWorkOrder.workorder_number :selectedWorkOrder.workorder_title}</li>
                    </ol>
                    <ol className='flex sm:hidden'>
                        <li className='breadcrumbsNav flex text-lg items-center' onClick={handleBackNavigation}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </div>
                            <span>Work Orders</span>
                        </li>
                    </ol>
                </nav>
            ): (
                <nav className='mb-2 flex items-center'>
                    <ol className='hidden sm:flex'>
                        <li className='breadcrumbsNav' onClick={handleBackNavigation}>Work Orders</li>
                        <li className='breadcrumbsSpacer'>/</li>
                        <li className='breadcrumbsCurrent'>{selectedWorkOrder.workorder_number  ? selectedWorkOrder.workorder_number :selectedWorkOrder.workorder_title}</li>
                    </ol>
                    <ol className='flex sm:hidden'>
                        <li className='breadcrumbsNav flex text-lg items-center' onClick={handleBackNavigation}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </div>
                            <span>Work Orders</span>
                        </li>
                    </ol>
                </nav>
            )}
           
            <div className='flex flex-col-reverse sm:flex-row w-full mt-2 px-2'>
                <nav>
                    <div className='flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-3 border-b border-blue-600 w-fit'>
                    {subNav.map((item) => (
                        <div
                        key={item.url}
                        className='relative pt-0.5 pb-1.5'
                        onClick={() => setSelectedTab(item)}
                        >
                        <span className={`${item.url === selectedTab.url ? 'font-medium':' font-base'} mr-1 pb-1 text-gray-600 dark:text-gray-100  hover:text-gray-900 dark:hover:text-gray-300  outline-none text-base cursor-pointer bg-transparent whitespace-nowrap`}>{`${item.name}`}</span>
                        {item === selectedTab ? (
                            <motion.div className="underline" layoutId="underline" />
                        ) : null}
                        </div>
                    ))}
                    </div>
                </nav>
                
            </div>
            <div className=''>
                {selectedSubNav && selectedWorkOrder && Object.keys(selectedWorkOrder).length > 0 && 
                    subLinkPage(selectedSubNav, selectedWorkOrder, loadingTable, workOrderLogs, mqttMessage, userInfo, selectedOrganization, getSelectedWorkOrder)
                }
            </div>
            
        </div>
    </div>
  )
}

export default SelectedWorkOrderPage


const subLinkPage = (link, selectedWorkOrder, loadingTable, workOrderLogs, mqttMessage, userInfo, selectedOrganization, getSelectedWorkOrder ) => {
    const allLinks = {
        details: { link: "details", content: <SelectedWODetailsPage selectedWorkOrder={selectedWorkOrder} selectedOrganization={selectedOrganization} getSelectedWorkOrder={getSelectedWorkOrder} />},
        messages: { link: "messages", content: <MessagingPage chatRoomId={selectedWorkOrder.chatroom_uuid} selectedWorkOrder={selectedWorkOrder} mqttMessage={mqttMessage} userInfo={userInfo} />},
        activity_logs: { link: "activity_logs", content: (
            <div className='w-full mt-4 p-4 bg-slate-50 dark:bg-slate-800/50 rounded-xl mb-4 overflow-scroll '>
                <LogsTables data={workOrderLogs} rowsPerPage={10} loadingTable={loadingTable} />
            </div>
        )
        },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )           
}


// {selectedWorkOrder && selectedWorkOrder.workorder_uuid && 
//     (
//         <div className='flex flex-col px-2'>
//             <div className='flex w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 pb-3'>
//                 <div className='flex flex-col w-full'>
//                         <div className='relative'>
//                             <input
//                                 type="text"
//                                 value={woTitle}
//                                 onChange={(e) => setWOTitle(e.target.value)}
//                                 // onBlur={handleTitleBlur}
//                                 // onKeyDown={handleKeyPressTitle}
//                                 onBlur={handleTitleUpdate}
//                                 onKeyDown={(e) => {
//                                     if (e.key === 'Enter' || e.key === 'Tab') {
//                                         handleTitleUpdate(e);
//                                     }
//                                 }}
//                                 className={`py-0 pr-3 pl-1 rounded-md text-2xl placeholder:text-gray-400 dark:placeholder:text-gray-600 font-medium
//                                     ${isEditingWOTitle 
//                                         ? 'w-full bg-transparent border shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800' 
//                                         : 'bg-transparent w-full text-gray-600 dark:text-gray-300 truncate border-none focus:ring-0 cursor-pointer'}
//                                 `}
//                                 placeholder={"Work order title"}
//                                 onClick={() => setIsEditingWOTitle(true)}
//                                 readOnly={!isEditingWOTitle}
//                                 autoFocus={isEditingWOTitle}
//                             />
//                             {loadingTitle && 
//                                     <div >
//                                         <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
//                                             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
//                                         </svg>
//                                     </div>
//                                     }

//                         </div>
//                         {/* <div className='relative'>
//                             <input
//                                 type="text"
//                                 value={woDescription}
//                                 onChange={(e) => setWODescription(e.target.value)}
//                                 // onBlur={handleTitleBlur}
//                                 // onKeyDown={handleKeyPressTitle}
//                                 onBlur={handleDescriptionUpdate}
//                                 onKeyDown={(e) => {
//                                     if (e.key === 'Enter' || e.key === 'Tab') {
//                                         handleDescriptionUpdate(e);
//                                     }
//                                 }}
//                                 className={`py-0 pr-3 pl-1 rounded-md text-lg text-gray-500 dark:text-gray-400 placeholder:text-gray-400 dark:placeholder:text-gray-600 font-light
//                                     ${isEditingWODescription
//                                         ? 'w-full bg-transparent border shadow-sm focus:outline-none focus:bg-transparent focus:ring-1  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300  dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800' 
//                                         : 'bg-transparent w-full  truncate border-none focus:ring-0'}
//                                 `}
//                                 placeholder={"Work order description"}
//                                 onClick={() => setIsEditingWODescription(true)}
//                                 readOnly={!isEditingWODescription}
//                                 autoFocus={isEditingWODescription}
//                             />
//                             {loadingDescription && 
//                                     <div >
//                                         <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
//                                             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
//                                         </svg>
//                                     </div>
//                                     }

//                         </div> */}
//                 </div>
//             </div>
//             <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4 '>
//                 <StatusButtons selectedWorkOrder={selectedWorkOrder} />
//             </div>
//             <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4'>
//                 <DescriptionImagesComponent selectedWorkOrder={selectedWorkOrder} handleDownloadFile={handleDownloadFile} getSelectedWorkOrder={getSelectedWorkOrder} />
//             </div>
//             <div className='grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))]  py-4 border-b-[0.01em] border-slate-300 dark:border-slate-700'>
//                 <div className='flex mx-1 my-4 justify-center border-l-[0.01em] border-slate-300 dark:border-slate-700'>
//                     <DueDateComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
//                 </div>
//                 {/* <div className='px-10'>
//                     <StartDateComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
//                 </div> */}
//                 <div className='flex mx-1 my-4 justify-center overflow-x-auto border-l-[0.01em] border-slate-300 dark:border-slate-700 px-2'>
//                     <PriorityComponent selectedWorkOrder={selectedWorkOrder} />
//                 </div>
//                 <div className='flex  mx-1 my-4 justify-center border-l-[0.01em] border-slate-300 dark:border-slate-700'>
//                     <SelectedGroupComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder}  />
//                 </div>
//                 <div className='flex  mx-1 my-4 justify-center border-l-[0.01em] border-slate-300 dark:border-slate-700'>
//                     <EstimatedTimeComponent selectedWorkOrder={selectedWorkOrder}  />
//                 </div>
//                 <div className='flex justify-center mx-1 my-4 border-l-[0.01em] border-slate-300 dark:border-slate-700'>
//                     <div className='flex flex-col'>
//                         <div className='flex justify-center text-gray-800 dark:text-white mb-2 font-medium'>
//                             <span>Work Order ID</span>
//                         </div>
//                         <div className={`flex justify-center text-gray-600 dark:text-gray-300 text-lg truncate`}>
//                             #{selectedWorkOrder.workorder_number}
//                         </div>
//                     </div>
//                 </div>
//                 <div className='flex justify-center mx-1 my-4 border-l-[0.01em]  border-slate-300 dark:border-slate-700'>
//                     <AssetComponent selectedWorkOrder={selectedWorkOrder} />
//                 </div>
//                 <div className='mx-1 my-4 border-l-[0.01em]  border-slate-300 dark:border-slate-700'>
//                     <AssigneesComponent selectedWorkOrder={selectedWorkOrder} selectedOrganization={selectedOrganization} />
//                 </div>
//                 <div className='mx-1 my-4 border-l-[0.01em] border-slate-300 dark:border-slate-700'>
//                     <div/>
//                 </div>
//             </div>
//             {selectedWorkOrder && selectedWorkOrder.workorder_uuid &&selectedWorkOrder.workorder_due_timestamp && (
//                 <div className='flex space-x-8 divide-x-[0.01em] divide-slate-300 dark:divide-slate-600 py-4  border-b-[0.01em] border-slate-300 dark:border-slate-700'>
//                     <div className='pr-10 w-full max-w-[800px]'>
//                         <SelectedRecurrenceComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
//                     </div>

//                 </div>
//             )}
//             <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4'>
//                 <FilesComponent selectedWorkOrder={selectedWorkOrder} handleDownloadFile={handleDownloadFile} getSelectedWorkOrder={getSelectedWorkOrder} />
//             </div>

//         </div>
//     )
// }