import React, { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../../../ApiCalls/DataApis'
import { apiLink } from '../../../ApiCalls/ApisVariables'


function ShiftSelectionFilterBtn() {
    const [allDayCheckbox, setAllDayCheckbox] = useState(false)
    const [allShiftsCheckbox, setAllShiftsCheckbox] = useState(false)
    const [allShifts, setAllShifts] = useState([])
    const [checkedShiftsState, setCheckedShiftsState] = useState([])
    const [selectedShifts, setSelectedShifts] = useState([])
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();

   useEffect(() => {

        if(selectedOrganization ){
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    setAllShifts(data.data)
                }
                }
            })

        }

  }, [selectedOrganization])

useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling all parts search parameters into an array
            let searchShifts = searchParams.getAll('shift_name')
            
            if(searchShifts.includes('All Day')){
                setSelectedShifts(['All Day'])
                setAllDayCheckbox(true)
                setAllShiftsCheckbox(false)
                setCheckedShiftsState(new Array(allShifts.length).fill(false))
            }else{
                let updatedCheckedState = allShifts.map((item, index) =>
                    searchShifts.includes(item.shift_name) ? true : false
                );
    
                let selectedSearchedShifts = [] 
                updatedCheckedState.map((checked,index) =>
                    checked ? selectedSearchedShifts.push(allShifts[index].shift_name) : null
                )

                const allTrue = updatedCheckedState.every(value => value === true);
                if(allTrue){
                    // If all shifts arte true, checkmark AllSHifts state as true
                    setAllShiftsCheckbox(true)
                }else{
                    setAllShiftsCheckbox(false)
                }

                setSelectedShifts(selectedSearchedShifts)
                setCheckedShiftsState(updatedCheckedState)
            }
            

        }else {
            setCheckedShiftsState(new Array(allShifts.length).fill(false))
        }

}, [location, allShifts])

const handleAllDayCheckboxChange = (e) => {
    setAllDayCheckbox(e.target.checked)
    if(e.target.checked){
        setAllShiftsCheckbox(false)
        setCheckedShiftsState(new Array(allShifts.length).fill(false))
    }
}

const handleAllShiftsCheckboxChange = (e) => {
    setAllShiftsCheckbox(e.target.checked)
    if(e.target.checked){
        setAllDayCheckbox(false)
        setCheckedShiftsState(new Array(allShifts.length).fill(true))
    }
}

const handleChangeShiftsState = (position) => {
    let updatedCheckedState = checkedShiftsState.map((item, index) =>
    index === position ? !item : item
    );

    const hasTrue = updatedCheckedState.some(value => value === true)
    const allTrue = updatedCheckedState.every(value => value === true);

    if(hasTrue){
        setAllDayCheckbox(false)
    }
    if(allTrue){
        setAllShiftsCheckbox(true)
    }else{
        setAllShiftsCheckbox(false)
    }

    setCheckedShiftsState(updatedCheckedState)
    
}

const handleClear = () => {

   
    // setSelectedShifts([])

    
    let select = searchParams.getAll('select')
    let departments = searchParams.getAll('departments')
    let machines = searchParams.getAll('machines')
    let parts = searchParams.getAll('parts')
    let machine_types = searchParams.getAll('machine_types') 
    let tags = searchParams.getAll('tags')
    let group_by = searchParams.getAll('group_by')
    let sort_by = searchParams.getAll('sort_by')
    let calculate_oee_by = searchParams.getAll('calculate_oee_by')
  //   let shift_name = searchParams.getAll('shift_name')
    let shift_start_time = searchParams.getAll('shift_start_time')
    let shift_date = searchParams.getAll('shift_date')
    let shift_entire_day = searchParams.getAll('shift_entire_day')
    let shift_by_min = searchParams.getAll('shift_by_min')
    let maximize = searchParams.getAll('maximize')
    let view = searchParams.getAll('view')
    let url_token = searchParams.getAll('url_token')
    let operations_expanded = searchParams.getAll('operations_expanded')
    let assets_expanded = searchParams.getAll('assets_expanded')
    let platform = searchParams.getAll('platform')
    let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
    let from = searchParams.getAll('from')
    let to = searchParams.getAll('to')
    let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
    let only_with_notes = searchParams.getAll('only_with_notes')
    let group_by_advanced = searchParams.getAll('group_by_advanced')
    let sort_by_advanced = searchParams.getAll('sort_by_advanced')
    let time_period = searchParams.getAll('time_period')
    let show_percentage = searchParams.getAll('show_percentage')
    let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
    let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')
    let operations = searchParams.getAll('operations')
    
    setSearchParams({select,parts, departments, machines, machine_types, tags, group_by, sort_by,calculate_oee_by, shift_start_time, shift_date, shift_entire_day,shift_by_min, maximize, 
        view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, from, to, downtime_severity_uuids, only_with_notes, group_by_advanced, sort_by_advanced, time_period, show_percentage, exclude_none_in_oee, operations, exclude_unknown_in_oee
    })
  
}
const handleApplyFilter = () => {

    // putting all selected parts into the button for visualization
    const tempShifts = []
    if(allDayCheckbox){
        tempShifts.push('All Day') 
      }else{
          checkedShiftsState.map((state, index) =>{
              if(state){
                  tempShifts.push(allShifts[index].shift_name) 
              }
          })
      }
    setSelectedShifts(tempShifts)

    
    let select = searchParams.getAll('select')
    let departments = searchParams.getAll('departments')
    let machines = searchParams.getAll('machines')
    let parts = searchParams.getAll('parts')
    let machine_types = searchParams.getAll('machine_types') 
    let tags = searchParams.getAll('tags')
    let group_by = searchParams.getAll('group_by')
    let sort_by = searchParams.getAll('sort_by')
    let calculate_oee_by = searchParams.getAll('calculate_oee_by')
  //   let shift_name = searchParams.getAll('shift_name')
    let shift_start_time = searchParams.getAll('shift_start_time')
    let shift_date = searchParams.getAll('shift_date')
    let shift_entire_day = searchParams.getAll('shift_entire_day')
    let shift_by_min = searchParams.getAll('shift_by_min')
    let maximize = searchParams.getAll('maximize')
    let view = searchParams.getAll('view')
    let url_token = searchParams.getAll('url_token')
    let operations_expanded = searchParams.getAll('operations_expanded')
    let assets_expanded = searchParams.getAll('assets_expanded')
    let platform = searchParams.getAll('platform')
    let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
    let from = searchParams.getAll('from')
    let to = searchParams.getAll('to')
    let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
    let only_with_notes = searchParams.getAll('only_with_notes')
    let group_by_advanced = searchParams.getAll('group_by_advanced')
    let sort_by_advanced = searchParams.getAll('sort_by_advanced')
    let time_period = searchParams.getAll('time_period')
    let show_percentage = searchParams.getAll('show_percentage')
    let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
    let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')
    let operations = searchParams.getAll('operations')
    
    setSearchParams({select,parts, departments, machines, machine_types, tags, group_by, sort_by,calculate_oee_by, shift_name: tempShifts, shift_start_time, shift_date, shift_entire_day,shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, from, to, downtime_severity_uuids, only_with_notes, group_by_advanced, sort_by_advanced, time_period, show_percentage, exclude_none_in_oee, operations, exclude_unknown_in_oee})
  
}


  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }}   
                    className='bg-white w-3 h-3 rounded-full m-1'
                    onClick={(e) => {
                        if(selectedShifts.length > 0){
                            e.stopPropagation();
                            handleClear();
                        }
                    }}  
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${selectedShifts.length > 0? ' rotate-45 ':'rotate-0'}  h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </motion.button>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Shifts
                </div>
                {selectedShifts.length > 0 && selectedShifts.length < 2 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {selectedShifts.map((filtered,index) => (
                        <div key={index} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered}</div>
                        </div>
                    ))}
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                {selectedShifts.length >= 2 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedShifts[0]} and {selectedShifts.length - 1} more</div>
                    </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg dark:shadow-lg-invert ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                    
                    <div className={`mb-1.5`}>
                       <input
                         id={`all-day-shift`}
                         type="checkbox"
                         className="checkboxBtn"
                         checked={allDayCheckbox}
                         onChange={handleAllDayCheckboxChange}
                       />
                         <label htmlFor={`all-day-shift`} className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                             {`All Day (24/7)`}
                         </label>
                    </div>
                    <div className={`mb-1.5`}>
                       <input
                         id={`all-day-shift`}
                         type="checkbox"
                         className="checkboxBtn"
                         checked={allShiftsCheckbox}
                         onChange={handleAllShiftsCheckboxChange}
                       />
                         <label htmlFor={`all-day-shift`} className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                             {`All Shifts`}
                         </label>
                         <ul className='pl-4'>
                            {allShifts.map((shift, index)=>(
                                <div key={index} className="flex items-center my-3">
                                    <input id={`shift-checkbox-${index}`} type="checkbox" checked={checkedShiftsState[index]} onChange={() => handleChangeShiftsState(index)}  className="checkboxBtn"/>
                                    <label htmlFor={`shift-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300">{shift.shift_name}</label>
                                </div>
                            ))}
                        </ul>
                    </div>
                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md mt-2' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default ShiftSelectionFilterBtn