import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import MessageDetails from './MessageDetails';
import { useRecoilValue } from 'recoil';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import useViewportFix from '../../utils/useViewportFix';


function MessagingPage({selectedWorkOrder, mqttMessage, userInfo, chatRoomId}) {
    const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [chatList, setChatList] = useState([])
  const [woMqttMessage, setWOMqttMessage] = useState([])
  const [interactionsEmojis, setInteractionsEmojis] = useState([])
  const [allEmojis, setAllEmojis] = useState([])
  const [chatUnreadDic, setChatUnreadDic] = useState({})
  const [globalSearchMessage, setGlobalSearchMessage] = useState([])
  const [membersMqttMessage, setMembersMqttMessage] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const [currentChat, setCurrentChat] = useState('')
  const location = useLocation();
  const [leftColumnWidth, setLeftColumnWidth] = useState(300);
  const minWidth = 300;
  const maxWidth = 500;

  useViewportFix();


  useEffect(() => {

      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
          setIsMobile(true)
      }else{
          setIsMobile(false)
      }

  },[])
  

  useEffect(()=>{
      if(selectedOrganization){

        getData(apiLink + '/v1/interaction_default_emojis')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                setInteractionsEmojis(data.data)
              }
            }
          })

        getData(apiLink + '/v1/emojis')
          .then(async response => {
            const data = await response.json();
            if(response.ok ){
              if(data && data.data){
                setAllEmojis(data.data)
              }
            }
          })
        
      }
    

  }, [selectedOrganization])


// I'm using this useEffect to see what mqtt messages are coming in and update the chatList depengin on the message
useEffect(() => {
  if(mqttMessage ){
    console.log('WO mqttMessage', mqttMessage)
    if(mqttMessage.chatroom_type === "workorder"){
        setWOMqttMessage(mqttMessage)
        // getChatList()
    }
  }

}, [mqttMessage])




  return (
    <div className='shadow-iotflows-lg border-[0.01em] border-slate-50 dark:border-slate-700 mt-4 mb-14 mx-2 rounded-3xl overflow-hidden'>
        <MessageDetails 
            userInfo={userInfo}  mqttMessage={woMqttMessage} allEmojis={allEmojis}   interactionsEmojis={interactionsEmojis} chatRoomId={chatRoomId} selectedWorkOrder={selectedWorkOrder}
        />
    </div>
  )
}

export default MessagingPage