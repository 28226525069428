import React, { useEffect, useState, Fragment } from 'react'
import { Avatar } from '@mui/material'
import { motion, AnimatePresence } from "framer-motion";
import MediaPage from '../../MyMessaging/chatComponents/MediaPage';
import FilesPage from '../../MyMessaging/chatComponents/FilesPage';
import VoicePage from '../../MyMessaging/chatComponents/VoicePage';
import InfoSectionHeader from './InfoSectionHeader';

var subNav = [
    // {
    //     name:'Members',
    //     url:'members'
    // },
    {
        name:'Media',
        url:'media' 
    },
    // {
    //     name:'Saved',
    //     url:'saved'
    // },
    {
        name:'Files',
        url:'files'
    },
    // {
    //     name:'Links',
    //     url:'links'
    // },
    // {
    //     name:'Music',
    //     url:'music'
    // },
    {
        name:'Voice',
        url:'voice'
    },
    // {
    //     name:'Groups',
    //     url:'groups'
    // },
]

function InfoSection({setMainSelectedSubNav, participants, userInfo, chatInfo}) {
    const [selectedSubNav, setSelectedSubNav] = useState('media')
    const [selectedTab, setSelectedTab] = useState(subNav[0])
    const [chatImage, setChatImage] = useState(null)
    const [chatName, setChatName] = useState(null)
    const [chatColor, setChatColor] = useState('')

   

    useEffect(() => {
        // console.log('color', color)
        // console.log('INFO Header', chatInfo)
        if(chatInfo && Object.keys(chatInfo).length > 0){

            setChatImage(chatInfo.chat_picture_url)
            setChatName(chatInfo.chat_name)
            setChatColor(chatInfo.chat_color)

        }else{
           
        }

    },[chatInfo])

    useEffect(() =>{
        setSelectedSubNav(selectedTab.url)
    },[selectedTab])


  return (
    <div >
        <AnimatePresence mode='wait'>
            <motion.div
            key={'mainInfoPage'}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            >
            <InfoSectionHeader setMainSelectedSubNav={setMainSelectedSubNav} />
            <div className=' overflow-y-scroll scrollbar-hide pb-10' style={{height:'calc(100vh - 400px)'}}>

                <div className='flex flex-col justify-center text-center mt-5'>
                    <div className='mx-auto'>
                        <Avatar
                            sx={{ width: 120, height: 120,bgcolor: chatColor, fontSize: 42}}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${chatColor} 100%)`,
                              }}
                            alt={chatName}
                            src={chatImage || '/brokenLink.png'}
                            >
                        </Avatar>
                    </div>
                    <div className='flex flex-col justify-center text-center mt-2'>
                        <span className='text-gray-600 dark:text-gray-300 font-medium'>{chatName}</span>
                    </div>

                </div>
                <div>
                    <div className='max-w-4xl mx-auto mt-3'>
                        <div className='mx-4 rounded-xl bg-slate-100 dark:bg-slate-800 mb-10'>
                            <nav >
                                <div className='flex overflow-x-scroll scrollbar-hide w-full justify-around text-center border-b-[0.01em] border-slate-200 dark:border-slate-600'>
                                {subNav.map((item) => (
                                    <div
                                    key={item.url}
                                    className='relative py-3 mx-3 text-center '
                                    onClick={() => setSelectedTab(item)}
                                    >
                                    <span className={`${item.url === selectedTab.url ? 'text-blue-600 dark:text-blue-500':' text-gray-600 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-300'}  pb-1  outline-none text-base cursor-pointer bg-transparent whitespace-nowrap `}>{`${item.name}`}</span>
                                    {item === selectedTab ? (
                                        <motion.div className="bg-blue-600 dark:bg-blue-500 absolute bottom-0 left-0 right-0 h-[2px] rounded-t-full" layoutId="underline" />
                                    ) : null}
                                    </div>
                                ))}
                                </div>
                            </nav>
                            <div className=' '>
                            {/* <div className='max-h-[300px] overflow-y-scroll scrollbar-hide px-4 py-2'> */}
                                {selectedSubNav &&
                                    subLinkPage(selectedSubNav, participants, chatInfo, userInfo)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </motion.div>
            

        </AnimatePresence>
    </div>
  )
}

export default InfoSection


const subLinkPage = (link, participants, chatInfo, userInfo) => {
    const allLinks = {
        media: { link: "media", content: <MediaPage participants={participants} chatInfo={chatInfo} userInfo={userInfo}  />},
        // saved: { link: "saved", content: <div className='text-[128px]'>🥸🧀 🥬</div> },
        files: { link: "files", content: <FilesPage participants={participants} chatInfo={chatInfo} userInfo={userInfo}  /> },
        // links: { link: "links", content: <div className='text-[128px]'>🧀 🥸🥬</div> },
        // music: { link: "music", content: <div className='text-[128px]'>🥸🧀 🥬</div> },
        voice: { link: "voice", content: <VoicePage userInfo={userInfo}  />},
        // groups: { link: "groups", content: <div className='text-[128px]'>🧀 🥬</div> },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )           
}