import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { apiLink } from '../ApiCalls/ApisVariables'
import { deleteData } from '../ApiCalls/DataApis'
import moment from "moment"
import { darkModeState } from '../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import EditDowntimeClassificationModal from './modals/assetmodals/EditDowntimeClassificationModal'
import SplitDowntimeModal from './modals/assetmodals/SplitDowntimeModal'
import { LightTooltip } from '../styles/Tooltip'

function AssetDowntimeCard({downtimeItem, refreshActivityList, downtimeCategories, refreshDowntimeCategories, handleCloseModal}) {
    const [removeClassification, setRemoveClassification] = useState(false)
    const isDark = useRecoilValue(darkModeState)
    const [loading, setLoading] = useState(false)
    const [highlightNewTimes, setHighlightNewTimes] = useState(false)
    const [isBouncing, setIsBouncing] = useState(false)

    useEffect(() =>{
        if(downtimeItem.utilization_start_timestamp){
            var currentTime = new Date().getTime() - 120000
            var currentEndTime = new Date(downtimeItem.utilization_start_timestamp).getTime()
            if(currentEndTime >  currentTime){
                setHighlightNewTimes(true)
                setIsBouncing(true)
                setTimeout(() => {
                    setIsBouncing(false)
                 }, 1550)
            }

        }

    },[downtimeItem])


    const formatDate = (end) => {
        if(end){
            // var startDate = new Date(start)
            // var startHours = startDate.getHours();
            // var startMinutes = startDate.getMinutes();
            // var startAmPm = startHours >= 12 ? 'pm' : 'am';
            // startHours = startHours % 12;
            // startHours = startHours ? startHours : 12; // the hour '0' should be '12'
            // startMinutes = startMinutes < 10 ? '0'+startMinutes : startMinutes;
            // var startStrTime = startHours + ':' + startMinutes + ' ' + startAmPm;
            // var shortStartDate = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2)

            var endDate = new Date(end)
            var endHours = endDate.getHours();
            var endMinutes = endDate.getMinutes();
            var endAmPm = endHours >= 12 ? 'pm' : 'am';
            endHours = endHours % 12;
            endHours = endHours ? endHours : 12; // the hour '0' should be '12'
            endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
            var endStrTime = endHours + ':' + endMinutes + ' ' + endAmPm;
            var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
            // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return shortEndDate + "  " + endStrTime;

            // if(shortStartDate == shortEndDate){
            //     return shortStartDate + "  " + startStrTime +  " - " + endStrTime;
            // }else{
            //     return shortStartDate + " " + startStrTime + " - " + shortEndDate + " " + endStrTime;
            // }
        }
    }

    const formatTime = (seconds) => {
        if(seconds){
          let formatted = moment.utc(seconds*1000).format('HH:mm:ss.SS')
          return formatted
        }else{
            let formatted = moment.utc(0).format('HH:mm:ss.SS')
            return formatted
        }
    }

    const submitRemoveClassification = () => {
        try {
            if(!loading){
                setLoading(true)
                deleteData(apiLink + '/v1/downtime/' + downtimeItem.utilization_id + '/classifications' )
                    .then(async response => {
                      if(response.ok){ 
                        toast.success('Classification Removed')
                        setLoading(false)
                        setRemoveClassification(false)
                        refreshActivityList()
                        handleCloseModal()
                      }
                      else{
                        try{
                          const data = await response.json();
                          toast.error(data.message)
                          setLoading(false)
                          setRemoveClassification(false)
                        }catch (e){
                          toast.error('Something went wrong');
                          setLoading(false)
                          setRemoveClassification(false)
                        }
                      }
                    })
        
              }
            
        } catch (error) {
            
        }
    }

    const leftBorderStyle = (severity) => { 
        if(severity === 'None'){
            return{
                borderColor: isDark ? '#a1a1aa' : '#a1a1aa',
              }
        }
        if(severity === 'Low'){
            return{
                borderColor: isDark  ? '#86efac' : '#22c55e',
              }
        }
        if(severity === 'Medium'){
            return{
                borderColor: isDark  ? '#fbbf24' : '#f59e0b',
              }
        }
        if(severity === 'High'){
            return{
                borderColor: isDark  ? '#fca5a5' : '#ef4444',
              }
        }else{
            return{
                borderColor: isDark  ? '#fca5a5' : '#ef4444',
              }
        }
        
      }

    const badgeStyle = (severity) => { 
        if(severity === 'None'){
            return{
                backgroundColor: isDark  ? 'rgba(212, 212, 212,0.3)' : 'rgba(212, 212, 212,0.5)',
                color: isDark  ? '#d4d4d4' : '#737373',
              }
        }
        if(severity === 'Low'){
            return{
                backgroundColor: isDark  ? 'rgba(134, 239, 172,0.5)' : 'rgba(134, 239, 172,0.5)',
                color: isDark  ? '#86efac' : '#16a34a',
              }
        }
        if(severity === 'Medium'){
            return{
                backgroundColor: isDark  ? 'rgba(253, 230, 138,0.3)' : 'rgba(253, 230, 138,0.5)',
                color: isDark  ? '#fcd34d' : '#d97706',
              }
        }
        if(severity === 'High'){
            return{
                backgroundColor: isDark  ? 'rgba(252, 165, 165,0.3)' : 'rgba(252, 165, 165,0.3)',
                color: isDark  ? '#fca5a5' : '#ef4444',
              }
        }else{
            return{
                backgroundColor: isDark  ? 'rgba(252, 165, 165,0.3)' : 'rgba(252, 165, 165,0.3)',
                color: isDark  ? '#fca5a5' : '#ef4444',
              }
        }
        
      }

    

  return (
    // <div style={leftBorderStyle(downtimeItem.downtime_severity_name)} className={`mx-1 my-1 ${highlightNewTimes ? ' bg-opacity-10 dark:bg-opacity-50':' dark:bg-opacity-50 bg-opacity-10'} ${isBouncing ? 'animate-bounce':''} p-1 rounded`} >
        <div className={`mx-1 my-1 ${highlightNewTimes ? 'bg-blue-200 bg-opacity-70 dark:bg-blue-900 dark:bg-opacity-50':'bg-[#187bcd] bg-opacity-10'} ${isBouncing ? 'animate-bounce':''} p-1 rounded`}>
            {!removeClassification && 
            <div >
                {/* {index === 0 ? (
                    <div className='my-1 py-1 border-b-2 border-gray-200 dark:border-gray-700'>
                        <div className='text-red-900 text-left dark:text-red-600 font-semibold text-sm uppercase'>{event.date}</div>
                    </div>
                ) : (
                    <div className=''>
                        <div className='text-gray-600 text-left dark:text-gray-400 font-semibold text-sm uppercase'>{event.date}</div>
                    </div>
                )} */}
                <div className='flex flex-col border-l-2 pl-2 ' style={leftBorderStyle(downtimeItem.downtime_severity_name)}>
                    <div className='grid grid-cols-8 gap-2 items-center ' >
                        {/* <div className='w-8'>
                            <OptimizeImage alt={downtimeItem.part_name} source={downtimeItem.part_picture_url} size='small' />
                        </div> */}
                        <div className='flex flex-col pl-3 max-w-[300px] col-span-4'>
                            <div>
                                <span className={` text-left ${isDark? 'text-gray-400':'text-gray-600'} font-base text-xs uppercase`}>{formatDate(downtimeItem.utilization_start_timestamp)}</span>
                            </div>
                            <p className={`text-sm truncate leading-5 font-normal ${isDark? 'text-gray-400':'text-gray-500'}`} >{downtimeItem.downtime_category_name}</p>
                            <p className={`text-sm truncate leading-4 font-base ${isDark? 'text-gray-400':'text-gray-500'}`}>{formatTime(downtimeItem.duration_s)}</p>
                        </div>
                        <div className='w-[70px] text-center col-span-2'>
                            <div className='py-0 text-sm rounded w-full' style={badgeStyle(downtimeItem.downtime_severity_name)}>{downtimeItem.downtime_severity_name}</div>
                        </div>
                        <div className='pl-2 col-span-2 flex ml-auto'>
                            {downtimeItem.downtime_category_name ? (
                                <LightTooltip title='Remove Classification'>
                                    <div className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} mr-2`} onClick={() => setRemoveClassification(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </div>
                                </LightTooltip>
                            ):(
                                <div/>
                            )}
                        {refreshDowntimeCategories && 
                            <EditDowntimeClassificationModal btnType='inlineIconBtn' selectedDowntime={downtimeItem}  refreshActivityList={refreshActivityList} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} handleCloseModal={handleCloseModal} />
                        }
                        <div className='ml-2 mr-1'>
                        <LightTooltip title='Split Downtime'>
                            <SplitDowntimeModal btnType='inlineIconBtn' selectedDowntime={downtimeItem}  refreshActivityList={refreshActivityList} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} handleCloseModal={handleCloseModal} />
                        </LightTooltip>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {removeClassification && 
            <div className={`flex ml-2 items-center pt-1.5 pb-3 ${isDark? 'border-slate-700':''} border-b-[0.01em]`}>
                <span className={`text-xs text-left font-light ${isDark? 'text-gray-300':'text-gray-600'} `}>
                    Are you sure you want to remove {downtimeItem.downtime_category_name} classification?
                </span>
                <div className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark? 'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} onClick={() => setRemoveClassification(false)}>
                    Cancel
                </div>
                <div className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-xs bg-red-600 hover:bg-red-700 hover:border-red-700 text-white cursor-pointer`} onClick={submitRemoveClassification}>
                    Remove
                    {loading && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin ${isDark? 'fill-white text-gray-500':' fill-red-600 text-gray-300'} `} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </div>
            </div>
        }
        
    </div>
  )
}

export default AssetDowntimeCard