import React, { useEffect, useRef, useState } from 'react'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { LightTooltip, MemberTooltip } from '../../styles/Tooltip';
import { Avatar, AvatarGroup, Zoom } from '@mui/material';
import { SearchIcon } from '@heroicons/react/outline';
import { getData, putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { motion, AnimatePresence } from "framer-motion";
import toast from 'react-hot-toast';

function AssigneesComponent({selectedWorkOrder, selectedOrganization}) {
    const [isEditing, setIsEditing] = useState(false)   
    const isDark = useRecoilValue(darkModeState); 
    const [loading, setLoading] = useState(false);
    const inputContainerRef = useRef(null);
    const inputRef = useRef(null);
    const [searchMemberText, setSearchMemberText] = useState('')
    const [selectedMembers, setSelectedMembers] = useState({}) 
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [usersList, setUsersList] = useState([])
    const [teamsList, setTeamsList] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState([])
    const [filteredTeamsList, setFilteredTeamsList] = useState([])
    const [isHighlighted, setIsHighlighted] = useState(null);

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if((selectedWorkOrder.assigned_to_teams && Object.keys(selectedWorkOrder.assigned_to_teams).length > 0) || (selectedWorkOrder.assigned_to_users && Object.keys(selectedWorkOrder.assigned_to_users).length > 0)){
                
                let members = []
                if(selectedWorkOrder.assigned_to_teams && Object.keys(selectedWorkOrder.assigned_to_teams).length > 0){
                    members = {
                        ...members,
                        ...selectedWorkOrder.assigned_to_teams.reduce((acc, team) => {
                          acc[`${team.team_handle}`] = team;
                          return acc;
                        }, {})
                      };
                }
                if(selectedWorkOrder.assigned_to_users && Object.keys(selectedWorkOrder.assigned_to_users).length > 0){
                    members = {
                        ...members,
                        ...selectedWorkOrder.assigned_to_users.reduce((acc, user) => {
                          acc[`${user.user_username}`] = user;
                          return acc;
                        }, {})
                      };
                }

                setSelectedMembers(members)
                
            }else{
                setSelectedMembers({})
            }
        }

    },[selectedWorkOrder])

    useEffect(() => {
        if(selectedOrganization ){
            updatedMemberList()
            updatedTeamsList()
        }
  },[selectedOrganization])

  useEffect(() => {
    if(searchMemberText  && searchMemberText.length > 0){
      const filtered = usersList.filter(member => {
        let fullName = member.user_first_name + ' ' + member.user_last_name
        if(member.user_username.toLowerCase().includes(searchMemberText.toLowerCase()) || fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
          return member
        }
      })
      setFilteredMemberList(filtered)

      const filteredTeam = teamsList.filter(team => {
        if(team.team_name.toLowerCase().includes(searchMemberText.toLowerCase()) || team.team_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || team.team_handle.toLowerCase().includes(searchMemberText.toLowerCase())){
          return team
        }
      })
      setFilteredTeamsList(filteredTeam)

    }else{
      setFilteredMemberList([])
      setFilteredTeamsList([])
    //   setFilteredAllIoTFlowsMemberList([])
    }
  },[searchMemberText])

  const updatedMemberList = () => {
    try {

        if(selectedOrganization ){
        //   getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user_contacts')
          getData(apiLink + '/v1/organizations/' + selectedOrganization + '/members')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                // const updatedMembers = removeUsersFromMembers(data.data, userInfo);

                setUsersList(data.data)

              }
            }
          }) 
        }
      } catch (error) {
        console.log(error.message)
      }
}

const updatedTeamsList = () => {
  try {
      if(selectedOrganization){
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/teams')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              setTeamsList(data.data)
            }
          }
        }) 
      }
    } catch (error) {
      console.log(error.message)
    }
}

function removeUsersFromMembers(members, usersToRemove) {
    // Create an array of usernames to remove for easy lookup
    const usernamesToRemove = usersToRemove.user_username;
    // const usernamesToRemove = usersToRemove.map(user => user.user_username);
    
    // Filter out members whose username is in the usernamesToRemove array
    return members.filter(member => !usernamesToRemove.includes(member.user_username));
  }


    const handleClickOutside = (event) => {
        if (inputContainerRef.current && !inputContainerRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setSearchMemberText('')
        }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

    const handleCancel = () => {
        setIsEditing(false)
    }

    const handleRemoveUser = (e) => {
        const key = e.key;
        if ((key === 'Backspace' || key === 'Delete') && !e.ctrlKey && !e.metaKey) {
            if(searchMemberText.length === 0){
    
                if (isHighlighted) {
                    let newSelectedItems = {...selectedMembers}; // Copy the existing array
                    let memberArray = newSelectedItems[isHighlighted]
                    
                    
                    let handle = null
                    if(memberArray && memberArray.user_organization_handle){
                        handle = memberArray.user_organization_handle
                    }else if(memberArray && memberArray.team_organization_handle){
                        handle = memberArray.team_organization_handle
                    }
                    
                    if(!handle) return
        
                    if(!loading){
                        setLoading(true)
                        putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
                        {'delete_assignee_handle': handle})
                        .then(async response => {
                            const data = await response.json();
                            if(response.ok){
                                toast.success(`${handle} removed`)
                                setLoading(false)

                                delete newSelectedItems[isHighlighted]
                                setSelectedMembers(newSelectedItems)
                                setIsHighlighted(null); // Reset highlight if unrelated key is pressed
                                
                            }
                            else{
                                setLoading(false)
                                try{
                                    toast.error(data.message)
                                }catch (e){
                                    toast.error(e)
                                }
                            }
                        })
                    }
                
                
                    
                } else {
                    const keys = Object.keys(selectedMembers);
                    const lastKey = keys[keys.length - 1];
                //   const lastValue = selectedMembers[selectedMembers.length - 1];
                    setIsHighlighted(lastKey);
                  
                }
                e.preventDefault(); // Prevent default backspace/delete behavior
              } else {
                setIsHighlighted(null); // Reset highlight if unrelated key is pressed
              }
        }else if (key === 'Tab' || key === 'Enter') {
          setIsDropdownOpen(false);
          setSearchMemberText('')
          inputRef.current?.blur(); // Lose focus on the input
    
      }
      };

    const removeMember = (member, memberArray) => {
        if(member){
            // let newSelectedItems = {...selectedMembers}; // Copy the existing array
            // delete newSelectedItems[member]
            // setSelectedMembers(newSelectedItems)
            
            let handle = null
            if(memberArray && memberArray.user_organization_handle){
                handle = memberArray.user_organization_handle
            }else if(memberArray && memberArray.team_organization_handle){
                handle = memberArray.team_organization_handle
            }
            
            if(!handle) return

            if(!loading){
                setLoading(true)
                putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
                {'delete_assignee_handle': handle})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        toast.success(`${handle} removed`)
                        setLoading(false)
                        let newSelectedItems = {...selectedMembers}; // Copy the existing array
            
                        delete newSelectedItems[member]
                
                        setSelectedMembers(newSelectedItems)
                    }
                    else{
                        setLoading(false)
                        try{
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e)
                        }
                    }
                })
            }
        }
    }

    const handleAddUser = (e, user, team) => {
        
        setSearchMemberText('')
    
        let itemId = ''
        let handle = null
        if(user && Object.keys(user).length > 0 ){
          itemId = user.user_username
          handle = user.user_organization_handle
        }else if(team && Object.keys(team).length > 0){
          itemId = team.team_handle
          handle = team.team_organization_handle
        }else return
    
        const newSelectedItems = {...selectedMembers}; // Copy the existing array
    
        if(!loading){
            setLoading(true)
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            {'add_assignee_handle': handle})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    toast.success(`${handle} added`)
                    setLoading(false)
                    const index = Object.keys(newSelectedItems).indexOf(itemId);
                    if (index === -1) {
                    if(user && Object.keys(user).length > 0 ){
                        newSelectedItems[itemId] = user;
                    }else if(team && Object.keys(team).length > 0){
                        newSelectedItems[itemId] = team;
                    }
                        // Item not found, add it to the array
                        // newSelectedItems.push(itemId);
                    } else {
                        // Item found, remove it from the array
                        // newSelectedItems.splice(index, 1);
                        delete newSelectedItems[itemId]
                    }
                    
                    setSelectedMembers(newSelectedItems);
                    
                }
                else{
                    setLoading(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }
        
        // const index = Object.keys(newSelectedItems).indexOf(itemId);
        // if (index === -1) {
        //   if(user && Object.keys(user).length > 0 ){
        //     newSelectedItems[itemId] = user;
        //   }else if(team && Object.keys(team).length > 0){
        //     newSelectedItems[itemId] = team;
        //   }
        //     // Item not found, add it to the array
        //     // newSelectedItems.push(itemId);
        // } else {
        //     // Item found, remove it from the array
        //     // newSelectedItems.splice(index, 1);
        //     delete newSelectedItems[itemId]
        // }
        
        // setSelectedMembers(newSelectedItems);
        inputRef.current?.focus();
    
      }

    const handleSearchMemberText = (e) => {
        let text= e.target.value.replace(/,/g, '')
          setSearchMemberText(e.target.value.replace(/,/g, ''))
          if(text.length > 0){
            setIsDropdownOpen(true)
          }
      }

    const handleInputClick = () => {
        setIsDropdownOpen((prev) => !prev);
    };


    // const handleUpdate = () => {

    // }


  return (
    <div className='flex flex-col group w-full px-2' >
        <div className={`flex transition-all duration-500 ${!isEditing ? "justify-center mx-auto" : "justify-start"}  text-gray-800 dark:text-white mb-2`}>
            <div className='relative'>
                <span className='font-medium'>Assigned to</span>
                {!isEditing && (
                    <LightTooltip title='Edit Assigned to'>
                        <button 
                            className={`absolute right-[-20px] top-1 text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                            onClick={() => setIsEditing(true)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>

                        </button>
                    </LightTooltip>
                )}

            </div>
            {isEditing ?(
                <div className='flex ml-1 space-x-2'>
                    {/* <div 
                        className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} 
                        onClick={handleUpdate}
                    >
                        Save
                    </div> */}
                    <div 
                        className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                        onClick={handleCancel}
                    >
                        Close
                    </div>
                </div>
            ):(
                <div/>
            )}
        </div>
        {isEditing ? (
            <div className={`relative w-full`} ref={inputContainerRef}>
                <div className=' w-full py-2 '>
                    <div className={`flex flex-wrap ${isDark? 'bg-slate-700/50':'bg-slate-100'} rounded-md px-2 py-1.5 relative`}>
                    <div className='flex items-center pointer-events-none ml-1 mr-2'>
                        <SearchIcon className='h-4 w-4 text-gray-400 ' />
                    </div>
                        {selectedMembers && Object.keys(selectedMembers).length > 0 && Object.keys(selectedMembers).map((member) => (
                            <div key={`${member}-selectedMembers`} className={`${member === isHighlighted ? 'bg-blue-600/40':'bg-blue-600/80'} flex text-white rounded-full pr-1 pl-2 py-0 mr-1 my-0.5 items-center font-light truncate`}>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 20, height: 20, }}  src={selectedMembers[member].user_image_url} />
                                <span className='px-2 truncate'>{member}</span>
                                <button 
                                onClick={() => removeMember(member, selectedMembers[member])}
                                // onClick={() => removeMember(member)}
                                className={`bg-white/50 ${isDark? 'text-slate-700/50':'text-slate-100'} rounded-full h-4 p-0.5`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-3 h-3">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        ))} 
                        <input className={`grow py-0 min-w-[150px] bg-transparent text-gray-600 border-0 border-gray-300 
                                        ${isDark? 'focus:ring-gray-500 text-white placeholder:text-gray-400':'focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '}
                                        focus:ring-[0px]  text-sm text-ellipsis `}
                            value={searchMemberText} 
                            ref={inputRef} 
                            // onFocus={handleInputFocus}
                            onChange={handleSearchMemberText}
                            onKeyDown={handleRemoveUser}
                            onClick={handleInputClick}
                            type='text' 
                            placeholder={selectedMembers && Object.keys(selectedMembers).length > 0 ? '':'Who would you like to add?'}

                            />
                            {loading && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute bottom-2 right-2 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                }
                    </div>
                </div>
                {isDropdownOpen && (
                    <div className={`absolute ${isDark ? 'bg-slate-700' : 'bg-white'}  rounded-md shadow-lg p-2 w-full z-10 `}>
                    <div className='overflow-y-scroll scrollbar-hide max-h-[200px] '>
                            {searchMemberText === '' && teamsList && Object.keys(teamsList).length > 0 &&
                                <motion.div
                                    key='allTeamsInOrg'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                > 
                                    <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                        Teams
                                        </div>
                                    {teamsList.map((team, index) => (
                                        <button 
                                            key={`${team.team_handle}-teamList`} 
                                            className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center `}
                                            onClick={(e) => handleAddUser(e, '', team)}
                                        >
                                            <input 
                                                type="checkbox" 
                                                checked={Object.keys(selectedMembers).includes(team.team_handle)} 
                                                onChange={(e) => handleAddUser(e,'', team)}  
                                                className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"

                                            />
                                            <div className={`relative `}>
                                                <Avatar 
                                                sx={{  
                                                    bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                    width: 32, 
                                                    height: 32, 
                                                // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                            }}  
                                                alt={team.team_name}
                                                src={'/brokenLink.jpeg'} />
                                            </div>
                                            <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{team.team_name}</h1>
                                                        
                                                    </div>
                                                </div>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{team.team_handle}</h1>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </button>
                                    ))}

                                    </div>
                                </motion.div>
                            }
                            {searchMemberText && searchMemberText.length > 0  && filteredTeamsList && Object.keys(filteredTeamsList).length > 0 &&
                                <motion.div
                                    key='allTeamsInOrg'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                > 
                                    <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                        Teams
                                        </div>
                                    {filteredTeamsList.map((team, index) => (
                                        <button 
                                            key={`${team.team_handle}-teamList`} 
                                            className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center `}
                                            onClick={(e) => handleAddUser(e, '', team)}
                                        >
                                            <input 
                                                type="checkbox" 
                                                checked={Object.keys(selectedMembers).includes(team.team_handle)} 
                                                onChange={(e) => handleAddUser(e,'', team)}  
                                                className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"

                                            />
                                            <div className={`relative `}>
                                                <Avatar 
                                                sx={{  
                                                    bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                    width: 32, 
                                                    height: 32, 
                                                // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                            }}  
                                                alt={team.team_name}
                                                src={'/brokenLink.jpeg'} />
                                            </div>
                                            <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{team.team_name}</h1>
                                                        
                                                    </div>
                                                </div>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{team.team_handle}</h1>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </button>
                                    ))}

                                    </div>
                                </motion.div>
                            }
                            {searchMemberText === '' && usersList && Object.keys(usersList).length > 0 &&
                                <motion.div
                                    key='allMembersInOrg'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                        Users
                                        </div>
                                    {usersList.map((member, index) => (
                                        <button 
                                            key={`${member.user_username}-usersList`} 
                                            className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center `}
                                            onClick={(e) => handleAddUser(e, member,'')}
                                        >
                                            <input 
                                                type="checkbox" 
                                                checked={Object.keys(selectedMembers).includes(member.user_username)} 
                                                onChange={(e) => handleAddUser(e, member,'')}  
                                                className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"

                                            />
                                            <div className={`relative `}>
                                                <Avatar 
                                                sx={{  
                                                    bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                    width: 32, 
                                                    height: 32, 
                                                // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                            }}  
                                                src={member.user_image_url} />
                                            </div>
                                            <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                        
                                                    </div>
                                                </div>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{member.user_username}</h1>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </button>
                                    ))}

                                    </div>
                                </motion.div>
                            }
                            {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                                <motion.div
                                    key='filteredListSection'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                > 
                                    <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                        Users
                                        </div>
                                        {filteredMemberList.map((member, index) => (
                                        <button 
                                            key={`${member.user_username}-${member.organization_handle}-filteredMembersList`} 
                                            className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                            onClick={(e) => handleAddUser(e, member,'')}
                                        >
                                            <input 
                                                type="checkbox" 
                                                checked={Object.keys(selectedMembers).includes(member.user_username)} 
                                                onChange={(e) => handleAddUser(e, member,'')}  
                                                className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                            />
                                            <div className={`relative`}>
                                                <Avatar 
                                                sx={{  
                                                    bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                    width: 32, 
                                                    height: 32, 
                                                // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                            }}  
                                                src={member.user_image_url} />
                                            </div>
                                            <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'}  font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                    </div>
                                                </div>
                                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                                        <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{member.user_username}</h1>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </button>
                                        ))}
                                    </div>
                                </motion.div>
                            }
                            {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length === 0 && filteredTeamsList && Object.keys(filteredTeamsList).length === 0 &&
                            <motion.div
                                key='noneFoundContainer'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            > 
                                <div className='mt-2 flex-flex-col p-4'>
                                    <h6 className={`${isDark? 'text-gray-300':'text-gray-600'}  text-2lx text-center mb-1`}>
                                        { `(;-;)`}
                                    </h6>
                                    <h6 className={`${isDark? 'text-gray-300':'text-gray-600'}  text-center `}>
                                    No users or teams was found.
                                    </h6>
                                    <h6 className={`${isDark? 'text-gray-300':'text-gray-600'}  text-center `}>
                                    If you're looking for a new member, consider inviting them!
                                    </h6>
                                </div>
                            </motion.div>
                            }

                    </div>
                </div>
                )}
            </div>
        ):(
            <div className='flex justify-center'>
                {selectedWorkOrder ? (
                    <div className='flex pr-1 '>
                        {AssignedCell(isDark, selectedMembers)}
                    </div>

                ):(
                    <div className='flex justify-center '>
                        <button
                            onClick={() => setIsEditing(true)} 
                            className='text-blue-600 dark:text-blue-400 italic text-sm'>
                            Add Assignee
                        </button>
                    </div>
                )}
            </div>
        )}
    </div>
  )
}

export default AssigneesComponent


const AssignedCell = (isDark, selectedMembers) => {
    let membersList = []
    if (selectedMembers && Object.keys(selectedMembers).length > 0) {
        const values = Object.values(selectedMembers);
        values.forEach(value => {
          if(value && value.user_organization_handle){
            let name = `${value.user_first_name ? value.user_first_name:''} ${value.user_last_name ? value.user_last_name:''}`
                membersList.push({
                    user_color: value.user_color ? value.user_color:'',
                    name: name,
                    image_url: value.user_image_url ? value.user_image_url : '/broken-image.jpg',
                })
            }else if(value && value.team_organization_handle){
                membersList.push({
                    user_color: value.user_color ? value.user_color:'',
                    name: value.team_name ? value.team_name:'',
                    image_url: '/broken-image.jpg',
                })
            }
        });
      }

      return(
        <MemberTooltip 
            title={
                <div className={`flex flex-col rounded-lg ${isDark? ' bg-slate-800':' bg-slate-100'} `}>
                    {membersList.map((member,index) =>(
                        <div key={index} className='flex pl-2 pr-1 py-1.5'>
                            <div>
                            <Avatar 
                                sx={{ 
                                    bgcolor: member.user_color? member.user_color : '' , 
                                    fontSize:'12px',
                                    width: 28, 
                                    height: 28,
                                }}
                                style={{
                                    background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                                }}
                                alt={member.name}
                                src={member.image_url} /> 
                            </div>
                            <div className='flex flex-col pl-4 justify-between'>
                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} truncate`}>{member.name}</h1>
                            </div>
                        </div>
                    ))}
                </div>
                }
        >
            <div className='flex  overflow-x-scroll  scrollbar-hide'>
            <AvatarGroup max={10} spacing={0} sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(203, 213, 225, 0.1)', width:28, height:28, fontSize:'12px' } }} >
                    {membersList.map((member,index) =>(
                        <Avatar
                            key={`avatar-group-${index}`}
                            sx={{ 
                                bgcolor: member.user_color? member.user_color : '' , 
                                fontSize:'12px',
                                width: 28, 
                                height: 28,
                            }}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                            }}
                            alt={member.name}
                            src={member.image_url ? member.image_url :'/broken-image.jpg'}
                        />
                    ))}
            </AvatarGroup>

            </div>
        </MemberTooltip>
    )

}