

import React, { useEffect, useState } from 'react'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import { Avatar, AvatarGroup } from '@mui/material'
import { motion } from 'framer-motion';
import InteractionsComponent from '../utils/InteractionsComponent';
import ExpandImageModal from '../../../components/modals/messagingModals/ExpandImageModal';
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { createHyperlinks } from '../utils/HyperlinkUtils';
import { shouldAddPin } from '../../../utils/messagingUtils';


const linkify = new LinkifyIt();
linkify.tlds(tlds);

function ReceiverCompressedImageInMessageComponent({isMobile, message, scrollToMessage, isGroup, showImage, handleTailCss, participants, userInfo, updateEmoji, showName, handleDownloadFile}) {
    const [status, setStatus] = useState('loading'); // Initial status is loading

    let cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content
    
    const links = linkify.match(cleanHTML);
    if(links && links.length > 0){
        cleanHTML = createHyperlinks(message.message_text_html);
    }

    // var cleanReplyHTML = ''; // Sanitize the HTML content
    // if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid){
    //     cleanReplyHTML = DOMPurify.sanitize(message.message_replied_from.message_text);
    // }


    useEffect(() => {
      if(!message.message_replied_from && message.message_text ==''){
        setStatus('only_image')
      }else if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid && message.message_text){
        setStatus('image_with_reply_and_comment')
      }else if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid ){
        setStatus('image_with_reply')
      }else if(message.message_text && !message.message_replied_from ){
        setStatus('image_with_comment')
      }

    },[message])


  
    const renderContent = () => {
      switch (status) {
        case 'loading':
          return <div/>;
        case 'only_image':
          return (
            <>
            <div 
                className={`rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'}  flex flex-wrap items-center ml-2 order-2`}
            >
                  <div className='flex flex-col relative'>
                    <div className='relative'>
                      {/* <img  src={message.compressed_image_url} className={` rounded-xl `} /> */}
                      <ExpandImageModal message={message} imageUrl={message.compressed_image_url} participants={participants} handleDownloadFile={handleDownloadFile} />
                      <span className={`flex absolute bottom-1 right-2 rounded-full bg-slate-500/80 text-gray-300 text-xs ml-auto mt-auto text-right italic px-2 py-0`}>
                      {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                      </span>
                    </div>
                    <div className='flex justify-end pt-1 w-full'>
                      <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                    </div>
                  </div>
            </div>
                  {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
                  <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
                    {showImage &&
                        <Avatar
                            sx={{ 
                                bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'',  
                                width: 32, 
                                height: 32 }}
                            style={{
                              background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                            }}
                            alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                            src={participants[message.user_username].user_image_url}
                        />
                    }
                  </div>
                  {/*THIS tail is for when the is an image showing and we need to move the tail to the left of the screen*/}   
                  {/* {showImage && isGroup &&
                    <div 
                        className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                    >
                        <svg width="30" height="30" viewBox="0 0 20 10">
                            <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                        </svg>
                    </div>
                  } */}
                  {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
                  {/* {showImage && !isGroup &&
                    <div 
                        className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                        >
                        <svg width="30" height="30" viewBox="0 0 20 10">
                            <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                        </svg>
                    </div>
                   } */}
            </>
          );
        case 'image_with_reply':
          return (
            <>
            <div 
                className={`rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-wrap items-center ml-2 order-2`}
            >
              <div className='flex flex-col inline-block w-[350px]'>
                  <div   className=' bg-slate-100 dark:bg-slate-800 w-full rounded-t-2xl flex flex-col mt-1 cursor-pointer text-sm px-3 py-2' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                      {isGroup && showName &&
                            <div 
                              style={{color: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'', }}
                                className='flex items-baseline w-full pb-1 text-sm'
                            >
                                {participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''} 
                                {participants && participants[message.user_username] && participants[message.user_username].from_team_name? (
                                    <span className='text-xs pl-1 italic text-gray-400'>
                                        {`- ${participants[message.user_username].from_team_name}`}
                                    </span>

                                ) : (
                                    <div/>
                                )}
                            </div>
                        }
                        <div className='flex'>
                          <div className={`bg-sky-400 dark:bg-sky-600 w-[3px] min-w-[3px] rounded-l-full`} />
                          <div className={`bg-sky-400/20 dark:bg-sky-600/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                              <span className={`text-sky-600 dark:text-sky-500 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                              <span className={`text-gray-500 dark:text-gray-400 truncate `} >{message.message_replied_from.message_text}</span>
                              {/* <div
                                  className="text-gray-500 dark:text-gray-400 h-5"
                                  dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                              /> */}
                          </div>

                        </div>
                  </div>
                  <div className='relative flex items-center rounded-b-2xl bg-slate-100 dark:bg-slate-800'>
                      {/* <img  src={message.compressed_image_url} className={` rounded-xl block w-[350px]`} /> */}
                      <ExpandImageModal message={message} imageUrl={message.compressed_image_url} size={350} participants={participants} handleDownloadFile={handleDownloadFile} />
                      <span className={`flex absolute bottom-1 right-2 rounded-full bg-slate-500/80 text-gray-300 text-xs ml-auto mt-auto text-right italic px-2 py-0`}>
                      {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                      </span>
                  </div>
                  <div className='flex justify-end pt-1 w-full'>
                    <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                  </div>
              </div>
            </div>
              {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
              <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
                    {showImage &&
                        <Avatar
                            sx={{ 
                                bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'',  
                                width: 32, 
                                height: 32 }}
                            style={{
                              background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                            }}
                            alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                            src={participants[message.user_username].user_image_url}
                        />
                    }
                  </div>
                  {/*THIS tail is for when the is an image showing and we need to move the tail to the left of the screen*/}   
                  {showImage && isGroup &&
                    <div 
                        className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                    >
                        <svg width="30" height="30" viewBox="0 0 20 10">
                            <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                        </svg>
                    </div>
                  }
                  {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
                  {showImage && !isGroup &&
                    <div 
                        className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                        >
                        <svg width="30" height="30" viewBox="0 0 20 10">
                            <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                        </svg>
                    </div>
                   }
            </>
        );
        case 'image_with_comment':
          return (
            <>
                <div 
                    className={`rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-wrap items-center ml-2 order-2 z-1`}
                >
                    <div  className='flex flex-col bg-slate-100 dark:bg-slate-800 text-gray-600 dark:text-gray-300 rounded-2xl inline-block w-[350px]'>
                        <div className='relative flex items-center rounded-2xl '>
                            {/* <img src={message.compressed_image_url} className={`block w-full rounded-xl w-[350px]`} /> */}
                            <ExpandImageModal message={message} imageUrl={message.compressed_image_url} size={350} participants={participants} handleDownloadFile={handleDownloadFile} />
                        </div>
                        <div className='p-1'>
                            {/* <span className='text-sm pl-1 '>{message.message_text}</span> */}
                            <div
                              // className="message-content pl-1"
                              className={`message-content pl-1 ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                              dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                            />
                            <div className='flex justify-between'>
                                <div className='flex  pt-1 grow'>
                                <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                                </div>
                                <span className={`flex text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto text-right mb-[-5px] italic pl-3 py-0.5 pr-2 text-nowrap	`}>
                                  {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
                    <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
                        {showImage &&
                            <Avatar
                                sx={{ 
                                    bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'',  
                                    width: 32, 
                                    height: 32 }}
                                style={{
                                  background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                                }}
                                alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                                src={participants[message.user_username].user_image_url}
                            />
                        }
                    </div>
                    {/*THIS tail is for when the is an image showing and we need to move the tail to the right of the screen*/}   
                    {showImage && isGroup &&
                        <div 
                            className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                            style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                        >
                            <svg width="30" height="30" viewBox="0 0 20 10">
                                <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                            </svg>
                        </div>
                    }
                    {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
                    {showImage && !isGroup &&
                        <div 
                            className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                            style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                            >
                            <svg width="30" height="30" viewBox="0 0 20 10">
                                <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                            </svg>
                        </div>
                    }
            </>
          )
        case 'image_with_reply_and_comment':
          return (
            <>
            <div 
                className={`rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-wrap items-center ml-2 order-2 z-1`}
            >
              <div className='flex flex-col bg-slate-100 dark:bg-slate-800 text-gray-600 dark:text-gray-300 rounded-2xl  inline-block	w-[350px]'>
                {isGroup && showName &&
                      <div 
                          style={{color: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'', }}
                          className='flex items-baseline w-full text-sm pl-4 pr-4 pt-1.5'
                      >
                          {participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                          {participants && participants[message.user_username] && participants[message.user_username].from_team_name? (
                                <span className='text-xs pl-1 italic text-gray-400'>
                                    {`- ${participants[message.user_username].from_team_name}`}
                                </span>

                            ) : (
                                <div/>
                            )}
                          
                      </div>
                  }
                  <div   className=' w-full  flex mt-1 cursor-pointer text-sm px-3 py-2'>
                      <div className={`bg-sky-400 dark:bg-sky-600 w-[3px] min-w-[3px] rounded-l-full`} />
                      <div className={`bg-sky-400/20 dark:bg-sky-600/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                          <span className={`text-sky-600 dark:text-sky-500 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                          <span className={`text-gray-500 dark:text-gray-400 truncate `} >{message.message_replied_from.message_text}</span>
                          {/* <div
                              className="text-gray-500 dark:text-gray-400 h-5"
                              dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                          /> */}
                      </div>
                  </div>
                  <div className='relative flex items-center '>
                    <ExpandImageModal message={message} imageUrl={message.compressed_image_url} size={350} participants={participants} handleDownloadFile={handleDownloadFile} />
                      {/* <img src={message.compressed_image_url} className={`block w-full rounded-xl w-[350px]`} /> */}
                  </div>
                  <div className='p-1'>
                      {/* <span className='text-sm pl-1'>{message.message_text}</span> */}
                      <div
                        className={`message-content pl-1 ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                        dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                      />
                      <div className='flex justify-between'>
                        <div className='flex  pt-1 grow'>
                          <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                        </div>
                          <span className={`flex text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto text-right mb-[-5px] italic pl-3 py-0.5 pr-2 text-nowrap	`}>
                          {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                            </span>
                      </div>
                  </div>
              </div>
            </div>
              {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
                  <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
                    {showImage &&
                        <Avatar
                            sx={{ 
                                bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'',  
                                width: 32, 
                                height: 32 }}
                            style={{
                              background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                            }}
                            alt={ participants && participants[message.user_username] && participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                            src={ participants && participants[message.user_username] && participants[message.user_username].user_image_url}
                        />
                    }
                  </div>
                  {/*THIS tail is for when the is an image showing and we need to move the tail to the left of the screen*/}   
                  {showImage && isGroup &&
                    <div 
                        className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                    >
                        <svg width="30" height="30" viewBox="0 0 20 10">
                            <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                        </svg>
                    </div>
                  }
                  {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
                  {showImage && !isGroup &&
                    <div 
                        className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800 z-[-1]`}
                        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                        >
                        <svg width="30" height="30" viewBox="0 0 20 10">
                            <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" /> 
                        </svg>
                    </div>
                   }
            </>
          )
        default:
          return <div/>;
      }
    };

  return (
         renderContent()
  )
}

export default ReceiverCompressedImageInMessageComponent