import { PlusSmIcon, XCircleIcon} from "@heroicons/react/outline"
import { CogIcon } from "@heroicons/react/solid"
import MetalPart from '../images/Testing/metal_parts.webp'
import GearPart from '../images/Testing/gear.jpeg'
import BarChartList from "../components/charts/BarChartList"
import ProgressGaugeLarge from '../components/charts/ProgressGaugeLarge'
import StackedBarChart from "../components/charts/StackedBarChart"
import CalendarCard from "../components/CalendarCard"
import AssetActivityCard from "../components/AssetActivityCard"
import AssetUnclassifiedActivityCard from "../components/AssetUnclassifiedActivityCard"
import OptimizePartImage from "../components/OptimizePartImage"
import moment from "moment"
import { useEffect, useState } from "react"
import { globalSelectedOrganization } from "../atoms/orgStates"
import { useRecoilValue } from "recoil"
import { getData } from "../ApiCalls/DataApis"
import { apiLink } from "../ApiCalls/ApisVariables"
import { useLocation, useParams } from "react-router-dom"
import ClassifyDowntimeListModal from "../components/modals/assetmodals/ClassifyDowntimeListModal"
import LineChartComparison from "../components/charts/LineChartComparison"
import ParetoChartDowntimesSelectedAsset from "../components/charts/ParetoChartDowntimesSelectedAsset"
import AssetDowntimeCard from "../components/AssetDowntimeCard"
import { Switch } from "@mui/material"
import DateRangeComponent from "../components/forms/DateRangeComponent"
import LinearProgress from '@mui/material/LinearProgress';
import { darkModeState } from "../atoms/darkModeState"
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon } from '@heroicons/react/solid'

import '../styles/dateRangeStyles.css'; // main style file
import '../styles/dateRangeTheme.css'; // theme css file
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, defaultStaticRanges, defaultInputRanges } from "react-date-range";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfYear,
    startOfMonth,
    endOfMonth,
    endOfYear,
    addMonths,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays
  } from "date-fns";
import EditSensorModal from "../components/modals/sensorModals/EditSensorModal"
import DowntimeSeveritySelectedAssetFilterBtn from "../components/filterButtons/DowntimeSeveritySelectedAssetFilterBtn"
import { LightTooltip } from "../styles/Tooltip"
import AssetOperationsList from "./SelectedAssetComponents/AssetOperationsList"
import ModifyOperationsCountsModal from "../components/modals/assetmodals/assetOperationsModals/ModifyOperationsCountsModal"
import toast from "react-hot-toast"
import RanpakOperationsList from "./SelectedAssetComponents/RanpakOperationsList"
import OeeGaugeLarge from "../components/charts/OeeGaugeLarge"


const shiftByMinutes = [
    {
        name:'00:00am - 23:59am',
        id: 0,
    },
    {
        name:'00:30am - 00:29am',
        id: 30,
    },
    {
        name:'01:00am - 00:59am',
        id: 60,
    },
    {
        name:'01:30am - 01:29am',
        id: 90,
    },
    {
        name:'02:00am - 01:59am',
        id: 120,
    },
    {
        name:'02:30am - 02:29am',
        id: 150,
    },
    {
        name:'03:00am - 02:59am',
        id: 180,
    },
    {
        name:'03:30am - 03:29am',
        id: 210,
    },
    {
        name:'04:00am - 03:59am',
        id: 240,
    },
    {
        name:'04:30am - 04:29am',
        id: 270,
    },
    {
        name:'05:00am - 04:59am',
        id: 300,
    },
    {
        name:'05:30am - 05:29am',
        id: 330,
    },
    {
        name:'06:00am - 05:59am',
        id: 360,
    },
    {
        name:'06:30am - 06:29am',
        id: 390,
    },
    {
        name:'07:00am - 06:59am',
        id: 420,
    },
    {
        name:'07:30am - 07:29am',
        id: 450,
    },
    {
        name:'08:00am - 07:59am',
        id: 480,
    },
    {
        name:'08:30am - 08:29am',
        id: 510,
    },
    {
        name:'09:00am - 08:59am',
        id: 540,
    },
    {
        name:'09:30am - 09:29am',
        id: 570,
    },
    {
        name:'10:00am - 09:59am',
        id: 600,
    },
    {
        name:'10:30am - 10:29am',
        id: 630,
    },
    {
        name:'11:00am - 10:59am',
        id: 660,
    },
    {
        name:'11:30am - 11:29am',
        id: 690,
    },
    {
        name:'12:00pm - 11:59am',
        id: 720,
    },
    {
        name:'12:30pm - 12:29pm',
        id: 750,
    },
    {
        name:'01:00pm - 12:59pm',
        id: 780,
    },
    {
        name:'01:30pm - 01:29pm',
        id: 810,
    },
    {
        name:'02:00pm - 01:59pm',
        id: 840,
    },
    {
        name:'02:30pm - 02:29pm',
        id: 870,
    },
    {
        name:'03:00pm - 02:59pm',
        id: 900,
    },
    {
        name:'03:30pm - 03:29pm',
        id: 930,
    },
    {
        name:'04:00pm - 03:59pm',
        id: 960,
    },
    {
        name:'04:30pm - 04:29pm',
        id: 990,
    },
    {
        name:'05:00pm - 04:59pm',
        id: 1020,
    },
    {
        name:'05:30pm - 05:29pm',
        id: 1050,
    },
    {
        name:'06:00pm - 05:59pm',
        id: 1080,
    },
    {
        name:'06:30pm - 06:29pm',
        id: 1110,
    },
    {
        name:'07:00pm - 06:59pm',
        id: 1140,
    },
    {
        name:'07:30pm - 07:29pm',
        id: 1170,
    },
    {
        name:'08:00pm - 07:59pm',
        id: 1200,
    },
    {
        name:'08:30pm - 08:29pm',
        id: 1230,
    },
    {
        name:'09:00pm - 08:59pm',
        id: 1260,
    },
    {
        name:'09:30pm - 09:29pm',
        id: 1290,
    },
    {
        name:'10:00pm - 09:59pm',
        id: 1320,
    },
    {
        name:'10:30pm - 10:29pm',
        id: 1350,
    },
    {
        name:'11:00pm - 10:59pm',
        id: 1380,
    },
    {
        name:'11:30pm - 11:29pm',
        id: 1410,
    },
]

const lineChartComparisonFilters = [
    {
        name:'Daily',
        id: 'daily',
    },
    {
        name:'Monthly',
        id: 'monthly',
    },
]

const now = new Date();
const maxCalendarDate = new Date(now.getTime()+ 1 * 24 * 60 * 60 * 1000)



function SelectedAssetPageOverviewTab({selectedAsset,getSensorList, assetHistoryActivity, refreshActivityList, handleToggleMqtt, operationsList, refreshDowntimeCategories, downtimeCategories, isMobile,updateParetoDowntimeChart, setUpdateParetoDowntimeChart, assetOeeGoal}) {
    const { assetId } = useParams()
    const isDark = useRecoilValue(darkModeState);
    const [selectedShift, setSelectedShift] = useState([])
    const [selectedShiftRuntimeComparison, setSelectedShiftRuntimeComparison] = useState([])
    const [shiftList, setShiftList] = useState([])
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [backgroundSeries, setBackgroundSeries] = useState([])
    const [loadingUpDownSeries, setLoadingUpDownSeries] = useState(false)
    const [loadingOperationCounts, setLoadingOperationCounts] = useState(false)
    const [uptimeSeries, setUptimeSeries] = useState([])
    const [downtimeSeries, setDowntimeSeries] = useState([])
    const [unknownSeries, setUnknownSeries] = useState([])
    // const [lastNumDays , setLastNumDays] = useState(barChartDayFilters[0])
    const [shiftedMinutes , setShiftedMinutes] = useState(shiftByMinutes[0])
    const [runtimeComparisonTime , setRuntimeComparisonTime] = useState(lineChartComparisonFilters[0])
    // const [operationsList, setOperationsList] = useState([])
    const [currentShift, setCurrentShift] = useState([])
    const [currentShiftIndex, setCurrentShiftIndex] = useState(null)
    const [oeeHistoricalCalc, setOeeHistoricalCalc] = useState(0)
    const [oeeHistoricalString, setOeeHistoricalString] = useState('')
    const [runtimeComparisonData, setRuntimeComparisonData] = useState([])
    // const [downtimeCategories, setDowntimeCategories] = useState([])
    const [toggleDowntimeSummary, setToggleDowntimeSummary] = useState(true)
    const [downtimeSummary, setDowntimeSummary] = useState([])
    const [loadingRuntimeComp, setLoadingRuntimeComp] = useState(false)
    const [loadingDowntimeClassifications, setLoadingDowntimeClassifications] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [upDownStartDate, setUpDownStartDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    const [upDownEndDate, setUpDownEndDate] = useState(new Date())
    const [startTempDate, setStartTempDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    const [endTempDate, setEndTempDate] = useState(new Date())
    const [uptime, setUptime] = useState('0:00h')
    const [downtime, setDowntime] = useState('0:00h')
    const [showDowntimeCalendar, setShowDowntimeCalendar] = useState(false)
    const [downtimeStartDate, setDowntimeStartDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    const [downtimeEndDate, setDowntimeEndDate] = useState(new Date())
    const [startDowntimeTempDate, setStartDowntimeTempDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    const [endDowntimeTempDate, setEndDowntimeTempDate] = useState(new Date())
    const [openEditSensorModal, setOpenEditSensorModal] = useState(false)
    const [appliedSeverityFilters, setAppliedSeverityFilters] = useState([])
    const [toggleExcludeNone, setToggleExcludeNone] = useState(true)
    const [selectedShifts, setSelectedShifts] = useState([
            {
            shift_name: "All Day",
            shift_start_time: '00:00:00',
            total_shift_s: 86400
            }
        ])
    const [selectedDowntimeShifts, setSelectedDowntimeShifts] = useState([
            {
            shift_name: "All Day",
            shift_start_time: '00:00:00',
            total_shift_s: 86400
            }
        ])
    const [showShiftSelector, setShowShiftSelector] = useState(false)
    const [showDowntimeShiftSelector, setShowDowntimeShiftSelector] = useState(false)
    const [allDayCheckbox, setAllDayCheckbox] = useState(true)
    const [allDayDowntimeCheckbox, setAllDayDowntimeCheckbox] = useState(true)
    const [allShiftsCheckbox, setAllShiftsCheckbox] = useState(false)
    const [allShiftsDowntimeCheckbox, setAllShiftsDowntimeCheckbox] = useState(false)
    const [allShifts, setAllShifts] = useState([])
    const [checkedShiftsState, setCheckedShiftsState] = useState([])
    const [checkedDowntimeShiftsState, setCheckedDowntimeShiftsState] = useState([])
    const [dateCategories, setDateCategories] = useState([])
    const [opsList, setOpsList] = useState([])
    const location = useLocation()
    const [totalFeet, setTotalFeet] = useState(null)
    const [averageFeet, setAverageFeet] = useState(null)
    const [runtimeData, setRuntimeData] = useState(null)
    const [showBoxData, setShowBoxData] = useState(false)
    const [timePassed, setTimePassed] = useState(null)
    
    const selectionRange = {
        startDate: startTempDate,
        endDate: endTempDate,
        key:'selection',
        background:['#f43f5e']
        
    }
    
    const selectionDowntimeRange = {
        startDate: startDowntimeTempDate,
        endDate: endDowntimeTempDate,
        key:'selectionDowntime',
        background:['#f43f5e']
        
    }
    
    const handleSelect = (ranges) => {
        setStartTempDate(ranges.selection.startDate)
        setEndTempDate(ranges.selection.endDate)
    }

    const handleDowntimeSelect = (ranges) => {
        setStartDowntimeTempDate(ranges.selectionDowntime.startDate)
        setEndDowntimeTempDate(ranges.selectionDowntime.endDate)
    }

    useEffect(() => {
        if(assetId){
            // console.log('Call Ops List')
            getOpsList()
        }
    }, [assetId, location, assetOeeGoal])
    


    useEffect(() => {
        if(selectedOrganization){

            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts/current' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        setCurrentShift(data.data[0])
                    }
                }
            })   

            // I had to call different API because every time I added an ALL DAY shift, it kjeept adding it to the allShifts state
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        const  noAllDay = data.data
                        setAllShifts(noAllDay)
                        setCheckedShiftsState(new Array(data.data.length).fill(false))
                        setCheckedDowntimeShiftsState(new Array(data.data.length).fill(false))
                        
                    }
                }
            })   
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        const  addAllDayShifts = data.data
                        addAllDayShifts.unshift(
                            // {
                            // shift_name: "",
                            // shift_start_time: '00:00:00'
                            // },
                            {
                            shift_name: "All Day",
                            shift_start_time: '00:00:00',
                            total_shift_s: 86400
                            },
                        )
                        setShiftList(addAllDayShifts)
                    }
                }
            })   
            
        }

    },[selectedOrganization, assetId])

    useEffect(() => {
        getShiftRunTime()

    }, [selectedShift, currentShift, upDownStartDate,upDownEndDate, toggleExcludeNone, selectedShifts, shiftedMinutes, assetOeeGoal])


    useEffect(() => {
        if(currentShift && Object.keys(currentShift).length && shiftList && Object.keys(shiftList).length ){
            // This Code was to see what is the current shift and have that as the default parameter
            // var shiftIndex = 0
            // shiftList.map((shift, index) => {
            //     if(shift.shift_name == currentShift.shift_name){
            //         shiftIndex = index
            //     }
            // })
            setCurrentShiftIndex(0)
            
        }

    },[currentShift, shiftList])
    
    useEffect(() => {
        
        refreshDowntimeSummary()

    }, [toggleDowntimeSummary, downtimeStartDate, downtimeEndDate,appliedSeverityFilters, selectedDowntimeShifts])

    useEffect(() => {
        if(updateParetoDowntimeChart){
            // this is to update in case a downtime has been categorized and we need to update the Pareto Chart of downtimes
            refreshDowntimeSummary()
        }

    }, [updateParetoDowntimeChart])

    const refreshDowntimeSummary = () => {
        try {
          if(assetId && !loadingDowntimeClassifications){
            
            setLoadingDowntimeClassifications(true)
            if(setUpdateParetoDowntimeChart){
                // this is to update in case a downtime has been categorized and we need to update the Pareto Chart of downtimes
                setUpdateParetoDowntimeChart(false)
            }

            let downtimeSummary = '&by=category'
            if(!toggleDowntimeSummary){
                downtimeSummary = '&by=severity'
            }
            var fromDate = downtimeStartDate.toISOString()

            var toDate = downtimeEndDate.toISOString()
            var url = `?from=${fromDate}&to=${toDate}`

            let severityFilter = ''
            if(appliedSeverityFilters && appliedSeverityFilters.length > 0){
                appliedSeverityFilters.map(severity => {
                    severityFilter += `&downtime_severity_uuids=${severity}`
                })
            }

            url = url + downtimeSummary + severityFilter

            let shiftsUrl = ''
            if(selectedDowntimeShifts && Object.keys(selectedDowntimeShifts).length > 0){
                selectedDowntimeShifts.map(shift => {
                    if(shift.shift_name != 'All Day'){
                        // if(parseFloat(shift.total_shift_s) < 0){
                        //     let secs = parseFloat(shift.total_shift_s);
                        //     let convertedSec = secs + 86400
                        //     seconds += convertedSec
                        // }else{
                        //     seconds += parseFloat(shift.total_shift_s)
                        // }
                        shiftsUrl += `&shift_name=${encodeURI(shift.shift_name)}`
                    }else{
                        shiftsUrl += `&shift_name=${encodeURI(shift.shift_name)}&shift_by_min=0`
                    }
                })
                // mins = seconds/60
                // hours = (mins/60).toFixed(2)
                // hours = parseFloat(hours)
                // if(hours <= 24){
                //     totalHours = hours
                // }

                url = url + shiftsUrl
            }
            // Get list of downtime categories 
            getData(apiLink + '/v1/assets/' + assetId + '/downtimes/summary' + url)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setDowntimeSummary(data.data)
                  setLoadingDowntimeClassifications(false)
                }else{
                    setLoadingDowntimeClassifications(false)
                }
              }
            });
          }
          
        } catch (error) {
          console.log(error.message)
          setLoadingDowntimeClassifications(false)
        }
  
      }

    const getShiftRunTime = () => {
        
        if(assetId && !loadingUpDownSeries){
            setLoadingUpDownSeries(true)
            let seconds = 0
            let mins = 0
            let hours = 0
            
            var totalHours = 24
            var fromDate = upDownStartDate.toISOString()

            var toDate = upDownEndDate.toISOString()
            var barChartSearch = `?from=${fromDate}&to=${toDate}&exclude_none_in_oee=${toggleExcludeNone}`

            let shiftsUrl = ''
            if(selectedShifts && Object.keys(selectedShifts).length > 0){
                selectedShifts.map(shift => {
                    if(shift.shift_name != 'All Day'){
                        if(parseFloat(shift.total_shift_s) < 0){
                            let secs = parseFloat(shift.total_shift_s);
                            let convertedSec = secs + 86400
                            seconds += convertedSec
                        }else{
                            seconds += parseFloat(shift.total_shift_s)
                        }
                        shiftsUrl += `&shift_name=${encodeURI(shift.shift_name)}`
                    }else{
                        shiftsUrl += `&shift_name=${encodeURI(shift.shift_name)}&shift_by_min=${shiftedMinutes.id}`
                    }
                })
                mins = seconds/60
                hours = (mins/60).toFixed(2)
                hours = parseFloat(hours)
                if(hours <= 24){
                    totalHours = hours
                }

                barChartSearch = barChartSearch + shiftsUrl
            }

            
            try{
                    
                getData(apiLink + '/v1/assets/'+ assetId + '/daily-runtime' + barChartSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            let backgroundData = []
                            let uptimeData = []
                            let downtimeData = []
                            let unknowData = []

                            let uptimeString  = formatTime(data.total_runtime_s)
                            let downtimeString  = formatTime(data.total_downtime_s)

                            setUptime(uptimeString)
                            setDowntime(downtimeString)


                            data.data.map(dateData => {
                                let time = new Date(dateData.d)
                                let seconds = time.getTime()
                                let runtimeSeconds = dateData.runtime_s
                                let runtimeMins = runtimeSeconds/60
                                let runtimeHours = (runtimeMins/60).toFixed(1)
                                runtimeHours = parseFloat(runtimeHours)
                                if(runtimeHours < 0){
                                    runtimeHours = 0
                                }
                                
                                let downtimeSeconds = dateData.downtime_s
                                let downtimeMins = downtimeSeconds/60
                                let downtimeHours = (downtimeMins/60).toFixed(1)
                                downtimeHours = parseFloat(downtimeHours)
                                if(downtimeHours < 0){
                                    downtimeHours = 0
                                }

                                let unknownSeconds = dateData.unknown_s
                                let unknownMins = unknownSeconds/60
                                let unknownHours = (unknownMins/60).toFixed(1)
                                unknownHours = parseFloat(unknownHours)
                                if(unknownHours < 0){
                                    unknownHours = 0
                                }
                                let bgArray = [seconds, totalHours]
                                let uptimeArray = [seconds, runtimeHours]
                                let downtimeArray = [seconds, downtimeHours]
                                let unknowArray = [seconds, unknownHours]
                                backgroundData.push(bgArray)
                                uptimeData.push(uptimeArray)
                                downtimeData.push(downtimeArray)
                                unknowData.push(unknowArray)
    
                            })
    
                            
                            setBackgroundSeries(backgroundData)
                            setUptimeSeries(uptimeData)
                            setDowntimeSeries(downtimeData)
                            setUnknownSeries(unknowData)
    
    
                            const totalUptime = uptimeData.reduce((total, currentValue, index, array) => {
                                return total + currentValue[1]
                            }, 0)
                            const totalDowntime = downtimeData.reduce((total, currentValue, index, array) => {
                                return total + currentValue[1]
                            }, 0)
    
                            let totalTime = totalUptime + totalDowntime
                            // let oee = (totalUptime/totalTime)*100
                            // let oeeString = 0 + '%'
                            // if(oee && oee.toFixed(0)){
                                //     oeeString = oee.toFixed(0) + '%'
                                // }
                            let oee = (totalUptime/totalTime)
                            setOeeHistoricalCalc(oee)
                            // setOeeHistoricalString(oeeString)
                            setLoadingUpDownSeries(false)
                            
                        }
                    }else {
                        console.log('Error in response')
                        setLoadingUpDownSeries(false)
                    }
                })

            } catch (e) {
                setLoadingUpDownSeries(false)
                console.log(e.message)
            }

        }

    }

    useEffect(() => {
        getRuntimeComparison()

    }, [selectedShiftRuntimeComparison, runtimeComparisonTime])

    const getRuntimeComparison = () => {
        if(!loadingRuntimeComp){
            try{
                setLoadingRuntimeComp(true)
                let runtimeCompSearch = `?interval=${runtimeComparisonTime.id}`
                if(selectedShiftRuntimeComparison && selectedShiftRuntimeComparison.shift_name){
                    let searchText = encodeURI(selectedShiftRuntimeComparison.shift_name)
                    runtimeCompSearch = runtimeCompSearch + `&shift_name=${searchText}`
                }else if(currentShift && currentShift.shift_name){
                    let searchText = encodeURI('All Day')
                    runtimeCompSearch = runtimeCompSearch + `&shift_name=${searchText}`
                    // if(currentShift.shift_name == 'Default'){
                    //     let searchText = encodeURI('All Day')
                    //     runtimeCompSearch = runtimeCompSearch + `&shift_name=${searchText}`
                    // }else{
                    //     let searchText = encodeURI(currentShift.shift_name)
                    //     runtimeCompSearch = runtimeCompSearch + `&shift_name=${searchText}`
                    // }
                }else{
                    let searchText = encodeURI('All Day')
                    runtimeCompSearch = runtimeCompSearch + `&shift_name=${searchText}`
                }
                
                getData(apiLink + '/v1/assets/'+ assetId + '/runtime-comparison' + runtimeCompSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setRuntimeComparisonData(data.data)
                            setLoadingRuntimeComp(false)
                            
                        }else{
                            setLoadingRuntimeComp(false)
                        }
                    }else{
                        setLoadingRuntimeComp(false)
                    }
                })
            }catch (e) {
                console.log(e.message)
                setLoadingRuntimeComp(false)
            }

        }

    }

    function handleTimeFormat(seconds) {
        if(seconds){
            let formatted = moment.utc(seconds*1000).format('HH:mm:ss.SS')
            return formatted
        }else{
            let formatted = moment.utc(0).format('HH:mm:ss.SS')
            return formatted
        }
    }

    const handleChangeShift = (e) => {
        setSelectedShift(shiftList[e.target.value])
    }

    const handleChangeShiftRuntimeComparison = (e) => {
        setSelectedShiftRuntimeComparison(shiftList[e.target.value])
    }

    // const handleChangeDays = (e) => {
    //     setLastNumDays(barChartDayFilters[e.target.value])
    // }
    const handleChangeShiftByMinutes = (e) => {
        setShiftedMinutes(shiftByMinutes[e.target.value])
    }

    const handleChangeOeeComparisonDays = (e) => {
        setRuntimeComparisonTime(lineChartComparisonFilters[e.target.value])
    }

    const formatTime =(seconds) => {
        let timeString = ''
        
        if(seconds){
            let totalMin = seconds/60
            let hours = totalMin/60
            hours = Math.floor(hours)
            let remMinutes = (totalMin - (hours*60))
            remMinutes = Math.floor(remMinutes)
            if(hours < 10 && hours >= 0){
                hours = '0' + hours 
            }
            if(remMinutes < 10 && remMinutes >= 0){
                remMinutes = '0' + remMinutes 
            }

            timeString = `${hours}:${remMinutes}h`



            // let formattedUptime  = moment.utc(seconds*1000).format('HH:mm')
            // let timeString = `${formattedUptime}h`
            // return timeString
        }else{
            timeString = `00:00h`
            
        }
        return timeString
    }

    const condenseNum = (num) => {
        let currentNum = num
        if(num > 1000000){
            currentNum = currentNum/1000000
            currentNum = currentNum.toFixed(1)
            currentNum = currentNum+ 'M'
        }else if(num > 100000){
            currentNum = currentNum/1000
            currentNum = currentNum.toFixed(1)
            currentNum = currentNum+ 'k'
        }else if(num > 10000){
            currentNum = currentNum/1000
            currentNum = currentNum.toFixed(1)
            currentNum = currentNum+ 'k'
        }
        return currentNum
    }

    const handleChangeOperationsDropdown = (e) => {
        setToggleDowntimeSummary(e.target.checked)
    }

    // const handleChangeDowntimeSummaryDates = (e, id) => {
    //     let updatedDates = {
    //       ...downtimeSummaryDates,
    //       [id]: e
    //     }
    //     setDowntimeSummaryDates(updatedDates);
        
    //   }

    const handleOpenCalendar = () => {
        setShowCalendar(!showCalendar)
        handleToggleMqtt(!showCalendar)
    }
    const handleOpenShiftSelector = () => {
        setShowShiftSelector(!showShiftSelector)
        handleToggleMqtt(!showShiftSelector)
    }
    const handleOpenDowntimeShiftSelector = () => {
        setShowDowntimeShiftSelector(!showDowntimeShiftSelector)
        handleToggleMqtt(!showDowntimeShiftSelector)
    }
    
    const handleApplyDates = () => {
        setShowCalendar(false)
        handleToggleMqtt(false)
        setUpDownStartDate(startTempDate)
        setUpDownEndDate(endTempDate)
    }

    const closeUpDownCalendar = () => {
        setShowCalendar(false)
    }
    
    const handleOpenDowntimeCalendar = () => {
        setShowDowntimeCalendar(!showDowntimeCalendar)
        handleToggleMqtt(!showDowntimeCalendar)
    }
    
    const handleApplyDowntimeDates = () => {
        setShowDowntimeCalendar(false)
        handleToggleMqtt(false)
        setDowntimeStartDate(startDowntimeTempDate)
        setDowntimeEndDate(endDowntimeTempDate)
    }

    const closeDowntimeCalendar = () => {
        setShowDowntimeCalendar(false)
    }

    const formatDate = (date) => {
        if(date){
            var currentDate = new Date(date)
            var shortCurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString()
            return shortCurrentDate;

        }
    }

    const handleCloseModal = () => {
        setOpenEditSensorModal(false)
    }

    const handleOpenEditSensorModal = () => {
        if(!isMobile){
            setOpenEditSensorModal(true)
        }
    }


    const handleAllDayCheckboxChange = (e) => {
        setAllDayCheckbox(e.target.checked)
        if(e.target.checked){
            setAllShiftsCheckbox(false)
            setCheckedShiftsState(new Array(allShifts.length).fill(false))
        }
    }
    const handleAllDayDowntimeCheckboxChange = (e) => {
        setAllDayDowntimeCheckbox(e.target.checked)
        if(e.target.checked){
            setAllShiftsDowntimeCheckbox(false)
            setCheckedDowntimeShiftsState(new Array(allShifts.length).fill(false))
        }
    }
    
    const handleAllShiftsCheckboxChange = (e) => {
        setAllShiftsCheckbox(e.target.checked)
        if(e.target.checked){
            setAllDayCheckbox(false)
            setCheckedShiftsState(new Array(allShifts.length).fill(true))
        }
    }

    const handleAllShiftsDowntimeCheckboxChange = (e) => {
        setAllShiftsDowntimeCheckbox(e.target.checked)
        if(e.target.checked){
            setAllDayDowntimeCheckbox(false)
            setCheckedDowntimeShiftsState(new Array(allShifts.length).fill(true))
        }
    }
    
    const handleChangeShiftsState = (position) => {
        let updatedCheckedState = checkedShiftsState.map((item, index) =>
        index === position ? !item : item
        );
    
        const hasTrue = updatedCheckedState.some(value => value === true)
        const allTrue = updatedCheckedState.every(value => value === true);
    
        if(hasTrue){
            setAllDayCheckbox(false)
        }
        if(allTrue){
            setAllShiftsCheckbox(true)
        }else{
            setAllShiftsCheckbox(false)
        }
    
        setCheckedShiftsState(updatedCheckedState)
        
    }

    const handleChangeDowntimeShiftsState = (position) => {
        let updatedCheckedState = checkedDowntimeShiftsState.map((item, index) =>
        index === position ? !item : item
        );
    
        const hasTrue = updatedCheckedState.some(value => value === true)
        const allTrue = updatedCheckedState.every(value => value === true);
    
        if(hasTrue){
            setAllDayDowntimeCheckbox(false)
        }
        if(allTrue){
            setAllShiftsDowntimeCheckbox(true)
        }else{
            setAllShiftsDowntimeCheckbox(false)
        }
    
        setCheckedDowntimeShiftsState(updatedCheckedState)
        
    }

    const handleApplyShiftSelector = () => {

        // putting all selected parts into the button for visualization
        const tempShifts = []
        if(allDayCheckbox){
            tempShifts.push({
                shift_name: "All Day",
                shift_start_time: '00:00:00',
                total_shift_s: 86400
                },) 
        }else{
            checkedShiftsState.map((state, index) =>{
                if(state){
                    tempShifts.push(allShifts[index]) 
                }
            })
        }
        setSelectedShifts(tempShifts)
        setShowShiftSelector(false)

    }

    const handleApplyDowntimeShiftSelector = () => {

        // putting all selected parts into the button for visualization
        const tempShifts = []
        if(allDayDowntimeCheckbox){
            tempShifts.push({
                shift_name: "All Day",
                shift_start_time: '00:00:00',
                total_shift_s: 86400
                },) 
        }else{
            checkedDowntimeShiftsState.map((state, index) =>{
                if(state){
                    tempShifts.push(allShifts[index]) 
                }
            })
        }
        setSelectedDowntimeShifts(tempShifts)
        setShowDowntimeShiftSelector(false)

    }

    const getOpsList = () => {
        try{
            if(assetId && !loadingOperationCounts){

                setLoadingOperationCounts(true)
                let currentSearch = location.search
                if(currentSearch){
                    currentSearch += '&time_interval_min=60'
                }else{
                    currentSearch = '?time_interval_min=60'
                }
                setOpsList([])
                setDateCategories([])
                if(selectedAsset.asset_machine_id == 2242){
                     getData(apiLink + '/v1/assets/'+ assetId + '/operations_made_by_interval' + currentSearch  )
                    .then(async response => {
                        // JSON data parsed by response.json() call
                        const data = await response.json();
                        // console.log('V1 Ops Made', data.data)
                        if(response.ok ){
                            if(data && data.data[0] && data.data[0].operations_made && Object.keys(data.data[0].operations_made).length > 0){
                                setOpsList(data.data)
        
                                let timeCategoriesAll = []
                                let timeCategoriesTrimmed = []
                                let lastDataIndex = Object.keys(data.data[0].operations_made).length -1
                                
                                data.data[0].operations_made.map((hourCount, index) => {
                                    let time = ''
                                    if(index === 0) {
                                        time = formatAMPM(hourCount.interval_timestamp)
                                        timeCategoriesTrimmed.push(time)
                                    }else if(index === lastDataIndex){
                                        time = formatAMPM(hourCount.interval_timestamp)
                                        timeCategoriesTrimmed.push(time)
                                    }else{
                                        timeCategoriesTrimmed.push(time)
                                    }
        
                                    let currentTime = formatAMPM(hourCount.interval_timestamp)
                                    timeCategoriesAll.push(currentTime)
        
                                })
                                setDateCategories(timeCategoriesAll)
                            }else{
                                setOpsList([])
                            }
                            setLoadingOperationCounts(false)
                        }else{
                            setLoadingOperationCounts(false)
                            toast.error(data.message)
                        }
                    })
                }else{
                    getData(apiLink + '/v2/assets/'+ assetId + '/operations_made_by_interval' + currentSearch  )
                    .then(async response => {
                        // JSON data parsed by response.json() call
                        const data = await response.json();
                        // console.log('V2 Ops Made', data.data)
                        if(response.ok ){
                            if(data && data.data ){
                                setOpsList(data.data[0])

                                if(data.data[0] && data.data[0].operations && data.data[0].operations[0] && data.data[0].operations[0].operations_made && Object.keys(data.data[0].operations[0].operations_made).length > 0){
                                    let operations = data.data[0].operations[0]
                                    let timeCategoriesAll = []
                                    let timeCategoriesTrimmed = []
                                    let lastDataIndex = Object.keys(operations.operations_made).length -1
                                    
                                    operations.operations_made.map((hourCount, index) => {
                                        let time = ''
                                        if(index === 0) {
                                            time = formatAMPM(hourCount.interval_timestamp)
                                            timeCategoriesTrimmed.push(time)
                                        }else if(index === lastDataIndex){
                                            time = formatAMPM(hourCount.interval_timestamp)
                                            timeCategoriesTrimmed.push(time)
                                        }else{
                                            timeCategoriesTrimmed.push(time)
                                        }
            
                                        let currentTime = formatAMPM(hourCount.interval_timestamp)
                                        timeCategoriesAll.push(currentTime)
            
                                    })
                                    setDateCategories(timeCategoriesAll)
                                }
        
                            }else{
                                setOpsList([])
                            }
                            setLoadingOperationCounts(false)
                        }else{
                            setLoadingOperationCounts(false)
                            toast.error(data.message)
                        }
                    })
                }

                    if(selectedAsset.asset_machine_id == 2242){
                        // Ranpak Data
                        getData(apiLink + '/v1/assets/'+ assetId + '/hourly-runtime' + currentSearch  )
                        .then(async response => {
                            // JSON data parsed by response.json() call
                            const data = await response.json();
                            if(response.ok ){
                                if(data && data.data){
                                    let passedShiftSecs = Number(data.data.passed_shift_s)
                                    if(passedShiftSecs == 0 ){
                                        passedShiftSecs = Number(data.data.total_shift_s)
                                    }
                                    let total_feet = calculateTotalFt(data.data.runtime_shift)
                                    setRuntimeData(data.data.hourly_runtimes)
                                    setTotalFeet(total_feet)
                                    setTimePassed(passedShiftSecs)
                                    let averageFeet = (total_feet/passedShiftSecs)*3600
                                    averageFeet = averageFeet.toFixed(1)
                                    setAverageFeet(averageFeet)
                                        
                                }else{
                                    setRuntimeData(null)
                                    setTotalFeet(null)
                                    setAverageFeet(null)
                                    setTimePassed(null)
                                }
                                // setLoadingOperationCounts(false)
                            }else{
                                // setLoadingOperationCounts(false)
                                toast.error(data.message)
                            }
                        })
                    }
            }
        }catch (e){
            toast.error(e.message)
            setLoadingOperationCounts(false)
        }
    }

    const calculateTotalFt = (totalRuntimeSeconds) => {
        if(totalRuntimeSeconds){
            let totalInches = totalRuntimeSeconds * 55
            let feet = totalInches * 0.0833333
            if(feet){
                feet = feet.toFixed(1)
            }

            return feet

        }
    }

    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
    }


      
  return (
    <div className='m-4 scrollbar-hide'>
        <div className='flex flex-col xl:flex-row'>
            <div className='flex flex-col grow'>
            {selectedAsset.asset_machine_id == 2242 ? (
                <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 m-1 overflow-hidden'>
                    <div className='flex  px-4 pt-5 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-left dark:text-gray-200 font-medium text-xl'>{`Production`}</div>
                        <div className='flex ml-auto'>
                                <LightTooltip title='Display Box Count'>
                                    <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                                        <p className='text-gray-600 dark:text-gray-400 text-sm'>Box Count</p>
                                        <Switch
                                            size="small"
                                            color="secondary"
                                            checked={showBoxData}
                                            onChange={(e) => setShowBoxData(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                </LightTooltip>
                            </div>
                    </div>
                    {loadingOperationCounts &&
                        <LinearProgress color="primary" sx={{height:2}}/>
                    }
                    {opsList && Object.keys(opsList).length > 0 ? (
                        opsList && Object.keys(opsList).length > 0 &&
                            opsList.slice(0,1).map((operation) => (
                                <RanpakOperationsList key={operation.operation_uuid}  operation={operation}  dateCategories={dateCategories} runtimeData={runtimeData} averageFeet={averageFeet} totalFeet={totalFeet} showBoxData={showBoxData} timePassed={timePassed} />
                            ))
                        
                    ):(
                        <div>
                            {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                            <div className='mt-2 flex flex-col justify-center rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4 h-[260px]'>
                                <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                                    { `(;-;)`}
                                </h6>
                                <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                    No production data
                                </h6>
                                <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                    is available for selected time period.
                                </h6>
                            </div>
                        </div>
                    )}
                </div>
            ):(
                <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 m-1 overflow-hidden'>
                    <div className='flex  px-4 pt-5 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-left dark:text-gray-200 font-medium text-xl'>{`Operation(s) Production`}</div>
                        <div className='flex ml-auto '>
                            <ModifyOperationsCountsModal btnType='standardBtn' getOpsList={getOpsList} />
                        </div>
                    </div>
                    {loadingOperationCounts &&
                        <LinearProgress color="primary" sx={{height:2}}/>
                    }
                    {opsList && opsList.operations && Object.keys(opsList.operations).length > 0 ? (
                        <AssetOperationsList selectedAsset={selectedAsset} opsList={opsList.operations} getOpsList={getOpsList} dateCategories={dateCategories} />
                    ):(
                        <div>
                            {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                            <div className='mt-2 flex flex-col justify-center rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4 h-[260px]'>
                                <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                                    { `(;-;)`}
                                </h6>
                                <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                    No production data
                                </h6>
                                <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                    is available for selected time period.
                                </h6>
                            </div>
                        </div>
                    )}
                </div>
            )}
                {/* card for uptime and downtime */}
                <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mx-1 mt-1 mb-1 '>
                    {/* Header of card */}
                    <div className='flex flex-col xl:flex-row  px-4 pt-5 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-left dark:text-gray-200 font-medium text-xl'>{'Uptime/Downtime (hours)'}</div>
                        <div className='flex flex-col md:flex-row item-center justify-end mt-2 xl:mt-0 md:ml-auto md:space-x-2'>
                            <div className='flex lg:ml-auto'>
                                <LightTooltip title='Exclude None will remove any categories under the None severity from the OEE calculation, such as breaks, no shift, etc...'>
                                    <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                                        <p className='text-gray-600 dark:text-gray-400 text-sm'>Exclude None</p>
                                        <Switch
                                            size="small"
                                            color="secondary"
                                            checked={toggleExcludeNone}
                                            onChange={(e) => setToggleExcludeNone(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                </LightTooltip>
                            </div>
                            {selectedShifts && Object.keys(selectedShifts).length > 0 && selectedShifts[0].shift_name == "All Day" &&
                                <div className='items-center pt-1 md:pt-0 '>
                                    <div className="flex justify-center items-center min-w-[200px]">
                                        <div className=" w-full outline-none flex justify-center">
                                            <select defaultValue={0} onChange={handleChangeShiftByMinutes}  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full justify-center text-center
                                            px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                            transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300 tracking-wider" aria-label=".form-select-sm ">
                                                {shiftByMinutes.map((shiftChange, index) => (
                                                    <option key={shiftChange.id} value={index}  className='tracking-wider'>{shiftChange.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='relative items-center pt-1 md:pt-0 '>
                                <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                                    className={`w-full flex justify-center overflow-hidden items-center px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
                                    onClick={handleOpenShiftSelector}
                                >
                                    <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                                        Shifts
                                    </div>
                                    {Object.keys(selectedShifts).length > 0 && Object.keys(selectedShifts).length < 2 &&
                                    <div className='flex items-center truncate'>
                                        <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                                        {selectedShifts.map((filtered,index) => (
                                            <div key={index} className='flex '>
                                            {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.shift_name}</div>
                                            </div>
                                        ))}
                                        <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                                    </div>
                                    }
                                    {Object.keys(selectedShifts).length >= 2 &&
                                    <div className='flex items-center truncate'>
                                        <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                                        <div className='flex '>
                                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedShifts[0].shift_name} and {Object.keys(selectedShifts).length - 1} more</div>
                                        </div>
                                        <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                                    </div>
                                    }
                                </motion.button>
                                {showShiftSelector && (
                                    <div className={`absolute z-20 right-0 top-10 flex flex-col w-[250px]`}>
                                        <div className=" overflow-hidden rounded-lg shadow-lg dark:shadow-lg-invert ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                                                <div className='flex justify-end bg-transparent'>
                                                    <button 
                                                        onClick={() => setShowShiftSelector(false)} 
                                                        className='rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 p-1'>
                                                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 dark:text-gray-400 ">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            <div className={`mb-1.5`}>
                                                <input
                                                    id={`all-day-shift`}
                                                    type="checkbox"
                                                    className="checkboxBtn"
                                                    checked={allDayCheckbox}
                                                    onChange={handleAllDayCheckboxChange}
                                                />
                                                <label htmlFor={`all-day-shift`} className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {`All Day (24/7)`}
                                                </label>
                                            </div>
                                            <div className={`mb-1.5`}>
                                                <input
                                                    id={`all-day-shift`}
                                                    type="checkbox"
                                                    className="checkboxBtn"
                                                    checked={allShiftsCheckbox}
                                                    onChange={handleAllShiftsCheckboxChange}
                                                />
                                                <label htmlFor={`all-day-shift`} className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {`All Shifts`}
                                                </label>
                                                <ul className='pl-4'>
                                                    {allShifts.map((shift, index)=>(
                                                        <div key={index} className="flex items-center my-3">
                                                            <input id={`shift-checkbox-${index}`} type="checkbox" checked={checkedShiftsState[index]} onChange={() => handleChangeShiftsState(index)}  className="checkboxBtn"/>
                                                            <label htmlFor={`shift-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300">{shift.shift_name}</label>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                                <button  
                                                    onClick={handleApplyShiftSelector} 
                                                    className='w-full text-base p-2 bg-slate-200 dark:bg-slate-700 text-gray-600 dark:text-white font-medium rounded-lg hover:bg-slate-300 dark:hover:bg-slate-900' >
                                                        Apply
                                                </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {shiftList && Object.keys(shiftList).length > 0 && currentShift && Object.keys(currentShift).length > 0 && currentShiftIndex != null &&
                                <div className='items-center pt-1 md:pt-0 mb-2 md:mb-0 md:mr-4'>
                                    <div className="flex justify-center items-center min-w-[150px]">
                                        <div className=" w-full outline-none">
                                            <select defaultValue={currentShiftIndex} onChange={handleChangeShift}  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                            px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                            transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                                {shiftList.map((shift, index) => (
                                                    <option key={shift.shift_name} value={index}  >{shift.shift_name? shift.shift_name : 'Current Shift'}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            } */}
                            <div className='relative flex items-center pt-1 md:pt-0 '>
                                <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                                    className={`w-full px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
                                    onClick={handleOpenCalendar}
                                >
                                    <span className='text-sm pl-2'>{formatDate(upDownStartDate)} {`-`} {formatDate(upDownEndDate)}</span>
                                </motion.button>
                                {showCalendar && (
                                    <div className={`absolute z-20 right-0 top-10 flex flex-col`}>
                                        <div className='flex justify-end bg-slate-700 p-1 rounded-t-lg'>
                                            <button 
                                                onClick={closeUpDownCalendar} 
                                                className='rounded-full hover:bg-slate-800 p-1'>
                                                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300 ">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                        <DateRangePicker
                                            ranges={[selectionRange]}
                                            maxDate={maxCalendarDate}
                                            rangeColors={['#3b82f6']}
                                            onChange={handleSelect}
                                            inputRanges={[{
                                                ...defaultInputRanges[0],
                                              }]}
                                            staticRanges={[
                                                // ...defaultStaticRanges,
                                                {
                                                    ...defaultStaticRanges[2]
                                                },
                                                {
                                                    ...defaultStaticRanges[3]
                                                },
                                                {
                                                    ...defaultStaticRanges[4]
                                                },
                                                {
                                                    ...defaultStaticRanges[5]
                                                },
                                                {
                                                  label: "Last Year",
                                                  range: () => ({
                                                    startDate: startOfYear(addYears(new Date(), -1)),
                                                    endDate: endOfYear(addYears(new Date(), -1))
                                                  }),
                                                  isSelected(range) {
                                                    const definedRange = this.range();
                                                    return (
                                                      isSameDay(range.startDate, definedRange.startDate) &&
                                                      isSameDay(range.endDate, definedRange.endDate)
                                                    );
                                                  }
                                                },
                                                {
                                                  label: "This Year",
                                                  range: () => ({
                                                    startDate: startOfYear(new Date()),
                                                    endDate: endOfDay(new Date())
                                                  }),
                                                  isSelected(range) {
                                                    const definedRange = this.range();
                                                    return (
                                                      isSameDay(range.startDate, definedRange.startDate) &&
                                                      isSameDay(range.endDate, definedRange.endDate)
                                                    );
                                                  }
                                                }
                                              ]}
                                        />
                                        <div>
                                        <button  
                                            onClick={handleApplyDates} 
                                            className='w-full text-base p-2 bg-slate-700 text-white font-medium rounded-b-lg hover:bg-slate-900' >
                                                Apply
                                        </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        
                    </div>
                    {loadingUpDownSeries &&
                        <LinearProgress color="primary" sx={{height:2}}/>
                    }
                    {/* Bar chart */}
                    <div className='flex flex-col md:flex-row min-h-[240px] overflow-hidden '>
                        <div className='mt-[-25px] grow'>
                            <StackedBarChart containerHeight={'260px'}  backgroundSeries={backgroundSeries} uptimeSeries={uptimeSeries} downtimeSeries={downtimeSeries} unknownSeries={unknownSeries}/>
                        </div>
                        <div className='mx-auto my-auto flex flex-col p-4'>
                            {/* <ProgressGaugeLarge stringValue={oeeHistoricalString} unit={'OEE'} numericValue={oeeHistoricalCalc} thresholdValue={60}/> */}
                            <OeeGaugeLarge oeeValue={oeeHistoricalCalc} unit={'OEE'} goal={assetOeeGoal} />
                            <div className='flex justify-center'>
                                <div className='font-medium text-2xl text-green-600'>{uptime}</div>
                                <div className='font-medium text-2xl text-red-600 ml-2'>{downtime}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className='hidden xl:flex xl:flex-col shadow-iotflows-lg min-w-[300px] sm:min-w-[400px] 2xl:min-w-[450px] overflow-hidden scrollbar-hide rounded-[28px] dark:bg-slate-800 ml-1 md:ml-1 mt-2 md:mt-1 mr-1 md:mr-0 mb-1 xl:mr-5'>
                <div className='mt-1 mb-0.5 py-1 border-b-[1px] border-gray-200 dark:border-gray-700 pl-4'>
                    <div className='text-blue-900 text-left dark:text-blue-600 font-semibold text-sm uppercase'>Asset Activity</div>
                </div>
                <div className={`overflow-y-scroll scrollbar-hide border-full pb-1 ${opsList && Object.keys(opsList).length > 1 ? 'h-[585px]':'h-[580px]'} `}>
                {/* <div className='h-[600px] lg:h-[765px] xl:h-[525px] 2xl:h-[520px] overflow-x-hidden overflow-y-scroll scrollbar-hide mb-4 md:mb-2 xl:mb-0 2xl:mb-4'> */}
                    {assetHistoryActivity && Object.keys(assetHistoryActivity).length > 0 ?
                    (
                        assetHistoryActivity.map(activity => (
                                assetActivityStatus(activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories)
                        ))
                    ):(
                        <div className="border-0 border-blue-300 p-4 w-full mx-auto">
                            <div className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                    {Array(20).fill(null).map((empty, index) => (
                                            <div key={`tp-${index}`} className="grid grid-cols-8 gap-4">
                                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-6"></div>
                                            </div>

                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
        <div className='flex flex-col ' >
            <div className='grid grid-cols-1 2xl:grid-cols-2'>
                <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mx-1 mt-2 mb-1'>
                    {/* Header of card */}
                    <div className='flex flex-col md:flex-row  px-4 pt-5 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-left dark:text-gray-200 font-medium text-xl'>{'Runtime Comparison (hours)'}</div>
                        <div className='grid grid-cols-2 gap-2 mt-2 md:mt-0 md:ml-auto'>
                            <div className='col-1 items-center pt-1 md:pt-0 flex'>
                                <div className="flex justify-center items-center mr-4 min-w-[150px]">
                                    <div className=" w-full outline-none">
                                        <select defaultValue={0} onChange={handleChangeOeeComparisonDays}  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                        px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                            {lineChartComparisonFilters.map((days, index) => (
                                                <option key={days.id} value={index}  >{days.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {shiftList && Object.keys(shiftList).length > 0 && currentShift && Object.keys(currentShift).length > 0 && currentShiftIndex != null &&
                            <div className='col-1 items-center ml-auto pt-1 md:pt-0 flex'>
                                <div className="flex justify-center items-center mr-4 min-w-[150px]">
                                    <div className=" w-full outline-none">
                                        <select defaultValue={currentShiftIndex} onChange={handleChangeShiftRuntimeComparison}  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                        px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                            {shiftList.map((shift, index) => (
                                                <option key={shift.shift_name} value={index}  >{shift.shift_name? shift.shift_name : 'Current Shift'}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {loadingRuntimeComp &&
                        <LinearProgress color="primary" sx={{height:2}}/>
                    }
                    {/* Bar chart */}
                    <div className='flex flex-col md:flex-row min-h-[240px] overflow-hidden px-2 pt-2.5'>
                        <div className='mt-[-25px] grow'>
                            <LineChartComparison chartData={runtimeComparisonData} />
                        </div>
                    </div>
                </div>
                <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mx-1 mt-2 mb-1 grow'>
                    <div className='flex flex-col lg:flex-row lg:items-center px-4 pt-5 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='hidden lg:flex text-gray-700 text-left dark:text-gray-200 font-medium text-xl'>{'Downtime Classifications'}</div>
                        <div className='flex flex-col sm:flex-row items-center lg:ml-auto'>
                            <div className='flex lg:hidden text-gray-700 text-left dark:text-gray-200 font-medium text-xl'>{'Downtime Classifications'}</div>
                            {/* Toogle category vs severity  */}
                            <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                                <p className='text-gray-600 dark:text-gray-400 text-sm'>{toggleDowntimeSummary? 'Category': 'Severity'} </p>
                                <Switch
                                    size="small"
                                    color="secondary"
                                    checked={toggleDowntimeSummary}
                                    onChange={handleChangeOperationsDropdown}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row '>
                            {/* Date range selector */}
                            {/* <div className='ml-auto sm:ml-0 mt-4 lg:mt-0 lg:ml-4 '>
                                <DateRangeComponent onChange={handleChangeDowntimeSummaryDates} valueStart={downtimeSummaryDates.startDate || ''} valueEnd={downtimeSummaryDates.endDate || ''} minEndDate={downtimeSummaryDates.startDate} />
                            </div> */}
                            <div className='ml-auto mt-2 sm:ml-0 sm:mr-auto lg:mx-4'>
                                <ClassifyDowntimeListModal btnType='standardBtn' assetUuid={assetId} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} refreshDowntimeSummary={refreshDowntimeSummary} />
                            </div>
                            <div className='relative mt-2 sm:ml-2 sm:mr-2 '>
                                <DowntimeSeveritySelectedAssetFilterBtn setAppliedSeverityFilters={setAppliedSeverityFilters} />
                            </div>
                            {/* <div className='relative items-center mt-2 sm:mr-2'>
                                <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                                    className={`w-full flex justify-center overflow-hidden items-center px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
                                    onClick={handleOpenDowntimeShiftSelector}
                                >
                                    <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                                        Shifts
                                    </div>
                                    {Object.keys(selectedDowntimeShifts).length > 0 && Object.keys(selectedDowntimeShifts).length < 2 &&
                                    <div className='flex items-center truncate'>
                                        <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                                        {selectedDowntimeShifts.map((filtered,index) => (
                                            <div key={index} className='flex '>
                                            {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.shift_name}</div>
                                            </div>
                                        ))}
                                        <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                                    </div>
                                    }
                                    {Object.keys(selectedDowntimeShifts).length >= 2 &&
                                    <div className='flex items-center truncate'>
                                        <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                                        <div className='flex '>
                                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedDowntimeShifts[0].shift_name} and {Object.keys(selectedDowntimeShifts).length - 1} more</div>
                                        </div>
                                        <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                                    </div>
                                    }
                                </motion.button>
                                {showDowntimeShiftSelector && (
                                    <div className={`absolute z-20 right-0 top-10 flex flex-col w-[250px]`}>
                                        <div className=" overflow-hidden rounded-lg shadow-lg dark:shadow-lg-invert ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                                                <div className='flex justify-end bg-transparent'>
                                                    <button 
                                                        onClick={() => setShowDowntimeShiftSelector(false)} 
                                                        className='rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 p-1'>
                                                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 dark:text-gray-400 ">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            <div className={`mb-1.5`}>
                                                <input
                                                    id={`all-day-shift`}
                                                    type="checkbox"
                                                    className="checkboxBtn"
                                                    checked={allDayDowntimeCheckbox}
                                                    onChange={handleAllDayDowntimeCheckboxChange}
                                                />
                                                <label htmlFor={`all-day-shift`} className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {`All Day (24/7)`}
                                                </label>
                                            </div>
                                            <div className={`mb-1.5`}>
                                                <input
                                                    id={`all-day-shift`}
                                                    type="checkbox"
                                                    className="checkboxBtn"
                                                    checked={allShiftsDowntimeCheckbox}
                                                    onChange={handleAllShiftsDowntimeCheckboxChange}
                                                />
                                                <label htmlFor={`all-day-shift`} className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {`All Shifts`}
                                                </label>
                                                <ul className='pl-4'>
                                                    {allShifts.map((shift, index)=>(
                                                        <div key={index} className="flex items-center my-3">
                                                            <input id={`shift-checkbox-${index}`} type="checkbox" checked={checkedDowntimeShiftsState[index]} onChange={() => handleChangeDowntimeShiftsState(index)}  className="checkboxBtn"/>
                                                            <label htmlFor={`shift-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300">{shift.shift_name}</label>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                                <button  
                                                    onClick={handleApplyDowntimeShiftSelector} 
                                                    className='w-full text-base p-2 bg-slate-200 dark:bg-slate-700 text-gray-600 dark:text-white font-medium rounded-lg hover:bg-slate-300 dark:hover:bg-slate-900' >
                                                        Apply
                                                </button>
                                        </div>
                                    </div>
                                )}
                            </div> */}
                            <div className='relative  mt-2'>
                                <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                                    className={`w-full px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
                                    onClick={handleOpenDowntimeCalendar}
                                >
                                    <span className='text-sm pl-2'>{formatDate(downtimeStartDate)} {`-`} {formatDate(downtimeEndDate)}</span>
                                </motion.button>
                                {showDowntimeCalendar && (
                                    <div className={`absolute z-20 right-0 top-10 lg:right-48 lg:top-[-60px] flex flex-col`}>
                                        <div className='flex justify-end bg-slate-700 p-1 rounded-t-lg'>
                                            <button 
                                                onClick={closeDowntimeCalendar} 
                                                className='rounded-full hover:bg-slate-800 p-1'>
                                                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300 ">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                        <DateRangePicker
                                            ranges={[selectionDowntimeRange]}
                                            maxDate={maxCalendarDate}
                                            rangeColors={['#3b82f6']}
                                            onChange={handleDowntimeSelect}
                                            inputRanges={[{
                                                ...defaultInputRanges[0],
                                              }]}
                                            staticRanges={[
                                                // ...defaultStaticRanges,
                                                {
                                                    ...defaultStaticRanges[2]
                                                },
                                                {
                                                    ...defaultStaticRanges[3]
                                                },
                                                {
                                                    ...defaultStaticRanges[4]
                                                },
                                                {
                                                    ...defaultStaticRanges[5]
                                                },
                                                {
                                                  label: "Last Year",
                                                  range: () => ({
                                                    startDate: startOfYear(addYears(new Date(), -1)),
                                                    endDate: endOfYear(addYears(new Date(), -1))
                                                  }),
                                                  isSelected(range) {
                                                    const definedRange = this.range();
                                                    return (
                                                      isSameDay(range.startDate, definedRange.startDate) &&
                                                      isSameDay(range.endDate, definedRange.endDate)
                                                    );
                                                  }
                                                },
                                                {
                                                  label: "This Year",
                                                  range: () => ({
                                                    startDate: startOfYear(new Date()),
                                                    endDate: endOfDay(new Date())
                                                  }),
                                                  isSelected(range) {
                                                    const definedRange = this.range();
                                                    return (
                                                      isSameDay(range.startDate, definedRange.startDate) &&
                                                      isSameDay(range.endDate, definedRange.endDate)
                                                    );
                                                  }
                                                }
                                              ]}
                                        />
                                        <div>
                                        <button  
                                            onClick={handleApplyDowntimeDates} 
                                            className='w-full text-base p-2 bg-slate-700 text-white font-medium rounded-b-lg hover:bg-slate-900' >
                                                Apply
                                        </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {loadingDowntimeClassifications &&
                        <LinearProgress color="primary" sx={{height:2}}/>
                    }
                    <div className='flex flex-col md:flex-row min-h-[240px] overflow-hidden px-2'>
                        <div className='pt-1 grow'>
                            <ParetoChartDowntimesSelectedAsset downtimeSummary={downtimeSummary}  />
                        </div>
                    </div>
                </div>
            </div>
            <div className='xl:hidden shadow-iotflows-lg min-w-[300px] sm:min-w-[400px] rounded-[28px] dark:bg-slate-800 ml-1 md:ml-2 mt-2 md:mt-1 mr-1 md:mr-0 mb-1'>
                <div className='mt-1 mb-0.5 py-1 border-b-[1px] border-gray-200 dark:border-gray-700 pl-4'>
                    <div className='text-blue-900 text-left dark:text-blue-600 font-semibold text-sm uppercase'>Asset Activity</div>
                </div>
                <div className='h-[600px] lg:h-[720px] xl:h-[500px] 2xl:h-[520px]  overflow-x-hidden overflow-y-scroll scrollbar-hide mb-4 md:mb-2 xl:mb-0 2xl:mb-4'>
                {/* <CalendarCard partCalendar={partCalendar} /> */}
                {assetHistoryActivity && Object.keys(assetHistoryActivity).length > 0 ?
                    (
                        assetHistoryActivity.map(activity => (
                                assetActivityStatus(activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories)
                        ))
                    ):(
                        <div className="border-0 border-blue-300 p-4 w-full mx-auto">
                            <div className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-4">
                                    {Array(20).fill(null).map((empty, index) => (
                                        <div key={`bt-${index}`} className="grid grid-cols-8 gap-4">
                                            <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                                            <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-6"></div>
                                        </div>

                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
        {openEditSensorModal && 
        <EditSensorModal btnType='hideBtn' sensor={selectedAsset} getSensorList={getSensorList} remoteOpen={openEditSensorModal} handleCloseModal={handleCloseModal}/>
        }
    </div>
  )
}

export default SelectedAssetPageOverviewTab

const assetActivityStatus = (activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories) => {
    
    if(activity.utilization_state_id_modified == '2' || activity.utilization_state_id_modified == '5'){
        return (
            <div key={activity.utilization_id}>
                <AssetDowntimeCard downtimeItem={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories}  />
            </div>
        )

    }else if(activity.utilization_state_id_modified == '3'){
        return (
            <div key={activity.utilization_id}>
                <AssetUnclassifiedActivityCard activityItem={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} />
            </div>
        )

    }else if(activity.utilization_state_id_modified == '4'){
        return (
            <div key={activity.utilization_id}>
                <AssetActivityCard activityItem={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} />
            </div>
        )

    }

}