import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import AddMemberModal from '../../components/modals/memberModals/AddMemberModal';
import { darkModeState } from '../../atoms/darkModeState';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchIcon } from "@heroicons/react/outline"
import { PencilIcon, TrashIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { MemberTooltip } from '../../styles/Tooltip';
import { Zoom } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { toast } from 'react-hot-toast';

function AddPersonChatPage({setSelectedSubNav, getChatList}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const isDark = useRecoilValue(darkModeState)
    // const [filteredAllIoTFlowsMemberList, setFilteredAllIoTFlowsMemberList] = useState([])
    const [searchMemberText, setSearchMemberText] = useState('')
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [userDoesNotExist, setUserDoesNotExist] = useState(false)
    const [teamsList, setTeamsList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [filteredTeamsList, setFilteredTeamsList] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState([])
    

    useEffect(() => {
        let currentSearch = location.search
            if(currentSearch && selectedOrganization){
                updatedMemberList()
            }
      },[selectedOrganization, location])

    const updatedMemberList = () => {
        try {

            if(selectedOrganization ){
            //   getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user_contacts')
            //   .then(async response => {
            //     if(response.ok ){
            //       const data = await response.json();
            //       if(data && data.data){
            //         //   console.log('Chat memebrs',data.data)
            //           setUsersList(data.data)
            //       }
            //     }
            //   })
              getData(apiLink + '/v2/organizations/' + selectedOrganization + '/user_contacts')
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                //   console.log('V2 user_contacts',data.data)
                  if(data && data.data && data.data[0]){
                      setTeamsList(data.data[0].teams)
                      setUsersList(data.data[0].users)
                  }
                }
              })  
            }
          } catch (error) {
            console.log(error.message)
          }
    }

    useEffect(() => {
        if(searchMemberText  && searchMemberText.length > 0){
          const filteredTeams = teamsList.filter(member => {
            if(member.team_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || member.team_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.team_handle.toLowerCase().includes(searchMemberText.toLowerCase())){
              return member
            }
          })
          // console.log('filteredTeams', filteredTeams)
          setFilteredTeamsList(filteredTeams)
    
        }else{
          setFilteredTeamsList([])
        }


        if(searchMemberText  && searchMemberText.length > 0){
          // console.log('usersList', usersList)
          const filteredUsers = usersList.filter(member => {
            let fullName = member.user_first_name + ' ' + member.user_last_name
            if(member.user_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
              return member
            }
          })
          setFilteredMemberList(filteredUsers)
    
        }else{
          setFilteredMemberList([])
        //   setFilteredAllIoTFlowsMemberList([])
        }
      },[searchMemberText])
    
    const handleNavBack = () => {
        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let section = searchParams.getAll('section')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section, platform})
    }

    const handleDoesUserExist = (e) => {
        const key = e.key;
        if (key === 'Enter' || key === ',' || key === 'Tab') {
            getData(apiLink + '/v1/handles/' + searchMemberText + '/exists' )
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data && data.data.exists){

                    setSearchMemberText('')
                    setUserDoesNotExist(false)

                    postData(apiLink + '/v1/organizations/' + selectedOrganization + '/chats', 
                    {'chat_name': null,
                    'chat_picture': null,
                    'chatroom_members': [searchMemberText],
                    'chat_is_direct': true})
                    .then(async response => {
                        const data = await response.json();
                        if(response.ok){
                            if(data && data.data && data.data[0]){
                                let select = searchParams.getAll('select')
                                setSearchParams({select,chatId:data.data[0].chat_uuid, section:'messages'})
                                getChatList()
                            }
                        }
                        else{
                        try{
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e.message)
                        }
                        }
                    })
                    


                  }else{
                    setUserDoesNotExist(true)
                  }
                  
                }
              })
        }
    }

    const startNewChat = (selectedMemberHandle) => {
        try{
            // console.log('selectedMemberHandle', selectedMemberHandle)
            postData(apiLink + '/v1/organizations/' + selectedOrganization + '/chats', 
            {'chat_name': null,
              'chat_picture': null,
              'chatroom_members': [selectedMemberHandle],
              'chat_is_direct': true})
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                    if(data && data.data && data.data[0]){
                        let select = searchParams.getAll('select')
                        let platform = searchParams.getAll('platform')
                        setSearchParams({select,chatId:data.data[0].chat_uuid, section:'messages', platform})
                        getChatList()
                    }
                }
                else{
                  try{
                    toast.error(data.message)
                  }catch (e){
                    toast.error(e.message)
                  }
                }
              })
        }catch (e){
            toast.error(e.message)
        }
    }

    const handleSearchMemberText = (e) => {
        setSearchMemberText(e.target.value.replace(/,/g, ''))
        if(userDoesNotExist){
            setUserDoesNotExist(false)
        }

    }


  return (
    <div>
        <div className='border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900'>
            <div className='grid grid-cols-3 '>
                <ol className='flex '>
                    <li className='breadcrumbsNav flex items-center' onClick={handleNavBack} >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span className='text-sm'>Back</span>
                    </li>
                </ol>
                {/* <div className='text-gray-600 dark:text-gray-300 text-center'>Info</div>
                <div className='breadcrumbsNav text-right text-sm'>Edit</div> */}
            </div>
        </div>
        <div className='px-4'>
            <button 
                className='mt-2 flex text-blue-600 dark:text-blue-400 space-x-2 px-2 py-2 border-b-[0.01em] dark:border-slate-600 w-full hover:opacity-80' 
                onClick={() => setSelectedSubNav('addGroupChat')}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                </svg>
                <span>New Group </span>
            </button>
            {/* <button className='flex text-blue-600 dark:text-blue-500 space-x-2 px-2 py-2 border-b-[0.01em] dark:border-slate-600 w-full hover:opacity-80'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                </svg>
                <span>New Contact </span>
            </button> */}
            {/* ----Member list in organization */}
            <div className='pb-4'>
                {/* Search bar for searching members in org and in all of IoTFlows */}
                <div className='relative rounded-full w-full my-2'>
                <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                    <SearchIcon className='h-4 w-4 text-gray-400 ' />
                </div>
                <input className={`bg-gray-100 text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                            block w-full pl-8 text-sm rounded-lg text-ellipsis py-2`}
                        value={searchMemberText} 
                        onChange={handleSearchMemberText}
                        onKeyDown={handleDoesUserExist}
                        type='text' 
                        placeholder="Search" 
                        />
                </div>
                {searchMemberText && searchMemberText.length > 0 && userDoesNotExist &&
                    // No member found section
                    <div className='text-center'>
                    <div className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} py-2 w-full text-center`}>
                        <span>No matches found for {searchMemberText}</span>
                    </div>
                    </div>
                }
                <AnimatePresence mode='wait'>
                {searchMemberText === '' && teamsList && Object.keys(teamsList).length > 0 &&
                        <motion.div
                            key='allTeamMembersInOrg'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        > 
                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                            Teams
                        </div>
                        {/* List of members */}
                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                            {teamsList.map((currentTeam, index) => (
                                <div 
                                key={currentTeam.team_organization_handle} 
                                className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                onClick={() => startNewChat(currentTeam.team_organization_handle)}
                            >
                                <div className='relative'>
                                    <Avatar 
                                        sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 32, height: 32, }}  
                                        alt={currentTeam.team_name}
                                        src={currentTeam.team_image_url || 'brokenLink.jpg'}  />
                                    {currentTeam.is_external &&
                                        <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <div className='flex flex-col pl-2 grow overflow-hidden'>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{currentTeam.team_name}</h1>
                                        </div>
                                        {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span> */}

                                    </div>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{currentTeam.team_organization_handle}</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            ))}

                            </div>
                        </motion.div>
                    }
                    {searchMemberText === '' && usersList && Object.keys(usersList).length > 0 &&
                        <motion.div
                            key='allMembersInOrg'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        > 
                            <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                Users
                            </div>
                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                            {usersList.map((member, index) => (
                            <div 
                                key={member.user_organization_handle} 
                                className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                onClick={() => startNewChat(member.user_organization_handle)}
                            >
                                <div className='relative'>
                                    <Avatar 
                                        sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 32, height: 32, }}  src={member.user_image_url} />
                                    {member.is_external &&
                                        <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <div className='flex flex-col pl-2 grow overflow-hidden'>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                        </div>
                                        {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span> */}

                                    </div>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{member.user_organization_handle}</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            ))}

                            </div>
                        </motion.div>
                    }
                    {searchMemberText && searchMemberText.length > 0  && filteredTeamsList && Object.keys(filteredTeamsList).length > 0 &&
                    <motion.div
                            key='filteredTeamsListSection'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        > 
                            <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                Teams
                            </div>
                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                {filteredTeamsList.map((currentTeam, index) => (
                                <div 
                                key={currentTeam.team_organization_handle} 
                                className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                onClick={() => startNewChat(currentTeam.team_organization_handle)}
                            >
                                <div className='relative'>
                                    <Avatar 
                                        sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 32, height: 32, }} 
                                        alt={currentTeam.team_name}
                                        src={currentTeam.team_image_url || 'brokenLink.jpg'}  
                                    />
                                    {currentTeam.is_external &&
                                        <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <div className='flex flex-col pl-2 grow overflow-hidden'>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{currentTeam.team_name}</h1>
                                        </div>
                                        {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{currentTeam.user_organization_handle}</span> */}

                                    </div>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{currentTeam.team_organization_handle}</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                                ))}
                            </div>
                        </motion.div>
                        }
                    {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                    <motion.div
                            key='usersListSection'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        > 
                            <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                Users
                            </div>
                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                {filteredMemberList.map((member, index) => (
                                <div 
                                key={member.user_organization_handle} 
                                className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                onClick={() => startNewChat(member.user_organization_handle)}
                            >
                                <div className='relative'>
                                    <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 32, height: 32, }}  src={member.user_image_url} />
                                    {member.is_external &&
                                        <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <div className='flex flex-col pl-2 grow overflow-hidden'>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                        </div>
                                        {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span> */}

                                    </div>
                                    <div className='flex items-baseline '>
                                        <div className=''>
                                            <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{member.user_organization_handle}</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                                ))}
                            </div>
                        </motion.div>
                        }
                </AnimatePresence>
            </div>
        </div>
    </div>
  )
}

export default AddPersonChatPage