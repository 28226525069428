import React, { useEffect, useState } from 'react'
import { LightTooltip } from '../../styles/Tooltip'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import {  motion } from "framer-motion";
import { getData, putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import WorkOrderImageUploadSection from '../../components/modals/workOrderModals/WorkOrderImageUploadSection';
import ExpandWOImage from './ExpandWOImage';
import SelectedWOImageUploadSection from './SelectedWOImageUploadSection';
import { formatFileSize } from '../../utils/FileFormating';
import SelectedWOFileUploadSection from './SelectedWOFileUploadSection';

function FilesComponent({selectedWorkOrder, handleDownloadFile, getSelectedWorkOrder}) {
    const [isEditing, setIsEditing] = useState(false)
    const isDark = useRecoilValue(darkModeState); 
    const [loading, setLoading] = useState(false);
    const [workOrderFile, setWorkOrderFile] = useState([])
    

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){

            if(selectedWorkOrder.workorder_files && Object.keys(selectedWorkOrder.workorder_files).length > 0){
                
                const unCompressedFiles = selectedWorkOrder?.workorder_files?.filter(file => !file.file_is_compressed) || [];
                // console.log('unCompressedFiles', unCompressedFiles)
                setWorkOrderFile(unCompressedFiles)
            }else{
                setWorkOrderFile([])
            }
        }

    },[selectedWorkOrder])

    const handleClose = () => {
        setIsEditing(false)
    }

    

  return (
    <div className='flex flex-col w-full group'>
        <div className='flex text-gray-800 dark:text-white mb-2'>
            <span className='font-medium'>Files</span>
            {isEditing ?(
                <div className='flex ml-1 space-x-2'>
                    {/* <div className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} onClick={handleUpdate}>
                        Save
                    </div> */}
                    <div 
                        className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                        onClick={handleClose}>
                        Close
                    </div>
                </div>
            ):(
                <LightTooltip title='Edit files'>
                    <button 
                        className={`text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                        onClick={() => {
                            setIsEditing(true)
                        }}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                        </svg>

                    </button>
                </LightTooltip>

            )}
        </div>
        {isEditing ? (
            <div className='flex flex-col'>
                <div className=''>
                    <SelectedWOFileUploadSection workOrderFile={workOrderFile} selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                </div>
            </div>
        ):(
            <div className='flex w-full'>
                {workOrderFile &&  Object.keys(workOrderFile).length > 0 ? (    
                    <div className={`flex w-full py-1 space-x-4 overflow-x-auto message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} `}>
                        {workOrderFile.map(file => (
                            <motion.div
                            key={`fileContainer-${file.file_uuid}`}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className={`relative shrink-0 w-full max-w-[250px] truncate ${isDark ? 'bg-slate-700':'border-[0.01em] border-slate-200 bg-transparent'} px-2 py-1.5 rounded-xl shadow-md `}
                        >
                            <div className={`flex ${isDark ? 'text-gray-300':'text-gray-500'}  truncate items-center`}>
                                <div className='w-6'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                    </svg>
                                </div>
                                <div className='px-2 flex flex-col truncate'>
                                    <h2 className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'} truncate`}>
                                        {file.file_name? file.file_name : 'Unknown'}
                                    </h2>
                                    <span className={`text-sm ${isDark ? 'text-gray-400':'text-gray-500'}`}>
                                        {formatFileSize(file.file_size)}
                                    </span>
                                </div>
                                <LightTooltip title='Download'>
                                    <motion.button  
                                        whileHover={{scale: 1.05}} 
                                        whileTap={{scale:0.95}}   
                                        className={`w-7 ${isDark?'text-blue-400':'text-blue-600'} rounded-full ml-auto z-[1]`}
                                        onClick={() => handleDownloadFile(file)}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.3} stroke="currentColor" className="w-7 h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>

                                    </motion.button>
                                </LightTooltip>
                            </div>
                        </motion.div>
                        ))} 
                    </div>
                ):(
                    <button
                        onClick={() => setIsEditing(true)} 
                        className='text-blue-600 dark:text-blue-400 italic text-sm'>
                        Add files
                    </button>
                )}
            </div>

        )}
    
</div>
  )
}

export default FilesComponent