import React, { useState, useRef, useEffect } from 'react';
import {
    Path,
    Stage,
    Layer,
    Rect,
    Circle,
    Line,
    Text,
    Arrow,
    Ellipse,
    RegularPolygon,
    Star,
    Image as KonvaImage,
    Transformer,
  } from 'react-konva';
import debounce from 'lodash.debounce';
import ImageObject from './ImageObject';
import { motion } from "framer-motion";
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../atoms/darkModeState';
import AssetStatus from './AssetStatus';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { getData, putData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import MqttService from '../core/services/MqttService';
import CanvasRightSideDrawer from './CanvasRightSideDrawer';
import Konva from 'konva';
import LineObject from './LineObject';
import ArrowObject from './ArrowObject';
import toast from 'react-hot-toast';



const Grid = React.memo(({ isDark }) => {
    const lines = [];
    for (let i = -10000; i < 10000; i += 50) {
      lines.push(
        <Line
          points={[-10000, i, 10000, i]}
          stroke={isDark ? '#334155' : '#e2e8f0'}
          strokeWidth={1}
          key={`h${i}`}
        />,
        <Line
          points={[i, -10000, i, 10000]}
          stroke={isDark ? '#334155' : '#e2e8f0'}
          strokeWidth={1}
          key={`v${i}`}
        />
      );
    }
    return <>{lines}</>;
  });
  


const TransformerComponent = ({ selectedShapeName }) => {
    const transformerRef = useRef();

  
    useEffect(() => {
      const stage = transformerRef.current.getStage();
      const selectedNode = stage.findOne(`#${selectedShapeName}`);
      if (selectedNode) {
        transformerRef.current.nodes([selectedNode]);
      } else {
        transformerRef.current.nodes([]);
      }
      transformerRef.current.getLayer().batchDraw();
    }, [selectedShapeName]);
  
    return <Transformer ref={transformerRef} />;
  };


function CanvasPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const isDark = useRecoilValue(darkModeState)
    const stageRef = useRef(null);
    const [objects, setObjects] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [stageScale, setStageScale] = useState(1);
    const [svgElements, setSvgElements] = useState([]);
    const [assetList, setAssetList] = useState([])
    const [loadingAssets, setLoadingAssets] = useState(false)
    const inputColorRef = useRef(null);
    const [mqttMessageNodeUUID, setMqttMessageNodeUUID] = useState('')
    const [mqttMessageTimestamp, setMqttMessageTimestamp] = useState(0)
    const [selectedImagesVideos, setSelectedImagesVideos] = useState(null);
    const hiddenImageVideoInput = useRef(null);
    const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
    const [panStartPosition, setPanStartPosition] = useState({ x: 0, y: 0 }); // To store pan start position
    const [isPanning, setIsPanning] = useState(false); // To track if we're panning
    const [shiftPressed, setShiftPressed] = useState(false); // To track Shift key press

    const [selectedObject, setSelectedObject] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);



    useEffect(() => {
        const savedScale = localStorage.getItem('stageScale');
        const savedPosition = localStorage.getItem('stagePosition');
        
        const savedState = localStorage.getItem('canvasState');
        
                    
        if (savedState) {
            setObjects(JSON.parse(savedState));
        }
        if (savedScale) {
          setStageScale(parseFloat(savedScale));
        }
    
        if (savedPosition) {
          setStagePosition(JSON.parse(savedPosition));
        }
      }, []);


  // Handle key press for Shift
    useEffect(() => {
        const handleKeyDown = (e) => {
        if (e.key === 'Shift') {
            setShiftPressed(true);
        }
        };
        const handleKeyUp = (e) => {
        if (e.key === 'Shift') {
            setShiftPressed(false);
        }
        };
    
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Handle mouse down (start selection or drag)
  const handleMouseDown = (e) => {

    const stage = stageRef.current;
    const isObjectClicked = e.target !== stage;

    if (!isObjectClicked && !shiftPressed) {
      // Start panning if mouse is down on empty space and Shift is not pressed
      setIsPanning(true);
      const pointerPos = stage.getPointerPosition();
      setPanStartPosition(pointerPos); // Store the pointer's initial position when the pan started
      setStagePosition(stage.position()); // Store the initial stage position when the pan started
    }

    if (e.target === e.target.getStage()) {
        setSelectedId(null);
        setDrawerOpen(false)
    }

  };

  // Handle mouse move (dragging selection box or panning)
  const handleMouseMove = (e) => {
    if (isPanning) {
        // Handle panning
        const stage = stageRef.current;
        const pointerPos = stage.getPointerPosition();
  
        const dx = pointerPos.x - panStartPosition.x; // Delta in x
        const dy = pointerPos.y - panStartPosition.y; // Delta in y
  
        const newStagePosition = {
          x: stagePosition.x + dx, // Update stage position based on the deltas
          y: stagePosition.y + dy,
        };
  
        stage.position(newStagePosition); // Update stage position for panning
        stage.batchDraw(); // Redraw the stage for smooth panning
    }
  };

  // Handle mouse up (finish selection or panning)
  const handleMouseUp = () => {
    // Finish panning
    setIsPanning(false);
  };

    useEffect(() => {
        if(selectedOrganization){
            getCanvas()
        }
      }, [selectedOrganization])
    
      const getCanvas = () => {
        if(selectedOrganization && !loadingAssets){
            setLoadingAssets(true)
    
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/canvas' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                if(data && data.data && data.data[0]){
                  
                        const savedState = data.data[0].canvas_state
                        const savedAssetState = data.data[0].canvas_asset_state
                        
                        if (savedState) {
                          setObjects(JSON.parse(savedState));
                        }
                        if(savedAssetState && savedAssetState.length > 3){
                          setAssetList(JSON.parse(savedAssetState))
                          setLoadingAssets(false)
                        }else{
                          getOrgAsset()
                            
                        }
    
                }else{
                    getOrgAsset()
                  }
              }else{
                  getOrgAsset()
              }
            })
            
    
        }
    
      }

      const getOrgAsset = () => {
        if(selectedOrganization ){
          getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/organization_assets' )
          .then(async response => {
              // JSON data parsed by response.json() call
              if(response.ok ){
              const data = await response.json();
                  if(data && data.data){
                      setLoadingAssets(false)
                      // setAssetList(data.data)
                        // Add "type: 'asset'" to each object
                        const updatedArray = data.data.map((obj, index) => ({
                            ...obj,           
                            type: 'asset',
                            id: obj.asset_uuid,
                            x: 100 + index * 150,
                            y: 100 + index * 150,
                            scaleX: 1,
                            scaleY: 1,
                            rotation: 0
                        }));

                        setAssetList(updatedArray)
                      

                  }else{
                      setLoadingAssets(false)
                  }
              }else{
                  setLoadingAssets(false)
              }
          })
        }

      }


  useEffect(() => {
    if(assetList && Object.keys(assetList).length > 0){
        const saveData = debounce(() => {
          const assetCanvasJson = JSON.stringify(assetList);

          putData(apiLink + '/v1/organizations/' + selectedOrganization + '/canvas', 
          {
            "canvas_asset_state": assetCanvasJson,
          })
            .then(async response => {
              const data = await response.json()
              if(response.ok) return
              else{
                try{
                  toast.error(data.message)
                }catch (e){
                  toast.error('Something went wrong')
                }
              }
            })


        }, 100); 
      
        saveData();
      
        // Cleanup function to cancel debounce on unmount or before next call
        return () => {
          saveData.cancel();
        };
    }
  }, [assetList]);

  useEffect(() => {
    if(assetList && Object.keys(assetList).length > 0){
        const saveData = debounce(() => {
          const canvasJson = JSON.stringify(objects);

          putData(apiLink + '/v1/organizations/' + selectedOrganization + '/canvas', 
          {"canvas_state": canvasJson,
          })
            .then(async response => {
              const data = await response.json()
              if(response.ok) return
              else{
                try{
                  toast.error(data.message)
                }catch (e){
                  toast.error('Something went wrong')
                }
              }
            })


        }, 100); 
      
        saveData();
      
        // Cleanup function to cancel debounce on unmount or before next call
        return () => {
          saveData.cancel();
        };
    }
  }, [objects]);


  useEffect(() => {
    const layer = stageRef.current.getLayers()[0];
    layer.batchDraw(); // Only redraw once when objects change
  }, [objects]);

  



  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.key === 'Delete' || e.key === 'Backspace') && selectedId) {
        deleteSelected();
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedId, objects]); 


  useEffect(() => {
    try {
        if(selectedOrganization){
            setTimeout(() => {
                //  MQTT Subcribe - We need a delay to ensure MQTT is connected before trying to subscribe
                if(MqttService.isConnected) {
                //   let activityTopic = `v1/organizations/${selectedOrganization}/senseai/nodes/+/events/#`
                  let activityTopic = `v1/organizations/${selectedOrganization}/senseai/nodes/+/events/activity`
                  MqttService.subscribe(activityTopic, onMQTTReceived)
                }
              }, 5000)
          
        }
        
    } catch (error) {
        
    }
}, [selectedOrganization])

const onMQTTReceived = message => {
    try{ 
        let split = message.topic.split('/')
        const payloadMessage = JSON.parse(message.payloadString);
        
        if(split[4] == "nodes"){
            // console.log('payloadMessage', payloadMessage)
            let node_uuid = split[5]
            let currentTime = new Date().getTime()
            setMqttMessageNodeUUID(node_uuid)
            setMqttMessageTimestamp(currentTime)
            
        }
      }
      catch(e){
        console.log("Error in reading MQTT");
        console.log(e.message);
      }
}




  


  const addRectangle = () => {
    // Get the current stage reference
    const stage = stageRef.current;

    // Get the current stage position (top-left corner of the viewport) and scale
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling

    // Get the viewport size (canvas dimensions)
    const stageWidth = stage.width();
    const stageHeight = stage.height();


     // Calculate the center of the current viewport, taking panning and zoom into account
    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;

    // Add the rectangle with coordinates based on the current zoom and position
    const newRect = {
        id: `rect${objects.length + 1}`,
        type: 'rectangle',
        x: Math.round(newX),  
        y: Math.round(newY),  
        width: 100,
        height: 50,
        fill: isDark ? '#475569':  '#cbd5e1',
        stroke: '#00000',
        strokeWidth: 0,
        rotation: 0,
        cornerRadius: 15,
    };

    // Update the objects state
    setObjects([...objects, newRect]);

  };

  const addLine = () => {
    const stage = stageRef.current;
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling
    const stageWidth = stage.width();
    const stageHeight = stage.height();

    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;
    const newLine = {
      id: `line${objects.length + 1}`,
      type: 'line',
      points: [newX, newY, newX + 200, newY + 200], // Example line points
      stroke: isDark ? '#fffff':  '#00000',
      strokeWidth: 4,
      opacity: 1,
    };
    setObjects([...objects, newLine]);
  };

  const addArrow = () => {
    const stage = stageRef.current;
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling
    const stageWidth = stage.width();
    const stageHeight = stage.height();

    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;
    const newArrow = {
      id: `arrow${objects.length + 1}`,
      type: 'arrow',
      points: [Math.round(newX), Math.round(newY), Math.round(newX) + 200, Math.round(newY) + 200], // Example line points
      stroke: isDark ? '#fffff':  '#00000',
      fill: isDark ? '#fffff':  '#00000',
      strokeWidth: 4,
      pointerLength: 10,
      pointerWidth: 10,
      opacity: 1
    };
    setObjects([...objects, newArrow]);
  };

  const addEllipse = () => {
    const stage = stageRef.current;

    // Get the current stage position (top-left corner of the viewport) and scale
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling

    // Get the viewport size (canvas dimensions)
    const stageWidth = stage.width();
    const stageHeight = stage.height();


     // Calculate the center of the current viewport, taking panning and zoom into account
    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;

    // Add the rectangle with coordinates based on the current zoom and position
    const newEllipse = {
      id: `ellipse${objects.length + 1}`,
      type: 'ellipse',
      x: Math.round(newX),  
      y: Math.round(newY),  
      radiusX: 50,
      radiusY: 30,
      fill: isDark ? '#475569':  '#cbd5e1',
      stroke: '#00000',
      strokeWidth: 0,
      borderWidth:'0px'
    };
    setObjects([...objects, newEllipse]);
  };

  const addPolygon = () => {
    const stage = stageRef.current;

    // Get the current stage position (top-left corner of the viewport) and scale
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling

    // Get the viewport size (canvas dimensions)
    const stageWidth = stage.width();
    const stageHeight = stage.height();


     // Calculate the center of the current viewport, taking panning and zoom into account
    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;

    // Add the rectangle with coordinates based on the current zoom and position
    const newPolygon = {
      id: `polygon${objects.length + 1}`,
      type: 'polygon',
      x: Math.round(newX),  
      y: Math.round(newY),  
      sides: 5,
      radius: 50,
      fill: isDark ? '#475569':  '#cbd5e1',
      stroke: '#00000',
      strokeWidth: 0,
    };
    setObjects([...objects, newPolygon]);
  };

  const addStar = () => {
    const stage = stageRef.current;

    // Get the current stage position (top-left corner of the viewport) and scale
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling

    // Get the viewport size (canvas dimensions)
    const stageWidth = stage.width();
    const stageHeight = stage.height();


     // Calculate the center of the current viewport, taking panning and zoom into account
    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;

    // Add the rectangle with coordinates based on the current zoom and position
    const newStar = {
      id: `star${objects.length + 1}`,
      type: 'star',
      x: Math.round(newX),  
      y: Math.round(newY),  
      numPoints: 5,
      innerRadius: 20,
      outerRadius: 40,
      fill: isDark ? '#475569':  '#cbd5e1',
      stroke: '#00000',
      strokeWidth: 0,
    };
    setObjects([...objects, newStar]);
  };

//   const addImage = () => {
//     const newImage = {
//       id: `image${objects.length + 1}`,
//       type: 'image',
//       x: 50,
//       y: 50,
//       src: 'https://konvajs.org/assets/yoda.jpg',
//       width: 100,
//       height: 100,
//     };
//     setObjects([...objects, newImage]);
//   };

  const addText = () => {
    const stage = stageRef.current;

    // Get the current stage position (top-left corner of the viewport) and scale
    const stagePos = stage.position();
    const stageScale = stage.scaleX(); // Assuming uniform scaling

    // Get the viewport size (canvas dimensions)
    const stageWidth = stage.width();
    const stageHeight = stage.height();


     // Calculate the center of the current viewport, taking panning and zoom into account
    const newX = (-stagePos.x + stageWidth / 2) / stageScale;
    const newY = (-stagePos.y + stageHeight / 2) / stageScale;

    // Add the rectangle with coordinates based on the current zoom and position
    const newText = {
      id: `text${objects.length + 1}`,
      type: 'text',
      x: Math.round(newX),  
      y: Math.round(newY),        
      text: 'Sample Text',
      fontSize: 32,
      fontFamily: 'Inter',
      fontWeight: 100,
      fill: isDark ? '#d1d5db' : '#4b5563',
      rotation: 0, 
      opacity: 1 ,
      strokeWidth:0
    };
    setObjects([...objects, newText]);
  };


  const handleWheel = (e) => {
    e.evt.preventDefault();
  
    const stage = e.target.getStage();
    const oldScale = stage.scaleX(); // Assuming uniform scaling
  
    // Get the mouse position relative to the stage
    const pointer = stage.getPointerPosition();
  
    // Zoom factor: reverse the deltaY to invert the scroll direction
    const scaleBy = 1.05; // Adjust zoom sensitivity
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStageScale(newScale); // Update state with new scale

  
    // Adjust the position of the stage to zoom around the mouse pointer
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };
  
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
  
    // Apply the new scale and position
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();

    // Save the scale and position to local storage
    localStorage.setItem('stageScale', newScale.toString());
    localStorage.setItem('stagePosition', JSON.stringify(newPos));
  };

  const handleSelect = (e, obj) => {
    setSelectedId(e.target.id());
    // console.log('obj', obj)
    setSelectedObject(obj);
        setDrawerOpen(true);
  };

  const handleMachineSelect = (id, obj) => {
    setSelectedId(id);
    setSelectedObject(obj);
        setDrawerOpen(true);
  };

  const handleDeselect = (e) => {

    // Deselect only if clicked on empty area
    if (e.target === e.target.getStage()) {
        setSelectedId(null);
        setDrawerOpen(false)
    }
  };

  const saveCanvas = () => {
    const json = JSON.stringify(objects);
    localStorage.setItem('canvasState', json);
  };

  
  const clearCanvas = () => {
    setObjects([]);
    localStorage.removeItem('canvasState');
    setSelectedId(null);
  };

  const deleteSelected = () => {
    if (selectedId) {
      const newObjects = objects.filter((obj) => obj.id !== selectedId);
      setObjects(newObjects);
      setSelectedId(null);
    }
  };

  const handleColorChange = (e) => {
    const color = e.target.value;

    console.log('e.target.value', e.target.value)

    const updatedObjects = objects.map((obj) => {
      if (obj.id === selectedId) {
        return { ...obj, fill: color };
      }
      return obj;
    });
    setObjects(updatedObjects);
  };



//   const handleMachineDragEnd = (id, pos) => {
//     setAssetList((prevMachines) =>
//       prevMachines.map((machine) =>
//         machine.asset_uuid === id ? { ...machine, ...pos } : machine
//       )
//     );
//   };

  const handleMachineDragEnd = debounce((id, pos) => {
    setAssetList((prevMachines) =>
      prevMachines.map((machine) =>
        machine.asset_uuid === id ? { ...machine, ...pos } : machine
      )
    );
  }, 0);
  
  const handleTransformEnd = (attrs) => {
    // console.log('attrs', attrs)
    setAssetList((prevMachines) =>
      prevMachines.map((machine) =>
        machine.asset_uuid === attrs.id ? { ...machine, ...attrs } : machine
      )
    );
  };

  const handleImageVideoClick = event => {
    hiddenImageVideoInput.current.click();
  };

  const handleImageVideoChange = event => {
    
    setSelectedImagesVideos(Array.from(event.target.files));
    // setIsFilePreviewModalOpen(true)
    
    // Reset input value
    event.target.value = '';
  };

  useEffect(() => {
      
    if(selectedImagesVideos && Object.keys(selectedImagesVideos).length > 0){

      const firstFile = selectedImagesVideos[0];

      if (firstFile) {
          const reader = new FileReader();

          // Event handler when file reading is complete
          reader.onload = () => {
              const imageSrc = reader.result; // Base64 data URL of the first file

              const img = new Image(); // Create a new Image object
              img.src = imageSrc;
              console.log('imageSrc', imageSrc);

              // Wait until the image is fully loaded to get its dimensions
              img.onload = () => {
                  const newImage = {
                      id: `image${objects.length + 1}`, // Unique ID for the image
                      type: 'image', // Define the object type
                      x: 50, // X position (can be customized)
                      y: 50, // Y position (can be customized)
                      src: imageSrc, // Set base64 data URL directly in src
                      rotation: 0, // Initial rotation
                      width: img.width, // Natural width of the image
                      height: img.height, // Natural height of the image
                  };

                  // Add the new image object to the existing state
                  setObjects((prevObjects) => [...prevObjects, newImage]);
              };
          };

          // Event handler for errors in file reading
          reader.onerror = (error) => {
              console.error("Error reading the file as base64:", error);
          };

          // Read the first file as a base64-encoded data URL
          reader.readAsDataURL(firstFile);
      }


        // const newPreviews = selectedImagesVideos.map(file => URL.createObjectURL(file))
        // newPreviews.forEach((imageSrc, index) => {
        //     const img = new Image();  // Create a new Image object
        //     img.src = imageSrc;
        //     console.log('imageSrc', imageSrc)
          
        //     // Wait until the image is loaded to get its dimensions
        //     img.onload = () => {
        //       const newImage = {
        //         id: `image${objects.length + 1}`,
        //         type: 'image',
        //         x: 50 + (index * 50),
        //         y: 50 + (index * 50),
        //         src: imageSrc,
        //         rotation: 0,
        //         width: img.width,  // Set the natural width of the image
        //         height: img.height,  // Set the natural height of the image
        //       };
          
        //       // Set the new object with updated dimensions
        //       setObjects((prevObjects) => [...prevObjects, newImage]);
        //     };
        //   });
        
    }

},[selectedImagesVideos])

//   const addImage = () => {
//     const newImage = {
//       id: `image${objects.length + 1}`,
//       type: 'image',
//       x: 50,
//       y: 50,
//       src: 'https://konvajs.org/assets/yoda.jpg',
//       width: 100,
//       height: 100,
//     };
//     setObjects([...objects, newImage]);
//   };

    

 // Update object properties
 const handleUpdateObject = (newProperties) => {
    // console.log('newProperties', newProperties)
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === selectedObject.id ? { ...obj, ...newProperties } : obj
      )
    );

    setAssetList((prevMachines) =>
        prevMachines.map((machine) =>
          machine.asset_uuid === selectedObject.id ? { ...machine, ...newProperties } : machine
        )
      );
  };

 



  return (
    <div>
    <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageVideoChange}
        ref={hiddenImageVideoInput}
    />
      <Stage
        perfectDrawEnabled={false}
        width={window.innerWidth}
        height={window.innerHeight}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stagePosition.x}
        y={stagePosition.y}
        onWheel={handleWheel}
        // draggable
        ref={stageRef}
        // onMouseDown={handleDeselect}
        onTouchStart={handleDeselect}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          <Grid isDark={isDark} />
          {objects.map((obj, i) => {
            switch (obj.type) {
              case 'rectangle':
                return (
                  <Rect
                    key={obj.id}
                    id={obj.id}
                    {...obj}
                    draggable
                    onClick={(e) => handleSelect(e, obj)}
                    onTap={(e) => handleSelect(e, obj)}
                    // dragBoundFunc={(pos) => {
                    //     const gridSize = 20;
                    //     const newX = Math.round(pos.x / gridSize) * gridSize;
                    //     const newY = Math.round(pos.y / gridSize) * gridSize;
                    //     return { x: newX, y: newY };
                    //   }}
                    onDragEnd={(e) => {
                      const newObjects = objects.slice();
                      newObjects[i] = {
                        ...obj,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                      };
                      setObjects(newObjects);
                      setSelectedObject(newObjects[i])
                    }}
                    onTransformEnd={(e) => {
                        // console.log('Rec transform e', e)
                      const node = e.target;
                      const scaleX = node.scaleX();
                      const scaleY = node.scaleY();

                      node.scaleX(1);
                      node.scaleY(1);

                      const newObjects = objects.slice();
                      newObjects[i] = {
                        ...obj,
                        x: Math.round(node.x()),  // Ensure two decimal places
                        y: Math.round(node.y()),  // Ensure two decimal places
                        rotation: Math.round(node.rotation()),  // Ensure two decimal places
                        width: Math.round(obj.width * scaleX),  // Ensure two decimal places
                        height: Math.round(obj.height * scaleY),  // Ensure two decimal places
                      };
                    //   console.log('newObjects', newObjects)
                      setObjects(newObjects);
                      setSelectedObject(newObjects[i])
                    }}
                  />
                );
              case 'line':
                return (
                    <LineObject key={obj.id} obj={obj} objects={objects} selectedObject={selectedObject} i={i} handleSelect={handleSelect} setObjects={setObjects} setSelectedObject={setSelectedObject}  />
                );
              case 'arrow':
                return (
                    <ArrowObject key={obj.id} obj={obj} objects={objects} selectedObject={selectedObject} i={i} handleSelect={handleSelect} setObjects={setObjects} setSelectedObject={setSelectedObject}  />
                );
              case 'ellipse':
                return (
                  <Ellipse
                    key={obj.id}
                    id={obj.id}
                    {...obj}
                    draggable
                    // dragBoundFunc={(pos) => {
                    //     const gridSize = 20;
                    //     const newX = Math.round(pos.x / gridSize) * gridSize;
                    //     const newY = Math.round(pos.y / gridSize) * gridSize;
                    //     return { x: newX, y: newY };
                    //   }}
                    onClick={(e) => handleSelect(e, obj)}
                    onTap={(e) => handleSelect(e, obj)}
                    onDragEnd={(e) => {
                      const newObjects = objects.slice();
                      newObjects[i] = {
                        ...obj,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                      };
                      setObjects(newObjects);
                      setSelectedObject(newObjects[i])
                    }}
                    onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                      
                        node.scaleX(1);
                        node.scaleY(1);
                      
                        const newObjects = objects.slice();
                        newObjects[i] = {
                          ...obj,
                          x: Math.round(node.x()),
                          y: Math.round(node.y()),
                          rotation: Math.round(node.rotation()),
                          radiusX: Math.round(obj.radiusX * scaleX),
                          radiusY: Math.round(obj.radiusY * scaleY),
                        };
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                      }}
                      
                  />
                );
              case 'polygon':
                return (
                  <RegularPolygon
                    key={obj.id}
                    id={obj.id}
                    {...obj}
                    draggable
                    // dragBoundFunc={(pos) => {
                    //     const gridSize = 20;
                    //     const newX = Math.round(pos.x / gridSize) * gridSize;
                    //     const newY = Math.round(pos.y / gridSize) * gridSize;
                    //     return { x: newX, y: newY };
                    //   }}
                    onClick={(e) => handleSelect(e, obj)}
                    onTap={(e) => handleSelect(e, obj)}
                    onDragEnd={(e) => {
                      const newObjects = objects.slice();
                      newObjects[i] = {
                        ...obj,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                      };
                      setObjects(newObjects);
                      setSelectedObject(newObjects[i])
                    }}
                    onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                      
                        node.scaleX(1);
                        node.scaleY(1);
                      
                        const newObjects = objects.slice();
                        newObjects[i] = {
                          ...obj,
                          x: Math.round(node.x()),
                          y: Math.round(node.y()),
                          rotation: Math.round(node.rotation()),
                          radius: Math.round(obj.radius * Math.max(scaleX, scaleY)), // Keep proportions
                        };
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                      }}
                      
                  />
                );
              case 'star':
                return (
                  <Star
                    key={obj.id}
                    id={obj.id}
                    {...obj}
                    draggable
                    // dragBoundFunc={(pos) => {
                    //     const gridSize = 20;
                    //     const newX = Math.round(pos.x / gridSize) * gridSize;
                    //     const newY = Math.round(pos.y / gridSize) * gridSize;
                    //     return { x: newX, y: newY };
                    //   }}
                    onClick={(e) => handleSelect(e, obj)}
                    onTap={(e) => handleSelect(e, obj)}
                    onDragEnd={(e) => {
                      const newObjects = objects.slice();
                      newObjects[i] = {
                        ...obj,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                      };
                      setObjects(newObjects);
                      setSelectedObject(newObjects[i])
                    }}
                    onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                      
                        // Reset the scale back to 1 to avoid compounding effects
                        node.scaleX(1);
                        node.scaleY(1);
                      
                        // Create a new objects array with the updated values
                        const newObjects = objects.slice();
                        newObjects[i] = {
                          ...obj,
                          x: Math.round(node.x()),
                          y: Math.round(node.y()),
                          rotation: Math.round(node.rotation()),
                          innerRadius: Math.round(obj.innerRadius * scaleX),  // Adjust innerRadius
                          outerRadius: Math.round(obj.outerRadius * scaleY),  // Adjust outerRadius
                        };
                      
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                      }}
                      
                  />
                );
              case 'image':
                return (
                    <ImageObject
                        key={obj.id}
                        obj={obj}
                        handleSelect={(e) => handleSelect(e, obj)}
                        handleDragEnd={(e) => {
                        const newObjects = objects.slice();
                        newObjects[i] = {
                            ...obj,
                            x: Math.round(e.target.x()),
                            y: Math.round(e.target.y()),
                        };
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                        }}
                        // onDragEnd={(e) => {
                        // const newObjects = objects.slice();
                        // newObjects[i] = {
                        //     ...obj,
                        //     x: Math.round(e.target.x()),
                        //     y: Math.round(e.target.y()),
                        // };
                        // setObjects(newObjects);
                        // setSelectedObject(newObjects[i])
                        // }}
                        handleTransformEnd={(e) => {
                            // console.log('Rec transform e', e)
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        node.scaleX(1);
                        node.scaleY(1);


                        const newObjects = objects.slice();
                        newObjects[i] = {
                            ...obj,
                            x: Math.round(node.x()),  // Ensure two decimal places
                            y: Math.round(node.y()),  // Ensure two decimal places
                            rotation: Math.round(node.rotation()),  // Ensure two decimal places
                            width: Math.round(obj.width * scaleX),  // Ensure two decimal places
                            height: Math.round(obj.height * scaleY),  // Ensure two decimal places
                        };
                        //   console.log('newObjects', newObjects)
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                        }}
                    />
                );
              case 'text':
                return (
                  <Text
                    key={obj.id}
                    id={obj.id}
                    {...obj}
                    draggable
                    // dragBoundFunc={(pos) => {
                    //     const gridSize = 20;
                    //     const newX = Math.round(pos.x / gridSize) * gridSize;
                    //     const newY = Math.round(pos.y / gridSize) * gridSize;
                    //     return { x: newX, y: newY };
                    //   }}
                    onClick={(e) => handleSelect(e, obj)}
                    onTap={(e) => handleSelect(e, obj)}
                    onDblClick={(e) => {
                        const textNode = e.target;
                        const stage = textNode.getStage();
                        const layer = textNode.getLayer();
                        const rotation = textNode.getAbsoluteRotation();
                      
                        // Hide text node and transformer during editing
                        textNode.hide();
                        layer.draw();
                      
                        // Get the absolute position of the text node
                        const textPosition = textNode.absolutePosition();
                      
                        // Get the stage container position and scaling
                        const stageBox = stage.container().getBoundingClientRect();
                      
                        // Get the scaleX and scaleY of the stage
                        const scaleX = stage.scaleX();
                        const scaleY = stage.scaleY();
                      
                        // Calculate the adjusted position of the textarea
                        const areaPosition = {
                          x: stageBox.left + textPosition.x * scaleX,
                          y: stageBox.top + textPosition.y * scaleY,
                        };
                      
                        // Create textarea and style it
                        const textarea = document.createElement('textarea');
                        document.body.appendChild(textarea);
                      
                        textarea.value = textNode.text();
                        textarea.style.position = 'absolute';
                        textarea.style.top = areaPosition.y + 'px';
                        textarea.style.left = areaPosition.x + 'px';
                        textarea.style.zIndex = 1000;
                      
                        // Adjust width and height according to scaleX and scaleY
                        textarea.style.width = textNode.width() * scaleX + 'px';
                        textarea.style.height = textNode.height() * scaleY + 'px';
                      
                        // Adjust font size according to the average scale
                        const averageScale = (scaleX + scaleY) / 2;
                        textarea.style.fontSize = textNode.fontSize() * averageScale + 'px';
                      
                        // Apply other styles
                        textarea.style.border = 'none';
                        textarea.style.padding = '0px';
                        textarea.style.margin = '0px';
                        textarea.style.overflow = 'hidden';
                        textarea.style.background = 'none';
                        textarea.style.outline = 'none';
                        textarea.style.resize = 'none';
                        textarea.style.lineHeight = textNode.lineHeight();
                        textarea.style.fontFamily = textNode.fontFamily();
                        textarea.style.transformOrigin = 'left top';
                        textarea.style.textAlign = textNode.align();
                        textarea.style.color = textNode.fill();
                        textarea.style.whiteSpace = 'pre';
                      
                        // Adjust the transform to account for scaleX and scaleY
                        textarea.style.transform = `rotate(${rotation}deg) scale(${scaleX}, ${scaleY})`;
                      
                        textarea.focus();
                      
                        const removeTextarea = () => {
                          textarea.parentNode.removeChild(textarea);
                          window.removeEventListener('click', handleOutsideClick);
                          textNode.show();
                          layer.draw();
                        };
                      
                        const setTextareaWidth = (newWidth) => {
                          if (!newWidth) {
                            newWidth = textNode.placeholder.length * textNode.fontSize();
                          }
                          // Adjust the width according to the new content
                          textarea.style.width = newWidth + 'px';
                        };
                      
                        // Automatically adjust the width and height of the textarea
                        textarea.addEventListener('input', () => {
                          textarea.style.height = 'auto';
                          textarea.style.height = textarea.scrollHeight + 3 + 'px';
                        });
                      
                        const handleOutsideClick = (e) => {
                          if (e.target !== textarea) {
                            updateText();
                          }
                        };
                      
                        const updateText = () => {
                          const newText = textarea.value;
                          
                          // Update the width and height based on the new text
                          const tempTextNode = new Konva.Text({
                            text: newText,
                            fontSize: textNode.fontSize(),
                            fontFamily: textNode.fontFamily(),
                            lineHeight: textNode.lineHeight(),
                          });
                      
                          const newWidth = tempTextNode.width();
                          const newHeight = tempTextNode.height();
                      
                          // Update the actual text node with new dimensions
                          const updatedObjects = objects.map((obj) => {
                            if (obj.id === textNode.id()) {
                              return {
                                ...obj,
                                text: newText,
                                width: newWidth,    // Update width based on the new text
                                height: newHeight,  // Update height based on the new text
                              };
                            }
                            return obj;
                          });
                      
                          setObjects(updatedObjects);
                          removeTextarea();
                        };
                      
                        setTimeout(() => {
                          window.addEventListener('click', handleOutsideClick);
                        });
                      }}
                    onDragEnd={(e) => {
                        const newObjects = objects.slice();
                        newObjects[i] = {
                            ...obj,
                            x: Math.round(e.target.x()),
                            y: Math.round(e.target.y()),
                        };
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                    }}
                    onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                      
                        node.scaleX(1);
                        node.scaleY(1);
                      
                        const newObjects = objects.slice();
                        newObjects[i] = {
                          ...obj,
                          x: Math.round(node.x()),
                          y: Math.round(node.y()),
                          rotation: Math.round(node.rotation()),
                        
                        //   width: Math.round(obj.width * scaleX),
                        //   height: Math.round(obj.height * scaleY),
                        };
                        setObjects(newObjects);
                        setSelectedObject(newObjects[i])
                      }}
                      
                  />
                );
              default:
                return null;
            }
          })}
          {assetList.map((asset, index) => (
                <AssetStatus
                    key={asset.asset_uuid}
                    id={asset.asset_uuid}
                    x={asset.x ? asset.x:100 + index * 150}
                    y={asset.y ? asset.y : 100 + index * 150}
                    scaleX={asset.scaleX ? asset.scaleX : 1}
                    scaleY={asset.scaleY ? asset.scaleY : 1}
                    rotation={asset.rotation ? asset.rotation : 0}
                    asset={asset}
                    imageSrc={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url}
                    onDragEnd={(pos) => handleMachineDragEnd(asset.asset_uuid, pos)}
                    onTransformEnd={handleTransformEnd}
                    setSelectedObject={setSelectedObject}
                    selectedObject={selectedObject}
                    isSelected={asset.asset_uuid === selectedId}
                    // onSelect={() => {
                    //     setSelectedMachineId(asset.asset_uuid);
                    //   }}
                    onSelect={handleMachineSelect}
                    mqttMessageNodeUUID={mqttMessageNodeUUID}  
                    mqttMessageTimestamp={mqttMessageTimestamp}


                />
            ))}
        {selectedObject && (selectedObject.type === 'line' || selectedObject.type === 'arrow') ? (
            <div/>
        ):(
            <TransformerComponent selectedShapeName={selectedId} />
        )}
        </Layer>
      </Stage>

      
        <CanvasRightSideDrawer
          selectedObject={selectedObject}
          onUpdate={handleUpdateObject}
          handleColorChange={handleColorChange}
          onClose={() => setDrawerOpen(false)}
          isOpen={drawerOpen}
        />


      <div className='fixed left-1/2 bottom-5' style={{transform: 'translateX(-50%)',zIndex: 1000}}>
        <div className='flex py-1 px-2 space-x-2 bg-slate-700 dark:bg-black rounded-md'>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addRectangle}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960" fill="currentColor"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/></svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addLine}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                    <path xmlns="http://www.w3.org/2000/svg" d="M212-212q-11-11-11-28t11-28l480-480q11-12 27.5-12t28.5 12q11 11 11 28t-11 28L268-212q-11 11-28 11t-28-11Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addArrow}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                    <path xmlns="http://www.w3.org/2000/svg" d="M680-624 244-188q-11 11-28 11t-28-11q-11-11-11-28t11-28l436-436H400q-17 0-28.5-11.5T360-720q0-17 11.5-28.5T400-760h320q17 0 28.5 11.5T760-720v320q0 17-11.5 28.5T720-360q-17 0-28.5-11.5T680-400v-224Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addEllipse}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addPolygon}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M298-200h364l123-369-305-213-305 213 123 369Zm0 80q-26 0-47-15t-29-40L99-543q-8-26 0-51t30-40l305-214q21-14 46-14t46 14l305 214q22 15 30 40t0 51L738-175q-8 25-29 40t-47 15H298Zm182-371Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addStar}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143Zm126 18L314-169q-11 7-23 6t-21-8q-9-7-14-17.5t-2-23.5l44-189-147-127q-10-9-12.5-20.5T140-571q4-11 12-18t22-9l194-17 75-178q5-12 15.5-18t21.5-6q11 0 21.5 6t15.5 18l75 178 194 17q14 2 22 9t12 18q4 11 1.5 22.5T809-528L662-401l44 189q3 13-2 23.5T690-171q-9 7-21 8t-23-6L480-269Zm0-201Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={handleImageVideoClick}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0 0v-560 560Zm80-80h400q12 0 18-11t-2-21L586-459q-6-8-16-8t-16 8L450-320l-74-99q-6-8-16-8t-16 8l-80 107q-8 10-2 21t18 11Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-gray-100 dark:text-gray-300 hover:bg-slate-500/70 dark:hover:bg-slate-500/50' onClick={addText}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M420-680H260q-25 0-42.5-17.5T200-740q0-25 17.5-42.5T260-800h440q25 0 42.5 17.5T760-740q0 25-17.5 42.5T700-680H540v460q0 25-17.5 42.5T480-160q-25 0-42.5-17.5T420-220v-460Z"/>
                </svg>
            </motion.button>
            <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className='p-1 rounded-lg text-red-600 dark:text-red-500 hover:bg-red-500/20 dark:hover:bg-red-500/20' onClick={clearCanvas}>
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="m424-480-76 76q-11 11-11 28t11 28q11 11 28 11t28-11l76-76 76 76q11 11 28 11t28-11q11-11 11-28t-11-28l-76-76 76-76q11-11 11-28t-11-28q-11-11-28-11t-28 11l-76 76-76-76q-11-11-28-11t-28 11q-11 11-11 28t11 28l76 76ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/>
                </svg>
            </motion.button>
        </div>
      </div>
    </div>
  );
}

export default CanvasPage