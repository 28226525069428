
import React, { useState, useRef, useEffect } from 'react';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import { getData, postData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import GroupOptionRow from './GroupOptionRow';
import ColorPicker from '../../utils/ColorPicker';


// const colorPalette= [
//     { label: "Green", lightColor: "#5ac57d", darkColor: "#5ac57d" },
//     { label: "Lime Green", lightColor: "#a8d14b", darkColor: "#a8d14b" },
//     { label: "Mint", lightColor: "#377d50", darkColor: "#377d50" },
//     { label: "Yellow", lightColor: "#c7b756", darkColor: "#c7b756" },
//     { label: "Violet", lightColor: "#724dca", darkColor: "#724dca" },
//     { label: "Purple", lightColor: "#9354d6", darkColor: "#9354d6" },
//     { label: "Dark Blue", lightColor: "#367cb0", darkColor: "#367cb0" },
//     { label: "Blue", lightColor: "#6799f5", darkColor: "#6799f5" },
//     { label: "Maroon", lightColor: "#ac3e55", darkColor: "#ac3e55" },
//     { label: "Dark Orange", lightColor: "#cd404e", darkColor: "#cd404e" },
//     { label: "Hot Pink", lightColor: "#ea337e", darkColor: "#ea337e" },
//     { label: "Pink", lightColor: "#ec66c0", darkColor: "#ec66c0" },
//     { label: "Orange", lightColor: "#ed6e40", darkColor: "#ed6e40" },
//     { label: "Mustard", lightColor: "#f1af54", darkColor: "#f1af54" },
//     { label: "Brown", lightColor: "#795549", darkColor: "#795549" },
//     { label: "Light Gray", lightColor: "#c4c4c4", darkColor: "#c4c4c4" },
//     { label: "Dark Gray", lightColor: "#c4c4c4", darkColor: "#757575" },
//   ]

const GroupDropdownSelector = ({setSelectedGroupUuid, selectedGroup}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedOrganization = useRecoilValue(globalSelectedOrganization)
  const isDark = useRecoilValue(darkModeState);
  const colorPickerRef = useRef(null);
  const listRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [colorPalette, setColorPalette] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState('');
  const [newOptionColor, setNewOptionColor] = useState([]);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if(selectedGroup && Object.keys(selectedGroup).length > 0){
        setSelectedOption(selectedGroup)
    }

  },[selectedGroup])

  

  useEffect(() => {
    if(selectedOrganization){
        getGroups()
        getColors()
    }
    
  }, []);

  useEffect(() => {
    if(selectedOption && selectedOption.group_uuid){
        // console.log('selectedOption', selectedOption)
        setSelectedGroupUuid(selectedOption.group_uuid)
    }else{
        setSelectedGroupUuid(null)
    }
    
  }, [selectedOption]);

  const getGroups = () => {
    if(selectedOrganization){
        getData(apiLink + `/v1/organizations/${selectedOrganization}/groups` )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                //   console.log('Groups',data.data)
                  setOptions(data.data)
                }
            }
        })
    }
  }

  const getColors = () => {
        // getData(apiLink + `/v1/group_colors` )
        // .then(async response => {
        //     // JSON data parsed by response.json() call
        //     if(response.ok ){
        //     const data = await response.json();
        //         if(data && data.data){
        //           console.log('V1 Colors',data.data)
        //         //   setColorPalette(data.data)
        //         //   setNewOptionColor(data.data[15])
        //         }
        //     }
        // })
        getData(apiLink + `/v1/group_colors` )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                  console.log('V1 Colors',data.data)
                  setColorPalette(data.data)
                  setNewOptionColor(data.data[15])
                }
            }
        })
    
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsAddingNew(false);
        setNewOptionValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Auto-scroll when color picker opens
  useEffect(() => {
    if (isColorPickerOpen && colorPickerRef.current && listRef.current) {
      const scrollContainer = listRef.current;
      const colorPickerElement = colorPickerRef.current;
      
      scrollContainer.scrollTo({
        top: colorPickerElement.offsetTop,
        behavior: 'smooth'
      });
    }
  }, [isColorPickerOpen]);

  useEffect(() => {
    if (isAddingNew && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingNew]);

  const handleAddOption = (e) => {
    try{
        if (newOptionValue.trim()) {
          if(!loading){
              setLoading(true)
              postData(apiLink + '/v1/organizations/' + selectedOrganization + '/groups', 
                {
                    "group_name": newOptionValue.trim(),
                    "group_light_color_tailwind_id": newOptionColor.tailwind_light_color_id,
                    "group_dark_color_tailwind_id": newOptionColor.tailwind_dark_color_id
                })
              .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    // console.log('POST data', data.data[0])
                    setOptions([...options, data.data[0]]);   
                    setSelectedOption(data.data[0]);
                    setNewOptionValue('');
                    setNewOptionColor(colorPalette[15]);
                    setIsAddingNew(false);
                    setIsOpen(false);
                    //   getGroups()
                    setLoading(false)
                    
                  }
                  else{
                  try{
                      const data = await response.json();
                      setLoading(false)
                      toast.error(data.message)
                  }catch (e){
                      toast.error('Something went wrong')
                      setLoading(false)
                  }
                  }
              })
          
            //   setOptions([...options, newOption]);
            //   setSelectedOption(newOption);
              
          }
        }
    }catch(e){
        toast.error(e)
    }
            
  };

  const colorCSS = (colorData, isDark) => {
    if(colorData && colorData.tailwind_color_name){
       
        return { backgroundColor: isDark ? colorData.tailwind_dark_color_hex : colorData.tailwind_light_color_hex };
    }else{
        return { backgroundColor: '#0ea5e9' };
    }
  };


  return (
    <div className="relative min-w-[300px] max-w-[500px]" ref={dropdownRef}>
        <button
            onClick={() => {
                setIsOpen(!isOpen)
                setIsColorPickerOpen(false)
                setIsAddingNew(false)
            }}
            className={`flex space-x-2 px-4 py-1 items-center text-sm rounded-lg border-0 shadow-iotflows-lg
                    ${isDark 
                        ? 'bg-slate-700 text-gray-300 border-slate-500' 
                        : 'bg-white text-gray-700 border-gray-300 '
                    } 
                `}
            style={{color: isDark ? selectedOption.group_dark_color_hex : selectedOption.group_light_color_hex}}
        >
            <span 
                className={` font-medium `}
            >
                {selectedOption && selectedOption.group_name ? selectedOption.group_name : 'Select a Group...'}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                className={`h-5 w-5  transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} 
                style={{ color: isDark ? selectedOption.group_dark_color_hex : selectedOption.group_light_color_hex}}
            >
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
        </button>
        

        {isOpen && (
            <div className={`absolute z-10 w-full mt-1 rounded-lg shadow-iotflows-lg ${isDark ? 'bg-slate-700':' bg-white'}`}>
            <ul className="py-1 px-1 max-h-80 overflow-y-scroll" ref={listRef}>
                {options.map((option, index) => (
                    <GroupOptionRow key={option.group_uuid} option={option} selectedOption={selectedOption} setIsOpen={setIsOpen}
                        setSelectedOption={setSelectedOption} setIsColorPickerOpen={setIsColorPickerOpen} 
                        setIsAddingNew={setIsAddingNew} isDark={isDark} getGroups={getGroups} colorPalette={colorPalette} 
                    />
                ))}
                
                {isAddingNew ? (
                <li className="px-2 py-1">
                    <motion.div
                        key='addGroupContainer'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        
                        <div className="flex flex-col w-full">
                            <div className='flex'>
                                <div className={`flex w-full items-center ${isDark? 'bg-slate-800':'bg-slate-100'} rounded-lg px-2 py-1`}>
                                    <div className="flex relative items-center">
                                        <button
                                            type="button"
                                            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                                            className="p-0.5  rounded-lg"
                                            style={colorCSS(newOptionColor, isDark)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5`}>
                                                <path xmlns="http://www.w3.org/2000/svg" d="M440-80q-33 0-56.5-23.5T360-160v-160H240q-33 0-56.5-23.5T160-400v-280q0-66 47-113t113-47h440q17 0 28.5 11.5T800-800v400q0 33-23.5 56.5T720-320H600v160q0 33-23.5 56.5T520-80h-80ZM240-560h480v-200h-40v120q0 17-11.5 28.5T640-600q-17 0-28.5-11.5T600-640v-120h-40v40q0 17-11.5 28.5T520-680q-17 0-28.5-11.5T480-720v-40H320q-33 0-56.5 23.5T240-680v120Zm0 160h480v-80H240v80Zm0 0v-80 80Z"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <input className={`grow py-0  bg-transparent text-gray-600 border-0 border-gray-300 
                                                    ${isDark? 'focus:ring-gray-500 text-white placeholder:text-gray-400':'focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '}
                                                    focus:ring-[0px]  text-sm text-ellipsis `}
                                        ref={inputRef}
                                        type="text"
                                        value={newOptionValue}
                                        onChange={(e) => setNewOptionValue(e.target.value)}
                                        placeholder="Type and press Enter..."
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                handleAddOption(e);
                                                setIsColorPickerOpen(false)
                                                setIsAddingNew(true)
                                            }
                                        }}
                                    />
                                </div>
                                <motion.button 
                                    whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                                    className={`${isDark ? 'text-gray-400 hover:text-gray-300':'text-gray-500 hover:text-gray-600'} ml-1`}
                                    onClick={() => {
                                        setIsAddingNew(false)
                                        setIsColorPickerOpen(false)
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </motion.button>
                            </div>
                            <motion.div
                                key='colorPickerContainer'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                ref={colorPickerRef}
                            >
                                {colorPalette && Object.keys(colorPalette).length > 0 && (
                                    <ColorPicker
                                        selectedColor={newOptionColor} 
                                        onColorSelect={setNewOptionColor}
                                        isOpen={isColorPickerOpen}
                                        setIsOpen={setIsColorPickerOpen}
                                        isDark={isDark}
                                        colorPalette={colorPalette}
                                        />
                                )}
                            </motion.div>
                        </div>
                    </motion.div>
                </li>
                ) : (
                    <li
                        onClick={() => setIsAddingNew(true)}
                        className={`px-2 py-1 cursor-pointer flex items-center justify-between rounded-md text-sm italic ${isDark ? 'text-blue-300 hover:bg-slate-800':'text-blue-600 hover:bg-slate-100'} `}
                    >
                    <motion.div
                        key='addGroupBtnContainer'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                            
                            <span>+ Add Group</span>
                    </motion.div>
                    </li>
                )}
            </ul>
            </div>
        )}
    </div>
  );
};

export default GroupDropdownSelector;