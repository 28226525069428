import { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Transition, PopoverPanel, PopoverButton } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { getData } from '../../../ApiCalls/DataApis'
import { apiLink } from '../../../ApiCalls/ApisVariables'
// import { statusOptions } from '../../../MyWorkOrdersPage/statusOptions'
import { useRecoilValue } from 'recoil'
import { darkModeState } from '../../../atoms/darkModeState'

const statusOptions = [
    {
        name: 'All',
        workorder_status_name: 'All ', 
        workorder_status_uuid: 'ALL_STATUSES',
        color: '', 
        dark_color: '', 
        icon_only_dark_color:'',
        icon_only_light_color:'',
        icon: ''
    },
    {
        name: 'All To-Do',
        workorder_status_name: 'All To-Do', 
        workorder_status_uuid: 'ALL_TO_DO',
        color: '', 
        dark_color: '', 
        icon_only_dark_color:'',
        icon_only_light_color:'',
        icon: ''
    },
    {
        name: 'Done',
        workorder_status_name: 'Done', 
        workorder_status_uuid: 'WORKORDER_STATUS_CLOSED', 
        color: 'text-green-950 bg-green-400 dark:text-white dark:bg-green-700', 
        dark_color: 'text-white bg-green-700', 
        icon_only_dark_color:'text-green-400',
        icon_only_light_color:'text-green-600',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"/>
            </svg> 
            )
    },
    {
        name: 'Open',
        workorder_status_name: 'Open', 
        workorder_status_uuid: 'WORKORDER_STATUS_OPEN', 
        color: 'text-white bg-blue-600', 
        dark_color: 'text-white bg-blue-600', 
        icon_only_dark_color:'text-blue-600',
        icon_only_light_color:'text-blue-600',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M240-160h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Zm0 80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h280v-80q0-83 58.5-141.5T720-920q71 0 124 43t70 108q5 17-6.5 33T880-720q-17 0-28-7t-16-23q-11-38-42.5-64T720-840q-50 0-85 35t-35 85v80h120q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Z"/>            
            </svg> 
            )
    },
    {
        name: 'In Progress',
        workorder_status_name: 'In Progress', 
        workorder_status_uuid: 'WORKORDER_STATUS_IN_PROGRESS', 
        color: 'text-blue-950 bg-blue-200', 
        dark_color: 'text-blue-950 bg-blue-200', 
        icon_only_dark_color:'text-blue-300',
        icon_only_light_color:'text-blue-500',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z"/>
            </svg> 
            )
    },
    {
        name: 'On Hold',
        workorder_status_name: 'On Hold', 
        workorder_status_uuid: 'WORKORDER_STATUS_ON_HOLD', 
        color: 'text-amber-950 bg-amber-200 ', 
        dark_color: 'text-amber-950 bg-amber-200 ', 
        icon_only_dark_color:'text-amber-500',
        icon_only_light_color:'text-amber-500',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path d="M400-320q17 0 28.5-11.5T440-360v-240q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v240q0 17 11.5 28.5T400-320Zm160 0q17 0 28.5-11.5T600-360v-240q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v240q0 17 11.5 28.5T560-320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
            </svg> 
            )
    },
]

const AllToDoParameters = ['WORKORDER_STATUS_OPEN', 'WORKORDER_STATUS_IN_PROGRESS', 'WORKORDER_STATUS_ON_HOLD']
const AllStatusesParameters = ['WORKORDER_STATUS_OPEN', 'WORKORDER_STATUS_IN_PROGRESS', 'WORKORDER_STATUS_ON_HOLD', 'WORKORDER_STATUS_CLOSED']


function StatusWOFilterBtn() {
   const isDark = useRecoilValue(darkModeState)
   const [selectedStatus, setSelectedStatus] = useState('')
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();





   useEffect(() => {
        // Gettting search parameters from URL
            let currentSearch = location.search
            if(currentSearch){
                // Pulling all parts search parameters into an array
                let searchStatus = searchParams.getAll('wo_status')
                console.log('searchStatus', searchStatus)
                console.log('length', searchStatus.length)
                const actualStatuses = new Set(searchParams.getAll('wo_status')); 
                console.log('actualStatuses', actualStatuses)
                
                let areStatusesEqualAllToDo = AllToDoParameters.every(status => actualStatuses.has(status))
                let areStatusesEqualAllStatuses = AllStatusesParameters.every(status => actualStatuses.has(status))
                console.log('areStatusesEqualAllStatuses', areStatusesEqualAllStatuses)
                console.log('areStatusesEqualAllToDo', areStatusesEqualAllToDo)
                if(searchStatus && searchStatus.length === 1){
                    setSelectedStatus(searchStatus[0])
                }else if(searchStatus && searchStatus.length === 3 && areStatusesEqualAllToDo){
                    setSelectedStatus('ALL_TO_DO')
                }else if(searchStatus && searchStatus.length === 4 && areStatusesEqualAllStatuses){
                    setSelectedStatus('ALL_STATUSES')
                }else{
                    setSelectedStatus('ALL_STATUSES')
                }
            }else {
                setSelectedStatus('ALL_STATUSES')
            }
    

    }, [location, statusOptions])



    const handleClear = () => {
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machines = searchParams.getAll('machines') 
        let machine_types = searchParams.getAll('machine_types') 
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let time_period = searchParams.getAll('time_period')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')

        let q = searchParams.getAll('q')
        let selected_wo = searchParams.getAll('selected_wo')
        let wo_priority = searchParams.getAll('wo_priority')
        
        setSearchParams({select, parts, departments, machines, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, 
            view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids, only_with_notes, time_period, q, selected_wo, wo_priority,
        })

    }

    const handleApplyFilter = (option) => {
        setSelectedStatus(option);
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machines = searchParams.getAll('machines') 
        let machine_types = searchParams.getAll('machine_types') 
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let time_period = searchParams.getAll('time_period')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')

        let q = searchParams.getAll('q')
        let selected_wo = searchParams.getAll('selected_wo')
        let wo_priority = searchParams.getAll('wo_priority')

        console.log('option', option)

        if(option == 'ALL_STATUSES'){
            let parameters = AllStatusesParameters
            console.log('Inside ALL_STATUSES parameters', parameters)
            setSearchParams({select, parts, departments, machines, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, 
                view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids, only_with_notes, time_period, q, selected_wo, wo_priority,wo_status: parameters
            })
        }else if(option == 'ALL_TO_DO'){
            let parameters = AllToDoParameters
            setSearchParams({select, parts, departments, machines, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, 
                view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids, only_with_notes, time_period, q, selected_wo, wo_priority,wo_status: parameters
            })
        }else{
            setSearchParams({select, parts, departments, machines, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, 
                view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids, only_with_notes, time_period, q, selected_wo, wo_priority, wo_status: option})
        }
        
    }


  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <PopoverButton
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }}   
                    className='bg-white w-3 h-3 rounded-full m-1'
                    onClick={(e) => {
                        if(Object.keys(selectedStatus).length > 0){
                            e.stopPropagation();
                            handleClear();
                        }
                    }}  
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${selectedStatus && selectedStatus != 'ALL_TO_DO' ? ' rotate-45 ':'rotate-0'}  h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </motion.button>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Status
                </div>
                {selectedStatus &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {statusOptions.map((filtered, index) => 
                        filtered.workorder_status_uuid === selectedStatus ? (
                            <div key={filtered.workorder_status_uuid} className='flex'>
                                <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs'>
                                    {filtered.workorder_status_name}
                                </div>
                            </div>
                        ) : (
                            <div key={index} />
                        )
                    )}
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                {/* {Object.keys(selectedStatus).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedStatus[0].workorder_status_name} and {Object.keys(selectedStatus).length - 1} more</div>
                    </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                } */}
            </PopoverButton> 
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-0 z-20 mt-3 w-[175px] transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Filter by Status</div>
                        {statusOptions.map((currentStatus, index)=>(
                            <div key={currentStatus.workorder_status_uuid} className="flex items-center my-3">
                                <input 
                                    id={`status-radio-${index}`} 
                                    type="radio" 
                                    value={currentStatus.workorder_status_uuid}
                                    checked={selectedStatus === currentStatus.workorder_status_uuid}
                                    onChange={() => {
                                        handleApplyFilter(currentStatus.workorder_status_uuid)
                                        close()
                                    }}
                                    className="w-4 h-4 text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded-full border-gray-200 border-[1px] shadow"
                                />
                                <div  className={`ml-2 ${isDark ? currentStatus.icon_only_dark_color: currentStatus.icon_only_light_color}`}>
                                    {currentStatus.icon ? currentStatus.icon : ''}
                                </div>
                                <label htmlFor={`priority-checkbox-${currentStatus.workorder_status_uuid}`} className="ml-1 text-xs font-medium text-gray-600 dark:text-gray-400">{currentStatus.name}</label>
                            </div>
                        ))}
                        {/* {statusOptions.map((currentStatus, index)=>(
                            <div key={currentStatus.workorder_status_uuid} className="flex items-center my-3">
                                <input id={`priority-radio-${index}`} type="radio" checked={checkedStatusState[index]} onChange={() => handleChangeStatusState(index)}  className="checkboxBtn"/>
                                <div  className={`ml-2 ${isDark ? currentStatus.icon_only_dark_color: currentStatus.icon_only_light_color}`}>
                                    {currentStatus.icon}
                                </div>
                                <label htmlFor={`priority-checkbox-${currentStatus.workorder_status_uuid}`} className="ml-1 text-xs font-medium text-gray-600 dark:text-gray-400">{currentStatus.workorder_status_name} {currentStatus.workorder_status_name == 'Done' && ' Only'}</label>
                            </div>
                        ))} */}
                        

                        {/* <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button> */}
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default StatusWOFilterBtn