import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { darkModeState } from '../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'

function SearchTextFilter() {
  const isDark = useRecoilValue(darkModeState)
  const [mainSearchText, setMainSearchText] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation();

  useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling all members search parameters into an array
            let searchText = searchParams.get('text')
            if(searchText && searchText.length > 0){
              if(searchText != mainSearchText){
                setMainSearchText(searchText)
              }
            }else{
              setMainSearchText('')
            }
        }

    }, [location])


  const handleApplyFilter = (e) => {
    // // putting all selected parts into the button for visualization
    // let tempMembers = []
    // checkedMemberState.map((state, index) =>{
    //     if(state){
    //         tempMembers.push(membersList[index]) 
    //     }
    // })
    // setSelectedMembers(tempMembers)

    // putting each part ID into an array to push it to the URL
    let parameters = e.target.value
    setMainSearchText(parameters)
    // tempMembers.map(member => {
    //     parameters.push(member.user_username)
    // })
    let select = searchParams.getAll('select')
    let departments = searchParams.getAll('departments')
    let parts = searchParams.getAll('parts')
    let machine_types = searchParams.getAll('machine_types')
    let tags = searchParams.getAll('tags')
    let group_by = searchParams.getAll('group_by')
    let sort_by = searchParams.getAll('sort_by')
    let calculate_oee_by = searchParams.getAll('calculate_oee_by')
    let shift_name = searchParams.getAll('shift_name')
    let shift_start_time = searchParams.getAll('shift_start_time')
    let shift_date = searchParams.getAll('shift_date')
    let shift_entire_day = searchParams.getAll('shift_entire_day')
    let maximize = searchParams.getAll('maximize')
    let url_token = searchParams.getAll('url_token')
    let operations_expanded = searchParams.getAll('operations_expanded')
    let assets_expanded = searchParams.getAll('assets_expanded')
    let platform = searchParams.getAll('platform')
    let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
    let users = searchParams.getAll('users')
    let to = searchParams.getAll('to')
    let from = searchParams.getAll('from')

    setSearchParams({select, departments, parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, users, text: parameters, to, from})
}


  return (
    <div className='relative rounded-full mx-1 w-full'>
        <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
            <svg className={`h-4 w-4 ${isDark? 'text-gray-300' : 'text-gray-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
        </div>
        <input className={` ${isDark? 'hover:bg-gray-700 focus:bg-gray-700 bg-gray-600 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400' : 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500'}  
                        block w-full pl-10 sm:text-xs rounded-full text-ellipsis py-1 `}
            value={mainSearchText} 
            onChange={handleApplyFilter} 
            // onChange={(e)=> setMainSearchText(e.target.value)} 
            type="text" 
            placeholder="Search..."
            
        />
    </div>
  )
}

export default SearchTextFilter