import React, { useEffect, useState } from 'react'
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import DepartmentMachineFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/DepartmentMachineFilterBtn'
import ShiftMinutesFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftMinutesFilterBtn'
import SortByAdvancedReportFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/SortByAdvancedReportFilterBtn'
import GroupByAdvancedReportFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/GroupByAdvancedReportFilterBtn'
import TimePeriodFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/TimePeriodFilterBtn'
import { useLocation, useSearchParams } from 'react-router-dom'
import OptimizeLargeImage from '../../components/OptimizeLargeImage';
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid"
import { darkModeState } from '../../atoms/darkModeState';
import { LinearProgress } from '@mui/material'
import AdvanceReportTableRow from './AdvanceReportTableRow'
import StackedBarChartLarge from '../../components/charts/AdvancedReportsCharts/StackedBarChartLarge'
import SectionedPieChartLarge from '../../components/charts/AdvancedReportsCharts/SectionedPieChartLarge'
import PercentDataToggleBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/PercentDataToggleBtn'
import DateRangeFilterAdvancedBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/DateRangeFilterAdvancedBtn'
import { Switch } from "@mui/material";
import ExcludeNoneSwitch from '../../components/filterButtons/AdvanceReportingFilterBtns/ExcludeNoneSwitch'
import ShiftSelectionFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftSelectionFilterBtn'
import ExcludeUnknownSwitch from '../../components/filterButtons/AdvanceReportingFilterBtns/ExcludeUnknownSwitch'
import DepartmentFilterBtn from '../../components/filterButtons/DepartmentFilterBtn'
import MobileRightDrawer from '../../components/MobileRightDrawer'
import AdvanceReportRowMobile from './AdvanceReportRowMobile'




function AdvanceReportPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const [isGrouped, setIsGrouped] = useState('')
    const isDark = useRecoilValue(darkModeState);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [depAssetList, setDepAssetList] = useState([])
    const [totalUptimeHours, setTotalUptimeHours] = useState('')
    const [totalDowntimeHours, setTotalDowntimeHours] = useState('')
    const [totalUnknownHours, setTotalUnknownHours] = useState('')
    const [totalUtilizationPercent, setTotalUtilizationPercent] = useState('')
    const [totalUtilizations, setTotalUtilizations] = useState([])
    const [toggleShowPercentData, setToggleShowPercentData] = useState(false)
    const [toggleOnlyUptimeData, setToggleOnlyUptimeData] = useState(false)
    const [csvData, setCsvData] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
      

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])

    
      const downloadCSV = (data) => {
        if(Object.keys(data).length === 0) return

        let csvString = ``
        let firstHeader = []
        Object.keys(data).map(header => {
            if(header === "departments" || header === 'no_departments' || header === "total_utilizations") return
            firstHeader.push(header)
            
        })
        let firstHeaderValues = []
        firstHeader.map(headerId => {
            firstHeaderValues.push(data[headerId])
            
        })
        csvString += firstHeader.join(',')
        csvString += '\r\n'
        csvString += firstHeaderValues.join(',')
        csvString += '\r\n'
        csvString += '\r\n'

        
        if(data.total_utilizations && Object.keys(data.total_utilizations).length > 0){
            Object.keys(data.total_utilizations).map(header => {
                csvString += header + ','
                csvString += data.total_utilizations[header].join(',')
                csvString += '\r\n'
            })
            csvString += '\r\n'
        }

        if(data.departments && Object.keys(data.departments).length > 0){
            
            Object.keys(data.departments).map(header => {
                    if(header === 'no_department'){
                        csvString += '\r\n'
                    }else{
                        csvString += '\r\n'
                        csvString += header
                        csvString += '\r\n'
                    }
                    
                    data.departments[header].map(asset => {
                        if(asset.asset_custom_name){
                            csvString += 'asset_custom_name,' + asset.asset_custom_name
                            csvString += '\r\n'
                        }else {
                            csvString += 'machine_name,' + asset.machine_name
                            csvString += '\r\n'
                        }
                        if(asset.asset_custom_identifier){
                            csvString += 'asset_custom_identifier,' + asset.asset_custom_identifier
                            csvString += '\r\n'
                        }else {
                            csvString += 'asset_uuid,' + asset.asset_uuid
                            csvString += '\r\n'
                        }
                        Object.keys(asset.asset_utilizations).map(header => {
                            if(header === 'asset_id') return
                            // skip the data that are not arrays
                            if(!(asset.asset_utilizations[header] && asset.asset_utilizations[header][0])) return                                
                            csvString += header + ','
                            csvString += asset.asset_utilizations[header].join(',')
                            csvString += '\r\n'
                        })
                        csvString += '\r\n'
                    })
            })
        }

        // console.log('csvString', csvString)
        
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Assets_Advanced_Report.csv');
        document.body.appendChild(link);
        link.click();
      };



    

    useEffect(() => {
        // Gettting search parameters from URL
            let currentSearch = location.search
            if(currentSearch){
                // Pulling to see how cards should be grouped
                let selectedGroupBy = searchParams.get('group_by_advanced')
                
                if(selectedGroupBy){
                    setIsGrouped(selectedGroupBy)
                }else{
                    setIsGrouped('')
                }
            }else {
                setIsGrouped('')
            }
    
        }, [location])
    
      

    useEffect(() => {
        if(selectedOrganization){
            getReportMainNumbers()
        }
    }, [selectedOrganization, location])
    
    const getReportMainNumbers = () => {
        let currentSearch = location.search
        if(selectedOrganization && currentSearch){
            let fromDate = searchParams.get('from')
            let toDate = searchParams.get('to')
            let period = searchParams.get('time_period')
            // forcing to have a date in the url to pull date, this is because of how long it takes the 6 weeks to load
            if(period != null && toDate != null && fromDate != null && !loading){
                setLoading(true)
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/advanced_reporting' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    const data = await response.json();
                    if(response.ok ){
                        setLoading(false)
                        if(data && data.data){
                            // console.log('Advanced report data',data.data)
                            setCsvData(data.data)
                            setDepAssetList(data.data.departments)
                            setTotalUptimeHours(data.data.total_uptime_hours)
                            setTotalDowntimeHours(data.data.total_downtime_hours)
                            setTotalUnknownHours(data.data.total_unknown_hours)
                            setTotalUtilizationPercent(data.data.total_utilization_percentage)
                            setTotalUtilizations(data.data.total_utilizations)
                        }
                    }
                })
            
            }

        }

    }


    const handleChangePercentDataToggle = (e) => {
        setToggleShowPercentData(e.target.checked)
    }

    const handleChangeOnlyUptimeDataToggle = (e) => {
        setToggleOnlyUptimeData(e.target.checked)
    }

    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

    
            return out
        }else{
            return '0'
        }
    }


  return (
    <div className={`px-2 md:px-0 mt-4  h-screen `}>
        <div className={`${isMobile ? 'flex ':'flex md:hidden'} justify-end mr-4`}>
            <MobileRightDrawer >
                <div className='flex flex-col space-y-3 '>
                    <DateRangeFilterAdvancedBtn /> 
                    <DepartmentMachineFilterBtn select='advanced_report' />
                    <ShiftSelectionFilterBtn select='advanced_report' />
                    {/* <ShiftMinutesFilterBtn select='advanced_report' /> */}
                    <SortByAdvancedReportFilterBtn select='advanced_report' />
                    <GroupByAdvancedReportFilterBtn />
                    <ExcludeUnknownSwitch />
                    <ExcludeNoneSwitch />
                    {/* <Only Uptime data /> */}
                    <div className='max-w-[300px]'>
                        <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                            <p className='text-gray-600 dark:text-gray-400 text-sm'>Only Uptime</p>
                            {/* <p className='text-gray-600 dark:text-gray-400 text-sm'>{toggleShowPercentData? 'Category': 'Severity'} </p> */}
                            <Switch
                                size="small"
                                color="secondary"
                                checked={toggleOnlyUptimeData}
                                onChange={handleChangeOnlyUptimeDataToggle}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                    {/* <PercentDataToggleBtn /> */}
                    <div className='relative max-w-[300px]'>
                        <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                            <p className='text-gray-600 dark:text-gray-400 text-sm'>Percent</p>
                            {/* <p className='text-gray-600 dark:text-gray-400 text-sm'>{toggleShowPercentData? 'Category': 'Severity'} </p> */}
                            <Switch
                                size="small"
                                color="secondary"
                                checked={toggleShowPercentData}
                                onChange={handleChangePercentDataToggle}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                </div>
            </MobileRightDrawer>
        </div>
        <div className={`${isMobile ? 'hidden':'hidden md:flex flex-col 2xl:flex-row w-full '}  `}>
            <div className='flex space-x-2'>
                <DepartmentMachineFilterBtn select='advanced_report' />
                <ShiftSelectionFilterBtn select='advanced_report' />
                {/* <ShiftMinutesFilterBtn select='advanced_report' /> */}
                <SortByAdvancedReportFilterBtn select='advanced_report' />
                <GroupByAdvancedReportFilterBtn />
            </div>
            <div className='ml-auto flex items-center mt-2 2xl:mt-0'>
                <ExcludeUnknownSwitch />
                <ExcludeNoneSwitch />
                {/* <Only Uptime data /> */}
                <div className='max-w-[300px]'>
                    <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                        <p className='text-gray-600 dark:text-gray-400 text-sm truncate'>Only Uptime</p>
                        {/* <p className='text-gray-600 dark:text-gray-400 text-sm'>{toggleShowPercentData? 'Category': 'Severity'} </p> */}
                        <Switch
                            size="small"
                            color="secondary"
                            checked={toggleOnlyUptimeData}
                            onChange={handleChangeOnlyUptimeDataToggle}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
                {/* <PercentDataToggleBtn /> */}
                <div className='relative max-w-[300px]'>
                    <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                        <p className='text-gray-600 dark:text-gray-400 text-sm'>Percent</p>
                        {/* <p className='text-gray-600 dark:text-gray-400 text-sm'>{toggleShowPercentData? 'Category': 'Severity'} </p> */}
                        <Switch
                            size="small"
                            color="secondary"
                            checked={toggleShowPercentData}
                            onChange={handleChangePercentDataToggle}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
                <button 
                    className='text-gray-600 dark:text-gray-300 mr-1 flex text-sm items-center space-x-1 border-[0em] border-gray-600 dark:border-gray-300 rounded-lg px-3 hover:text-gray-500 dark:hover:text-gray-400'
                    onClick={() => downloadCSV(csvData)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>

                    <span className='leading-3'>CSV</span>
                </button>
                <DateRangeFilterAdvancedBtn /> 
            </div>
        </div>
        <div className='rounded-full mt-2 mb-1' style={{height:2}}>
        {loading &&
                <LinearProgress color="primary" sx={{height:2}}/>
            }
        </div>
        <div className='pb-1'>
            <p className='text-gray-600 dark:text-gray-300 italic text-sm font-light'>{`This report was ran on the organizations time zone of ${selectedOrganizationTimeZone.name}, dates and times may vary from your local time zone.`}</p>
        </div>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-4 mt-0'>
                <div  className='rounded-2xl bg-slate-50/50 dark:bg-slate-800 w-full shadow-iotflows-lg w-full px-4 pt-2 pb-4'>
                    <div className='flex flex-col'>
                        <div className='flex items-center text-lg text-gray-500 dark:text-gray-300 py-1 border-b-[0.01em] dark:border-slate-600'>
                            Total Utilization
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" fill="currentColor" className='h-5 w-5 text-gray-600 dark:text-gray-300 ml-1'>
                                <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q64 0 123-24t104-69L480-480v-320q-134 0-227 93t-93 227q0 134 93 227t227 93Z"/>
                            </svg>
                        </div>
                        <div className='pt-2'>
                            <p className='text-3xl text-gray-600 dark:text-gray-300 font-semibold'>{totalUtilizationPercent? `${totalUtilizationPercent}%`: '0%'}</p>
                        </div>
                        <div className='pt-2'>
                            <p className='text-xs md:text-base text-gray-500 dark:text-gray-400'>
                                {`Total utilization metric represents the overall usage of assets over the analyzed time period`}
                            </p>
                        </div>

                    </div>
                </div>
                <div  className='rounded-2xl bg-slate-50/50 dark:bg-slate-800 w-full shadow-iotflows-lg w-full px-4 pt-2 pb-4'>
                    <div className='flex flex-col'>
                        <h6 className='flex items-center text-lg text-gray-600 dark:text-gray-300 py-1 border-b-[0.01em] dark:border-slate-600'>
                            Uptime Hours
                            <ArrowSmUpIcon className='h-5 w-5 text-gray-600 dark:text-gray-300' />
                        </h6>
                        <div className='pt-2 flex'>
                            <p className='text-3xl text-blue-600 dark:text-sky-500 font-semibold'>{`${addCommasInNumbers(totalUptimeHours)}h`}</p>
                            {/* <ArrowSmUpIcon className='h-9 w-9 text-blue-600 dark:text-blue-300' /> */}
                        </div>
                        <div className='pt-2'>
                            <p className='text-xs md:text-base text-gray-500 dark:text-gray-400'>
                                {`Uptime hours is the cumulative duration during which assets have been operational and actively utilized over the specified period.`}
                            </p>
                        </div>

                    </div>
                </div>
                <div  className='rounded-2xl bg-slate-50/50 dark:bg-slate-800 w-full shadow-iotflows-lg w-full px-4 pt-2 pb-4'>
                    <div className='flex flex-col'>
                        <div className='flex text-lg text-gray-600 dark:text-gray-300 py-1 border-b-[0.01em] dark:border-slate-600 items-center'>
                            Downtime Hours
                            <ArrowSmDownIcon className='h-5 w-5 text-gray-600 dark:text-gray-300' />
                        </div>
                        <div className='pt-2 flex'>
                            <p className='text-3xl text-rose-600 dark:text-rose-500 font-semibold'>{`${addCommasInNumbers(totalDowntimeHours)}h`}</p>
                            {/* <ArrowSmDownIcon className='h-9 w-9 text-rose-600 dark:text-rose-500' /> */}
                        </div>
                        <div className='pt-2'>
                            <p className='text-xs md:text-base text-gray-500 dark:text-gray-400'>
                                {`Downtime hours is the cumulative duration during which assets have been nonoperational or unavailable for utilization over the specified period.`}
                            </p>
                        </div>

                    </div>
                </div>
                <div  className='rounded-2xl bg-slate-50/50 dark:bg-slate-800 w-full shadow-iotflows-lg w-full px-4 pt-2 pb-4'>
                    <div className='flex flex-col'>
                        <h6 className='flex items-center text-lg text-gray-600 dark:text-gray-300 py-1 border-b-[0.01em] dark:border-slate-600'>
                            Unknown Hours
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='h-5 w-5 text-gray-600 dark:text-gray-300'>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                            </svg>
                        </h6>
                        <div className='pt-2 flex'>
                            <p className='text-3xl text-gray-500 dark:text-gray-400 font-semibold'>{`${addCommasInNumbers(totalUnknownHours)}h`}</p>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='h-9 w-9 text-gray-500 dark:text-gray-400'>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                            </svg> */}
                        </div>
                        <div className='pt-2'>
                            <p className='text-xs md:text-base text-gray-500 dark:text-gray-400'>
                                {`Unknown hours is the cumulative duration during which SenseAi devices were unplugged or disconnected over the specified period.`}
                            </p>
                        </div>

                    </div>
                </div>
        </div>
        <div className='flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 mt-2'>
            <div className='rounded-2xl bg-slate-50/50 dark:bg-slate-800 w-full shadow-iotflows-lg overflow-hidden'>
                <StackedBarChartLarge totalUtilizations={totalUtilizations} toggleShowPercentData={toggleShowPercentData} toggleOnlyUptimeData={toggleOnlyUptimeData} />
            </div>
            <div className='2xl:min-w-[350px] flex rounded-2xl bg-slate-50/50 dark:bg-slate-800 shadow-iotflows-lg overflow-hidden items-center justify-center px-8 py-4'>
                <SectionedPieChartLarge downtimePercent={totalDowntimeHours} uptimePercent={totalUptimeHours} unknownPercent={totalUnknownHours} totalUtilizationPercent={totalUtilizationPercent} />
            </div>

        </div>

        {isGrouped == 'department' && 
            <div className='flex flex-col pb-10 mt-2'>
                {depAssetList && Object.keys(depAssetList).length > 0 && Object.keys(depAssetList).map((department,index) => (
                        <div key={`${department}-${index}`}>
                            <div className='relative mt-5 mb-[15px]'>
                                <div className='mb-[-15px] border-[1px] border-dashed border-t-gray-400 dark:border-slate-800' />
                                <div className='text-sm text-gray-400 dark:text-gray-300 border-2 border-gray-200 dark:border-slate-600  py-0 px-3 w-fit rounded-full bg-white dark:bg-slate-600 ml-6'>{department == 'no_department'? '' : department}</div>
                            </div>
                                <div className='w-full space-y-2 md:hidden'>
                                    {depAssetList[department].map((asset) => (
                                        <AdvanceReportRowMobile asset={asset} key={asset.asset_uuid} toggleShowPercentData={toggleShowPercentData} toggleOnlyUptimeData={toggleOnlyUptimeData}  />
                                    ))}
                                </div>
                                <table className="hidden md:table table-auto w-full border-separate border-spacing-y-2">
                                    <tbody >
                                    {depAssetList[department].map((asset) => (
                                        <AdvanceReportTableRow asset={asset} key={asset.asset_uuid} toggleShowPercentData={toggleShowPercentData} toggleOnlyUptimeData={toggleOnlyUptimeData}  />
                                    ))}
                                    </tbody>
                                </table>
                            
                        </div>
                ))}
            </div>
        }
        {isGrouped == '' && 
            <div className='flex flex-col pb-10 mt-2'>
                {depAssetList && Object.keys(depAssetList).length > 0 && Object.keys(depAssetList).map((department,index) => (
                        <div key={`${department}-${index}`}>
                            {/* <div className='relative mt-5 mb-[15px]'>
                                <div className='mb-[-15px] border-[1px] border-dashed border-t-gray-400 dark:border-slate-800' />
                                <div className='text-sm text-gray-400 dark:text-gray-300 border-2 border-gray-200 dark:border-slate-600  py-0 px-3 w-fit rounded-full bg-white dark:bg-slate-600 ml-6'>{department}</div>
                            </div> */}
                            
                            <div className='w-full space-y-2 md:hidden'>
                                {depAssetList[department].map((asset) => (
                                    <AdvanceReportRowMobile asset={asset} key={asset.asset_uuid} toggleShowPercentData={toggleShowPercentData} toggleOnlyUptimeData={toggleOnlyUptimeData}  />
                                ))}
                            </div>
                            <table className="hidden md:table table-auto w-full border-separate border-spacing-y-2">
                                <tbody >
                                {depAssetList[department].map((asset) => (
                                    <AdvanceReportTableRow asset={asset} key={asset.asset_uuid} toggleShowPercentData={toggleShowPercentData} toggleOnlyUptimeData={toggleOnlyUptimeData}  />
                                ))}
                                </tbody>
                            </table>
                        </div>
                ))}
            </div>
        }

    </div>
  )
}

export default AdvanceReportPage
