import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { statusOptions, statusXLOptions } from '../statusOptions';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import toast from 'react-hot-toast';
import { PlusSmIcon } from "@heroicons/react/solid"
import SearchQFilter from '../../components/filterButtons/SearchQFilter';
import StatusKanbanView from './StatusKanbanView';
import PriorityKanbanView from './PriorityKanbanView';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import GroupKanbanView from './GroupKanbanView';

const WOKanbanView = ({workOrdersList,workOrdersDoneList, getWorkOrders, isMobile, selectedOrganization}) => {
    const isDark = useRecoilValue(darkModeState);
    const [isAddingColumn, setIsAddingColumn] = useState(false);
    const [isManagingColumns, setIsManagingColumns] = useState(false);
    const [columns, setColumns] = useState({
        'col-1': {
          title: 'Open',
          items: []
        },
        'col-2': {
          title: 'In Progress',
          items: []
        },
        'col-3': {
          title: 'On Hold',
          items: []
        },
        'col-4': {
          title: 'Done',
          items: []
        }
      });
      const [newColumnTitle, setNewColumnTitle] = useState('');
      // const [activeView, setActiveView] = useState("status");
      const [activeView, setActiveView] = useState(() => {
        return localStorage.getItem('woKanbanViewIoTFlows') || 'status';
      });
      const [prioritiesList, setPrioritiesList] = useState([]);
      const [groupList, setGroupList] = useState([]);

      
      useEffect(() => {
        if(selectedOrganization){
          getGroups()
        }

      },[selectedOrganization])

      useEffect(() => {
        getData(apiLink + '/v1/workorder_priorities' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                  console.log('Priority',data.data)
                  setPrioritiesList(data.data)
                }
            }
        })
      },[])

      const getGroups = () => {
        if(selectedOrganization){
            getData(apiLink + `/v1/organizations/${selectedOrganization}/groups` )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                      console.log('Groups',data.data)
                    setGroupList(data.data)
                    }
                }
            })
        }
      }



    
      // const toggleColumnVisibility = (colId) => {
      //   if (hiddenColumns.includes(colId)) {
      //     setHiddenColumns(hiddenColumns.filter(id => id !== colId));
      //   } else {
      //     setHiddenColumns([...hiddenColumns, colId]);
      //   }
      // };
    
      // const addColumn = () => {
      //   if (!newColumnTitle.trim()) return;
      //   const newColId = `col-${Object.keys(columns).length + 1}`;
      //   setColumns({
      //     ...columns,
      //     [newColId]: {
      //       title: newColumnTitle,
      //       items: []
      //     }
      //   });
      //   setOrderedColumns([...orderedColumns, newColId]);
      //   setNewColumnTitle('');
      //   setIsAddingColumn(false);
      // };
    
      // const deleteColumn = (colId) => {
      //   const newColumns = { ...columns };
      //   delete newColumns[colId];
      //   setColumns(newColumns);
      //   setOrderedColumns(orderedColumns.filter(id => id !== colId));
      //   setHiddenColumns(hiddenColumns.filter(id => id !== colId));
      // };

      // const matchColumnNames = (colTitle, size) => {
      //       let matchingStatus = []
      //       if(size === 'xl'){
      //           matchingStatus = statusXLOptions.find(
      //               (status) => status.workorder_status_name === colTitle
      //           );
      //           if (matchingStatus) {
      //               const { workorder_status_name: name, icon } = matchingStatus;

      //               return (
      //                   <div className={`font-semibold text-gray-600 dark:text-gray-200 flex items-center items-center`}>
      //                       <div className={`${isDark ? matchingStatus.icon_only_dark_color: matchingStatus.icon_only_light_color}`}>
      //                           {icon}
      //                       </div>
      //                       <span className='ml-1'>{name}</span>
      //                   </div>
      //               );
      //           }else{
      //               return(
      //                   <h3 className="font-semibold text-gray-600 dark:text-gray-200 ">{colTitle}</h3>
      //               )
      //           }
      //       }else{
                    
      //           matchingStatus = statusOptions.find(
      //               (status) => status.workorder_status_name === colTitle
      //               );
      //           if (matchingStatus) {
      //               const { workorder_status_name: name, icon } = matchingStatus;

      //               return (
      //                   <div className={`font-normal flex items-center items-center`}>
      //                       <div className={`${isDark ? matchingStatus.icon_only_dark_color: matchingStatus.icon_only_light_color}`}>
      //                           {icon}
      //                       </div>
      //                       <span className='ml-1'>{name}</span>
      //                   </div>
      //               );
      //           }else{
      //               return(
      //                   <h3 className="font-normal ">{colTitle}</h3>
      //               )
      //           }
      //       }
      //   }

        // const handleKeyPressTitle = (event) => {
        //     if (event.key === 'Enter' || event.key === 'Tab') {
        //       if(!event.target.value) {
        //           toast.error("Column Title Empty")
        //       }
        //       addColumn()
        //     //   if(!loadingName){
        //         //   setLoadingName(true)
        //         //   setIsEditingName(false);
        //         //   putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
        //         //   {'operation_name': event.target.value})
        //         //   .then(async response => {
        //         //       const data = await response.json();
        //         //       if(response.ok){
        //         //           setLoadingName(false)
        //         //           setOperationName(event.target.value)
        //         //       }
        //         //       else{
        //         //           try{
        //         //               setOperationName(operation.operation_name)
        //         //               setLoadingName(false)
        //         //               toast.error(data.message)
        //         //           }catch (e){
        //         //               setOperationName(operation.operation_name)
        //         //               toast.error(e.message)
        //         //               setLoadingName(false)
        //         //           }
        //         //       }
        //         //   })
        //     //   }
        //     }
        //   };

        console.log('workOrdersList', workOrdersList)

        const handleViewChange = (view) => {
          // Update state
          setActiveView(view);
          
          // Save to local storage
          localStorage.setItem('woKanbanViewIoTFlows', view);
      };
    
      return (
        <div className="flex flex-col">
          <div className="flex justify-end items-center ">
            <div className='flex mr-1 space-x-1'>
                  <button
                  onClick={() => {
                      setIsAddingColumn(false)
                      setIsManagingColumns(!isManagingColumns)
                  }}
                  className="px-4 py-1 bg-gray-200 dark:bg-gray-700/80 text-sm text-gray-600 dark:text-white rounded hover:bg-gray-300 dark:hover:bg-gray-700 truncate"
                  >
                  Manage Columns
                  </button>
                  {/* {activeView === "group" ? (
                    <button
                    onClick={() => {
                      setIsAddingColumn(true)
                      setIsManagingColumns(false)
                    }}
                    className="text-sm flex items-center truncate text-white items-center bg-blue-600 hover:bg-blue-700 rounded py-1 pl-1 pr-3"
                    >
                      <PlusSmIcon className='btnMainIcon' />
                      <span className='btnMainText pl-0.5'>Add Group</span>
                    </button>
                  ) : (
                    <div/>
                  )} */}
            </div>
            <div className='flex'>
              <button 
              className={`group flex px-3 py-1 rounded-l-lg text-sm space-x-0.5  text-blue-600 border border-r-[0.01em] border-gray-200 dark:border-gray-700  hover:bg-gray-100 dark:hover:bg-gray-700/50 justify-center items-center
                ${
                activeView === "status"
                  ? "bg-gray-200/80 dark:bg-gray-700/30"
                  : ""
              }`}
              onClick={() => handleViewChange("status")}
            >
              {/* <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5 transition ease-out delay-100 group-hover:-translate-y-0.5' viewBox="0 -960 960 960"  fill="currentColor">
                  <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Zm120 80q-17 0-28.5 11.5T280-640v320q0 17 11.5 28.5T320-280q17 0 28.5-11.5T360-320v-320q0-17-11.5-28.5T320-680Zm320 0q-17 0-28.5 11.5T600-640v240q0 17 11.5 28.5T640-360q17 0 28.5-11.5T680-400v-240q0-17-11.5-28.5T640-680Zm-160 0q-17 0-28.5 11.5T440-640v120q0 17 11.5 28.5T480-480q17 0 28.5-11.5T520-520v-120q0-17-11.5-28.5T480-680Z"/>
              </svg> */}
              <span className='transition ease-out delay-100 group-hover:-translate-y-0.5'>
                Status
              </span>
            </button>

            {/* Category View Button */}
            <button
              className={`group flex px-3 py-1  text-sm space-x-0.5  text-blue-600 border border-l-[0.01em] border-gray-200 dark:border-gray-700  hover:bg-gray-100 dark:hover:bg-gray-700/50 justify-center items-center
                ${
                activeView === "priority"
                  ? "bg-gray-200/80 dark:bg-gray-700/30"
                  : ""
              }`}
              onClick={() => handleViewChange("priority")}
            >
              {/* <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5 transition ease-out delay-100 group-hover:-translate-y-0.5' viewBox="0 -960 960 960"  fill="currentColor">
                  <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Zm120 80q-17 0-28.5 11.5T280-640v320q0 17 11.5 28.5T320-280q17 0 28.5-11.5T360-320v-320q0-17-11.5-28.5T320-680Zm320 0q-17 0-28.5 11.5T600-640v240q0 17 11.5 28.5T640-360q17 0 28.5-11.5T680-400v-240q0-17-11.5-28.5T640-680Zm-160 0q-17 0-28.5 11.5T440-640v120q0 17 11.5 28.5T480-480q17 0 28.5-11.5T520-520v-120q0-17-11.5-28.5T480-680Z"/>
              </svg> */}
              <span className='transition ease-out delay-100 group-hover:-translate-y-0.5'>
              Priority
              </span>
            </button>
            <button
              className={`group flex px-3 py-1 rounded-r-lg text-sm space-x-0.5  text-blue-600 border border-l-[0.01em] border-gray-200 dark:border-gray-700  hover:bg-gray-100 dark:hover:bg-gray-700/50 justify-center items-center
                ${
                activeView === "group"
                  ? "bg-gray-200/80 dark:bg-gray-700/30"
                  : ""
              }`}
              onClick={() => handleViewChange("group")}
            >
              {/* <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5 transition ease-out delay-100 group-hover:-translate-y-0.5' viewBox="0 -960 960 960"  fill="currentColor">
                  <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Zm120 80q-17 0-28.5 11.5T280-640v320q0 17 11.5 28.5T320-280q17 0 28.5-11.5T360-320v-320q0-17-11.5-28.5T320-680Zm320 0q-17 0-28.5 11.5T600-640v240q0 17 11.5 28.5T640-360q17 0 28.5-11.5T680-400v-240q0-17-11.5-28.5T640-680Zm-160 0q-17 0-28.5 11.5T440-640v120q0 17 11.5 28.5T480-480q17 0 28.5-11.5T520-520v-120q0-17-11.5-28.5T480-680Z"/>
              </svg> */}
              <span className='transition ease-out delay-100 group-hover:-translate-y-0.5'>
              Group
              </span>
            </button>

            </div>

          </div>
        {/* <AnimatePresence mode='wait'>
            {isManagingColumns && (
            <motion.div
            key={'managingContainer'}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            // exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
             className="my-1 px-4 py-2 shadow-iotflows-lg bg-white dark:bg-slate-800/70 rounded">
                <h3 className="font-semibold mb-2 text-gray-600 dark:text-gray-300">Toggle Column Visibility</h3>
                <div className="flex gap-2 flex-wrap">
                {orderedColumns.map(colId => (
                    <motion.button
                    whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                    key={colId}
                    onClick={() => toggleColumnVisibility(colId)}
                    className={` pl-3 pr-4 py-0.5 rounded-full ${
                        hiddenColumns.includes(colId) 
                        ? `bg-gray-100 text-gray-600 dark:bg-gray-600 dark:text-white opacity-60` 
                        : `bg-sky-50 dark:bg-sky-950 text-gray-600 dark:text-white`
                    }`}
                    >
                    {matchColumnNames(columns[colId].title, 'md')}
                    </motion.button>
                ))}
                </div>
            </motion.div>
            )}
            {isAddingColumn && (
            <motion.div
                key={'titleInputContainer'}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                // exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.2 }} 
                className="flex gap-2 my-1">
                <input
                value={newColumnTitle}
                onChange={(e) => setNewColumnTitle(e.target.value)}
                onKeyDown={handleKeyPressTitle}
                placeholder="Enter column title"
                className={`block bg-transparent w-[200px] border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                />
                <button 
                onClick={addColumn}
                className={`rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                >
                Add
                </button>
                <button 
                onClick={() => setIsAddingColumn(false)}
                className={`rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                >
                Cancel
                </button>
            </motion.div>
            )}
        </AnimatePresence> */}

        <AnimatePresence mode='wait'>
          {activeView === "status" && (
            <motion.div
              key={'statusKanbanContainer'}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StatusKanbanView workOrdersList={workOrdersList} workOrdersDoneList={workOrdersDoneList} isManagingColumns={isManagingColumns} getWorkOrders={getWorkOrders} isMobile={isMobile} selectedOrganization={selectedOrganization} setIsManagingColumns={setIsManagingColumns} />
            </motion.div>
          )}
          {activeView === "priority" && (
            <motion.div
            key={'priorityKanbanContainer'}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            >
            <PriorityKanbanView workOrdersList={workOrdersList} workOrdersDoneList={workOrdersDoneList} isManagingColumns={isManagingColumns} getWorkOrders={getWorkOrders} isMobile={isMobile} selectedOrganization={selectedOrganization} setIsManagingColumns={setIsManagingColumns} prioritiesList={prioritiesList} />
            </motion.div>
          )}
          {activeView === "group" && (
            <motion.div
            key={'groupKanbanContainer'}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            >
            <GroupKanbanView workOrdersList={workOrdersList} workOrdersDoneList={workOrdersDoneList} isManagingColumns={isManagingColumns} getWorkOrders={getWorkOrders} isMobile={isMobile} selectedOrganization={selectedOrganization} setIsManagingColumns={setIsManagingColumns} groupList={groupList} />
            </motion.div>
          )}
        </AnimatePresence>



        
      </div>
      );
    };

export default WOKanbanView;