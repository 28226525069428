import { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { globalSelectedOrganization } from '../../atoms/orgStates'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { useRecoilValue } from 'recoil'



function DepartmentFilterBtn({select}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
   const [allMachineTypes, setAllMachineTypes] = useState([])
   const [selectedMachineTypes, setSelectedMachineTypes] = useState([])
   const [checkedMachineTypesState, setCheckedMachineTypesState] = useState([])
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();

//    useEffect(() =>{
//         let tempMachineTypes = []
//         machineTypeList.map(type => {
//             tempMachineTypes.push({name: type.machine_type, id: type.id})
//         })
//         setAllMachineTypes(tempMachineTypes)
//    }, [])

   useEffect(() =>{
    try {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/machine_types' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        setAllMachineTypes(data.data)
                    }
                }
            })
        }
        
    } catch (error) {
      console.log(error.message)
    }
   }, [selectedOrganization])

   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling all parts search parameters into an array
            let searchMachineTypes = searchParams.getAll('machine_types')

            let updatedCheckedState = allMachineTypes.map((item, index) =>
                searchMachineTypes.includes(item.machine_type) ? true : false
            );

            let selectedSearchedMachineTypes = [] 
            updatedCheckedState.map((checked,index) =>
                checked ? selectedSearchedMachineTypes.push(allMachineTypes[index]) : null
            )
            setSelectedMachineTypes(selectedSearchedMachineTypes)
            setCheckedMachineTypesState(updatedCheckedState)
        }else {
            setSelectedMachineTypes([])
            setCheckedMachineTypesState(new Array(allMachineTypes.length).fill(false))
        }

    }, [location, allMachineTypes])

    // Currently donb't need because I'm just using the dates of the array and don't need the entire json of dep info
//    useEffect(() => {
//     let checkedParts = []
//         if(checkedMachineTypesState.includes(true)){
//             allMachineTypes.map((part, index) =>{
//                 if(checkedMachineTypesState[index]){
//                     checkedParts.push(part)
//                 }
//             })
//         }
//    }, [checkedMachineTypesState])


   const handleChangeMachineTypesState = (position) => {
        let updatedCheckedState = checkedMachineTypesState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedMachineTypesState(updatedCheckedState)
        
    }

    const handleClear = () => {
        
        setSelectedMachineTypes([])
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        setSearchParams({select,parts, departments,tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, url_token,  operations_expanded, assets_expanded, platform, is_god_view_enabled})
    }

    const handleApplyFilter = () => {
        // putting all selected parts into the button for visualization
        let tempParts = []
        checkedMachineTypesState.map((state, index) =>{
            if(state){
                tempParts.push(allMachineTypes[index]) 
            }
        })
        setSelectedMachineTypes(tempParts)

        // putting each part ID into an array to push it to the URL
        let parameters = []
        tempParts.map(part => {
            parameters.push(part.machine_type)
        })
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        setSearchParams({select,parts, departments, machine_types: parameters,tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, url_token,  operations_expanded, assets_expanded, platform, is_god_view_enabled})
    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }}   
                    className='bg-white w-3 h-3 rounded-full m-1'
                    onClick={(e) => {
                        if(Object.keys(selectedMachineTypes).length > 0){
                            e.stopPropagation();
                            handleClear();
                        }
                    }}  
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedMachineTypes).length > 0? ' rotate-45 ':'rotate-0'}  h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </motion.button>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300 truncate min-w-fit'>
                    Machine type
                </div>
                {Object.keys(selectedMachineTypes).length > 0 && Object.keys(selectedMachineTypes).length < 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {selectedMachineTypes.map((filtered,index) => (
                        <div key={filtered.machine_type} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.machine_type}</div>
                        </div>
                    ))}
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                {Object.keys(selectedMachineTypes).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedMachineTypes[0].machine_type} and {Object.keys(selectedMachineTypes).length - 1} more</div>
                    </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Filter by Machine Type</div>
                        {allMachineTypes.map((machineType, index)=>(
                            <div key={machineType.machine_type} className="flex items-center my-3 ">
                                <input id={`machineType-checkbox-${index}`} type="checkbox" checked={checkedMachineTypesState[index]} onChange={() => handleChangeMachineTypesState(index)}  className="checkboxBtn"/>
                                <label htmlFor={`machineType-checkbox-${machineType.machine_type}`} className="ml-2 text-xs font-medium text-gray-600 dark:text-gray-400">{machineType.machine_type}</label>
                            </div>
                        ))}
                        

                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default DepartmentFilterBtn