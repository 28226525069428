import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Avatar } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import ChatInput from './ChatInput';
import MessagesSectionHeader from './MessagesSectionHeader';
import { formatDateToAmPm, formatDateToDayMonthYear } from '../../utils/DateFormating';
import MessageComponent from './MessageComponent';
import ContextMenu from '../../utils/ContextMenu';
import MqttService from '../../core/services/MqttService'; 
import { createMessagingMQTT } from '../../core/services/MqttMessaging';
import { toast } from 'react-hot-toast';
import { getData, putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import CircularProgress from '@mui/material/CircularProgress';
import { removeMessage, updateOrInsertMessageByDate } from '../../utils/MessageDataSorting';
import { motion } from 'framer-motion';
import '../../styles/ScrollbarStyles.css'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import _ from 'lodash';



let elementsArray = []
let prevElementsArray = []





function MessagesSection({messages, participants, userInfo, chatInfo, getChatList, mqttMessage, allEmojis, interactionsEmojis, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage, pinnedMessages, setPinnedMessages, memberObjects}) {
  const selectedOrganization = useRecoilValue(globalSelectedOrganization)  
  const [headerDate, setHeaderDate] = useState(null)
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const messagesRef = useRef(null); // Ref to the div element
    const [isVisible, setIsVisible] = useState(false);
    const timeoutRef = useRef(null); // Ref to hold the timeout ID
    const [contextMenu, setContextMenu] = useState(null);
    const [replyMessage, setReplyMessage] = useState([]);
    const [editMessage, setEditMessage] = useState([]);
    const contextMenuRef = useRef(null);
    const goToBottomButtonRef = useRef(null);
    const [isNewMessage, setIsNewMessage] = useState(false);
    const [pendingMessages, setPendingMessages] = useState({});
    const [unreadNumMessages, setUnreadNumMessages] = useState(null)
    const [lastMessageReadUntilDate, setLastMessageReadUntilDate] = useState(null)
    const [lastMessageReadUntilUuid, setLastMessageReadUntilUuid] = useState(null)
    const [loadingLastReadData, setLoadingLastReadData] = useState(false)
    const [isInitalLoad, setIsInitalLoad] = useState(true)
    const [isLoadingNewerMessages, setIsLoadingNewerMessages] = useState(false)
    const [hasMoreNewerMessages, setHasMoreNewerMessages] = useState(true)
    // const [hasMoreOlderMessages, setHasMoreOlderMessages] = useState(true)
    const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false)
    const [removeMessageObject, setRemoveMessageObject] = useState(null)
    const prevScrollHeightRef = useRef(0);
    const [isDragOver, setIsDragOver] = useState(false);
    const [droppedFiles, setDroppedFiles] = useState([])
    const [navigateSearchMessageUuid, setNavigateSearchMessageUuid] = useState('')
    const [navigatePinnedMessageUuid, setNavigatePinnedMessageUuid] = useState('')
    const processedRef = useRef(false);
    const isDark = useRecoilValue(darkModeState)
    const [isMobile, setIsMobile] = useState(false)
    // const [scrollTriggered, setScrollTriggered] = useState(false)
    const isLoadingOlderMessagesRef = useRef(false);
    const scrollTriggeredRef = useRef(false);
    const hasMoreOlderMessagesRef = useRef(true);
    const prevScrollTopRef = useRef(0); // Track the previous scroll position



    useEffect(() => {

      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
          setIsMobile(true)
          

      }else{
          setIsMobile(false)
      }

    },[])

  

    
    useEffect(() => {
      if(chatInfo){
        let count = chatInfo.unread_count ? chatInfo.unread_count : 0
        count = Number(count)
        // console.log('hatList[i]', chatInfo.chat_created_at)
        setLastMessageReadUntilUuid(chatInfo.last_read_message_uuid)
        setLastMessageReadUntilDate(chatInfo.chatroom_member_read_until_timestamp)
        setUnreadNumMessages(count)
      }

    }, [chatInfo])

    useEffect(() => {
      if(removeMessageObject){
        // console.log('remove Message', removeMessageObject.message_uuid)
        let updatedMessages = removeMessage(messages, removeMessageObject)
        setMessages({...updatedMessages})
      }

    },[removeMessageObject])

    useEffect(()=> {
      // I'm using this globalSearchMessage if state to determine if the user is searching a message from the global chat search bar, this will help me 
      // determine if the selectedChatId has been properly assigned and then I can search for that specific message
      let selectedChatId = searchParams.get('chatId')
      if(globalSearchMessage && globalSearchMessage.chat_uuid && globalSearchMessage.chat_uuid == selectedChatId){
        checkSearchMessage(globalSearchMessage.message_uuid)
      }

    }, [location, globalSearchMessage])

    const getUnreadMessages = (messages) => {
      if(!isLoadingNewerMessages && hasMoreNewerMessages){
        setIsLoadingNewerMessages(true)
        let selectedChatId = searchParams.get('chatId')
        // Convert the object to an array of entries ([key, value] pairs)
        let messageArray = Object.entries(messages);
        // Get the first entry (assuming there is at least one message)
        let [lastKey, lastMessage] = messageArray[messageArray.length - 1];
        let dateStart = new Date(lastMessage.message_created_at);
        let dateStartObject  = dateStart.toISOString()
  
        // getData(apiLink + '/v1/chats/' + selectedChatId + `/messages?messages_after_timestamp=${dateStartObject}`)
        getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/messages?messages_after_timestamp=${dateStartObject}`)
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              setIsLoadingNewerMessages(false)
              let newMessages = data.data

              setHasMoreNewerMessages(data.has_newer_messages)
              let combinedMessage = { ...messages, ...newMessages }
              setMessages((prevMessages) => {
                return { ...prevMessages, ...newMessages };
              });
              
            }else{
              
              setIsLoadingNewerMessages(false)
            }
          }else{
            setIsLoadingNewerMessages(false)
          }
        })

      }

    }




    // const handleScroll = useCallback(() => {
    //   const list = messagesRef.current;
    //     if (list && list.scrollTop < 500 && !isLoadingOlderMessages && !isMobile) {
    //       // Load more in desktop and web
    //       loadMoreMessages(messages);
    //     }else if (list && list.scrollTop < 50 && !isLoadingOlderMessages && isMobile) {
    //       // Load more in Mobile
    //       toast.success(`list.scrollTop:  ${list.scrollTop}`)
    //       toast.success(`isLoadingOlderMessages:  ${isLoadingOlderMessages}`)
    //     toast.success(`hasMoreOlderMessages:  ${hasMoreOlderMessages}`)
    //       // loadMoreMessages(messages);
    //     }
        
    //     // This is the reference for the hover button to show up if the user starts scrolling up towards older messages
    //     if (messagesRef.current && goToBottomButtonRef.current) {
    //       const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
    //       const isScrolledUp = scrollHeight - scrollTop - clientHeight > 100; // More than 100px from bottom
    //       const loadNewerMessages = scrollHeight - scrollTop - clientHeight < 5000; 
          
          
    //       if(isScrolledUp){
    //         goToBottomButtonRef.current.style.display = 'block'
    //       }else{
    //         goToBottomButtonRef.current.style.display = 'none'
    //       }

    //       if(loadNewerMessages && !isLoadingNewerMessages){
    //         getUnreadMessages(messages)
    //       }
    //     }

    //     prevScrollHeightRef.current = list?.scrollHeight || 0;

    // }, [messages]); // Add messages as a dependency

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    };

    const debounceInterval  = isMobile ? 100 : 100

    const handleScroll = debounce(() => {
      const list = messagesRef.current;
        if (!list || isLoadingOlderMessagesRef.current) return;

        const currentScrollTop = list.scrollTop;
        const isScrollingUp = currentScrollTop < prevScrollTopRef.current; // Check if the user is scrolling up
        const isCloseToTop = list.scrollTop < (isMobile ? 100 : 500);

        if (isScrollingUp && isCloseToTop && hasMoreOlderMessagesRef.current && !scrollTriggeredRef.current) {
        // if (isCloseToTop && hasMoreOlderMessages && !scrollTriggered) {
          // setScrollTriggered(true);  // Set the flag to true
          scrollTriggeredRef.current = true
          loadMoreMessages(messages);
        }

         // Store the current scroll position for the next scroll event
          prevScrollTopRef.current = currentScrollTop;
    
        // Handle "go to bottom" button visibility
        const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
        const isScrolledUp = scrollHeight - scrollTop - clientHeight > 100;
        goToBottomButtonRef.current.style.display = isScrolledUp ? 'block' : 'none';
    
        const loadNewerMessages = scrollHeight - scrollTop - clientHeight < 5000;
        if (loadNewerMessages && !isLoadingNewerMessages) {
          getUnreadMessages(messages);
        }
    
        prevScrollHeightRef.current = list?.scrollHeight || 0;
    }, debounceInterval);


    useEffect(() => {
      if (messagesRef.current) {
        messagesRef.current.addEventListener('scroll', handleScroll);
      }
  
      return () => {
        if (messagesRef.current) {
          messagesRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, [messages]); // Add dependencies to re-run effect when messages or loadMoreMessages change

    const loadMoreMessages = (updatedMessages) => {

      
      if (isLoadingOlderMessagesRef.current || !hasMoreOlderMessagesRef.current) return;
      // if (isLoadingOlderMessagesRef.current || !hasMoreOlderMessages) return;
      
      isLoadingOlderMessagesRef.current = true;
      // toast.success(`isLoadingOlderMessagesRef.current ${isLoadingOlderMessagesRef.current}`)
      setIsLoadingOlderMessages(true); // Update state for UI

      const scrollContainer = messagesRef.current;
      const oldScrollHeight = scrollContainer.scrollHeight; // Get current scroll height
      const oldScrollTop = scrollContainer.scrollTop;       // Get current scroll position

      
          // Get the first entry (assuming there is at least one message)
          const firstMessage = Object.values(updatedMessages)[0];
          const dateStartObject = new Date(firstMessage.message_created_at).toISOString();

      // Fetch more messages logic
      getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/messages?messages_before_timestamp=${dateStartObject}`)
        .then(async response => {
          if (response.ok) {
            const data = await response.json();
            if (data && data.data) {
              // toast.success(`Loading more messages`)
              hasMoreOlderMessagesRef.current = data.has_older_messages
              // setHasMoreOlderMessages(data.has_older_messages);
              // setScrollTriggered(false);  // Reset the trigger after loading completes
              scrollTriggeredRef.current = false
              
              isLoadingOlderMessagesRef.current = false;
            setIsLoadingOlderMessages(false); // Update state for UI


              setMessages((prevMessages) => ({
                ...data.data,
                ...prevMessages
              }));

              if(isMobile){
                // Adjust scroll to keep the view stable
                setTimeout(() => {
                  const newScrollHeight = scrollContainer.scrollHeight;
                  scrollContainer.scrollTop =  (newScrollHeight - oldScrollHeight) + 350;
                }, 300);
              }

             
            }
          }
        })
        .catch((error) => {
          toast.error(error.message);
          isLoadingOlderMessagesRef.current = false;
          setIsLoadingOlderMessages(false);
          // setScrollTriggered(false);  // Reset trigger in case of error
          scrollTriggeredRef.current = false
        });
    };
    
    
    
    // const loadMoreMessages = (updatedMessages) => {
    //   try{
    //     toast.success(`isLoadingOlderMessages:  ${isLoadingOlderMessages}`)
    //     toast.success(`hasMoreOlderMessages:  ${hasMoreOlderMessages}`)
    //     if(!isLoadingOlderMessages && hasMoreOlderMessages){
    //       setIsLoadingOlderMessages(true)
    //       // setisLoadingOlderMessages(true);

    //       // Store the current scroll position and height
    //       const scrollContainer = messagesRef.current;
    //       const oldScrollTop = scrollContainer.scrollTop;
    //       const oldHeight = scrollContainer.scrollHeight;

          
    //       let selectedChatId = searchParams.get('chatId')

    //       // Convert the object to an array of entries ([key, value] pairs)
    //       let messageArray = Object.entries(updatedMessages);
  
    //       // Get the first entry (assuming there is at least one message)
    //       let [firstKey, firstMessage] = messageArray[0];
    //       let dateStart = new Date(firstMessage.message_created_at);
    //       let dateStartObject  = dateStart.toISOString()
    //       // getData(apiLink + '/v1/chats/' + selectedChatId + `/messages?messages_before_timestamp=${dateStartObject}`)
    //       getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/messages?messages_before_timestamp=${dateStartObject}`)
    //       .then(async response => {
    //         if(response.ok ){
    //           const data = await response.json();
    //           if(data && data.data){
    //             setHasMoreOlderMessages(data.has_older_messages)
    //             setIsLoadingOlderMessages(false)
    //             let newMessages = data.data
    //             // let combinedMessages = {...newMessages, ...updatedMessages};
  
    //             setMessages((prevMessages) => {
    //               return { ...newMessages, ...prevMessages };
    //             });

    //              // After the state updates and component re-renders, adjust the scroll position
    //             // setTimeout(() => {
    //             //   const newHeight = scrollContainer.scrollHeight;
    //             //   const heightDifference = newHeight - oldHeight;
    //             //   scrollContainer.scrollTop = oldScrollTop + heightDifference;
    //             // }, 0);
                
    //             // setHasMoreMessages(newMessages.length > 0);
    //           }else{
    //             setIsLoadingOlderMessages(false)
    //           }
    //         }
    //       })
    //     }
        

    //   }catch(e){
    //     toast.error(e.message)
    //     setIsLoadingOlderMessages(false)
    //   }
    // };
   


  
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        setContextMenu(null);
      }
    };

    useEffect(() => {
      // Add event listener on component mount
      document.addEventListener('click', handleClickOutside);
  
      // Remove event listener on component unmount
      return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    

    // ****OLD [Might not use]****
    useEffect(() => {
        let currentSearch = location.search
            if(currentSearch){
                elementsArray = []
                setHeaderDate(null)
            }

    },[location])
    // ****OLD [Might not use]****
    
    const smoothScrollToBottom = useCallback((behavior = 'smooth') => {
      if (messagesRef.current) { 
        const scrollHeight = messagesRef.current.scrollHeight;
        const height = messagesRef.current.clientHeight;
        // const maxScrollTop = scrollHeight - height;
        // messagesRef.current.scrollTo({
        //   top: maxScrollTop > 0 ? maxScrollTop : 0,
        //   behavior: behavior
        // });


        const maxScrollTop = messagesRef.current.scrollHeight - messagesRef.current.clientHeight;
        messagesRef.current.scrollTo({
          top: maxScrollTop > 0 ? maxScrollTop : 0,
          behavior: behavior
        });
      }
    }, []);

    const instantScrollToBottom = useCallback((behavior = 'instant') => {
      if (messagesRef.current) { 
        const scrollHeight = messagesRef.current.scrollHeight;
        const height = messagesRef.current.clientHeight;
        // const maxScrollTop = scrollHeight - height;
        // messagesRef.current.scrollTo({
        //   top: maxScrollTop > 0 ? maxScrollTop : 0,
        //   behavior: behavior
        // });


        const maxScrollTop = messagesRef.current.scrollHeight - messagesRef.current.clientHeight;
        // console.log('maxScrollTop', maxScrollTop)
        messagesRef.current.scrollTo({
          top: maxScrollTop > 0 ? maxScrollTop : 0,
          behavior: behavior
        });
      }
    }, []);

    useEffect(() => {
      
      if(mqttMessage){
        let selectedChatId = searchParams.get('chatId')
        // Check to make sure new message is in proper chat window
        if(mqttMessage && mqttMessage.message && (selectedChatId === mqttMessage.message.chat_uuid)){
          // check to make sure sender is the one who sent the message, if it is then scroll down
          if(mqttMessage.message.user_username == userInfo.user_username){
              // Scroll to the bottom initially
              if(mqttMessage.action != 'update_emoji_interaction'){
                // const scrollToBottom = () => {
                //     messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
                // };
                  // Attempt to scroll immediately
                  smoothScrollToBottom();
                  
          
                  // And then try again after a short delay
                  const timeoutId1 = setTimeout(() => {
                    smoothScrollToBottom()
                  }, 50);
                  const timeoutId2 = setTimeout(smoothScrollToBottom, 300);
          
                  return () => {
                    clearTimeout(timeoutId1);
                    clearTimeout(timeoutId2);
                  };
              }
            }else{
              // This is for receivers, depending on where they are in their screen they will be automatically scrolled down or just show the new messages
              if(mqttMessage.action != 'update_emoji_interaction'){

                if (isNearBottom()) {
                  
                  smoothScrollToBottom();
                  // And then try again after a short delay
                  const timeoutId1 = setTimeout(() => {
                    smoothScrollToBottom()
                  }, 50);
                  const timeoutId2 = setTimeout(smoothScrollToBottom, 300);
          
                  return () => {
                    clearTimeout(timeoutId1);
                    clearTimeout(timeoutId2);
                  };

                } else {
                  // setShowScrollButton(true);
                  // I need to add to the count of unread messages to make sure its updated properly for all the pending messages
                  let count  = Number(unreadNumMessages)
                  count += 1
                  setUnreadNumMessages(count)
                  let chatDic = chatUnreadDic
                  if(selectedChatId && chatDic[selectedChatId]){
                    chatDic[selectedChatId].unread_count = Number(count)
                    setChatUnreadDic({...chatDic})
                  }
                  let updatedPendingMessages = updateOrInsertMessageByDate(pendingMessages, mqttMessage.message)

                  setPendingMessages({...updatedPendingMessages});
                }
              }
            }
          }
      }
    }, [mqttMessage]); 


    

    // Function to handle intersection change
    const handleIntersection = (inView, entry) => {

        const messageDate = entry.target.getAttribute('data-date');
        if (inView) {
          elementsArray.push(messageDate)
          // console.log('elementsArray THis will give the all the dates in the View of the DOM', elementsArray)
        }else {
            const newArray = elementsArray.filter(element => element !== messageDate);
            elementsArray = newArray
        }
        let oldDate = getOldestDate(elementsArray)
        setHeaderDate(oldDate);

        if(isInitalLoad){
          // console.log('initial load')
          setTimeout(() => {
            getNewestDateReadUntil(elementsArray)
            setIsInitalLoad(false)
          }, 2000);
        }else{
          getNewestDateReadUntil(elementsArray)
        }
        
    };

    function getOldestDate(dates) {
        // Check if the array is empty or contains non-date objects
        if (elementsArray.length === 0 ) {
          return null; // Return null for invalid input
        }
      
        // Initialize oldestDate with the first element (assuming it's a date)
        let oldestDate = dates[0];
      
        // Loop through the remaining dates
        for (let i = 1; i < dates.length; i++) {
          const currentDate = dates[i];
          const currentDateMilliseconds = new Date(dates[i]); // Parse the first string
            const oldestDateMilliseconds = new Date(oldestDate); // Parse the second string
          if (currentDateMilliseconds < oldestDateMilliseconds) { // Compare timestamps using < operator
            oldestDate = currentDate;
          }
        }
        
        return oldestDate;
    }

    

    function getNewestDateReadUntil(dates) {
      if (elementsArray.length === 0 ) {
        return null; // Return null for invalid input
      }
        let selectedChatId = searchParams.get('chatId')
        const sortedDateStrings = dates.sort((a, b) => new Date(b) - new Date(a));
        const newestDateString = sortedDateStrings[0];
        
        const newDate = new Date(newestDateString);
        const readUntilDate = new Date(lastMessageReadUntilDate);
        try{
          // I am stringafying these to make sure I don't continuuosly call the put function
          if(JSON.stringify(dates) != JSON.stringify(prevElementsArray) && (readUntilDate <= newDate)){
            prevElementsArray = dates
            let newReadUntilISO  = newDate.toISOString()
            if(!loadingLastReadData){
              setLoadingLastReadData(true)
              putData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/read_until`,
                { 'read_until_timestamp': newReadUntilISO})
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  // console.log('PUT for new last read', data)
                  if(data && data.data){
                    setLoadingLastReadData(false)
                    let count = data.data.unread_count ? data.data.unread_count : 0
                    count = Number(count)
                    
                    setUnreadNumMessages(count)
                    setLastMessageReadUntilDate(data.data.chatroom_member_read_until_timestamp)
                    setLastMessageReadUntilUuid(data.data.last_read_message_uuid)

                    
                    let chatDic = chatUnreadDic
                    // console.log('selectedChatId', selectedChatId)
                    // console.log('chatDic[selectedChatId]', chatDic[selectedChatId])
                    // console.log('count', count)
                    if(selectedChatId && chatDic[selectedChatId]){
                      chatDic[selectedChatId].unread_count = Number(count)
                      // console.log('new chat Dic', chatDic)
                      setChatUnreadDic({...chatDic})
                    }
                
    
                    
                  }
                }else{
                  setLoadingLastReadData(false)
                }
              })
            }

          }

      }catch(e){
          setLoadingLastReadData(false)
      }
        
    }


      
    
      useEffect(() => {
        const handleScroll = () => {
            clearTimeout(timeoutRef.current);
            setIsVisible(true);
            timeoutRef.current = setTimeout(() => setIsVisible(false), 3000);
        };

          const targetElement = document.getElementById('scrollable-element');

            // Add scroll event listener when component mounts
            targetElement.addEventListener('wheel', handleScroll);

        // Remove scroll event listener when component unmounts
        return () => {
            targetElement.removeEventListener('wheel', handleScroll);
        };
      }, []);


      
      const handleRightClick = (event, message, message_username, user_username) => {
        event.preventDefault();
        // console.log('message', message)
        if(contextMenu){
          setContextMenu(null);
        }else{
          setContextMenu({
            x: event.clientX,
            y: event.clientY,
            message,
            message_username,
            user_username
          });
        }

      };

     



      const handleReply = () => {
        // console.log('Replying to:', contextMenu.message);
        setEditMessage([])
        setReplyMessage(contextMenu.message)
        // Implement the reply functionality
        setContextMenu(null);

      };

      const handleMobileReply = (msg) => {
        // console.log('Replying to:', contextMenu.message);
        setEditMessage([])
        setReplyMessage(msg)
        // Implement the reply functionality
        setContextMenu(null);

      };

      const handleEdit = () => {
        // console.log('Replying to:', contextMenu.message);
        setReplyMessage([])
        setEditMessage(contextMenu.message)
        // Implement the reply functionality
        setContextMenu(null);

      };

      const handleMobileEdit = (msg) => {
        // console.log('Replying to:', contextMenu.message);
        setReplyMessage([])
        setEditMessage(msg)
        // Implement the reply functionality
        setContextMenu(null);

      };


      const handleAddEmoji = (emoji) => {
        // console.log('Adding emoji to:', contextMenu.message);
        // Implement the add emoji functionality
        // console.log('emoji', emoji)

        let replyMessageObject = contextMenu.message
        if (MqttService.isConnected) {

          let msg = {
              from_username: userInfo.user_username,
              action: 'update_emoji_interaction',
              message_uuid: replyMessageObject.message_uuid,
              emoji_codepoints: emoji.emoji_codepoints
          }
          
          msg = createMessagingMQTT(msg);

          if(msg.topic && msg.payload){
              MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
          }
          setContextMenu(null);
        }else{
         toast.error('Trying to connect')
        }
      }

      const handleCloseContextMenu = () => {
        // console.log('CLOSE MENUY')
        setContextMenu(null);
      };

      
      // Check to see if user is near the bottomr of the screen
      const isNearBottom = () => {
        if (messagesRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
          return scrollHeight - scrollTop - clientHeight < 100; // within 100px of bottom
        }
        return false;
      };

    

      const goToBottomButton = () => {
        setNavigateSearchMessageUuid('')

        let selectedChatId = searchParams.get('chatId')
        // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages?latest_messages=true')
        getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + '/messages?latest_messages=true')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                setPendingMessages({});
                setMessages(data.data)
                instantScrollToBottom()
                setIsInitalLoad(true)
                setTimeout(() => {
                  instantScrollToBottom()
                }, 100);
                let chatDic = chatUnreadDic
                  if(selectedChatId && chatDic[selectedChatId]){
                    chatDic[selectedChatId].unread_count = 0
                    setChatUnreadDic({...chatDic})
                  }
              }
            }
          })

      }

      const checkSearchMessage = (messageUuid) => {
        const exists = messageUuid in messages;
        if(exists){
          setNavigateSearchMessageUuid(messageUuid)
          // setGlobalSearchMessage([]) //This will only be clearned if there was a global search which won't always be the case
        }else{
          // I had some issues with setNavigateSearchMessageUuid inside the getData that it was erasing the state so I put it on the outside 
          setNavigateSearchMessageUuid(messageUuid)
          let selectedChatId = searchParams.get('chatId')
          // getData(apiLink + '/v1/chats/' + selectedChatId + `/messages?messages_around_message_uuid=${messageUuid}`)
          getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/messages?messages_around_message_uuid=${messageUuid}`)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setMessages(data.data)
                  // setNavigateSearchMessageUuid(messageUuid)
                  // setNavigateSearchMessageUuid(messageUuid)
                  // setTimeout(()=>{
                  //   console.log('setTimeout messageUuid', messageUuid)
                  //   setNavigateSearchMessageUuid(messageUuid)
                  // },100)
                  // setGlobalSearchMessage([]) //This will only be clearned if there was a global search which won't always be the case
                }
              }
            })

        }
      }

      const checkPinnedMessage = (messageUuid) => {
        const exists = messageUuid in messages;
        if(exists){
          setNavigatePinnedMessageUuid(messageUuid)
          // setGlobalSearchMessage([]) //This will only be clearned if there was a global search which won't always be the case
        }else{
          
          let selectedChatId = searchParams.get('chatId')
          getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/messages?messages_around_message_uuid=${messageUuid}`)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setMessages(data.data)
                  setTimeout(()=>{
                    setNavigateSearchMessageUuid(messageUuid)
                  },100)
                  // setGlobalSearchMessage([]) //This will only be clearned if there was a global search which won't always be the case
                }
              }
            })

        }
      }

      
      const handleFileDrop = (files) => {
        setDroppedFiles(files)
        // setMessages([...messages, { type: 'file', file }]);
        // Here, you can add the file to your messaging system or send it to the server
      };
    
      const handleDragOver = (event) => {
        event.preventDefault();
        // console.log('handleDragOver event', event)
        setIsDragOver(true);
      };
    
      const handleDragLeave = () => {
        setIsDragOver(false);
      };
    

  

      
  return (
    <div 
      className='relative' 
      style={{overflowAnchor: 'auto'}} 
    >
      <div 
        className='mt-2' 
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={(event) => {
          event.preventDefault();
          setIsDragOver(false);
          handleFileDrop(event.dataTransfer.files);
        }}
      >
        <div className={`fixed flex items-center justify-center inset-0 w-full h-full  z-[999] transition-all duration-500 ease-in-out `}
          style={{
            border: isDragOver ? '2px dashed #cbd5e1' : '0px solid #ccc',
            visibility: isDragOver? 'visible' : 'hidden',
            transition: 'opacity 0.5s ease, visibility 0.5s ease',
            opacity: isDragOver? 1 : 0,
            backgroundColor: isDragOver ? 'rgba(2, 6, 23,0.8)' : '',
          }}
          >
          <div className='text-xl text-center my-auto text-gray-500 dark:text-gray-500'>
            Drop files here to send them
          </div>
        </div>

      <motion.button
        whileHover={{ scale:1.05 }} 
        whileTap={{ scale: 0.95  }}  
        ref={goToBottomButtonRef} 
        onClick={goToBottomButton} 
        className='transition ease-in-out duration-300 absolute hidden  right-7 bottom-5 bg-slate-200 text-gray-600 dark:bg-slate-700 dark:text-gray-300 rounded-full p-1 z-[50]'
      >
        <div className='relative'>
          {(unreadNumMessages  &&  unreadNumMessages > 0) ?
            (
            <div className='absolute top-[-15px] inset-x-0 flex justify-center item-center'>
              <span className='bg-blue-500 text-white text-xs mx-auto my-auto min-w-[24px]  rounded-full p-1'>
              {unreadNumMessages}
              </span>
            </div>
            ):(
            <div/>
          )
          }
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>

        </div>
      </motion.button>
        <MessagesSectionHeader chatInfo={chatInfo} participants={participants} userInfo={userInfo} getChatList={getChatList} checkSearchMessage={checkSearchMessage} checkPinnedMessage={checkPinnedMessage} pinnedMessages={pinnedMessages} setPinnedMessages={setPinnedMessages} memberObjects={memberObjects} />
          <div ref={messagesRef} id="scrollable-element" className={`overflow-x-hidden overflow-y-scroll message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} `} style={{overflowAnchor: 'auto', height: isMobile ? 'calc(100vh - 100px)':'calc(100vh - 200px)'}} >
            {scrollTriggeredRef.current && 
                <div className='py-5' style={{overflowAnchor: 'none'}} >
                    <div className='mt-2 flex justify-center p-4'>
                        <div className="flex items-center justify-center font-medium text-gray-600 dark:text-gray-300 text-light">
                            Loading more messages
                            <span className="dot1 animate-bounce ml-0.5" style={{ animationDelay: '0s' }}>.</span>
                            <span className="dot2 animate-bounce ml-0.5" style={{ animationDelay: '0.2s' }}>.</span>
                            <span className="dot3 animate-bounce ml-0.5" style={{ animationDelay: '0.4s' }}>.</span>
                        </div>
                    </div>
                </div>
            }
              <div 
                  className={`ease-in-out duration-300  ${isVisible && headerDate ? 'opacity-100':'opacity-0 '} absolute top-16 inset-x-0 text-gray-300 w-full justify-center z-[30]`}
                  style={{overflowAnchor: 'none'}} 
              >
                  <div className='px-4 py-1 text-sm bg-white dark:bg-slate-900/95 w-fit mx-auto rounded-full text-gray-500 dark:text-gray-400'>
                  {formatDateToDayMonthYear(headerDate)}
                  </div>
              </div>
              
                
                  <div onClick={handleCloseContextMenu}>
                    {messages && Object.keys(messages).length > 0 &&
                      Object.keys(messages).map((message, index) => (
                        <MessageComponent 
                            key={`${messages[message].message_uuid}`}
                            messages={messages} 
                            message={messages[message]} 
                            participants={participants} 
                            index={index} 
                            onIntersectionChange={handleIntersection}
                            isGroup={!chatInfo.chat_is_direct}
                            chatInfo={chatInfo}
                            userInfo={userInfo}
                            onRightClick={handleRightClick}
                            contextMenu={contextMenu}
                            mqttMessage={mqttMessage}
                            setMessages={setMessages}
                            lastMessageReadUntilDate={lastMessageReadUntilDate}
                            lastMessageReadUntilUuid={lastMessageReadUntilUuid}
                            messagesRef={messagesRef}
                            goToBottomButtonRef={goToBottomButtonRef}
                            navigateSearchMessageUuid={navigateSearchMessageUuid}
                            setNavigateSearchMessageUuid={setNavigateSearchMessageUuid}
                            navigatePinnedMessageUuid={navigatePinnedMessageUuid}
                            setNavigatePinnedMessageUuid={setNavigatePinnedMessageUuid}
                            setGlobalSearchMessage={setGlobalSearchMessage}
                            processedRef={processedRef}
                            interactionsEmojis={interactionsEmojis}
                            onReply={handleMobileReply}
                            onEdit={handleMobileEdit}
                            allEmojis={allEmojis}
                            pinnedMessages={pinnedMessages}
                            setPinnedMessages={setPinnedMessages}
                        />
                      ))
                    }
                  </div>
                {contextMenu && (
                  <div ref={contextMenuRef}>
                    <ContextMenu
                      contextMenu={contextMenu}
                      setContextMenu={setContextMenu}
                      x={contextMenu.x}
                      y={contextMenu.y}
                      onReply={handleReply}
                      onEdit={handleEdit}
                      onAddEmoji={handleAddEmoji}
                      interactionsEmojis={interactionsEmojis}
                      allEmojis={allEmojis}
                      userInfo={userInfo}
                      setMessages={setMessages}
                      messages={messages}
                      pinnedMessages={pinnedMessages}
                      setPinnedMessages={setPinnedMessages}
                    />
                  </div>
                )}
              </div>
              
          
              {chatInfo && chatInfo.chat_uuid && userInfo && Object.keys(userInfo).length > 0 &&
                  <div className='fixed bottom-0 ' style={{ width: `calc(100vw - ${leftColumnWidth}px)` }} >
                      <ChatInput 
                        chatInfo={chatInfo} 
                        userInfo={userInfo} 
                        messagesRef={messagesRef} 
                        goToBottomButtonRef={goToBottomButtonRef} 
                        replyMessage={replyMessage} 
                        setReplyMessage={setReplyMessage} 
                        editMessage={editMessage} 
                        setEditMessage={setEditMessage} 
                        allEmojis={allEmojis} 
                        messages={messages} 
                        setMessages={setMessages}
                        setRemoveMessageObject={setRemoveMessageObject}
                        droppedFiles={droppedFiles}
                        setDroppedFiles={setDroppedFiles}
                      />
                  </div>
              }
      </div>
    </div>
  )
}

export default MessagesSection