import React, { useEffect, useRef, useState } from 'react'
import ChatList from './chatComponents/ChatList'
import ChatDetails from './chatComponents/ChatDetails'
import { motion, AnimatePresence } from "framer-motion";
import { getData } from '../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { apiLink } from '../ApiCalls/ApisVariables';
import MqttService from '../core/services/MqttService'; 
import {Resizable} from 'react-resizable';
import '../styles/react-resizable.css'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useViewportFix from '../utils/useViewportFix';



function ChatsPage({mqttMessage, userInfo}) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [chatList, setChatList] = useState([])
  const [chatMqttMessage, setChatMqttMessage] = useState([])
  const [interactionsEmojis, setInteractionsEmojis] = useState([])
  const [allEmojis, setAllEmojis] = useState([])
  const [chatUnreadDic, setChatUnreadDic] = useState({})
  const [globalSearchMessage, setGlobalSearchMessage] = useState([])
  const [membersMqttMessage, setMembersMqttMessage] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const [currentChat, setCurrentChat] = useState('')
  const location = useLocation();
  const [leftColumnWidth, setLeftColumnWidth] = useState(300);
  const minWidth = 300;
  const maxWidth = 500;

  useViewportFix();

  const handleResize = (event, { size }) => {
    const newWidth = Math.max(minWidth, Math.min(maxWidth, size.width));
    setLeftColumnWidth(newWidth);
  };

  useEffect(() => {

      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
          setIsMobile(true)
          setLeftColumnWidth(0)
      }else{
          setIsMobile(false)
      }

  },[])

  useEffect(() => {

    let selectedChatId = searchParams.get('chatId')
    let current_sub_section = searchParams.get('sub_section')
    if(selectedChatId){
      setCurrentChat(selectedChatId)
    }else if(current_sub_section){
      setCurrentChat(current_sub_section)
    }else{
      setCurrentChat('')
    }

  },[location])

  

  

  useEffect(()=>{
      if(selectedOrganization){
  
        getChatList()

        getData(apiLink + '/v1/interaction_default_emojis')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                setInteractionsEmojis(data.data)
              }
            }
          })

        getData(apiLink + '/v1/emojis')
          .then(async response => {
            const data = await response.json();
            if(response.ok ){
              if(data && data.data){
                setAllEmojis(data.data)
              }
            }
          })
        
      }
    

  }, [selectedOrganization, MqttService, MqttService.isConnected])



const getChatList = () => {
    if(selectedOrganization){

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/chats')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              setChatList(data.data)
              let dic = {}
              data.data.map(chat => {
                dic[chat.chat_uuid] = {'unread_count' : chat.unread_count}
              })
              // console.log('dic', dic)
              setChatUnreadDic(dic)
            }
          }
        })
      }

}


// I'm using this useEffect to see what mqtt messages are coming in and update the chatList depengin on the message
useEffect(() => {
  if(mqttMessage && chatList && Object.keys(chatList).length > 0){
    // console.log('mqttMessage', mqttMessage)
    if(mqttMessage.chatroom_type === "chat"){
      if(mqttMessage.action === "update_members"){
        setMembersMqttMessage(mqttMessage)
        const chatUuid = mqttMessage.chat_uuid
        if(chatUuid){
          let list = chatList
          const targetIndex = chatList.findIndex(
            (chat) => chat.chat_uuid === chatUuid
          ); // Find index of the target object
          if (targetIndex !== -1) { // Check if target exists
            const targetObject = list.splice(targetIndex, 1)[0]; // Remove target object
            // console.log('targetIndex', targetIndex)
            // console.log('targetObject', targetObject) 
            targetObject.members = mqttMessage.members
            list.unshift(targetObject); // Prepend target object to the beginning
            // console.log('list', list)
            setChatList([...list])
          }else{ // Chat doesn't exist so refresh entire list ot make sure it is showing up for the user
            getChatList()
          }
        }

      }else if(mqttMessage.action === "delete_member"){
        setMembersMqttMessage(mqttMessage)
        if(mqttMessage.chatroom_member_to_delete_username == userInfo.user_username){
          let selectedChatId = searchParams.get('chatId')
          if(selectedChatId == mqttMessage.chat_uuid){
            if(selectedOrganization){
              navigate(`/${selectedOrganization}/chats`)
            }
          }
          const chatUuid = mqttMessage.chat_uuid
          if(chatUuid){
            let list = chatList
            const targetIndex = chatList.findIndex(
              (chat) => chat.chat_uuid === chatUuid
            ); // Find index of the target object
            if (targetIndex !== -1) { // Check if target exists
              const targetObject = list.splice(targetIndex, 1)[0]; // Remove target object
  
              // console.log('list', list)
              setChatList([...list])
            }else{ // Chat doesn't exist so refresh entire list ot make sure it is showing up for the user
              getChatList()
            }
          }
        }

      }else{
        setChatMqttMessage(mqttMessage)
        getChatList()
      }
    }
    
  }

}, [mqttMessage])

  

  return (
    <div>
      {isMobile ? (
      <div className="h-screen w-full bg-white dark:bg-slate-900 overflow-hidden relative">
          {/* Chat List */}
          <div
            className={`absolute inset-0 bg-white dark:bg-slate-900 transition-transform duration-300 ease-in-out ${
              currentChat ? '-translate-x-full' : 'translate-x-0'
            }`}
          >
            <ChatList chatList={chatList} userInfo={userInfo} chatUnreadDic={chatUnreadDic} setGlobalSearchMessage={setGlobalSearchMessage} />
          </div>
    
          {/* Messages Section */}
          <div className="h-full bg-white dark:bg-slate-900">
            {currentChat ? (
              <AnimatePresence mode='wait'>
                <motion.div
                  key='ChatDetailsSection'
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}

                >
                  <ChatDetails 
                    chatList={chatList}     getChatList={getChatList}               userInfo={userInfo}           mqttMessage={chatMqttMessage}       membersMqttMessage={membersMqttMessage}
                    allEmojis={allEmojis}   interactionsEmojis={interactionsEmojis} chatUnreadDic={chatUnreadDic} setChatUnreadDic={setChatUnreadDic} 
                    leftColumnWidth={0}   globalSearchMessage={globalSearchMessage}    setGlobalSearchMessage={setGlobalSearchMessage}
                  />
                </motion.div>
            </AnimatePresence>
            ) : (
              <div />
            )}
          </div>
        </div>
      ):(
        <div className='h-screen' style={{display: 'grid', gridTemplateColumns: `${leftColumnWidth}px auto`}} >
            <Resizable
              width={leftColumnWidth}
              height={200} // I could add 100% since its not a number, put 200 to see what happens
              // minWidth={minWidth}
              // maxWidth={maxWidth}
              // axis='x'
              onResize={handleResize}
            >
              <div >
                <ChatList chatList={chatList} userInfo={userInfo} chatUnreadDic={chatUnreadDic} setGlobalSearchMessage={setGlobalSearchMessage} />
              </div>

            </Resizable>
              <AnimatePresence mode='wait'>
                  <motion.div
                    key='ChatDetailsSection'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}

                  >
                    <ChatDetails 
                      chatList={chatList}     getChatList={getChatList}               userInfo={userInfo}           mqttMessage={chatMqttMessage}       membersMqttMessage={membersMqttMessage}
                      allEmojis={allEmojis}   interactionsEmojis={interactionsEmojis} chatUnreadDic={chatUnreadDic} setChatUnreadDic={setChatUnreadDic} 
                      leftColumnWidth={leftColumnWidth}   globalSearchMessage={globalSearchMessage}    setGlobalSearchMessage={setGlobalSearchMessage}
                    />
                  </motion.div>
              </AnimatePresence>
        </div>
      )}

    </div>
  )
}

export default ChatsPage