
import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';
import { Calendar } from 'react-date-range';
import { motion } from "framer-motion";
import { getDay } from 'date-fns';
import { tzStrings } from '../../data/TimezonesList';
import { globalSelectedOrganizationTimezone } from '../../atoms/orgStates';

const CustomRecurrenceSelectors = ({ initialDate, setRecurrenceFrequency, setRecurrenceInterval, setRecurrenceDaysOfWeek, setRecurrenceMonthOrder, setRecurrenceEndDate, setRecurrenceEndTime, selectedTimezoneOption, setSelectedTimezoneOption, customTimezone, setCustomTimezone, localTimezone }) => {
  const isDark = useRecoilValue(darkModeState);
  const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
  const [recurrenceType, setRecurrenceType] = useState('weekly');
  const [selectedDate, setSelectedDate] = useState(initialDate ? new Date(initialDate) : new Date());
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });
  const [weeklyFrequency, setWeeklyFrequency] = useState(1);
  const [monthlyDay, setMonthlyDay] = useState(1);
  const [monthlyFrequency, setMonthlyFrequency] = useState(1);
  const [yearlyFrequency, setYearlyFrequency] = useState(1);
  const [warningMessage, setWarningMessage] = useState('');

  // Recurrence End Options
  const [recurrenceEndType, setRecurrenceEndType] = useState('never');
//   const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);
  const [recurrenceOccurrences, setRecurrenceOccurrences] = useState(1);

  const endDateDropdownRef = useRef(null);
const [showEndDateCalendar, setShowEndDateCalendar] = useState(false)
const [endDate, setEndDate] = useState(null)
const [endDateTime, setEndDateTime] = useState('9:00 AM')

  // Days of week mapping
  const daysMap = {
    0: 'sunday',
    1: 'monday', 
    2: 'tuesday', 
    3: 'wednesday', 
    4: 'thursday', 
    5: 'friday', 
    6: 'saturday'
  };


  useEffect(() => {
    // Setting initial startes for Parent Modal
    setRecurrenceFrequency('WEEKLY')
    setRecurrenceInterval(1)
  
    const recurrenceDay = getDay(initialDate);
    setRecurrenceDaysOfWeek(recurrenceDay)
    setRecurrenceEndTime('9:00 AM')

  }, [])

  // Effect to auto-select based on initial date
  useEffect(() => {
    if (initialDate) {
      const date = initialDate;
      const dayOfWeek = daysMap[date.getDay()];
      
      // Auto-select for weekly recurrence
    //   setSelectedDays(prev => ({
    //     ...prev,
    //     [dayOfWeek]: true
    //   }));

      setSelectedDays({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        [dayOfWeek]: true
      });

      // Set monthly day
      setMonthlyDay(date.getDate());

      const futureDate = new Date(initialDate);
        futureDate.setMonth(futureDate.getMonth() + 3);
      setEndDate(futureDate)
    }
  }, [initialDate]);

  const toggleDay = (day) => {
    if (recurrenceType === 'daily') {
      // No toggling for daily
      return;
    }
    if (recurrenceType === 'weekly') {
      // Allow multiple day selection for weekly
      setSelectedDays(prev => ({
        ...prev,
        [day]: !prev[day]
      }));
    }
  };

  useEffect(() => {
    if(recurrenceType === 'weekly'){
        console.log('selectedDays', selectedDays)
        const result = getActiveDays(selectedDays);
        console.log('result', result)
        setRecurrenceDaysOfWeek(result)
    }
  }, [selectedDays])

  const getActiveDays = (days) => {
    const dayOrder = ['saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    const activeDays = [];
  
    dayOrder.forEach((day, index) => {
      if (days[day]) {
        activeDays.push(index);
      }
    });
  
    return activeDays;
  };


  const handleMonthlyDayChange = (value) => {
    const numValue = Number(value);
    
    // Validate input
    if (numValue < 1) {
      setMonthlyDay(1);
      return;
    }
    if (numValue > 31) {
      setMonthlyDay(31);
      setWarningMessage('Maximum day is 31. Adjusted to 31.');
      return;
    }

    // Check for month-specific day limitations
    const testDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
    const lastDayOfMonth = testDate.getDate();

    if (numValue > lastDayOfMonth) {
      setMonthlyDay(lastDayOfMonth);
      setWarningMessage(`This month only has ${lastDayOfMonth} days. Event will be scheduled on the last day.`);
    } else {
      setMonthlyDay(numValue);
      setWarningMessage('');
    }
  };

  const generateRecurrenceDescription = () => {
    if (!recurrenceType) return '';

    let description = 'Repeats ';

    switch (recurrenceType) {
      case 'daily':
        description += 'every day';
        break;
      case 'weekly':
        const selectedWeekDays = Object.keys(selectedDays)
          .filter(day => selectedDays[day])
          .map(day => day.charAt(0).toUpperCase() + day.slice(1));
        
        description += `every ${weeklyFrequency > 1 ? `${weeklyFrequency} weeks` : 'week'} on ${selectedWeekDays.join(', ')}`;
        break;
      case 'monthly':
        description += `every ${monthlyFrequency} month${monthlyFrequency > 1 ? 's' : ''} on day ${monthlyDay}`;
        break;
      case 'yearly':
        description += `every ${yearlyFrequency} year${yearlyFrequency > 1 ? 's' : ''}`;
        break;
    }

    // Add recurrence end condition
    switch (recurrenceEndType) {
      case 'date':
        description += ` until ${endDate ? endDate.toLocaleDateString() : 'a specific date'}`;
        break;
      case 'occurrences':
        description += ` for ${recurrenceOccurrences} occurrence${recurrenceOccurrences > 1 ? 's' : ''}`;
        break;
      default:
        description += ' indefinitely';
    }

    return description + '.';
  };

  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];


  const handleChangeRecurrenceType = (e) => {
    const value = e.target.value;
    setRecurrenceType(value);

    setRecurrenceFrequency(e.target.value.toUpperCase())

    setWeeklyFrequency(1)
    setMonthlyFrequency(1)
    setYearlyFrequency(1)
    setRecurrenceInterval(1)
    
    // Reset days when changing recurrence type
    setSelectedDays({
    monday: value === 'daily',
    tuesday: value === 'daily',
    wednesday: value === 'daily',
    thursday: value === 'daily',
    friday: value === 'daily',
    saturday: value === 'daily',
    sunday: value === 'daily'
    });

    if(value === 'daily'){
        setRecurrenceInterval(1)
        setRecurrenceDaysOfWeek(null)
    }else if(value === 'weekly'){
        const date = selectedDate;
        const dayOfWeek = daysMap[date.getDay()];

        // console.log('date', date)
        // console.log('dayOfWeek', dayOfWeek)
        
        // Auto-select for weekly recurrence
        setSelectedDays(prev => ({
            ...prev,
            [dayOfWeek]: true
        }));

        // Set monthly day
        setMonthlyDay(date.getDate());

        const recurrenceDay = getDay(selectedDate);
        setRecurrenceDaysOfWeek(recurrenceDay)
    }else{
        setRecurrenceDaysOfWeek(null)
    }
  }

  const formatDate = (date) => {
        if(date){
            var currentDate = new Date(date)
            var shortCurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString()
            return shortCurrentDate;

        }else{
        return 'mm/dd/yyyy'
        }
    }

    const timeOptions = Array.from({ length: 48 }, (_, i) => {
        let hour = Math.floor(i / 2)
        const minute = i % 2 === 0 ? '00' : '30'
        const amPm = hour < 12 ? 'AM' : 'PM'
        
        // Convert to 12-hour format
        hour = hour % 12
        // Handle midnight and noon special cases
        hour = hour === 0 ? 12 : hour
        
        return `${hour}:${minute} ${amPm}`
      })

    const handleSelectEndDate = (selectedDate) => {
        setEndDate(selectedDate)
        setRecurrenceEndDate(selectedDate)
        setShowEndDateCalendar(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (endDateDropdownRef.current && !endDateDropdownRef.current.contains(event.target)) {
            setShowEndDateCalendar(false)
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }, [])

    const handleEndType = (e) => {
        setRecurrenceEndType(e.target.value)
        if(e.target.value === 'never'){
            setRecurrenceEndDate(null)
        }
    }

    const handleOptionChange = (e) => {
      setSelectedTimezoneOption(e.target.value);
      if (e.target.value !== "custom") {
        setCustomTimezone("");
      }
    };
  
    const handleCustomTimezoneChange = (e) => {
      setCustomTimezone(e.target.value);
    };

  return (
    <div >
      <div>
        <label className={`block text-sm font-medium mb-2 ${isDark?'text-white':'text-gray-600'}`}>
          Recurrence Frequency
        </label>
        <div className="mb-6">
          <select
            value={recurrenceType}
            onChange={handleChangeRecurrenceType}
            // className="w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            // className={`w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-normal border-[0px] rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
            className={`w-full flex justify-between px-4 py-2 text-left text-sm ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'} shadow-iotflows-lg rounded-lg focus:outline-none focus:ring-[1px] focus:ring-blue-500`}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
      </div>

      {/* Daily/Weekly Day Selection */}
      {(recurrenceType === 'daily' || recurrenceType === 'weekly') && (
        <div className="flex space-x-2 justify-center mb-6">
          {daysOfWeek.map(day => (
            <button
              key={day}
              onClick={() => toggleDay(day)}
              className={`
                p-2 rounded-full text-sm w-12 h-12
                ${selectedDays[day] 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-200 text-gray-700'}
                transition-colors duration-200
              `}
            >
              {day.slice(0, 1).toUpperCase() + day.slice(1, 3).toLowerCase()}
            </button>
          ))}
        </div>
      )}

      {/* Weekly Frequency */}
      {recurrenceType === 'weekly' && (
        <div className="flex items-baseline">
          <label className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} mr-1`}>
            Repeat every
          </label>
          <input 
            type="number" 
            value={weeklyFrequency}
            onChange={(e) => {
                setWeeklyFrequency(Number(e.target.value))
                setRecurrenceInterval(Number(e.target.value))
            }}
            min="1"
            className={`bg-transparent w-[50px] border-b-[2px] border-t-0 border-r-0 border-l-0  p-0 text-center shadow-sm focus:outline-none focus:bg-transparent focus:ring-0 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
          />
          <span className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} ml-1`}>week{weeklyFrequency >=2 &&'s'}</span>
        </div>
      )}

      {/* Monthly Selection */}
      {recurrenceType === 'monthly' && (
        <div className="flex items-baseline">
            <label className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} mr-1`}>
              Repeat every
            </label>
            <input 
              type="number" 
              value={monthlyFrequency}
              onChange={(e) => {
                setMonthlyFrequency(Number(e.target.value))
                setRecurrenceInterval(Number(e.target.value))
            }}
              min="1"
              className={`bg-transparent w-[50px] border-b-[2px] border-t-0 border-r-0 border-l-0  p-0 text-center shadow-sm focus:outline-none focus:bg-transparent focus:ring-0 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
            />
            <span className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} ml-1`}>month{monthlyFrequency >=2 &&'s'} on day {monthlyDay}</span>
            {/* <input 
              type="number" 
              value={monthlyDay}
              onChange={(e) => handleMonthlyDayChange(e.target.value)}
              min="1"
              max="31"
              className={`bg-transparent w-[50px] border-b-[2px] border-t-0 border-r-0 border-l-0  p-0 text-center shadow-sm focus:outline-none focus:bg-transparent focus:ring-0 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
            /> */}
        </div>
      )}

      {/* Yearly Selection */}
      {recurrenceType === 'yearly' && (
        <div className="flex">
          <label className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} mr-1`}>
            Repeat every
          </label>
          <input 
            type="number" 
            value={yearlyFrequency}
            onChange={(e) => {
                setYearlyFrequency(Number(e.target.value))
                setRecurrenceInterval(Number(e.target.value))
            }}
            min="1"
            className={`bg-transparent w-[50px] border-b-[2px] border-t-0 border-r-0 border-l-0  p-0 text-center shadow-sm focus:outline-none focus:bg-transparent focus:ring-0 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
          />
          <span className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} ml-1`}>year{yearlyFrequency >=2 &&'s'}</span>
        </div>
      )}

      {/* Recurrence End Options */}
      <div className="space-y-4 mt-10">
        <label className={`block text-sm font-medium ${isDark?'text-white':'text-gray-600'}`}>
          End Recurrence
        </label>
        <select
          value={recurrenceEndType}
          onChange={handleEndType}
        //   className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        className={`w-full flex justify-between px-4 py-2 text-left text-sm ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'} shadow-iotflows-lg rounded-lg focus:outline-none focus:ring-[1px] focus:ring-blue-500`}
        >
          <option value="never">Never</option>
          <option value="date">On a Specific Date</option>
          <option value="occurrences">After Number of Occurrences</option>
        </select>

        {recurrenceEndType === 'date' && (
          <div className="relative" >
            <div className='flex items-center'>
                <motion.button  
                // whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                    className={`w-44 items-center flex justify-between pl-3 pr-2 py-1.5 text-sm font-normal ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'}  bg-clip-padding bg-no-repeat  rounded shadow-iotflows-lg`} 
                    onClick={() => setShowEndDateCalendar(!showEndDateCalendar)}
                >
                    <span className='text-sm tracking-wider'>{formatDate(endDate)}</span>
                </motion.button>
                {endDate && (
                <div className='flex'>
                    <motion.div
                    key='selectorDueTimeContainer' 
                    className={`flex ml-2`}
                    >
                    <select
                        value={endDateTime}
                        onChange={(e) => {
                            setEndDateTime(e.target.value)
                            setRecurrenceEndTime(e.target.value)
                        }}
                        className={`pr-10 py-1.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                    >
                        {timeOptions.map(t => (
                        <option key={t} value={t}>{t}</option>
                        ))}
                    </select>
                    </motion.div>
                    <motion.button  
                        whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                        className={`ml-2 ${isDark?'text-gray-400':'text-gray-600'} `} 
                        onClick={(e) => {
                        setEndDate(null)
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </motion.button>
                </div>
                )}
            </div>
            <div className="pt-2 relative" >
                <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} >
                    Time Zone
                </label>
                <div className='flex space-x-2 items-center'>
                    <div >
                    <select
                        className={`pr-10 py-1.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                        value={selectedTimezoneOption}
                        onChange={handleOptionChange}
                    >
                        <option value="local">Local Timezone</option>
                        <option value="organization">Organization Timezone</option>
                        <option value="custom">Custom Timezone</option>
                    </select>
                    </div>
                    <div className={`${isDark ? 'text-gray-300 ' : 'text-gray-600'} text-sm items-center`}>
                    {selectedTimezoneOption === 'local' && (
                        <span>{localTimezone?.label}</span>
                    )}
                    {selectedTimezoneOption === 'organization' && (
                        <span>{selectedOrganizationTimeZone?.full_name}</span>
                    )}
                    {selectedTimezoneOption === 'custom' && (
                        <div className="outline-none">
                            <select onChange={handleCustomTimezoneChange} value={customTimezone}  aria-label="timezone-select" 
                            className={`pr-2 py-1.5 text-sm font-normal border-[0px] rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                            >
                                <option disabled value=''> -- select a timezone -- </option>
                                {tzStrings.map((tz, index) => (
                                    <option key={`${tz.label}`} value={tz.value}>{tz.label}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    </div>
                </div>
            </div>
            {showEndDateCalendar && (
                <div ref={endDateDropdownRef} className={`absolute z-20 left-0 bottom-10 flex flex-col bg-slate-700 rounded-xl max-h-[450px] overflow-y-scroll md:max-h-[600px] `}>
                    <div className='customWrapper'>
                        <Calendar
                            date={endDate}
                            onChange={handleSelectEndDate}
                            color="#3182ce"  // Customizable color
                            minDate={selectedDate}
                        />
                    </div>
                </div>
            )}
          </div>
        )}

        {recurrenceEndType === 'occurrences' && (
            <div className='flex space-x-2 items-baseline'>
                <input
                  type="number"
                  value={recurrenceOccurrences}
                  onChange={(e) => setRecurrenceOccurrences(Number(e.target.value))}
                  min="1"
                  className={` block bg-transparent w-[50px] border-b-[2px] border-t-0 border-r-0 border-l-0  p-0 text-center shadow-sm focus:outline-none focus:bg-transparent focus:ring-0 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                />
                <span className={`text-sm ${isDark?'text-gray-300':'text-gray-600'} ml-1`}>
                    {recurrenceOccurrences >= 2 ? 'occurrences':'occurrence'}
                </span>
            </div>
        )}
      </div>

      {/* Recurrence Description */}
      {recurrenceType && (
        <div className={`mt-8 italic pl-1 text-sm ${isDark?'text-gray-300':'text-gray-600'}`}>
          {generateRecurrenceDescription()}
        </div>
      )}
      {warningMessage && (
        <div className={`mt-8 italic pl-1 text-sm ${isDark?'text-red-500':'text-red-600'}`}>
          {warningMessage}
        </div>
      )}
    </div>

  );
};

export default CustomRecurrenceSelectors;